import { _postV1, _getV1 } from './config/index'

export async function getUserInterestsEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getUserInterestsOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getUserInterestsEnum:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getCountryOptions(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getCountryOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getCountryOptions:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getNationalitiesEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getNationalityOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getNationalitiesEnum:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getGendersEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getGenderOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getGendersEnum:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getReligionsEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getReligionOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getReligionsEnum:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getPoliticalBeliefsEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getPoliticalBeliefOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getPoliticalBeliefsEnum:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getBodyTypesEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getBodyTypeOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getBodyTypeOptions:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getUserQuestionsEnum(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getUserQuestionOptions`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getUserQuestionOptions:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getRandomVerifyPhoto(onSuccess?: (res: any)=>void, onFail?: (e:any)=>void): Promise<any> {
  try {
    const response = await _getV1(`/getRandomVerifyPhoto`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    console.log('Error on GET getRandomVerifyPhoto:', error)
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function getDefaultPreferences(onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _getV1('/getDefaultPreferences', {})
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function getFAQs(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1('/getFAQ', {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    console.log('Error on GET FAQ:', error)
    throw error
  }
}

export async function getFDR(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1('/getFDR', {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    console.log('Error on GET FDR:', error)
    throw error
  }
}