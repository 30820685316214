import React, { HTMLAttributes, useState, ReactElement, useEffect } from 'react'
import { Skeleton as Skeleton_ } from 'primereact/skeleton'

export type Structure = {
  width?: string
  height?: string
  className?: string
  style?: HTMLAttributes<HTMLDivElement>['style']
  children?: Structure | Structure[] | string[]
}

type SkeletonProps<T> = {
  dependency?: Promise<T>
  structure?: Structure | Structure[] | string[]
  children?: (_:T) => ReactElement
} & Omit<Structure,'children'>

export const Skeleton = <T,>(props: SkeletonProps<T>): JSX.Element => {
  const [value, setValue] = useState<T>()

  // TODO: factor out? - same pattern as `useFetch`
  useEffect(() => {
    setValue(undefined)
    let ignore = false
    ;(async () => {
      const value = await props.dependency
      if(!ignore)
      {
        setValue(value)
      }
    })()
    return () => { ignore = true }
  }, [props.dependency])

  return (
    <>
      {value === undefined
        ? (
          props.structure
          ? Array.isArray(props.structure)
          ? <div style={{ 'height':props.height,'width':props.width,...props.style }} className={props.className}>
              {props.structure.map((x,i) =>
                typeof x === 'string'
                ? <Skeleton key={i} className={x}></Skeleton>
                : <Skeleton key={i} structure={x.children} width={x.width} height={x.height} className={x.className} style={x.style}></Skeleton>
              )}
            </div>
          : <Skeleton
              structure={props.structure.children}
              width={props.structure.width}
              height={props.structure.height}
              className={props.structure.className}
              style={props.structure.style}
            ></Skeleton>
          : <Skeleton_
              width={props.width}
              height={props.height}
              className={props.className}
              style={props.style}
            >&nbsp;</Skeleton_>
        )
        : props.children
        ? props.children(value)
        : undefined
      }
    </>
  )
}

export default Skeleton
