import React, { useState, ReactElement, useEffect } from 'react'

type AwaitProps<T> = {
  dependency: Promise<T>
} & ({
  either?:false
  children:(_:T) => ReactElement | undefined
} | {
  either:true
  children:(_:T | undefined) => ReactElement | undefined
})

export const Await = <T,>(props: AwaitProps<T>): JSX.Element => {
  const [value, setValue] = useState<T>()

  // TODO: factor out? - same pattern as `useFetch`
  useEffect(() => {
    setValue(undefined)
    let ignore = false
    ;(async () => {
      const value = await props.dependency
      if(!ignore)
      {
        setValue(value)
      }
    })()
    return () => { ignore = true }
  }, [props.dependency])

  return (
    <>
      {value === undefined
        ? props.either
        ? props.children(undefined)
        : undefined
        : props.children(value)
      }
    </>
  )
}

export default Await
