import React, { ChangeEvent, useEffect, useState } from 'react'
import { FloatLabel } from 'primereact/floatlabel'
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea'
import { useUpdateEffect } from 'primereact/hooks'

export type EditableAreaProps = InputTextareaProps & {
  label: string
  errors: string | undefined
}

export const EditableArea: React.FC<EditableAreaProps> = (props) => {
  const [value, setValue] = useState<string>(props.value)
  const [event, setEvent] = useState<ChangeEvent<HTMLTextAreaElement>>()

  useUpdateEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  useUpdateEffect(() => {
    return event && props.onChange && props.onChange(event)
}, [value]);

  return (
    <div className="flex-grow-1">
      <FloatLabel>
        <label htmlFor={props.id}>{props.label}</label>
        <InputTextarea
          {...props}
          value={value}
          onChange={(e) => {setValue(e.target.value);setEvent(e)}}
        />
      </FloatLabel>
      {!!props.errors &&
        <small id={props.id + '-error'} className="text-danger">
          {props.errors}
      </small>}
    </div>
  )
}
