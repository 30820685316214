import React, { useState } from 'react'

interface AboutMeCardProps {
  aboutMeText: string
}

const AboutMeCard: React.FC<AboutMeCardProps> = ({ aboutMeText }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const maxlength = 255

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const displayText =
    isCollapsed && aboutMeText.length > maxlength ? (
      <p className="text-start">
        {`${aboutMeText.substring(0, maxlength)} `}
        <span className="text-info d-flex justify-content-end">[READ MORE...]</span>
      </p>
    ) : (
      <p className="text-start">{aboutMeText}</p>
    )

  return (
    <>
      <h4>About Me</h4>
      <div className="card bg-light about-content mb-3">
        {aboutMeText.length > maxlength ? (
          <button onClick={handleToggle} className="no-underline-link btn p-0">
            {displayText}
          </button>
        ) : (
          <>{displayText}</>
        )}
      </div>
    </>
  )
}

export default AboutMeCard
