import React, { useRef, useState } from 'react'
import PhotoCropper from '../../../../../pages/public/modals/photoEdit/photoCropper'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { User, UserPhoto } from '../../../../../../../common/types/user'
import blankImage from '../../../../../assets/images/no_profile_image@2x.png'
import { Menu } from 'primereact/menu'
import { MenuItem } from 'primereact/menuitem'
import { Image } from 'primereact/image'
import { swapProfilePhoto } from '_services/users.service'


interface UploadProps {
  images: UserPhoto[]
  userId: string
  onUpload: () => void
  onRemove: (_: string) => void
}

const PhotoUpload: React.FC<UploadProps> = (props) => {
  const [visible, setVisible] = useState<null | true | { imgUrl: string }>(null)
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const menuButton = useRef<Button>(null)
  const popMenus = [
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
  ]
  const toast = useRef<Toast>(null)

  const primaryImageSelected = async (x: string) => {
    swapProfilePhoto(x,async () => {
      await props.onUpload()
   })
    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Profile photo selected ', life: 3000 })
  }

  function accept() {
    setVisible(true)
  }

  const reject = () => {
    toast.current?.show({ severity: 'warn', summary: 'Cancelled', detail: 'Upload cancelled', life: 3000 })
  }

  const deletePicture = (x: { imgUrl: string }) => {
    props.onRemove(x.imgUrl)
    toast.current?.show({ severity: 'success', summary: 'Photo Deletion ', detail: 'Deletion successful', life: 3000 })
  }

  const doUpload = async () => {
    if (!!visible) {
      if (visible === true) {
        await props.onUpload()
        setVisible(null)
      } else {
        await props.onRemove(visible.imgUrl)
        await props.onUpload()
        setVisible(null)
      }
    }
    toast.current?.show({ severity: 'success', summary: 'Photo Uploaded ', detail: 'Upload photo successful', life: 3000 })
  }

  const items: (_: { imgUrl: string },_i:number) => MenuItem[] = (x,i) => [
    {
      label: 'Options',
      items: [
        {
          label: 'Replace',
          icon: 'pi pi-sync',
          command: (e) => i== 0 ? showTemplate(e) : setVisible(x), //() => setVisible(true)
        },
        {
          label: 'Delete',
          icon: 'pi pi-trash',
          command: () => deletePicture(x),
        },
      ].concat(i != 0 ? [ {
        label: 'Make Primary',
        icon: 'pi pi-star',
        command: () => primaryImageSelected(x.imgUrl)
      } ] : [])
    }
  ]

  const showTemplate = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      group: 'template',
      header: 'Confirmation',
      message: (
        <div className="flex justify-content-center align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-exclamation-circle d-flex justify-content-center text-center rounded py-1" style={{ color: 'orange', fontSize: '50px', background: 'floralwhite' }}></i>
          <p><b>This image will be your profile's initial photo!</b></p>
          <p >Please follow these step's for good results :</p>
          <ul className='px-4'>
            <li>Only you and no one else.</li>
            <li>Proper lighting.</li>
            <li>Un-obstructed face.</li>
          </ul>
          <p>Can be replaced after it has been added!!</p>
          <span>Please confirm to proceed moving forward.</span>
        </div>
      ),
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-sm',
      acceptClass: 'p-button-outlined p-button-sm',
      accept,
      reject,
    })
  }

  const previewIcon = (<i className="fs-3 pi pi-search"></i>)

  return <>
      <p className="fs-6 text-center">Add / Remove and choose your best photo(s)!</p>
      <PhotoCropper isVisible={!!visible} onClose={() => setVisible(null)} onUpload={doUpload} />
      <ConfirmPopup group={"template"} />
      <div
        className="w-100"
        style={{ 'display': 'grid', 'gridTemplateColumns': 'repeat(2, 1fr)', 'rowGap': '.5rem', 'columnGap': '.5rem' }}
      >
        {props.images.sort((a,b) => a.sort - b.sort)
          .concat(Array(Math.max(6, props.images.length) - props.images.length).fill(null))
          .map((x, index) => (
            <div key={index} className="rounded position-relative m-auto">
              {/* <img
                className={[
                  'rounded-4',
                  index === 0 ? 'first-photo' : undefined,
                ].join(' ')}
                src={x ? x.imgUrl : blankImage}
                alt="user image"
                style={{ 'width': '200px' }}
              /> */}
              <Image
                className={[
                  'rounded-4 overflow-hidden',
                  index === 0 ? 'first-photo' : undefined,
                ].join(' ')}
                src={x ? x.imgUrl : blankImage}
                alt="user image"
                width='200'
                preview
                indicatorIcon={previewIcon}
              />
              <span className="position-absolute bottom-0 end-0 p-1">
                <Toast ref={toast}></Toast>
                <Menu model={items(x,index)} popup popupAlignment='left' ref={popMenus[index]} />
                {x ?
                  index === 0 && props.images.length < 0 ?
                    undefined
                    :
                    <Button
                      ref={menuButton}
                      type="button"
                      className="rounded-circle fw-2"
                      icon={"pi pi-ellipsis-v"}
                      rounded
                      onClick={(event) => popMenus[index].current?.toggle(event)
                      }
                    />
                  :
                  <Button
                    type="button"
                    className='rounded-circle fw-2'
                    icon="pi pi-plus"
                    rounded
                    onClick={index == 0 ? showTemplate : () => setVisible(true)}
                  />
                }
              </span>
            </div>
          ))
        }
      </div>
  </>
}
export default PhotoUpload
