export interface EnumCountryData {
  gid: string
  name: string
  nameLong: string
}

export interface EnumNationalitiesData {
  isoCode: string
  nationality: string
  advancedOption: boolean
}

export interface EnumReligionsData {
  name: string
  display: string
  advancedOption: boolean
}

export interface EnumUserInterestsDataGrouped {
  _: {
      name: string
      display: string
      category: string
    }
}

export interface EnumUserInterestsData {
  name: string
  display: string
  category: string
}

export interface EnumUserGendersData {
  name: string
  display: string
  advancedOption: boolean
}

export interface EnumPoliticalBeliefsData {
  name: string
  display: string
}

export interface EnumBodyTypesData {
  name: string
  display: string
}
export interface EnumUserQuestionsOptions {
  display: string
  id: string
  questionId: string
  sort: number
}


export interface EnumUserQuestionsData {
  response: string
  id: string
  question: string
  sort_order: number
  options: EnumUserQuestionsOptions[]
}

export interface EnumNormalizedStructure {
  id: string
  display: string
  advancedOption: boolean
}

export interface EnumVerifyPhoto  {
  id: string
  photoUrl: string
}

export interface EnumProductsData {
  title: EnumPromoCard
  id: string
  name: string
  description: string
  productType:string
  price: number
  mostPopular: boolean
  bestOffer: boolean
}

export interface EnumPromoCard {
  id?: number
  title: string
  description: string
}

export interface faq {
  id          : string
  question    : string
  answer      : string
}

export interface fdr {
  id          : string
  question    : string
  answer      : string
}

export namespace GenderDbTypes {
  export enum GenderPreference {
    Man         = 'man',
    Woman       = 'woman',
    Everyone    = 'everyone',
  }
}

//ONBOARDING
export const genderOptions: EnumNormalizedStructure[] = [
  { id: GenderDbTypes.GenderPreference.Man,      display: 'men',      advancedOption: false },
  { id: GenderDbTypes.GenderPreference.Woman,    display: 'women',    advancedOption: false },
  { id: GenderDbTypes.GenderPreference.Everyone, display: 'everyone', advancedOption: false }
]

//PREFERENCES
export const genderPreferenceOptions: EnumNormalizedStructure[] = [
  { id: GenderDbTypes.GenderPreference.Man,      display: 'Men',      advancedOption: false },
  { id: GenderDbTypes.GenderPreference.Woman,    display: 'Women',    advancedOption: false },
  { id: GenderDbTypes.GenderPreference.Everyone, display: 'Everyone', advancedOption: false },
]
