import { _getV1, _postV1 } from './config/index'

export async function getBlogs(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1('/blogs', {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    console.log('Error on GET blogs:', error)
    throw error
  }
}

export async function getBlog(x: string, onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1(`/blog/${x}`, {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    throw error
  }
}

export async function getSuccessStories(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1('/successStories', {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    console.log('Error on GET successStories:', error)
    throw error
  }
}

export async function getStory(x: string, onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1(`/story/${x}`, {})
    if (onSuccess) {
      onSuccess(response)
    }
    return response
  } catch (error: any) {
    if (onFail) {
      onFail(error)
    }
    throw error
  }
}
