import { ReactNode } from 'react'
import { Breakpoint,BreakpointValue,useResize } from '../hooks/useResize'

type BreakpointFilter = BreakpointValue | 'mobile' | 'desktop' | 'all' | 'none'

type Filters = {
  lt?:BreakpointValue
  lte?:BreakpointValue
  eq?:BreakpointFilter
  neq?:BreakpointFilter
  gt?:BreakpointValue
  gte?:BreakpointValue
}

type Props = Filters & {
  children:ReactNode
}

const order : BreakpointValue[] = ['xs','sm','md','lg','xl','xxl']

export const checkBreakpoint = (props:Filters,bp:Breakpoint) : boolean => {
  const index : number = order.indexOf(bp.value)
  return [
    () => props.lt ? index < order.indexOf(props.lt) : false,
    () => props.lte ? index <= order.indexOf(props.lte) : false,
    () => props.eq ?
      props.eq == 'mobile' ? [0,1,2].includes(index) :
      props.eq == 'desktop' ? [3,4,5].includes(index) :
      props.eq == 'all' ? true :
      props.eq == 'none' ? false :
      order.indexOf(props.eq) == index :
      false,
    () => props.neq ?
      props.neq == 'mobile' ? [3,4,5].includes(index) :
      props.neq == 'desktop' ? [0,1,2].includes(index) :
      props.neq == 'all' ? false :
      props.neq == 'none' ? true :
      order.indexOf(props.neq) != index :
      false,
    () => props.gte ? index >= order.indexOf(props.gte) : false,
    () => props.gt ? index > order.indexOf(props.gt) : false,
  ].some((f) => f())
}

export const BreakpointRender : React.FC<Props> = (props) => {
  const breakpoint = useResize()

  return (
    checkBreakpoint(props,breakpoint) ? props.children : undefined
  )
}

export default BreakpointRender
