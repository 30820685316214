import React, { useEffect, useState } from 'react'
import { User } from '../../../../../common/types/user'
import { getUserById } from '_services/users.service'
import ProfileBox from '../_components/profile/profileBox'
import { authStore } from '../../../_core/stores/authStore'
import EditProfile from './web-mobile/edit-profile'
import { useNavigate,useParams } from 'react-router-dom'
import EditButton from '../../../components/buttons/EditButton'
import ReportUser from '../../../components/buttons/ReportUserButton'
import BreakpointRender from '../../../_core/components/breakpoint-render'

const ProfileScreen: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [userId, setUserId] = useState<string | undefined>(authStore.userID)

  useEffect(() => {
    if(userId === undefined)
    {
      setUserId(authStore.userID)
    }
  },[userId])

  return (
    <div className="d-flex gap-1 h-100 w-100">
      {!params.action ? undefined :
      <BreakpointRender eq="mobile">
        <div className="w-100">
          <EditProfile action={params.action} onUpdate={() => setUserId(undefined)}/>
        </div>
      </BreakpointRender>}
      <BreakpointRender eq="desktop">
        <div>
          <EditProfile action={params.action} onUpdate={() => setUserId(undefined)}/>
        </div>
      </BreakpointRender>
      {!userId ? undefined :
      <BreakpointRender key="profile" eq={params.action == 'edit' ? 'desktop' : 'all'}>
      <ProfileBox
        userId={userId}
        actionButtons={
          <BreakpointRender eq="mobile">
            <div
              className="d-flex justify-content-center position-sticky w-100"
              style={{ bottom: 0, }}
            >
              <EditButton
                style={{height: "80px"}}
                onPress={() => navigate('/profile/edit')}
              />
            </div>
          </BreakpointRender>
        }
      />
      </BreakpointRender>}
    </div>
  )
}

export default ProfileScreen
