import React, { useState, ReactElement, useEffect, useMemo } from 'react'

type Props<a,b> = {
  state:a
  dependency:b
  render:(_a:a,_b:b) => React.ReactElement
  transitions:((_a:a,_b:b) => a | undefined)[]
  onChange:(_:a) => void
}

export const StateMachine = <a,b>(props:Props<a,b>): JSX.Element => {
  useEffect(() => {
    const matched = [] as a[]
    props.transitions.forEach((f) => {
      const temp = f(props.state,props.dependency)
      if(temp !== undefined)
      {
        matched.push(temp)
      }
    })
    switch(matched.length)
    {
      case 0:
        break
      case 1:
        props.onChange(matched[0])
        break
      default:
        throw `StateMachine - multiple transitions matched: ${props.state} ${props.dependency} ${matched}`
    }
  },[props.dependency,props.transitions])
  return useMemo(() => props.render(props.state,props.dependency),[props.state,props.dependency])
}

export default StateMachine
