import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image'
import { Divider } from 'primereact/divider'
import { VirtualScroller } from 'primereact/virtualscroller'
import { Skeleton } from '../../../../components/skeleton'
import ContextInteractions from '../../../../_core/contexts/interactions'
import { useSignalR } from '../../../../_core/hooks'
import { Badge } from 'primereact/badge'
import noProfileImage from '../../../../assets/images/no_profile_image@2x.png'
import OurImage from '../../_components/profile/components/OurImage'

const Chat = () => {
  const { matches,conversations,setInteractions } = useContext(ContextInteractions)
  const [matchWidth, setMatchWidth] = useState<number>(108)
  const [chatHeight, setChatHeight] = useState<number>(132)

  const invoke = useSignalR([
  ])
  useEffect(() => {
    invoke('SetSeen','matches').then(setInteractions)
    invoke('SetSeen','conversations').then(setInteractions)
  },[])

  return (
    <div className="d-flex flex-column d-mobile h-100 w-100">
      <Skeleton dependency={matches} structure={['p-5']}>
        {(matches) =>
          <>
            <p className="fw-bold fs-4">
              Matches (<span className="fw-normal">{matches.length}</span>)
            </p>
            {
              matches.length ? (
                <VirtualScroller
                  orientation="horizontal"
                  items={matches}
                  itemSize={matchWidth}
                  scrollWidth="100%"
                  className="horizontal-scroll"
                  style={{ 'minHeight':'115px' }}
                  itemTemplate={(x) => (
                    <Link
                      to={`/discover/messages/${x.user.id}`}
                      className="d-flex mx-2"
                      key={x.id}
                    >
                      <OurImage
                        alt={'Image'}
                        default={noProfileImage}
                        src={x?.user.userPhotos?.[0]?.imgUrl}
                        width='100px'
                        height='100px'
                        rounded='4'
                      />
                    </Link>
                  )}
                />
              ) : (
                <p>Your passport to love isn't stamped... yet! Keep exploring!</p>
              )
            }
          </>
        }
      </Skeleton>
      <Divider color="bg-primary" />
      <Skeleton dependency={conversations} structure={Array(3).fill('p-5 my-1')}>
        {(conversations) =>
          <>
            <p className="fw-bold fs-4">Chats ({conversations.length})</p>
            <div className="flex-grow-1">
              <VirtualScroller
                orientation="vertical"
                items={conversations}
                itemSize={chatHeight}
                scrollHeight="100%"
                itemTemplate={(x) => (
                  <Link
                    to={`/discover/messages/${x.conversationId}`}
                    key={x.id}
                    className="no-underline-link d-flex flex-column my-3"
                  >
                    <div className="d-flex">
                      <span className="p-overlay-badge fs-4 ">
                      <OurImage
                        alt='Image'
                        default={noProfileImage}
                        src={x.convoPhoto}
                        width='100px'
                        height='100px'
                        rounded='4'
                        objectFit='cover'
                      />
                        {((n) => n ? <Badge value={n}></Badge> : undefined)(x.unread)}
                      </span>
                      <div className="d-flex flex-column justify-content-center mx-3">
                        <div className="fs-5 fw-bold">{x.convoName}</div>
                        <span>{x.lastMessage.length > 65 ? x.lastMessage.substring(0,65)+'...' : x.lastMessage}</span>
                      </div>
                    </div>
                  </Link>
                )}
              />
            </div>
          </>
        }
      </Skeleton>
    </div>
  )
}

export default Chat
