import React, { useEffect,useRef,useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Avatar } from 'primereact/avatar'
import { Menu } from 'primereact/menu'
import { Badge } from 'primereact/badge'
import { Conversation } from 'types/messages'
import { User } from 'types/user'
import useSignalR from '../../../_core/hooks/useSignalR'
import { HubInvoke, HubReceive } from 'enums/HubMethods'
import { authStore } from '../../../_core/stores/authStore'
import { authRoutes,webNav,mobileNav } from '../../../_core/router/routes'
import { BreakpointRender } from '../../../_core/components/breakpoint-render'
import { Interactions,ContextInteractions, promiseConstant } from '../../../_core/contexts/interactions'
import Await from '../../../_core/components/await'

const AuthLayout: React.FC = () => {
  const navigate = useNavigate()
  const menuAvatarRight = useRef<Menu>(null)
  const location = useLocation()
  const params = useParams()
  const currentRoute = authRoutes.find(r => `/${r.replaced ? r.replaced(params) : r.path}` === location.pathname)

  const [convos, setConvos] = useState<Promise<Conversation[]>>(new Promise(() => {}))
  const [likesMe, setLikesMe] = useState<Promise<{ user:User,is_new:boolean }[]>>(new Promise(() => {}))
  const [matches, setMatches] = useState<Promise<{ user:User,is_new:boolean }[]>>(new Promise(() => {}))
  const invoke = useSignalR([
    [HubReceive.Likes,setLikesMe],
    [HubReceive.Conversations,(x) => { console.log(x);setConvos(x) }],
    [HubReceive.Matches,setMatches],
  ])
  // TODO: need to apply ignore pattern like `useFetch`?
  useEffect(() => {
    setLikesMe(invoke(HubInvoke.GetLikes))
    setConvos(invoke(HubInvoke.GetConversations))
    setMatches(invoke(HubInvoke.GetMatches))
  },[])
  const interactions : Interactions = {
    'likes':likesMe,
    'matches':matches,
    'conversations':convos,
    'setInteractions':(x) => {
      if(x)
      {
        if(x.likes)
        {
          setLikesMe(promiseConstant(x.likes))
        }
        if(x.matches)
        {
          setMatches(promiseConstant(x.matches))
        }
        if(x.conversations)
        {
          setConvos(promiseConstant(x.conversations))
        }
      }
    }
  }

  const avatarMenus = [
    {
      label: authStore.name,
      items: [
        {
          label: 'Logout',
          command: () => {
            authStore.logout(() => {
              navigate('/')
            })
          },
        },
      ],
    },
  ]

  return (
    <ContextInteractions.Provider value={interactions}>
    <div className="template-auth-root h-100 d-flex flex-column">
      {/* mobile header */}
      <BreakpointRender eq="mobile">
      <header className="d-flex p-2">
        <NavLink className="nav-link" to="/discover">
          <img
            src="/assets/images/horizontal-logo.png"
            srcSet="/assets/images/horizontal-logo@2x.png 2x"
            width="350px"
            alt="Dating Across the Pond"
          />
        </NavLink>
      </header>
      </BreakpointRender>
      {/* web header */}
      <BreakpointRender eq="desktop">
      <header className="p-2">
        <div className="card shadow w-100">
          <div className="card-body p-1">
            <div className="d-flex justify-content-between align-items-center w-100">
              <NavLink
                onClick={e => {
                  if (!currentRoute?.renderNav) e.preventDefault();
                }}
                className="nav-link px-3"
                to="/discover"
                aria-disabled={!currentRoute?.renderNav}
              >
                <img
                  src="/assets/images/horizontal-logo.png"
                  srcSet="/assets/images/horizontal-logo@2x.png 2x"
                  width="350px"
                  alt="Dating Across the Pond"
                />
              </NavLink>
              <ul className="navbar-nav flex-row align-items-center d-flex">
                {webNav()
                  .filter(_ => {
                    return currentRoute?.renderNav
                  })
                  .map((route) => (
                    <li className="nav-item" key={route.path}>
                      <NavLink
                        to={`/${route.path}`}
                        className={({ isActive }) =>
                          isActive ? 'btn btn-primary px-4 ms-1' : 'btn px-4 ms-1'
                        }
                      >
                        {route.title}
                      </NavLink>
                    </li>
                  ))}
                <li>
                  <NavLink
                    to={`/profile`}
                    className={({ isActive }) => (isActive ? 'disabled-link nav-link px-4 ' : 'disabled-link nav-link px-4')}
                    onClick={e => {
                      if (!currentRoute?.renderNav) e.preventDefault();
                    }}
                  >
                    <Avatar
                      title="profile"
                      image={authStore.img}
                      size="large"
                      shape="circle"
                      // onMouseEnter={(event) => menuAvatarRight.current?.toggle(event)}
                    />
                    {/* <Menu
                      onMouseLeave={(event) => menuAvatarRight.current?.toggle(event)}
                      model={avatarMenus}
                      popup
                      ref={menuAvatarRight}
                      id="popup_menu_left"
                    /> */}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      </BreakpointRender>
      <main className="position-relative p-2 flex-grow-1" style={{ 'minHeight':0 }}>
        <Outlet />
      </main>
      <BreakpointRender eq="mobile">
      <nav className="w-100 justify-content-center p-0">
        <ul className="navbar-nav align-items-center d-flex flex-row justify-content-evenly w-100 h-100">
          {mobileNav()
            .filter(_ => {
              return currentRoute?.renderNav
            })
            .map((route) => (
              <li className="nav-item" key={route.path}>
                <span className="p-overlay-badge">
                  <NavLink
                    onClick={e => {
                      if (!currentRoute?.renderNav) e.preventDefault();
                    }}
                    to={`/${route.path}`}
                    end
                    aria-disabled={!currentRoute?.renderNav}
                    className={({ isActive }) => (isActive ? 'icon-active-color' : 'icon-non-active-color')}
                  >
                    <span className="d-flex justify-content-center">
                      <span className={route.icon} style={{fontSize: '1.5rem'}}></span>
                    </span>
                    {route.title}
                  </NavLink>
                  <Await dependency={route.badge(interactions)}>
                    {(badge) => badge ? <Badge value={badge}></Badge> : undefined}
                  </Await>
                </span>
              </li>
            ))}
          {/* <li>
            <NavLink
              onClick={e => {
                if (!currentRoute?.renderNav) e.preventDefault();
              }}
              to={`/profile`}
              className="d-flex justify-content-end">
              <Avatar
                hidden={!currentRoute?.renderNav}
                title="profile"
                image={authStore.img}
                size="large"
                shape="circle"
                onMouseEnter={(event) => menuAvatarRight.current?.toggle(event)}
              />
              <Menu
                onMouseLeave={(event) => menuAvatarRight.current?.toggle(event)}
                model={avatarMenus}
                popup
                ref={menuAvatarRight}
                id="popup_menu_left"
              />
            </NavLink>
          </li> */}
        </ul>
      </nav>
      </BreakpointRender>
    </div>
    </ContextInteractions.Provider>
  )
}

export default AuthLayout
