import React, { useEffect, useState } from 'react'
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { ToggleButton } from "primereact/togglebutton"
import { values } from 'mobx'

export type DynamicButtonListProps<T, U = T | T[]> =
  {
    primaryOptions      : T[]
    secondaryOptions?   : T[]
    vertical?           : boolean
  } & (
    U extends T[]
    ? {
      multiple            : true
      initialValue        : T[]
      onChange            : (_:T[]) => void | Promise<void>
      optionLabel         : keyof T
      optionLabelKey      : keyof T
      maxSelection        : number
    }
    : {
      initialValue        : T | undefined
      onChange            : (_:T | undefined) => void | Promise<void>
      optionLabel?        : keyof T
      optionLabelKey?     : keyof T
    }
  )

export const DynamicButtonList = <X,>(props: DynamicButtonListProps<X>) => {
  const [value, setValue]
    = useState(props.initialValue)

  function hasOverlap(item: X, array2: X[]): boolean {
    return array2.some(obj2 => props.optionLabelKey ? item[props.optionLabelKey] === obj2[props.optionLabelKey] : item === obj2)
  }

  const toggleSelection = (option:X) => {
    if (Array.isArray(value)) {
      const currentValues = value
      const index = currentValues.findIndex(v => props.optionLabelKey ? v[props.optionLabelKey] === option[props.optionLabelKey] : v === option)
      if (index > -1) {
        setValue(currentValues.filter(v => props.optionLabelKey ? v[props.optionLabelKey] !== option[props.optionLabelKey] : v !== option))
      } else if (!('maxSelection' in props) || !props.maxSelection || currentValues.length < props.maxSelection) {
        setValue(currentValues.concat([option]))
      }
    } else {
      setValue(option)

    }
  }

  const handleMultiSelectChange = (selectedItems: X[]) => {
    if (props.secondaryOptions) {
      let itemsToAdd = props.secondaryOptions.filter(v =>
        selectedItems.find(si =>
          props.optionLabelKey ? v[props.optionLabelKey] === si : v === si
      ))
      if (Array.isArray(value)) {
        let primarySelections = props.primaryOptions.filter(po =>
          value.find(v =>
            props.optionLabelKey ? po[props.optionLabelKey] === v[props.optionLabelKey] : po === v
        ))
        if (!('maxSelection' in props) || !props.maxSelection || value.length <= props.maxSelection) {
          setValue([...primarySelections,...itemsToAdd]);
        }
      }else{
        if(selectedItems.length > 1 && value){
          setValue(itemsToAdd.filter(x => props.optionLabelKey ? x[props.optionLabelKey] !== value[props.optionLabelKey] : value !== x )[0])
        }else{
          setValue(itemsToAdd[0])

        }
      }
    }
  }


  useEffect(() => {
    props.onChange(value)
  }, [value,])

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        {props.primaryOptions.map(option =>
          <div key={props.optionLabelKey && ((x) => typeof x === 'string' ? x : '!ERROR!')(option[props.optionLabelKey])}
              className="p-2"
              style={{ width:props.vertical ? '100%' : 'calc(50% - 1rem)' }}
          >
            <ToggleButton
              checked={
                Array.isArray(value)
                ? value.some(v => props.optionLabelKey ? v[props.optionLabelKey] === option[props.optionLabelKey] : v === option)
                : (value && (props.optionLabelKey ? value[props.optionLabelKey] === option[props.optionLabelKey] : value === option))
              }
              offLabel={typeof option == 'string' ? option : props.optionLabel && ((x) => typeof x === 'string' ? x : '!ERROR!') (option[props.optionLabel])}
              onLabel={typeof option == 'string' ? option : props.optionLabel && ((x) => typeof x === 'string' ? x : '!ERROR!')(option[props.optionLabel])}
              onChange={() => toggleSelection(option)}
              className="w-100"
            />
          </div>
        )}
        {props.secondaryOptions && (
          <MultiSelect
            options={props.secondaryOptions.map((x) => ({ 'label':props.optionLabel ? x[props.optionLabel] : '!ERROR!','value':props.optionLabelKey ? x[props.optionLabelKey] : '!ERROR!' }))}
            // optionLabel={props.optionLabel}
            value={Array.isArray(value)
                    ? value.filter(x => !hasOverlap(x,props.primaryOptions)).map(x => props.optionLabelKey ? x[props.optionLabelKey] : x)
                    : value && !hasOverlap(value,props.primaryOptions) ? [props.optionLabelKey ? value[props.optionLabelKey] : value] : []
            }
            onChange={(e) => handleMultiSelectChange(e.value)}
            display="chip"
            filter
            disabled={ Array.isArray(value) && 'maxSelection' in props && props.maxSelection
            ? (
              props.maxSelection
              - (
                Array.isArray(value)
                ? value.filter(x => hasOverlap(x,props.primaryOptions)).length
                : 0
              )
            ) == 0
            : undefined}
            multiple={Array.isArray(value)}
            placeholder="more"
            max={
              Array.isArray(value) && 'maxSelection' in props && props.maxSelection
                ? (
                  props.maxSelection
                  - (
                    Array.isArray(value)
                    ? value.filter(x => hasOverlap(x,props.primaryOptions)).length
                    : 0
                  )
                )
                : undefined
            }
            selectionLimit={
              Array.isArray(value) && 'maxSelection' in props && props.maxSelection
              ? (
                props.maxSelection
                - (
                  Array.isArray(value)
                  ? value.filter(x => hasOverlap(x,props.primaryOptions)).length
                  : 0
                )
              )
              : undefined
            }
            className="w-50"
          />
        )}
      </div>

    </>
  )
}
