import React, { useEffect, useRef, useState } from 'react'
import { verifySmsOtp } from '_services/auth.service'
import { authStore } from '../_core/stores/authStore'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { InputOtp } from 'primereact/inputotp'

interface OtpFormProps {
  phoneNumber: string
  onSubmit: () => void
}

const OtpForm: React.FC<OtpFormProps> = ({ phoneNumber, onSubmit }) => {
  const navigate = useNavigate()
  const [token, setToken] = useState<string>('')
  const [validationError, setValidationError] = useState<boolean>(false)
  const [pendingOtp, setPendingOtp] = useState<boolean>(false)
  const thirtySecondsInMs = 30 * 1000
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if(token?.length === 6)
    {
      confirmOTP()
    }
  }, [token])

  async function confirmOTP() {
    const res = await verifySmsOtp(phoneNumber, token, '')
    if(res.error) {
      setValidationError(true)
    } else {
      authStore.setToken(res.data.token)
      if(!res.data.userComplete){
        navigate('/onboarding')
      }else{
        navigate('/discover')
      }
    }
  }

  function resendOtp() {
    setPendingOtp(true)
    setTimeout(() => setPendingOtp(false), thirtySecondsInMs)

    onSubmit()
  }
  let isFirst = true
  return (
    <form onSubmit={(e) => { e.preventDefault(); confirmOTP() }}>
      <div className="d-flex flex-column align-items-center">
        <p className="font-bold text-xl-center mb-2">Authenticate Your Account</p>
        <p className="text-color-secondary d-block mb-2">Please enter the code sent to your phone.</p>
        <div className="d-flex flex-column justify-content-center text-center my-4">
          <InputOtp
            integerOnly
            value={token}
            onChange={(e) => setToken(e.value as string)}
            length={6}
            pt={{
              'hooks':{
                'useMountEffect':() => { isFirst = true },
              },
              'input':() => {
                const temp = isFirst
                isFirst = false
                return {
                  'autoFocus':temp,
                  'style':{ 'width':'30px' },
                }
              },
            }}
          />
          { validationError && <i className="text-danger">Invalid code. Please try again.</i>}
        </div>
        <div className="d-flex justify-content-between align-self-stretch mt-2">
          <Button type="button" label="Resend Code" link onClick={resendOtp} disabled={pendingOtp}></Button>
          <Button type="submit" label="Submit Code" className="btn btn-primary"></Button>
        </div>
      </div>
    </form>
  )
}

export default OtpForm
