import { Sidebar,SidebarProps } from 'primereact/sidebar'
import React from 'react'

interface SidebarWrapperProps {
  title?: string
  visible: boolean
  onHide: () => void
  position?: 'top' | 'bottom' | 'left' | 'right' | undefined
  children?: React.ReactNode
  header?: React.ReactNode
}

export default function SidebarWrapper(props: SidebarWrapperProps & SidebarProps) {
  return (
      <Sidebar {...props}>
        <span>{props.header}</span>
        <h2 className="sticky-top bg-white text-center">{props.title}</h2>
        <span>{props.children}</span>
      </Sidebar>
  )
}
