import React from 'react'
import { UserMetadata } from '../../../../../../../common/types/user'
import { IoMdGlobe, IoMdBeer } from 'react-icons/io'
import { IconType } from 'react-icons'
import { GiBodyHeight } from 'react-icons/gi'
import { formatHeightMeasurement } from '../../../../../../../common/util/helpers.converters'
import { FaPersonPraying, FaPerson,FaCannabis} from 'react-icons/fa6'
import {
  alcoholUseOptions,
  drugUseOptions,
  kidsOptions,
  tobaccoUseOptions,
} from '../../../../../../../common/enums/LifestyleChoiceEnums'
import { RiGovernmentFill } from 'react-icons/ri'
import { FaBabyCarriage, FaSmoking } from 'react-icons/fa'

interface MetadataDisplayItem {
  key: keyof UserMetadata
  icon: IconType
  render: (_metadata: UserMetadata) => string | undefined
}

interface ExtraInfoBoardProps {
  userMetadata: UserMetadata
}


const ExtraInfoBoard: React.FC<ExtraInfoBoardProps> = ({ userMetadata }) => {

  const metadataDisplayItems: MetadataDisplayItem[] = [
    {
      key: 'Nationalities',
      icon: IoMdGlobe,
      render: (metadata) => metadata && metadata.Nationalities ? metadata.Nationalities?.map((n) => n.EnumNationality.nationality).join(', ') : '',
    },
    {
      key: 'heightMeasurement',
      icon: GiBodyHeight,
      render: (metadata) =>
        metadata && metadata.heightMeasurement
          ? formatHeightMeasurement(metadata.heightMeasurement).dualLocaleDisplay
          : undefined /*TODO Need to handle undefined properly */,
    },
    {
      key: 'religiousBeliefName',
      icon: FaPersonPraying,
      render: (metadata) => (metadata && metadata.Religion ? metadata.Religion.display : undefined),
    },
    {
      key: 'bodyTypeName',
      icon: FaPerson,
      render: (metadata) => (metadata && metadata.BodyType ? metadata.BodyType.display : undefined),
    },
    {
      key: 'politicalBeliefName',
      icon: RiGovernmentFill,
      render: (metadata) => (metadata && metadata.PoliticalBelief ? metadata.PoliticalBelief.display : undefined),
    },
    {
      key: 'hasOrWantsKids',
      icon: FaBabyCarriage,
      render: (metadata) => metadata ? kidsOptions.find((x) => x.id == metadata.hasOrWantsKids)?.display : undefined,
    },
    {
      key: 'drinksAlcohol',
      icon: IoMdBeer,
      render: (metadata) => metadata ? alcoholUseOptions.find((x) => x.id == metadata.drinksAlcohol)?.display : undefined,
    },
    {
      key: 'smokesTobacco',
      icon: FaSmoking,
      render: (metadata) => metadata ? tobaccoUseOptions.find((x) => x.id == metadata.smokesTobacco)?.display : undefined,
    },
    {
      key: 'smokesWeed',
      icon: FaCannabis,
      render: (metadata) => metadata ? drugUseOptions.find((x) => x.id == metadata.smokesWeed)?.display : undefined,
    },
  ]

  return (
    <div className="horizontal-scroll">
      <ul className="list-unstyled extraInfoCard">
        {metadataDisplayItems.map((item, index) => {
          const content = item.render(userMetadata)
          return content ? (
            <li key={index} className="mb-2 d-flex align-items-center text-secondary">
              <item.icon style={{ padding: 2, marginRight: '4px' }} size={34} />
              <span>{content}</span>
            </li>
          ) : null
        })}
      </ul>
    </div>
  )
}

export default ExtraInfoBoard
