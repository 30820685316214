import React, { useEffect, useState } from 'react'
import { FloatLabel } from 'primereact/floatlabel'
import { Calendar, CalendarProps } from 'primereact/calendar'

export type EditableCalendarProps = CalendarProps & {
  label: string
  errors: string | undefined
}

export const EditableCalendar: React.FC<EditableCalendarProps> = (props) => {
  return (
    <div className="flex-grow-1">
    <FloatLabel>
        <Calendar
         {...props}
        />
        <label htmlFor={props.id}>{props.label}</label>
    </FloatLabel>
      {!!props.errors &&
        <small id={props.id + '-error'} className="text-danger">
          {props.errors}
      </small>}
    </div>
  )
}
