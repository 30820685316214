import React, { useContext, useEffect, useState } from 'react'
import { Image } from 'primereact/image'
import { TabView, TabPanel, TabPanelPassThroughOptions } from 'primereact/tabview'
import { DataScroller } from 'primereact/datascroller'
import { Badge } from 'primereact/badge'
import { Link } from 'react-router-dom'
import { Conversation } from '../../../../../common/types/messages'
import useSignalR from '../../../_core/hooks/useSignalR'
import { WrapperCard } from '../../../components/cards/WrapperCardNew'
import Skeleton from '../../../components/skeleton'
import Await from '../../../_core/components/await'
import blankImage from '../../../assets/images/no_profile_image@2x.png'
import ContextInteractions from '../../../_core/contexts/interactions'
import OurImage from './profile/components/OurImage'

const listSkeletonStructure = Array(5).fill({
  'className':'d-flex mb-1 mx-1',
  'children':[
    'flex-grow-1 py-4 me-1',
    {
      'className':'d-flex flex-column flex-grow-4',
      'children':[
        'flex-grow-3 mb-1',
        'flex-grow-1',
      ],
    }
  ],
})

const HotListMessages: React.FC<{ 'tab':'matches' | 'messages' | 'likes','userId':string | null }> = (props) => {
  const { likes,matches,conversations,setInteractions } = useContext(ContextInteractions)

  const [activeIndex, setActiveIndex] = useState<number>()
  const [activeMatch, setActiveMatch] = useState<string | null>(null)
  const [mouseIsOver, setMouseIsOver] = useState<string | null>(null)

  const invoke = useSignalR([
  ])

  useEffect(() => setActiveMatch(props.userId),[props.userId])
  useEffect(() => setActiveIndex({ 'matches':0,'messages':1,'likes':2 }[props.tab]),[props.tab])
  useEffect(() => {
    if(activeIndex !== undefined)
    {
      invoke('SetSeen',{ 0:'matches',1:'conversations',2:'likes' }[activeIndex]).then(setInteractions)
    }
  },[activeIndex])

  const passportUnstamped =
    <h6 className="text-center py-5">
      Your passport to love isn't stamped... yet!
      <br/>
      Keep exploring!
    </h6>
  const passportUnnewstamped =
    <h6 className="text-center py-5">
      Your passport to love hasn't been stamped... recently!
      <br/>
      <button type="button" className="btn btn-primary" onClick={() => setActiveIndex(1)}>Continue a conversation!</button>
    </h6>

  return (
    <WrapperCard>
      <TabView
        className="tab-view"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        pt={{
          'root':{
            'className':'d-flex flex-column h-100',
            'style':{ 'maxWidth':'390px' },
          },
          'panelContainer':{
            'className':'flex-grow-1 p-0',
          },
        }}
      >
        <TabPanel
          header={
            <span className="p-overlay-badge">
              Matches
              <Await dependency={matches}>
                {(matches) => ((n) => n ? <Badge value={n}></Badge> : undefined)(matches.filter((x) => x.is_new).length)}
              </Await>
            </span>
          }
          pt={{ 'root':{ 'className':'h-100' } }}
        >
          <Skeleton dependency={Promise.all([matches,conversations])} structure={listSkeletonStructure} className="p-2">
            {([matches,conversations]) => !matches.length ? !conversations.length ? passportUnstamped : passportUnnewstamped :
              <div className="row row-cols-xl-3 row-cols-md-3 w-100 p-2">
                {matches.map((match, index) => {
                  const isActive = match.user.id === activeMatch
                  const isOver = match.user.id === mouseIsOver
                  return (
                    <Link
                      onClick={() => setActiveMatch(match.user.id)}
                      onMouseEnter={() => setMouseIsOver(match.user.id)}
                      onMouseLeave={() => setMouseIsOver(null)}
                      to={`/discover/matches/${match.user.id}`}
                      key={index}
                      className={[
                        'col-5 position-relative',
                        isActive ? 'selectedMatch' : activeMatch ? 'defaultProfile unSelectedMatch' : 'defaultProfile',
                        isOver ? 'isHovered ' : '',
                      ].join(' ')}
                    >
                      <div >
                        <OurImage
                          alt={match.user.alt}
                          default={blankImage}
                          src={match.user.userPhotos[0].thumbUrl}
                          height='100px'
                          width='100px'
                          rounded='4'
                        />
                        <label className="imageLabelThumb">
                          {match.user.firstName} {match.user.lastName}
                        </label>
                      </div>
                    </Link>
                  )
                })}
              </div>
            }
          </Skeleton>
        </TabPanel>
        <TabPanel
          header={
            <span className="p-overlay-badge">
              Messages
              <Await dependency={conversations}>
                {(conversations) => ((n) => n ? <Badge value={n}></Badge> : undefined)(conversations.filter((x) => !!x.unread).length)}
              </Await>
            </span>
          }
        >
          <Skeleton dependency={conversations} structure={listSkeletonStructure} className="p-2">
            {(conversations) => !conversations.length ? passportUnstamped :
              <DataScroller
                value={conversations}
                rows={5}
                onChange={() => {}}
                itemTemplate={(x: Conversation) => (
                  <Link
                    to={`/discover/messages/${x.conversationId}`}
                    className="no-underline-link d-flex flex-column flex-xl-row align-items-start p-2 gap-2"
                  >
                    <span className="p-overlay-badge fs-4">
                      <OurImage
                        alt={'Image'}
                        default={blankImage}
                        src={x.convoPhoto}
                        height='100'
                        width='100'
                        rounded='4'
                      />
                      {((n) => n ? <Badge value={n}></Badge> : undefined)(x.unread)}
                    </span>
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-xl-start flex-grow-1 gap-4">
                      <div className="d-flex flex-column align-items-center align-items-sm-start gap-3">
                        <div className="fs-3 fw-bold">{x.convoName}</div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="d-flex align-items-center gap-2">
                            {x.lastMessage.length > 65 ? x.lastMessage.substring(0,65)+'...' : x.lastMessage}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              />
            }
          </Skeleton>
        </TabPanel>
        <TabPanel
          header={
            <span className="p-overlay-badge">
              Likes Me
              <Await dependency={likes}>
                {(likes) => ((n) => n ? <Badge value={n}></Badge> : undefined)(likes.filter((x) => x.is_new).length)}
              </Await>
            </span>
          }
        >
          <Skeleton dependency={likes} structure={listSkeletonStructure} className="p-2">
            {(likes) => !likes.length ? passportUnstamped :
              <DataScroller
                value={likes}
                rows={5}
                onChange={() => {}}
                itemTemplate={(like) => (
                  <Link
                    to={`/discover/likes/${like.user.id}`}
                    className="no-underline-link d-flex flex-column flex-xl-row align-items-start p-2 gap-2"
                  >
                    <OurImage
                      alt={'Image'}
                      default={blankImage}
                      src={like.user.userPhotos[0]?.imgUrl}
                      height='100'
                      width='100'
                      rounded='4'
                    />
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-xl-start flex-grow-1 gap-4">
                      <div className="d-flex flex-column align-items-center align-items-sm-start gap-3">
                        <div className="fs-3 fw-bold">
                          {like.user.firstName} {like.user.lastName}
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="d-flex align-items-center gap-2">
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              />
            }
          </Skeleton>
        </TabPanel>
      </TabView>
    </WrapperCard>
  )
}

export default HotListMessages
