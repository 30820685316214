import { EnumNormalizedStructure } from "../../common/types/enums.options"

export namespace AgeDbTypes {
  export enum KidsTypes {
    _18_to_21 = '18_21',
    _22_to_25 = '22_25',
    _26_to_30 = '26_30',
    _31_to_35 = '31_35',
    _36_to_40 = '36_40',
    _41_to_45 = '41_45',
    _46_to_50 = '46_50',
    _51_to_55 = '51_55',
    _56_to_60 = '56_60',
    _61_plus = '61_plus'
  }
}

// PREFERENCES
export const agePreferenceOptions: EnumNormalizedStructure[] = [
  { id: AgeDbTypes.KidsTypes._18_to_21, display: '18 to 21', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._22_to_25, display: '22 to 25', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._26_to_30, display: '26 to 30', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._31_to_35, display: '31 to 35', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._36_to_40, display: '36 to 40', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._41_to_45, display: '41 to 45', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._46_to_50, display: '46 to 50', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._51_to_55, display: '51 to 55', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._56_to_60, display: '56 to 60', advancedOption: false },
  { id: AgeDbTypes.KidsTypes._61_plus,  display: '61 and above', advancedOption: false },
]