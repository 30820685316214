import { EnumNormalizedStructure } from "types/enums.options";

export enum SchoolTypes {
  HighSchool    = 'High School',
  TradeSchool   = 'Trade School',
  Bachelors     = 'Bachelors',
  Masters       = 'Masters',
  Doctorate     = 'Doctorate',
  Advanced      = 'Phd/M.D/DO/JD'
}

export const EducationOptions: EnumNormalizedStructure[] = [
  { id: SchoolTypes.HighSchool,     display: 'High School',     advancedOption: false },
  { id: SchoolTypes.TradeSchool,    display: 'Trade School',    advancedOption: false },
  { id: SchoolTypes.Bachelors,      display: 'Bachelors',       advancedOption: false },
  { id: SchoolTypes.Masters,        display: 'Masters',         advancedOption: false },
  { id: SchoolTypes.Doctorate,      display: 'Doctorate',       advancedOption: false },
  { id: SchoolTypes.Advanced,       display: 'Phd/M.D/DO/JD',   advancedOption: false }
]