import React, { useEffect, useState } from 'react'
import { usePrevious, useUpdateEffect } from 'primereact/hooks'
import WebPreferenceCard from './WebPreferenceCard'
import { EnumNormalizedStructure } from 'types/enums.options'
import { getMyPreferences, updateMyPreferences } from '_services/users.service'
import { getDefaultPreferences } from '_services/enums.service'
import { WebUserPreferencesSelector } from './WebUserPreferenceSelection'
import { getEnumOptions } from '../../../../../../../common/hooks/getEnumOptions'
import { agePreferenceOptions } from '../../../../../../../common/enums/AgeRangeChoices'
import { genderPreferenceOptions } from '../../../../../../../common/types/enums.options'
import {
  kidsPreferenceOptions,
  drugUseOptions,
  alcoholUseOptions,
  tobaccoUseOptions,
} from '../../../../../../../common/enums/LifestyleChoiceEnums'
import { PreferenceKeys, PreferencesType, emptyPreferences } from 'types/preferenceKeys'
import { Skeleton } from '../../../../../components/skeleton'

export type PreferencesTypeLocal = {
  [key in PreferenceKeys]:{ 'enabled':boolean,'selected':string[] }
}

const prefsToLocal = (prefs:PreferencesType) : PreferencesTypeLocal =>
  Object.fromEntries(Object.entries(prefs).map(([k,v]) => [k,{ 'enabled':!!v.length,'selected':v }])) as PreferencesTypeLocal
const localToPrefs = (prefs:PreferencesTypeLocal) : PreferencesType =>
  Object.fromEntries(Object.entries(prefs).map(([k,v]) => [k,v.enabled ? v.selected : []])) as PreferencesType
const rawToPrefs = (
  raw:{ 'preferenceKey':PreferenceKeys,'preferenceValue':string }[]
) : PreferencesType => {
  return raw.reduce(
    (acc, item) => {
      if(!(item.preferenceKey in acc))
      {
        acc[item.preferenceKey] = []
      }
      if(!acc[item.preferenceKey].includes(item.preferenceValue))
      {
        acc[item.preferenceKey].push(item.preferenceValue)
      }
      return acc
    },
    emptyPreferences()
  )
}

export const UserPreferences : React.FC<{ onChange?:(_:PreferencesTypeLocal) => void }> = (props) => {
  const { countryData, nationalityData, bodyTypeData, religionData, politicalBeliefData } = getEnumOptions()
  const [initializer, setInitializer] = useState<Promise<null>>()
  const [userPreferences, setUserPreferences] = useState<PreferencesTypeLocal>()
  const prevUserPreferences = usePrevious(userPreferences)
  const [defaultPreferences, setDefaultPreferences] = useState<PreferencesType>(emptyPreferences())

  useUpdateEffect(() => {
    if(prevUserPreferences && userPreferences)
    {
      updateMyPreferences(localToPrefs(userPreferences))
      props.onChange && props.onChange(userPreferences)
    }
  },[userPreferences])
  useEffect(() => {
    let ignore = false
    setInitializer((async () => {
      const [defaults,preferences] = await Promise.all([getDefaultPreferences(),getMyPreferences()])
      if(!ignore)
      {
        const defaultPreferences = rawToPrefs(defaults.data)
        setDefaultPreferences(defaultPreferences)
        const userPreferences = prefsToLocal(rawToPrefs(preferences.data))
        setUserPreferences(userPreferences)
        return null
      }
      else
      {
        return null
      }
    })())
    return () => { ignore = true }
  },[])

  const handleCategoryChange = async (category:PreferenceKeys,enabled:boolean) => {
    setUserPreferences((prev) => prev && ({
      ...prev,
      [category]:{ 'enabled':enabled,'selected':enabled ? defaultPreferences[category] : [] },
    }))
  }
  const handleValueChange = (category:PreferenceKeys,value:EnumNormalizedStructure,selected:boolean) => {
    setUserPreferences((prev) => prev && ({
      ...prev,
      [category]:{
        ...prev[category],
        'selected':selected ? [...prev[category].selected,value.id] : prev[category].selected.filter((x) => x != value.id),
      },
    }))
  }

  const renderPreferenceCard = (
    category: PreferenceKeys,
    title: string,
    subtitle: string,
    data: EnumNormalizedStructure[],
    showInput = false
  ) => {
    return <WebPreferenceCard
      title={title}
      enabled={userPreferences![category].enabled}
      onChange={handleCategoryChange}
      preferenceKey={category}
    >
      <WebUserPreferencesSelector
        preferenceKey={category}
        title={subtitle}
        data={data}
        showInput={showInput}
        selectedPreferences={userPreferences![category].selected.map((id) => data.find((x) => x.id === id)).filter((x) => x != undefined) as EnumNormalizedStructure[]}
        onChange={handleValueChange}
      />
    </WebPreferenceCard>
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <Skeleton dependency={initializer}>
        {(_) =>
          <>
            {renderPreferenceCard(
              'location',
              'Location',
              'Locations',
              countryData,
              true
            )}
            {renderPreferenceCard(
              'nationality',
              'Nationality',
              'Nationalities',
              nationalityData,
              true
            )}
            {renderPreferenceCard(
              'gender',
              'Gender',
              'Gender Options',
              genderPreferenceOptions,
            )}
            {renderPreferenceCard(
              'age',
              'Age',
              'Age Ranges',
              agePreferenceOptions,
            )}
            {renderPreferenceCard(
              'politicalBelief',
              'Political Belief',
              'Political Beliefs',
              politicalBeliefData,
            )}
            {renderPreferenceCard(
              'religiousBelief',
              'Religious Belief',
              'Religious Beliefs',
              religionData,
            )}
            {renderPreferenceCard(
              'bodyType',
              'Body Type',
              'Body Types',
              bodyTypeData,
            )}
            {renderPreferenceCard(
              'kids',
              'Children / Kids',
              'Kids Options',
              kidsPreferenceOptions,
            )}
            {renderPreferenceCard(
              'weedUse',
              'Weed Use ',
              'Drug Use Options',
              drugUseOptions,
            )}
            {renderPreferenceCard(
              'alcoholUse',
              'Alcohol Use',
              'Alcohol Use Options',
              alcoholUseOptions,
            )}
            {renderPreferenceCard(
              'tobaccoUse',
              'Tobacco Use',
              'Tobacco Use Options',
              tobaccoUseOptions,
            )}
          </>
        }
      </Skeleton>
    </div>
  )
}

export default UserPreferences
