import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { IoCloseSharp } from 'react-icons/io5'
import * as jt from '@js-joda/core'

import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'

import { User, UserMessage } from 'types/user'
import { HubReceive,HubInvoke } from 'enums/HubMethods'
import { getUserById } from '_services/users.service'
import { formatDateRelative,formatTimeRelative } from 'util/formatters'

import useResize from '../../../../src/_core/hooks/useResize'
import useSignalR from '../../../../src/_core/hooks/useSignalR'
import WrapperCard from '../../../components/cards/WrapperCardNew'
import SubmitButton from '../../../components/submit-button'

import EmojiPicker from 'emoji-picker-react';
import { OverlayPanel } from 'primereact/overlaypanel'

interface MessagesBoxProps {
  userId: string
}

type UserMessageParsed = Omit<UserMessage, 'createdDate'> & {
  created: jt.LocalDateTime
  createdDate: jt.LocalDate
}

const MessagesBox : React.FC<MessagesBoxProps> = (props) => {
  const breakpoint = useResize()
  const [scroll,setScroll] = useState<boolean>(true)
  const [showTimes,setShowTimes] = useState<{ [_:string]:null }>({})
  const [user, setUser] = useState<Promise<User>>(new Promise(() => {}))
  const [_messages, _setMessages] = useState<UserMessageParsed[]>([])
  const [messageText, setMessageText] = useState<string>('')
  const [sending, setSending] = useState<Promise<unknown>>()
  const parseMessage = (x:UserMessage) : UserMessageParsed => ({
    ...x,
    'created':jt.Instant.parse(x.created_date).atZone(jt.ZoneId.SYSTEM).toLocalDateTime(),
    'createdDate':jt.Instant.parse(x.created_date).atZone(jt.ZoneId.SYSTEM).toLocalDate()
  })
  const invoke = useSignalR([
    [HubReceive.Message,(x:UserMessage) => _setMessages((prev) => [...prev, parseMessage(x)].sort((a,b) => a.createdDate.compareTo(b.createdDate)))],
  ])

  const op = useRef(null);


  // TODO: need to apply ignore pattern like `useFetch`?
  useEffect(() => setUser(getUserById(props.userId)),[props.userId])
  useEffect(() => {
    invoke(HubInvoke.GetMessages,props.userId).then((xs:UserMessage[]) => _setMessages(xs.map(parseMessage).sort((a,b) => a.createdDate.compareTo(b.createdDate))))
    invoke(HubInvoke.SetSeenConversation,props.userId)
  },[props.userId])

  const onSubmit = async (userId:string) => {
    setSending(invoke(HubInvoke.SendMessage,userId,messageText))
    await sending
    setMessageText('')
  }

  const messages : [jt.LocalDate,UserMessageParsed[]][] =
    _messages
      .toSorted((a,b) => a.created.compareTo(b.created))
      .reduce((agg,x) => {
        if(!agg.length || !x.createdDate.equals(agg[agg.length-1][0]))
        {
          agg.push([
            x.createdDate,
            [],
          ])
        }
        agg[agg.length-1][1].push(x)
        return agg
      },[] as [jt.LocalDate,UserMessageParsed[]][])
  return (
    <WrapperCard
      dependency={user}
      structure={Array(3).fill('rounded-pill px-3 py-2 mb-2')}
      className="m-auto w-100"
      style={{ 'maxWidth':'700px' }}
      headerContent={
        (user) =>
          <div className="d-flex justify-content-between align-items-center m-2">
            <h5>
              {breakpoint.isMobile // TODO: currently link still shows at `md` but Discover starts showing profile at `md`
                ? <NavLink to={`/discover/profile/${props.userId}`}>{user.firstName} {user.lastName}</NavLink>
                : `${user.firstName} ${user.lastName}`
              }
            </h5>
            <NavLink to={breakpoint.isMobile ? '/discover/messages' : '/discover'}>
              <Button icon={<IoCloseSharp size={25} color="#000" />} outlined rounded />
            </NavLink>
          </div>
      }
      footerContent={
        <form
          className="d-flex align-items-center p-2"
          style={{ 'borderTop':'1px solid #dee2e6' }}
          onSubmit={(e) => { e.preventDefault(); onSubmit(props.userId) }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Type a message..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <span className="mx-2 pi pi-face-smile" onClick={(e) => op.current.toggle(e)}></span>
          <OverlayPanel ref={op}>
            <EmojiPicker onEmojiClick={(x) => {setMessageText(x.emoji)}} />
          </OverlayPanel>

          <SubmitButton label="Send" className="btn btn-primary ms-3" disabled={!messageText} dependency={sending}/>
        </form>
      }
    >
      <div className="d-flex flex-column p-3">
        {messages.map(([k,xs]) => (
          <React.Fragment key={k.toString()}>
            <div className="text-center mt-3">{formatDateRelative(k)}</div>
            {xs.map((x) =>
              <React.Fragment key={x.id}>
                <div
                  className={[
                    'rounded-20 px-3 py-2 text-white',
                    x.user_from_id === props.userId ? 'bg-secondary me-auto' : 'bg-primary ms-auto',
                  ].join(' ')}
                  style={{ 'maxWidth':'90%' }}
                  onClick={() => setShowTimes(({ [x.id]:temp,...prev }) => temp === undefined ? { ...prev,[x.id]:null } : prev)}
                >
                  {x.message_text}
                </div>
                <small
                  className={[
                    'mb-2',
                    x.user_from_id === props.userId ? 'text-start' : 'text-end',
                  ].join(' ')}
                >{x.id in showTimes ? formatTimeRelative(x.created.toLocalTime()) : undefined}</small>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
        {scroll ? <div ref={(x) => (x?.scrollIntoView({ 'behavior': 'smooth' }),setScroll(false))}></div> : undefined}
      </div>
    </WrapperCard>
  )
}

export default MessagesBox
