import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { EnumProductsData } from 'types/enums.options'
import { InputText } from 'primereact/inputtext'

interface ProductTableProps {
  enumProduct: EnumProductsData
}

const CheckoutTable = (props: ProductTableProps) => {
  const [promoCode, setPromoCode] = useState('')

  return (
    <div>
      <div className="card shadow">
        <h1 className="text-center">Checkout</h1>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Subscription</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <tr>
              <td>{props.enumProduct.name}</td>
              <td>${(props.enumProduct.price / 100).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>${(props.enumProduct.price / 100).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-evenly  my-4">
        <div className="w-50">
          <span className="p-float-label text-center col">
            <InputText
              id="username"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Enter Code"
            />
            <label htmlFor="username">Apply Code</label>
          </span>
        </div>
        <div className="">
          <Button className="rounded-2" title="Apply Code">
            Apply Code
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CheckoutTable
