import { DependencyList, useEffect, useState } from 'react'

export const useFetch = <a>(
  fetch: () => Promise<a>,
  handle: (_: a | undefined) => void,
  deps?: DependencyList
): boolean => {
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    let ignore = false
    ;(async () => {
      try {
        setLoading(true)
        handle(undefined)
        const result = await fetch()
        if (!ignore) {
          handle(result)
        } else {
        }
      } catch {
        alert('useFetch - error')
      } finally {
        setLoading(false)
      }
    })()
    return () => {
      ignore = true
    }
  }, deps)
  return loading
}

export default useFetch
