import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import 'react-phone-number-input/style.css'
import SignInForm from '../../../forms/signInForm'
import OtpForm from '../../../forms/otpForm'
import { sendSmsOtpForLogin } from '_services/auth.service'
import ConfirmPhoneForm from '../../../forms/confirmPhoneForm'
import { Skeleton } from 'primereact/skeleton'
import StateMachine from '../../../_core/components/state-machine'

interface SignInModalProps {
  isCreateModal? : boolean
  title       : string
  isVisible   : boolean
  onClose     : () => void
}

enum EnumState {
  SIGNIN='signin',
  LOADING='loading',
  OTP='otp',
  CONFIRM_PHONE='confirm-phone',
}

type State = {
  'state':EnumState
}

const SignInModal: React.FC<SignInModalProps> = (props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('')

  const [stateState,setState] = useState<State>({ 'state':EnumState.SIGNIN })
  const [stateNext,setNext] = useState<State>()

  async function generateOTP(phoneNumber: string, countryCode: string) {
    setPhoneNumber(phoneNumber)
    setCountryCode(countryCode)

    setNext({ 'state':EnumState.LOADING })
    let response = await sendSmsOtpForLogin('phone',phoneNumber,countryCode)
    switch(response.data.status)
    {
      case 'otp':
        setNext({ 'state':EnumState.OTP })
        break
      case 'phoneRequired':
        setNext({ 'state':EnumState.CONFIRM_PHONE })
        break
      default:
        // TODO: error?
        break
    }
  }

  function onHide() {
    props.onClose()
    setState({ 'state':EnumState.SIGNIN })
    setNext(undefined)
  }

  return (
    <Dialog
      className="h-100 h-md-auto"
      header={props.title}
      visible={props.isVisible}
      onHide={onHide}
      headerClassName="p-1 p-md-3"
      contentClassName="p-1 p-md-3"
      style={{ width:'100%',maxWidth:'850px',maxHeight:'100%' }}
    >
      <div className="p-2 p-sm-3 p-md-5">
        <div className="text-center img-fluid">
          <img src="../../../assets/images/horizontal-logo.png" alt="Brand title" />
        </div>
        <StateMachine
          state={stateState}
          onChange={setState}
          dependency={stateNext}
          transitions={[
            (_,dependency) => dependency,
          ]}
          render={(state,_) => {
            switch(state.state)
            {
              case EnumState.LOADING:
                return <Skeleton></Skeleton>
              case EnumState.SIGNIN:
                return <SignInForm isCreateForm={props.isCreateModal || false} onSubmit={generateOTP} onSuccess={props.onClose}/>
              case EnumState.OTP:
                return <OtpForm phoneNumber={phoneNumber} onSubmit={() => sendSmsOtpForLogin("phone",phoneNumber, countryCode)} />
              case EnumState.CONFIRM_PHONE:
                return <ConfirmPhoneForm onSubmit={generateOTP} />
            }
          }}
        ></StateMachine>
      </div>
    </Dialog>
  )
}

export default SignInModal
