import { _getV1, _postV1 } from './config/index'
import { IncomingFormPayload } from '../types/payloads.users'
import { UserPreferencesType } from '../../mobileApp/phoneSrc/screens/profileScreens/PreferenceScreen'
import { WipeAccountData } from '../../mobileApp/phoneSrc/screens/profileScreens/WipeAccountSetting'
import { User } from 'types/user'

export async function getUser(): Promise<any> {
  try {
    const response = await _getV1('/getUser', {})
    if (!response || response.error) {
      throw new Error(response.message || 'Error fetching users')
    }
    return response
  } catch (error: any) {
    console.log('Error on GET getUser:', error)
    throw error
  }
}

export async function getUserById(userToId: string): Promise<User> {
  try {
    const response = await _getV1(`/getUser/${userToId}`, {})
    return response.data
  } catch (error: any) {
    console.log('Error on GET getUser:', error)
    throw error
  }
}

export async function getRandomUser() : Promise<User> {
  try {
    const response = await _getV1('/getRandomUser', {})
    return response.data
  } catch (error: any) {
    console.log('Error on GET getRandomUser:', error)
    throw error
  }
}

export async function getRandomUserId() : Promise<string | null> {
  try {
    const response = await _getV1('/getRandomUserId',{},{ 404:{ 'data':null } })
    return response.data
  } catch (error: any) {
    console.log('Error on GET getRandomUserId:', error)
    throw error
  }
}

export async function getCurrentUser(onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _getV1('/getCurrentUser', {})
    return response
  } catch (error: any) {
    console.log('Error on GET getCurrentUser:', error)
    throw error
  }
}

export async function getCurrentUserInterests(): Promise<any> {
  try {
    const response = await _getV1('/getUserInterests', {})
    return response
  } catch (error: any) {
    console.log('Error on GET getCurrentUser:', error)
    throw error
  }
}

export async function getCurrentUserMetadata(): Promise<any> {
  try {
    const response = await _getV1('/getUserMetadata', {})
    return response
  } catch (error: any) {
    console.log('Error on GET getCurrentUserMetadata:', error)
    throw error
  }
}

export async function getUserQuestions(userToId: string): Promise<any> {
  try {
    const response = await _getV1(`/getUserQuestions/${userToId}`, {})
    return response
  } catch (error: any) {
    console.log('Error on GET getUserQuestions:', error)
    throw error
  }
}

export async function updateProfile(payload: IncomingFormPayload, onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/updateProfile', payload)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function createUser(toNumber: string, countryCode: string): Promise<any> {
  try {
    const response = await _postV1('/createUser', { emailPhone: toNumber, countryCode: countryCode })
    return response
  } catch (error: any) {
    console.log('Error on POST createUser:', error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function wipeUserAccount(wipeAccountData: WipeAccountData, onSuccess:(res: any)=>void, onFail:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/deleteUser', wipeAccountData)
    onSuccess(response)
  } catch (error: any) {
    console.log('Error on POST deleteUser:', error)
    onFail(error)
  }
}

export async function getMyLikes(onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/getLikes', {})
    onSuccess && onSuccess(response)
    return response.data
  } catch (error: any) {
    onFail && onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function updateMyPreferences(userPreferences: UserPreferencesType, onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/updatePreferences', userPreferences)
    onSuccess && onSuccess(response)
  } catch (error: any) {
    onFail && onFail(error)
  }
}

export async function getMyPreferences(onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _getV1('/getPreferences', {})
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function requestMediaUrl(mimeType:string,onSuccess?:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/requestMediaUrl', {mimeType:mimeType})
    return onSuccess ? await onSuccess(response) : response.data
  } catch (error: any) {
    if(onFail)
    {
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}
export async function deletePhoto(url:string,onSuccess:(res: any)=>void, onFail:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/deletePhoto', {img_url:url})
    onSuccess(response)
  } catch (error: any) {
    onFail(error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function confirmVerifyUpload(id:string,verifyPhotoId:string,onSuccess:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/confirmVerifyUpload', {
      id:id,
      verifyPhotoId:verifyPhotoId
    })
    onSuccess(response)
  } catch (error: any) {
    if(onFail){
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}


export async function confirmPhotoUpload(id:string,onSuccess:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/confirmPhotoUpload', {
      id:id
    })
    await onSuccess(response)
  } catch (error: any) {
    if(onFail){
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}

export async function swapProfilePhoto(new_img_url:string,onSuccess:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1('/swapProfilePhoto', {
      newImgUrl:new_img_url
    })
    await onSuccess(response)
  } catch (error: any) {
    if(onFail){
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}

export async function getUsers(onSuccess:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _getV1('/getUsers', {
    })
    await onSuccess(response)
  } catch (error: any) {
    if(onFail){
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}


export async function cancelStripeSubscription(stripeSubId:string,onSuccess:(res: any)=>void, onFail?:(e:any)=>void): Promise<any> {
  try {
    const response = await _postV1(`/cancelStripeSubscription/${stripeSubId}`, {})
    await onSuccess(response)
  } catch (error: any) {
    if(onFail){
      onFail(error)
    }
    return {
      error: true,
      message: error,
    }
  }
}