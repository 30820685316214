import React from 'react'
import Logo from '../../../assets/images/horizontal-logo.png'
import { Image } from 'primereact/image'


const PrivacyPolicy: React.FC = () => {
  return (
    <div className="d-flex justify-content-center vh-100 html-text">
      <header className='position-fixed bg-white flex justify-content-center w-100 text-center py-1 shadow-sm'>
        <Image src={Logo} alt="Dating Across the Pond" />
      </header>
      <div className=" w-75 p-3 my-2">
        <h5>DAtP privacy</h5>

        <h5>DAtP Privacy Policy</h5>
        <p>
          Welcome to the Dating Across the Pond ("DAtP") Privacy Policy ("Policy")! This explains how we collect,
          store, protect, and share your information, and with whom we share it. We suggest you read this in
          conjunction with our Terms and Conditions of Use.
        </p>
        <p>
          Whilst you’re enjoying the DAtP mobile application (including the desktop version) ("App"), our websites and
          microsites (such as https://www.datingacrossthepond.com) or using our digital products and services (such as
          our competitions or surveys) (together, referred to in this Privacy Policy as our "Sites"), we collect some
          information about you. In addition, you may choose to use the App or Sites to share information with other
          users, including your friends and contacts ("Users"). We may also need to share your information sometimes.
        </p>
        <p>
          The App and Sites are global, and your information will be sent to and used in the United States and the UK
          regardless of the country you reside in. This Policy explains how we protect your personal data when we
          transfer it overseas, so please read very carefully!
        </p>

        <h5>Who we are</h5>
        <p>
          The App and Sites are operated by "Dating Across The Pond LLC" (also referred to in this policy as "we" or
          "us") which is the controller of personal information collected and processed through the DAtP App and
          Sites.
        </p>
        <p>
          Dating Across The Pond LLC has designated a Data Protection Officer and they can be reached by emailing{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a> or by post at the
          following address: 1133 South Allen Street, State College, PA, 16801, USA.
        </p>

        <h5>1. COLLECTION OF INFORMATION.</h5>
        <p>Registration Information</p>
        <p>
          When you download the App and create an account ("Account"), we may collect certain information
          ("Registration Information") about you, such as:
        </p>
        <ul>
          <li>Name;</li>
          <li>Username;</li>
          <li>Email address;</li>
          <li>Mobile number;</li>
          <li>Gender identity;</li>
          <li>Date of birth;</li>
          <li>Sexual preference;</li>
          <li>Photographs;</li>
          <li>Location; and</li>
          <li>
            Login information for social media accounts that you connect to your DAtP Account (this could include, for
            example, your Facebook and Instagram accounts). For more information about this, see "Linking other
            Accounts to DAtP" in section 7 below.
          </li>
        </ul>
        <p>
          Once you register, you will be able to review and change this information at any time just by logging in to
          DAtP (other than your date of birth and location (which, if you have given DAtP access to your location in
          your device settings, is automatically updated based on the location of your device)). It is your
          responsibility to ensure that your account details are kept up to date. If your phone number changes, please
          ensure that you update this in your account.
        </p>
        <p>
          The information we collect helps to enhance the App and verify our Users (AI bots are not welcome!).
          Registration Information such as your sexual preference, name and username may be visible to other Users who
          view your profile page.
        </p>
        <p>
          For Users who are California residents, the data we may collect falls within the following categories of
          "personal information", as defined by the California Consumer Privacy Act (CCPA):
        </p>
        <ul>
          <li>A. Identifiers, such as name and location;</li>
          <li>
            B. Personal information, as defined in the California customer records law, such as contact (including
            email and telephone number) and financial information;
          </li>
          <li>
            C. Characteristics of protected classifications under California or federal law (if you choose to provide
            them), such as age, gender identity, marital status, sexual orientation, race, ancestry, national origin,
            religion, and medical conditions;
          </li>
          <li>D. Commercial information, such as transaction information and purchase history;</li>
          <li>E. Biometric information;</li>
          <li>
            F. Internet or network activity information, such as browsing history and interactions with our Sites and
            App;
          </li>
          <li>G. Geolocation data, such as mobile device location;</li>
          <li>H. Audio, electronic, visual and similar information, such as photos and videos;</li>
          <li>I. Professional or employment-related information, such as work history and prior employer;</li>
          <li>J. Non-public education information; and</li>
          <li>
            K. Inferences drawn from any of the personal information listed above to create a profile or summary
            about, for example, an individual’s preferences and characteristics.
          </li>
        </ul>

        <h5>Profile Information</h5>
        <p>
          We recommend and encourage you (and all our members) to think carefully about the information you disclose
          about yourself. We also do not recommend that you put email addresses, URLs, instant messaging details,
          phone numbers, full names or addresses, credit card details, national identity numbers, drivers’ license
          details and other sensitive information which is open to abuse and misuse on your profile.
        </p>
        <p>
          When you post information about yourself or use the messaging function to communicate with other Users, the
          amount of personal information you share is at your own risk. Please see Section 4 below for more
          information on who can access what you post on DAtP.
        </p>

        <p>Profile Verification Information (Including Biometric Information)</p>
        <p>
          For safety and security and to ensure you have the best possible user experience, we require Users to verify
          their accounts and might ask for your phone number and, in some instances, we might also ask that you carry
          out photo verification. We want to make sure you are not a AI bot! And we also want to avoid fake DAtP
          accounts being created which can be used for malicious activities and cybercrime – they threaten the DAtP
          network and spoil things for everyone. This verification might be required by us for the prevention of
          fraud. You can also verify your photo on a voluntary basis (to add the blue ‘verified’ badge to your
          profile).
        </p>
        <p>
          If you choose to verify your profile photo, we will scan each photo that you submit. The scan may include
          the use of facial recognition technology so that we can compare the photo you submit to your profile photo,
          to help ensure that you are who you say you are. We do not add the verification photos to your profile. We
          retain the scans so that we can verify you in the future and for our record-keeping purposes until we no
          longer need them for such purposes or for three years after your last interaction with us, whichever occurs
          first. After the applicable retention period expires, we take commercially reasonable steps to permanently
          and securely delete the scans from our systems.
        </p>

        <h5>Purchases Information</h5>
        <p>
          If you decide to purchase any of our premium services, we will process your payment information and retain
          this securely for the prevention of fraud and for audit/tax purposes.
        </p>
        <p>
          DAtP uses automated decisions to prevent fraudulent payment transactions being processed as part of its
          anti-fraud procedures. In order to do this, our systems check payment transactions for behavior that
          indicates breaches of our Terms and Conditions of Use. If a transaction meets certain criteria that
          demonstrate that the Terms and Conditions of Use are likely to have been breached and the transaction is
          likely to be fraudulent, the relevant transaction may automatically be blocked. Where a transaction is
          blocked, the user will be notified that their transaction cannot be processed and affected Users can contact
          DAtP to contest the decision.
        </p>
        <p>
          Please note that if your account is blocked for any reason, any account(s) that you have on other DAtP Group
          platforms/applications, such as Datebritishguys.com], may also be blocked as part of our anti-spam and
          anti-fraud procedures.
        </p>

        <h5>Geolocation Information</h5>
        <p>
          If you have given DAtP access to your location in your device settings, when you use your mobile, we will
          collect information about WiFi access points as well as other location information about your longitude and
          latitude and may save your device’s coordinates to offer certain features to you. This information helps us
          identify your physical location and we use it to personalize the App and make it easier for you to interact
          with other Users, by enabling the general locality information to be displayed to Users seeing your profile
          and showing you the profiles of other Users who are near you.
        </p>
        <p>
          If you have given DAtP access to your location, but wish to turn this off, you can do so by the following
          methods:
        </p>
        <ol>
          <li>iPhone app — settings, privacy, location services, DAtP</li>
          <li>Android — settings, location, DAtP, permissions, location</li>
        </ol>

        <h5>Device Information</h5>
        <p>
          We may collect information about your device when you use the App including the unique device identifier,
          device model, and operating system, for a number of purposes, as set out in this policy. In addition, if you
          permit us to do so, the App may access your device’s address book solely in order to add someone to your
          contacts.
        </p>

        <h5>Links</h5>
        <p>
          We may keep track of how you interact with links available on DAtP including third party services by
          redirecting clicks or through other means. We may share aggregate click statistics such as how many times a
          particular link was clicked on.
        </p>

        <h5>DAtP Success Stories, Surveys and other Contributions</h5>
        <p>
          From time to time, we run surveys for research purposes, and we may contact you to find out if you would
          like to take part. We may also contact you to find out if you would like to provide feedback, a testimonial
          or take part in marketing campaigns (for example, if you let us know that you have found a match on the App,
          we may contact you to ask if you would be happy to feature in advertising for DAtP). Such surveys and
          marketing campaigns are optional, and more information will be provided at the point of contact. If you do
          not wish to be contacted to take part in a survey or marketing campaign, please contact our Customer Support
          Team via our Contact Us page.
        </p>

        <h5>When you Contact Customer Support</h5>
        <p>
          If you contact our Customer Support team, we will receive your email address, and may track your IP address,
          as well as the information you send to us to help resolve your query. We will keep records of our
          communications with you, including any complaints that we receive from you about other Users (and from other
          Users about you) for 6 years after deletion of your account.
        </p>

        <h5>Cookies and similar technologies</h5>
        <p>
          When you visit our Sites or when you use our App, we may collect personal data from you automatically by
          using cookies or similar technologies. A cookie is a small file that can be placed on your device or browser
          that allows us to recognize and remember you.
        </p>
        <p>
          If you would like to find out more about cookies, including how we use them and what choices are available
          to you, please refer to our Cookie Policy below.
        </p>

        <h5>Cookies Policy</h5>
        <p>
          Your trust, privacy and data are important to us and we are committed to being transparent about our data
          practices and how we use and protect your data.
        </p>
        <p>
          We use cookies and similar technologies when you use the DAtP mobile application (including the desktop
          version) ("App") or websites (such as Datingacrossthepond.com) ("Websites").
        </p>
        <p>
          This policy provides more detail about the cookies and tracking technologies we use and should be read in
          conjunction with our Privacy Policy and the information available in our "Manage Cookies" section (linked at
          the bottom of our Websites) or in your profile settings in our App (under "Settings" &gt; "Security &
          Privacy" &gt; "Privacy Settings" on iOS and Android) where you can adjust your cookie settings at any time.
        </p>

        <h5>1. What are 'cookies' and which 'cookies' does DAtP use?</h5>
        <p>
          Some of our members call these digital biscuits, but with fewer raisins! We collect information by placing
          cookies on your computer or mobile device. A cookie is a piece of text containing a unique identifier, which
          is stored on your computer by your web browser or by your mobile device. They are basically a tool that
          stores information about App use and Websites’ visits. Cookies also allow us to recognize you and your
          preferences each time you visit DAtP. They ensure the functioning of the App and Websites which in turn
          allows us to provide the services that our members request and to improve your user experience.
        </p>
        <p>
          We may also use other technologies such as web beacons (also called pixels), tracking URLs and software
          development kits (SDKs) for similar purposes as cookies. Web beacons are files that contain a unique
          identifier that enable us to recognize when someone has accessed content on our Websites. Tracking URLs are
          unique links that help us understand where visitors to our Websites are coming from. SDKs are small pieces
          of code included in applications, which function like cookies and web beacons. For simplicity, in this
          Policy we also refer to these technologies as "Cookies".
        </p>

        <table className="table col-8">
          <thead>
            <tr>
              <th className="text-start col-2">Type of Cookie</th>
              <th className="text-center col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Strictly necessary Cookies</td>
              <td>
                <p>
                  These Cookies are necessary for the App and Websites to function and cannot be switched off in our
                  systems, or our App and Websites will not work properly. They are usually only set in response to
                  actions made by you which amount to a request of services, such as setting your privacy preferences,
                  logging in or filling in forms, making payments, uploading photographs, chatting or to localize your
                  experience, such as when you’ve requested to view DAtP’s Site in your local language or when you’ve
                  asked DAtP to ‘remember me’, etc. You can always control the Websites’ strictly necessary Cookies in
                  your browser, as explained below.
                </p>
                <p>
                  We also use Cookies to help keep DAtP and our members safe and secure. These Cookies do things like
                  protect DAtP users from spam and fraud by ensuring the safety of your personal data when you pay for
                  things on DAtP like DAtP’s premium services.
                </p>
                <p>
                  We may use persistent Cookies, which will help us to ensure we have identified that the same device
                  is logging in to the correct account. These types of Cookies also help with our anti-spam measures
                  and help us to prevent phishers, scammers, unauthorized login attempts to your account and accessing
                  any hacked accounts.
                </p>
                <p>
                  Some of these strictly necessary Cookies are third parties’, as some social media platforms can be
                  used as an entry point to register for our services.
                </p>
              </td>
            </tr>
            <tr>
              <td>Analytics Cookies</td>
              <td>
                <p>
                  These Cookies allow us to collect information about how visitors interact with our App and Websites.
                  We use this information to compile reports and to help measure and improve the performance of the
                  App and Websites.
                </p>
                <p>
                  DAtP uses Google Analytics to collect information about how visitors use the DAtP Site. We use the
                  information to compile reports and to help us improve the Site or App. The Cookies collect
                  information in an aggregated form, including the number of visitors to the Site or App, where
                  visitors have come to the Site from and the pages they visited.
                </p>
              </td>
            </tr>
            <tr>
              <td>Social Media Cookies</td>
              <td>
                <p>
                  These Cookies help provide experiences, such as links to other social media Websites and social
                  plugins, including making it easier for you to share content between DAtP and your other favorite
                  social networks.
                </p>
                <p>
                  In some cases, the Site feature you choose may allow a third party to place Cookies on your device.
                </p>
              </td>
            </tr>
            <tr>
              <td>Advertising Cookies</td>
              <td>
                These Cookies may be set on our Websites and App by us or our advertising partners. They may be used
                to build a profile of your interests and show you relevant adverts on our App and Websites or other
                Websites you visit, as well as to improve reporting on any advertising campaign and to avoid showing
                you ads that you have already seen.
              </td>
            </tr>
            <tr>
              <td>Performance</td>
              <td>
                These Cookies may be set on our Websites and App by us or our marketing services providers. They help
                us and our marketing services providers improve and measure the effectiveness of DAtP ads on other
                apps and Websites. For example, they help us understand which DAtP ads lead people to download our App
                or sign-up for DAtP. You can find out more information about this in our Privacy Policy (under the
                heading "Sharing your personal data with Marketing Services Providers").
              </td>
            </tr>
          </tbody>
        </table>
        <h5>2. Cookies lifespan and third parties placing Cookies</h5>
        <p>
          Cookies last for different periods of time depending on which of the following two categories they fall
          into:
        </p>
        <ul>
          <li>
            Session cookies – these cookies only last as long as your online session, and expire when you close your
            browser (for example Internet Explorer or Safari).
          </li>
          <li>
            Persistent cookies – these cookies stay on your device after your browser has been closed. These cookies
            are used when we need to remember you for more than one browsing session, for instance to remember your
            preferences from one visit to the next.
          </li>
        </ul>
        <p>
          If you would like to obtain more information about the third parties placing Cookies on our Websites and App
          (including a link to their privacy practices) and their lifespan you can access this information:
        </p>
        <ul>
          <li>
            in your profile settings for the DAtP App (under "Settings" &gt; "Security & Privacy" &gt; "Privacy
            Settings" on iOS or under "Advertising Preferences" on Android); and
          </li>
          <li>in the "Manage Cookies" section linked at the bottom of our Websites.</li>
        </ul>
        <h5>3. How can you refuse or withdraw consent to the use of Cookies?</h5>
        <p>
          <b>When using our Websites</b>, you are in control and may refuse to accept Cookies at any time by managing
          your preferences in the "Manage Cookies" section (linked at the bottom of our Websites) or by altering the
          settings on your internet browser (for example Internet Explorer, Chrome and Firefox).
        </p>
        <p>
          If you would like to know more about Cookies and how to refuse Cookies, the following websites provide
          useful information:
        </p>
        <ul>
          <li>
            <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>
          </li>
          <li>
            <a href="http://www.youronlinechoices.eu">www.youronlinechoices.eu</a>
          </li>
          <li>
            <a href="http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html">
              http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
            </a>
          </li>
        </ul>
        <p>
          <b>When using our App</b>, you are also in control and may refuse to accept Cookies at any time by managing
          your Privacy Preferences in your Profile in the App (under "Settings" &gt; "Security & Privacy" &gt;
          "Privacy Settings" on iOS and Android).
        </p>
        <p>
          Please note that if you choose not to permit Cookies some areas of our Websites or App may not function
          properly or be accessible.
        </p>
        <h5>4. How to contact us?</h5>
        <p>
          DAtP has designated a Data Protection Officer and they can be reached by emailing{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a> or by post at the
          following address: 1133 South Allen Street, State College, PA, 16801, USA.
        </p>
        <p>
          If you have any questions regarding this Cookie Policy, please visit our Feedback Page or email us at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>. You can also contact
          us by post at the address above.
        </p>

        <h5>2. USE OF YOUR INFORMATION.</h5>
        <p>
          Our main goal is to ensure your experience on DAtP is an enjoyable one and you don’t end up getting stung!
          In order to deliver an enjoyable experience to you, we may use your Registration and other information to:
        </p>
        <ul>
          <li>offer you our services and features;</li>
          <li>contact you with information about the App (e.g., updates and new features);</li>
          <li>personalize the App/Sites and the content we deliver to you;</li>
          <li>conduct research and analytics about how you use and interact with the App/Sites;</li>
          <li>to test new technologies and processes designed to enhance and improve the App/Sites;</li>
          <li>resolve disputes, troubleshoot problems and to enforce our Terms & Conditions;</li>
          <li>investigate fraud, protect our legal rights, and to enforce our Terms & Conditions.</li>
          <li>
            to send you information about the promotions and offers we have available (e.g., specify the types of
            goods/services/offers to be provided via direct marketing) by direct marketing or other modes of
            communication - if you’ve signed up for our communications or otherwise told us it’s OK. We will not use
            your information in email direct marketing unless you give us your consent during the Account creating
            process or via Settings in the App (you can withdraw from marketing at any time via Settings in the App or
            by using the opt-out mechanisms and links provided in each message); and
          </li>
          <li>protect our Users and third parties from harm.</li>
        </ul>

        <p>Our Matching Algorithms</p>
        <p>
          We have developed matching algorithms to predict your compatibility with other users and so we can show you
          people we think are a good match for you. Our matching algorithms use the following data about you to
          predict your compatibility with others and generate profile recommendations: the things you tell us about
          yourself in your profile; information about your activity in our apps; whether you are a photo verified
          user; and your device coordinates, which are necessary to understand your proximity to other members. The
          legal basis for processing profile and proximity information is that this is necessary for the provision of
          our contractual services to you pursuant to Article 6(1)(b) GDPR.
        </p>
        <p>
          Where we process information about your app activity, this is based on our legitimate interests pursuant to
          Article 6(1)(f) GDPR, and specifically our legitimate interest in generating more personalized and relevant
          recommendations for who you can match with in our App. If you have chosen to include sensitive information
          in your profile, such as information about your beliefs, the processing of this information is based on your
          voluntary and explicit consent.
        </p>

        <p>Moderation Practices</p>
        <p>
          We use a combination of automated systems and a team of moderators to monitor and review accounts (including
          photos and any other information uploaded onto user profiles) and messages for content that indicates
          breaches of our Terms and Conditions of Use. If an account or message meets certain criteria that
          demonstrate that the Terms and Conditions of Use are likely to have been breached, the relevant account will
          be subject to a warning and the user’s access restricted and/or blocked. Affected Users can contact DAtP to
          contest the decision.
        </p>
        <p>
          If you post anything that is inconsistent with our Terms and Conditions of Use, we reserve the right to
          terminate or restrict access to your Account.
        </p>

        <p>Lawful Basis</p>
        <p>
          Under EU and UK data protection laws, we are required to tell you our lawful basis for using your data and
          we have set this out in the table below. Where the legal basis is consent, you can withdraw consent at any
          time. Where the legal basis is legitimate interests, you have a right to object to our use of your data. We
          explain in the relevant sections in this Policy how you can withdraw consent or opt-out of certain data uses
          (where applicable).
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Purpose for which data is used</th>
              <th>Data</th>
              <th>Source</th>
              <th>Legal basis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>To provide you with the DAtP social networking service</td>
              <td>Name, email address, date of birth, location (CCPA Categories A and B)</td>
              <td>
                You provide your name, email address and date of birth to us. We obtain location data from the device
                that you use to access the service
              </td>
              <td>Contractual necessity</td>
            </tr>
            <tr>
              <td>To facilitate networking opportunities on the DAtP App</td>
              <td>
                Optional information that you choose to provide in your profile, including through profile
                verification, or adding DAtP badges, which may include information about your sexual preferences,
                non-binary gender, religion, ethnic background, photos, interests, etc. (CCPA Categories B, C, H, I,
                J)
              </td>
              <td>You provide this information to us</td>
              <td>
                Contractual necessity and our legitimate interests – it is in our legitimate interests to facilitate
                networking opportunities in the DAtP app. For special category/sensitive personal data, we rely on
                your explicit consent.
              </td>
            </tr>
            <tr>
              <td>To verify your identity and prevent fraud and to ensure the safety and security of Users</td>
              <td>
                Phone number and if requested or permitted, photo provided as part of profile verification and for
                users based in countries where age verification is required by law: photo of Government ID +
                geolocation and device information (CCPA Categories A, B, C, E, F, G and H)
              </td>
              <td>
                You provide this information to us, except for the geolocation and device information, which we obtain
                from the device that you use to access the service
              </td>
              <td>
                Legitimate interests – it is in our legitimate interests to ensure that accounts are not set up
                fraudulently and to safeguard Users of the site
              </td>
            </tr>
            <tr>
              <td>
                To take payment for premium services (not applicable for Users making payments via the Apple App
                Store)
              </td>
              <td>Payment card details (CCPA Categories B and D)</td>
              <td>You provide this information to us</td>
              <td>Contractual necessity</td>
            </tr>
            <tr>
              <td>To send you marketing information about our events, offers and services</td>
              <td>
                Name, email address, postal address, Instagram handle and mobile phone number (CCPA Categories A and
                B)
              </td>
              <td>You provide this information to us</td>
              <td>
                Consent or legitimate interests and in accordance with the laws applying to our marketing activities.
                We have a legitimate interest in promoting our business and products
              </td>
            </tr>
            <tr>
              <td>
                To carry out research and analysis to help us improve the App and to test new technologies and
                processes designed to enhance and improve the App/Sites
              </td>
              <td>
                Log and usage data, including IP address, browser type, referring domain, pages accessed, mobile
                carrier and search terms, images and video, registration information, profile information (CCPA
                Categories A, F and H)
              </td>
              <td>
                You provide photos and videos to us. We obtain the log and usage information from the device that you
                use to access the service
              </td>
              <td>
                Legitimate interests – it is in our legitimate interests to analyze the way in which Users are
                accessing and using our services and to test new technologies so that we can further develop the App,
                implement security measures and improve the service
              </td>
            </tr>
            <tr>
              <td>To respond to correspondence and queries that you submit to us, including social media queries</td>
              <td>Email address and IP address, social media name, phone number (CCPA Categories B and F)</td>
              <td>
                You provide your email address, social media name and phone number to us when you contact us and we
                obtain your IP address from the device that you use to contact us
              </td>
              <td>
                You provide your email address, social media name and phone number to us when you contact us and we
                obtain your IP address from the device that you use to contact us Legitimate interests – it is in our
                legitimate interests to respond to your queries to ensure that we provide a good service to Users and
                troubleshoot problems
              </td>
            </tr>
            <tr>
              <td>To investigate and block Users for reported infringements of our Terms and Conditions of Use</td>
              <td>
                Name and user registration details, profile information, content of messages and photographs + usage
                and device data such as IP address and IP session information (CCPA Categories A, B, C, F, and H)
              </td>
              <td>
                You provide your registration details, profile information, messages and photographs to us. We obtain
                the other information from the device that you use to access the service.
              </td>
              <td>
                Legitimate interests - it is in our legitimate interests to prevent unauthorized behavior and to
                maintain the safety and integrity of our services
              </td>
            </tr>
            <tr>
              <td>
                To enable Users to create and enhance their DAtP profile and log into the App via third party accounts
              </td>
              <td>
                Data from Facebook and other third-party accounts (see section 7 of this Privacy Policy for more
                details) (CCPA Categories A, B, C, and H)
              </td>
              <td>
                We obtain this information from Facebook, or the providers of any other accounts you use to log in or
                connect with your DAtP account
              </td>
              <td>Legitimate interests – it is in our legitimate interests to facilitate access to our services</td>
            </tr>
            <tr>
              <td>To block payment transactions as part of our anti-fraud procedures</td>
              <td>
                Name, IP address, email address, mobile number, cardholder name, payments received, type of payment,
                user ID, country (CCPA Categories, A, B, and D)
              </td>
              <td>
                You provide your name, email address, mobile number and card details to us. We obtain your IP address
                from the device that you use to contact us. We obtain your payment information from your interactions
                with our service
              </td>
              <td>
                Legitimate interests – it is in our legitimate interests to prevent fraudulent transactions and to
                maintain the security of our services
              </td>
            </tr>
            <tr>
              <td>To serve promo cards and advertisements on the App</td>
              <td>
                Location, gender, age, and information that you have optionally provided us with via your profile
                (CCPA Categories A, C and G)
              </td>
              <td>
                We obtain age, gender and profile information from you, and location data from the device that you use
                to access the service
              </td>
              <td>
                Legitimate interests – it is in our legitimate interests to target advertisements so that Users see
                relevant advertisements and to allow us to generate income from advertising revenue
              </td>
            </tr>
            <tr>
              <td>To serve advertisements on third party networks and measure the effectiveness of such ads</td>
              <td>
                Data about your visit to our Sites or App and action taken on those (for example if you downloaded our
                App or created an account with DAtP), IP address (and your estimated location based on your IP
                address), age and gender, device ID (CCPA Categories B, C, G, F and K)
              </td>
              <td>
                We obtain age and gender from you and we obtain other information from the device or browser that you
                use to access the service
              </td>
              <td>
                Consent – as indicated by you in your Privacy Settings/Cookies Settings preferences and via your
                browser or device privacy preferences (where required by your device manufacturer, for example Apple
                devices using iOS 14.5).
              </td>
            </tr>
            <tr>
              <td>
                To contact you in order to run surveys for research purposes and to obtain feedback, and to find out
                if you want to take part in marketing campaigns
              </td>
              <td>Email address and mobile phone number (CCPA Category B)</td>
              <td>You provide this information to us</td>
              <td>
                Legitimate interests – it is in our legitimate interests to carry out research so that we can further
                develop the app and improve the service
              </td>
            </tr>
            <tr>
              <td>To enable video and audio call functions, and the sending of images and video</td>
              <td>Video and audio call usage data, images and video (CCPA Categories F and H)</td>
              <td>We obtain this information from the device that you use to access the service</td>
              <td>
                Legitimate interests – it is in our legitimate interests to provide these functionalities as part of
                the services
              </td>
            </tr>
            <tr>
              <td>To defend legal claims, protect legal rights and to protect people from harm</td>
              <td>This could include any information that is relevant to the issue</td>
              <td>
                This information may be obtained directly from you, from your device or from third parties, depending
                on the information involved
              </td>
              <td>
                Legitimate interests – it is in our legitimate interests to protect our legal rights, defend legal
                claims and to protect our Users and third parties from harm
              </td>
            </tr>
          </tbody>
        </table>

        <h5>3. DISCLOSURE OF INFORMATION.</h5>
        <p>
          Our policy is to not disclose your Registration Information or personal data, except in the limited
          circumstances described here:
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Circumstances where data may be disclosed</th>
              <th>Disclosed data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Service Providers – We engage certain trusted third parties to perform functions and provide services
                to us. We may share your Registration Information or personal data with these third parties, but only
                for the purposes of performing these functions and providing such services.More information about this
                is available directly below.
              </td>
              <td>This could include all data, including all CCPA Categories listed above</td>
            </tr>
            <tr>
              <td>Moderators – To monitor activity on the App and approve content.</td>
              <td>
                Name and user registration details, profile information, content of messages and photographs (CCPA
                Categories A, B, C, and H)
              </td>
            </tr>
            <tr>
              <td>
                Payment Processing and Telecommunications Companies – To facilitate payments for our premium services.
              </td>
              <td>
                Cardholder name, cardholder address, card number, payment amount, transaction date/time (CCPA
                Categories A, B, and D)
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Law and Harm – As we mentioned in the <a href="/terms-and-conditions">Terms & Conditions</a>, we
                  think it is very important that all Users behave whilst using the App. We will cooperate with all
                  third parties to enforce their intellectual property or other rights. We will cooperate with
                  lawfully made law enforcement requests for information from within or outside your country of
                  residence where we are required to by law. This may include where there is an investigation into
                  alleged criminal behavior or to protect the vital interests of a person. We may preserve or disclose
                  any of your information, including your Registration Information, if we believe in good faith that
                  it is necessary to comply with a law or regulation, or when we believe in good faith that disclosure
                  is necessary:
                </p>
                <ul>
                  <li>to comply with a binding direction court order, or lawful request;</li>
                  <li>to protect the safety of any person;</li>
                  <li>
                    to address fraud, security or technical issues e.g. through anti-spam providers to protect the
                    service from criminal activity; or
                  </li>
                  <li>
                    <p>to protect our rights or property or those of third parties.</p>
                    <p>In such cases we may raise or waive any legal objection or right available to us.</p>
                  </li>
                </ul>
              </td>
              <td>
                This could include any personal data that DAtP holds about you, depending on the nature of the request
                or the issue that we are dealing with, including all CCPA Categories listed above
              </td>
            </tr>
            <tr>
              <td>
                Business Transfers – In the event that Dating Across The Pond LLC undergoes a business transition or
                change of ownership, such as a merger, acquisition by another company, re-organization, or sale of all
                or a portion of its assets, or in the event of insolvency or administration, we may be required to
                disclose your personal data.
              </td>
              <td>
                This could include all personal data that DAtP holds about you, including all CCPA Categories listed
                above
              </td>
            </tr>
            <tr>
              <td>
                Marketing Services Providers – To help us serve marketing and advertising on third party websites and
                applications and measure the effectiveness of our advertising campaigns. More information on this is
                available below
              </td>
              <td>
                Advertising identifier associated with your device (Device ID), estimated location (based on your IP
                address), age, gender and data about your visit to our Sites or App and action taken on those (for
                example if you downloaded our App or created an account with our App), hashed email address (for
                ‘custom audiences’ only) (CCPA Categories B, C, G, F and K)
              </td>
            </tr>
            <tr>
              <td>
                Anti-Spam and Anti-Fraud – Your data may be shared with other DAtP Group companies, for example, to
                block accounts and suspected fraudulent payment transactions as part of our anti-spam and anti-fraud
                procedures.
              </td>
              <td>
                Email address, phone number, IP address and IP session information, social network ID, username, user
                agent string, and transaction and payment data (CCPA Categories B, F and D).
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Aggregated Information – <b>We will not share</b> aggregated information with third parties that includes
          your personal data, together with other information including log data for industry analysis and demographic
          profiling.
        </p>

        <h5>MORE INFORMATION ABOUT DISCLOSURES</h5>

        <p>Service Providers</p>
        <p>
          We engage certain trusted third parties to perform functions and provide services to us ("Service
          Providers"). The suppliers with which DAtP shares User personal data vary depending on a variety of factors,
          such as which of our App, Sites and services a User engages with. For example, to provide our services to
          Users, we typically use the following suppliers:
        </p>
        <ul>
          <li>
            Billing services – to allow customers to purchase paid features of our App (for example, Google Play)
          </li>
          <li>Authentication services – to allow customers to authenticate their account (for example, Apple ID)</li>
          <li>
            Social media providers – to allow customers to create/connect their DAtP account with their account(s) on
            such platforms (for example Facebook)
          </li>
          <li>
            Product improvement and market research – we may use third party platforms in the future and agencies to
            carry out customer surveys and market research to improve our products and services
          </li>
          <li>
            IT services – some of the third-party software providers used in the operation of our business may process
            Users’ personal data (for example, if a User contacts us via social media with a support inquiry, their
            inquiry is processed by our community management software provider Zoho.com).
          </li>
        </ul>

        <p>
          We carry out due diligence on all Service Providers we engage to ensure they have adequate data protection
          and information security measures in place and only provide them with the personal data necessary to the
          service they are providing. Measures are taken to ensure that the data shared is non-attributable to the
          greatest extent possible and our suppliers are also subject to extensive obligations under our contractual
          arrangements, including strict data retention limits.
        </p>

        <p>Marketing Services Providers</p>
        <p>
          Dating Across The Pond LLC will not pass or sell your information to any 3rd party marketing services
          providers such as Facebook.
        </p>

        <h5>4. WHAT OTHERS MAY SEE ABOUT YOU.</h5>
        <p>
          We think our Users are awesome, and we want you to share how awesome you are with the world, so we have
          built certain features to enable this. Our App is designed to make it easier for you to connect with other
          Users and to interact with them.
        </p>
        <p>
          When using the DAtP App, you should assume that anything you post or submit on the App may be
          publicly-viewable and accessible, both by Users and non-users of the App. We want our Users to be careful
          about posting information that may eventually be made public.
        </p>
        <p>
          Please be careful about posting sensitive details about yourself on your profile such as your religious
          denomination and health details. You may also choose to add sensitive information about yourself when you
          add certain DAtP badges to your profile, such as your religion and political leanings. While you may
          voluntarily provide this information to us when you create your profile, including your sexual preferences,
          there is no requirement to do so. Please remember that photographs that you post on DAtP may reveal
          information about yourself as well. Where you do upload and choose to tell us sensitive information about
          yourself, including through the addition of badges to your profile, you are explicitly consenting to our
          processing of this information and making it public to other Users.
        </p>
        <p>
          Your DAtP profile and other information you make available via the App, including certain information added
          to your profile through third party accounts (such as Facebook, or Instagram – see section 7 of this Privacy
          Policy for more information about this) may be viewed and shared by Users with individuals who may or may
          not be users of the App. For example, a User may recommend you as a match to his or her Facebook friend(s)
          by taking a screenshot of your profile picture and sharing it, regardless of whether such friend(s) is also
          a User of the App.
        </p>
        <p>
          Please also note that in certain countries (currently, South Korea only), Users are provided with
          functionality enabling them to download a copy of their conversation history (as this is required by local
          laws) and that DAtP has no control over how users may then publish or use that information.
        </p>
        <p>
          If you log in to or access the App through your Facebook account or another third-party account on a device
          which you share with others, remember to log out of the App and the relevant third-party account when you
          leave the device in order to prevent other users from accessing your DAtP account.
        </p>

        <h5>5. OUR POLICY TOWARDS AGE.</h5>
        <p>
          Although we want as many people as possible to enjoy our creation, you have to be at least 18 years old to
          use DAtP.
        </p>
        <p>
          DAtP does not knowingly collect any information about or market to children, minors or anyone under the age
          of 18. If we become aware that a child, minor or anyone under the age of 18 has registered with us and
          provided us with personal information, we will take steps to terminate that person’s registration.
        </p>

        <h5>6. SECURITY.</h5>
        <p>
          Here at DAtP, we pride ourselves on taking all appropriate security measures to help protect your
          information against loss, misuse and unauthorized access, or disclosure.
        </p>
        <p>
          We use reasonable security measures to safeguard the confidentiality of your personal information such as
          secured servers using firewalls.
        </p>
        <p>
          Unfortunately, no website or Internet transmission is ever completely 100% secure and even we cannot
          guarantee that unauthorized access, hacking, data loss or other breaches will never occur, but here are some
          handy tips to help keep your data secure:
        </p>
        <ol>
          <li>
            Please make sure you log out of your Account after use as you never know who may stumble onto your
            Account!
          </li>
          <li>Please don’t share the password you use to access your DAtP Account with anyone else!</li>
          <li>Change your password periodically.</li>
        </ol>
        <p>
          If you ever think someone has had access to your password or Account, please change your Facebook password
          or whatever platform you registered for DAtP with. We cannot guarantee the security of your personal data
          while it is being transmitted to our site and any transmission is at your own risk.
        </p>

        <h5>7. LINKING OTHER ACCOUNTS TO DATP.</h5>
        <p>Using your social media details to sign in to DAtP</p>
        <p>
          When you sign in to our App using your Facebook account, you give permission to Facebook to share with us
          your name and profile picture. Unless you opt-out, you also give permission to Facebook to share with us
          your email address (if there is one associated with your Facebook account), date of birth, profile photos,
          gender, Page likes and current town/city.
        </p>
        <p>
          If you register or sign in with your Apple ID, you give Apple permission to share your Apple login, a name
          (that can be edited by you) and an email (you can choose to hide your email and Apple will create a random
          email address so your personal email can stay private). This email address will be linked to your DAtP
          account and will be used to retrieve your DAtP account.
        </p>
        <p>
          We will then use this personal data to form your DAtP account. If you remove the DAtP app from your Facebook
          settings, or from your Apple ID, we will no longer have access to this data. However, we will still have the
          personal data that we received when you first set up your DAtP account using your Facebook or Apple ID (you
          must delete your DAtP account entirely for us to no longer have access to this data).
        </p>

        <h5>8. YOUR CALIFORNIA PRIVACY RIGHTS.</h5>
        <p>
          For Users who are California residents, you have the following rights (in addition to those listed at
          section 10 below) under the California Consumer Privacy Act, and you have the right to be free from unlawful
          discrimination for exercising your rights under the Act:
        </p>
        <ol>
          <li>
            You have the right to request that we disclose certain information to you and explain how we have
            collected, used and shared your personal information over the past 12 months.
          </li>
          <li>
            You have the right to request that we delete your personal information that we collected from you, subject
            to certain exceptions.
          </li>
        </ol>
        <p>
          California’s "Shine the Light" law, Civil Code section 1798.83, requires certain businesses to respond to
          requests from California customers asking about the businesses’ practices related to disclosing personal
          information to third parties for the third parties’ direct marketing purposes. If you wish to find out about
          any rights you may have under California Civil Code section 1798.83, you can write to us at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>.
        </p>
        <p>
          In addition, under California law, operators of online services are required to disclose how they respond to
          "do not track" signals or other similar mechanisms that provide consumers the ability to exercise choice
          regarding the collection of personal information of a consumer over time and across third party online
          services, to the extent the operator engages in that collection. At this time, we do not track our Users’
          personal information over time and across third-party online services. This law also requires operators of
          online services to disclose whether third parties may collect personal information about their users’ online
          activities over time and across different online services when the users use the operator’s service. We do
          not knowingly permit third parties to collect personal information about an individual User’s online
          activities over time and across different online services when using the App.
        </p>

        <h5>9. YOUR RIGHTS.</h5>
        <p>Privacy laws applicable in your country may give you the following rights:</p>
        <ol>
          <li>
            Right to be informed: what personal data an organization is processing and why (we provide this
            information to you in this Privacy Policy).
          </li>
          <li>Right of access: you can request a copy of your data.</li>
          <li>Right of rectification: if the data held is inaccurate, you have the right to have it corrected.</li>
          <li>Right to erasure: you have the right to have your data deleted in certain circumstances.</li>
          <li>
            Right to restrict processing: in limited circumstances, you have the right to request that processing is
            stopped but the data retained.
          </li>
          <li>
            Right to data portability: you can request a copy of certain data in a machine-readable form that can be
            transferred to another provider.
          </li>
          <li>
            Right to object: in certain circumstances (including where data is processed on the basis of legitimate
            interests or for the purposes of marketing) you may object to that processing.
          </li>
          <li>
            Rights related to automated decision-making including profiling: there are several rights in this area
            where processing carried out on a solely automated basis results in a decision which has legal or
            significant effects for the individual. In these circumstances your rights include the right to ensure
            that there is human intervention in the decision-making process.
          </li>
        </ol>
        <p>
          The particular rights which are applicable to you (which might include other rights not listed above) may
          vary depending on your country. You should make yourself aware of the rights you have under applicable
          privacy laws in your country.
        </p>
        <p>
          If you want to exercise any of your rights listed above please visit our Contact Us Page or email our Data
          Protection Officer at <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>.
          You can also contact us by post at the following address: 1133 South Allen Street, State College, PA, 16801,
          USA. For your protection and the protection of all of our Users, we may need to request specific information
          from you to help us confirm your identity before we can answer the above requests.
        </p>
        <p>
          If you have a concern about how we have processed your request or your personal data, you should contact us
          in the first instance via the contact details listed above.
        </p>
        <p>
          If you feel we have not resolved your concern, you have the right to make a complaint at any time to the
          Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues
          (www.ico.org.uk). If you live in a country or territory located in the European Union (EU) or European
          Economic Area (EEA), you may also get in touch with your local Data Protection Regulator. If you live in a
          country outside the EU, you may have the right to lodge a complaint with your local privacy or data
          protection regulator.
        </p>
        <p>EU Representative</p>
        <p>
          Pursuant to Article 27 of the General Data Protection Regulation (GDPR), DAtP will be the contact regarding
          matters pertaining to the GDPR by emailing us with the direct request at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>
        </p>

        <h5>10. DATA LOCATIONS.</h5>
        <p>
          We want you to be able to access DAtP wherever you happen to be in the world. To enable us to provide that
          service, we operate a global network of servers including in the US, UK, EU, and (for Users located in
          Russia) Russia. The hardware is located in third-party data centers but is owned by Amazon Web Services
          (AWS).
        </p>

        <h5>11. DATA RETENTION AND DELETION.</h5>
        <p>
          We keep your personal information only as long as we need it for the legal basis relied upon (as set out in
          Section 2 above) and as permitted by applicable law. Please see "Profile Verification Information (Including
          Biometric Information)" in Section 1, above, to learn about our retention policy with respect to biometric
          information.
        </p>
        <p>
          When your Account is deleted, we make sure it is no longer viewable in the App. For up to 1 day, it is still
          possible to restore your Account if it was accidentally deleted. After 1 day, we begin the process of
          deleting your personal information from our systems, unless:
        </p>
        <ol>
          <li>
            we must keep it to comply with applicable law (for instance, if you make purchases within the App, some
            personal data may be kept for tax and accounting purposes);
          </li>
          <li>
            we must keep it to evidence our compliance with applicable law (for example, if an account is blocked, we
            keep some account information and a record of the behavior that led to the block - this information is
            retained for evidential purposes in case of queries or legal claims concerning the block);
          </li>
          <li>
            there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is
            resolved; or
          </li>
          <li>
            the information must be kept for our legitimate business interests, such as fraud prevention and enhancing
            Users’ safety and security (for example, information may need to be kept to prevent a user who was banned
            for unsafe behavior or security incidents from opening a new account).
          </li>
        </ol>

        <p>
          Warning: Even after you remove information from your profile or delete your Account, copies of that
          information may still be viewable and/or accessed to the extent such information has been previously shared
          with others, or copied or stored by others. We cannot control this, nor do we accept any liability for this.
          If you have given third party applications or websites access to your personal information, they may retain
          such information to the extent permitted under their terms of service or privacy policies.
        </p>

        <p>Phew! You completed the reading, most members just click past.</p>

        <h5>12. CHANGES TO THIS POLICY.</h5>
        <p>
          As DAtP evolves, we may revise this Privacy Policy from time to time. The most current version of the policy
          will govern our use of your information and will always be at{' '}
          <a href="/privacyPolicy">datingacrossthepong.com/privacy</a>. If we make a change to this policy that, in
          our sole discretion, is material, we will notify you, for example, via an email to the email associated with
          your Account or by posting a notice within DAtP.
        </p>

        <dl>
          <dt>Effective date</dt>
          <dd>This Privacy Policy was last updated 25th October 2023.</dd>
        </dl>
        <br />
      </div>
    </div>
  )
}

export default PrivacyPolicy
