module.exports = {
    _API_BASE_URL: "http://test-datp-env.eba-gpgv7ayh.eu-west-1.elasticbeanstalk.com",
    API_BASE_URL: "/API/V1",
    BASE_URL: "http://test-datp-env.eba-gpgv7ayh.eu-west-1.elasticbeanstalk.com",

    STRIPE_S_KEY: "sk_test_51OPQlVAutSmhs03stEwp0HSq2miJhGBtu2h1wL2EcK0ZQtVdNf0avjeUwBVMZcqQCatL3woalG0p41r3Ju7EexZC000KqHT6AA",
    STRIPE_P_KEY: "pk_test_51OPQlVAutSmhs03sphgoZPhvn8bnPZs2BaCHulZpVoxflRdwzJ0QDxmHGezWVrae3E9PibLeeiXPyjxarUlIytPs00Qj0R83V4",

    GCP_MAPS_API_KEY: "AIzaSyBVseDa3i0Hf8g1L8qfSrOwOV28OgjWVy0",
    GEOCODE_BASE_URL:"https://maps.googleapis.com/maps/api/geocode",

    FACEBOOK_APP_ID: "REDACTED",
    GOOGLE_APP_ID: "REDACTED",
    APPLE_CLIENT_ID: "REDACTED",
    APPLE_REDIRECT_URL: "http://test-datp-env.eba-gpgv7ayh.eu-west-1.elasticbeanstalk.com/discover",
    EMAIL_SCOPE: "email name",

    ROLLBAR_KEY: "6b6634472ae248ed8cf126a6b8bb320e",
    ROLLBAR_ENV: "testenv",
    USERBACK_KEY: "A-5F2E816dXa1Dd4sgRObW4W54Z",
}