import * as jt from '@js-joda/core'
// import * as jttz from '@js-joda/timezone'
// import * as jtl from '@js-joda/locale' // cldr-data - here be dragons
import * as jtl from '@js-joda/locale_en-us'

export const formatDateTimeAbsolute = (x:jt.Instant | jt.LocalDateTime,format:string='eeee, MMM d, u h:mm:ssa') => {
  const datetime =
      x instanceof jt.Instant
    ? x.atZone(jt.ZoneId.SYSTEM).toLocalDateTime()
    : x
  return datetime.format(jt.DateTimeFormatter.ofPattern(format).withLocale(jtl.Locale.US)) // TODO: get user's locale?
}

export const formatDateAbsolute = (x:jt.Instant | jt.LocalDateTime | jt.LocalDate,asof:jt.Instant=jt.Instant.now()) => {
  const date =
      x instanceof jt.Instant
    ? x.atZone(jt.ZoneId.SYSTEM).toLocalDate()
    : x instanceof jt.LocalDateTime
    ? x.toLocalDate()
    : x
  return date.format(jt.DateTimeFormatter.ofPattern('eeee, MMM d, u').withLocale(jtl.Locale.US)) // TODO: get user's locale?
}

export const formatDateRelative = (x:jt.Instant | jt.LocalDateTime | jt.LocalDate,now:jt.Instant=jt.Instant.now()) => {
  const date =
      x instanceof jt.Instant
    ? x.atZone(jt.ZoneId.SYSTEM).toLocalDate()
    : x instanceof jt.LocalDateTime
    ? x.toLocalDate()
    : x
  const asof = now.atZone(jt.ZoneId.SYSTEM).toLocalDate()
  return {
    [-1]:'Tomorrow',
    [ 0]:'Today',
    [ 1]:'Yesterday',
    [ 2]:'Two days ago',
  }[Math.floor(jt.Period.between(date,asof).days())] || formatDateAbsolute(x,now)
}
export const formatDateTime = formatDateTimeAbsolute

export const formatTimeAbsolute = (x:jt.Instant | jt.LocalDateTime | jt.LocalTime,now:jt.Instant=jt.Instant.now()) => {
  const time =
      x instanceof jt.Instant
    ? x.atZone(jt.ZoneId.SYSTEM).toLocalTime()
    : x instanceof jt.LocalDateTime
    ? x.toLocalTime()
    : x
  return time.format(jt.DateTimeFormatter.ofPattern('h:mma').withLocale(jtl.Locale.US)) // TODO: get user's locale?
}

export function formatDateToLocalStandard(date: Date): string {
  const userLocale = navigator.language || 'en-US'

  const formatter = new Intl.DateTimeFormat(userLocale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
  })
  return formatter.format(date)
}

export const formatTimeRelative = (x:jt.Instant | jt.LocalDateTime | jt.LocalTime,now:jt.Instant=jt.Instant.now()) => {
  return formatTimeAbsolute(x,now) // TODO: implement
}

export function formatPrice(priceInPennies: number,currency: string): string {
  const price = priceInPennies / 100;
  const locale = 'en-US';

  const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });

  return formatter.format(price);
}

export default {
  formatDateTimeAbsolute,
  formatDateAbsolute,
  formatDateRelative,
  formatDateTime,
  formatTimeAbsolute,
  formatTimeRelative,
  formatPrice
}
