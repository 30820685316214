import React from 'react'
import { Carousel } from 'primereact/carousel'
import { UserQuestionsResponses } from '../../../../../../../common/types/user'

interface QuestionCarouselProps {
  questions: UserQuestionsResponses[]
}

function QuestionBoxTemplate(question: UserQuestionsResponses) {
  return (
    <div className="card border question-content my-2">
      <h4 className="text-center">{question.toQuestion.question}</h4>
      <div className="my-3">
        <p className="text-center">
          {/* {question.response} */}
          {/* {question.toQuestion.response.find((x:any) => x.id === question.questionOptionId).display} */}
          {question.toQuestion.response}
          {question.toQuestion.options.find((x:any) => x.id === question.questionOptionId)!.display}
        </p>
      </div>
    </div>
  )
}

const QuestionsCarousel: React.FC<QuestionCarouselProps> = ({ questions }) => {
  return (
    <Carousel
      value={questions}
      numVisible={1}
      numScroll={1}
      orientation="horizontal"
      verticalViewPortHeight="160px"
      itemTemplate={QuestionBoxTemplate}
      showIndicators={false}
    />
  )
}

export default QuestionsCarousel
