import React, { useEffect, useRef, useState } from 'react'
import { getUserById, getUserQuestions } from '_services/users.service'
import { User, UserQuestionsResponses } from 'types/user'
import { getAgeFromBirthday } from '../../../../../../common/util/helpers.converters'
import PhotoGallery from './components/PhotoGallery'
import QuestionsCarousel from './components/QuestionsCarousel'
import { Chip } from 'primereact/chip'
import AboutMeCard from './components/AboutMeCard'
import ExtraInfoBoard from './components/ExtraInfoCard'
import { WrapperCard } from '../../../../components/cards/WrapperCardNew'
import DislikeButton from '../../../../components/buttons/DislikeButton'
import LikeButton from '../../../../components/buttons/LikeButton'
import ReportUser from '../../../../components/buttons/ReportUserButton'
import { useUpdateEffect } from 'primereact/hooks'
import { blockUser, reportUser } from '_services/actions.services'
import { useFetchV2 } from '../../../../_core/hooks'
import { authStore } from '../../../../_core/stores/authStore'

type ProfileBoxProps = {
  userId: string
  actionButtons?: React.ReactNode
  title?: string
  refresh?: number
  showBlockReport?: boolean
  showLikeDislike?: [(_:string) => void,(_:string) => void]
}

const ProfileBox: React.FC<ProfileBoxProps> = (props) => {
  const [currentUser,reloadCurrentUser] = useFetchV2<User>(async () => {
    return getUserById(authStore.userID)
  })
  const [user, setUser] = useState<Promise<User>>(new Promise(() => { }))
  const [userQuestions, setUserQuestions] = useState<UserQuestionsResponses[]>([])
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [reportReason, setReportReason] = useState<string>('')

  useEffect(() => {setUser(getUserById(props.userId)) }, [props.userId])

  useUpdateEffect(() => {
    if (props.refresh && props.refresh > 2)
      setUser(getUserById(props.userId))
  }, [props.refresh]);

  const fetchData = async () => {
    try {
      setUserQuestions((await getUserQuestions(props.userId)).data)
    } catch (error) {
      error
    }
  }
  useEffect(() => { fetchData() }, [props.userId])

  const handleReport = async () => {
    if (props.userId) {
      await reportUser(props.userId)
      await blockUser(props.userId)
      setReportReason('')
      setIsDialogVisible(false)
      setTimeout(fetchData, 500)
    } else {
      console.error("Error reporting user: No user data available.")
    }
  }

  return (
    <WrapperCard
      dependency={Promise.all([user,currentUser])}
      structure={{ 'className': 'd-flex flex-column flex-grow-1 h-100', 'children': ['flex-grow-6 mb-1'].concat(Array(3).fill('flex-grow-_5 mb-1')) }}
      className="m-auto w-100"
      style={{ 'maxWidth': '700px' }}
      title={props.title}
    >
      {([user,currentUser]) =>
        <>
          <PhotoGallery
            photos={user.userPhotos}
            videos={currentUser.userVideoInterviews.length === 0 && user.userVideoInterviews.length > 0 ? null : user.userVideoInterviews}
          >
            {props.showLikeDislike &&
            <div className="position-relative">
            <div
              className="d-flex justify-content-center gap-3 position-absolute w-100"
              style={{ height: '100px',transform:'translateY(-50%)' }}
            >
              <DislikeButton onPress={() => props.showLikeDislike![1](props.userId)} />
              <LikeButton onPress={() => props.showLikeDislike![0](props.userId)} />
            </div>
            </div>
            }
            <div className="d-flex justify-content-between pt-3 px-3">
              <h3 className="text-start strong ">
                <strong>
                  {user.firstName} {user.lastName}
                </strong>
              </h3>
              <h3 className="text-end">
                <strong>{user.userMetadata && user.userMetadata.birthday ? getAgeFromBirthday(user.userMetadata.birthday) : ''}</strong>
              </h3>
            </div>
            <div className="rounded px-3">
              <div className="text-start ">
                <div className="d-flex justify-content-between">
                  <div className="text-start">{user.userMetadata && user.userMetadata.occupationName ? user.userMetadata.occupationName : ''}</div>
                  <div className="text-end">{user.countryFriendlyName}</div>
                </div>
              </div>
            </div>
          </PhotoGallery>
          <div className="px-3">
            <AboutMeCard aboutMeText={user.userMetadata?.aboutMe ?? 'No about me given.'} />
          </div>
          <h4 className="px-3">Bio</h4>
          <div className="px-3">
            <ExtraInfoBoard userMetadata={user.userMetadata!} />
          </div>
          <h4 className="text-start px-3">Interests</h4>
          <div className="d-flex flex-wrap px-3">
            <ul className="list-unstyled w-100 d-flex flex-wrap">
              {user.userInterest.map((interest, index) => (
                <li key={index} className="chip-item">
                  <Chip label={interest.EnumInterest.display} />
                </li>
              ))}
            </ul>
          </div>
          <div className="px-3">
            <QuestionsCarousel questions={userQuestions} />
          </div>
          <>
            {props.actionButtons}
            <div className='my-3'>
              {props.showBlockReport &&
                <ReportUser
                  openDialog={() => setIsDialogVisible(true)}
                  closeDialog={() => setIsDialogVisible(false)}
                  isDialogVisible={isDialogVisible}
                  handleReport={handleReport}
                  setReportReason={setReportReason}
                  reportReason={reportReason}
                />
              }
            </div>
          </>
          <div className="rose-box my-3"></div>
        </>
      }
    </WrapperCard>
  )
}

export default ProfileBox
