import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { PrimeReactProvider } from 'primereact/api'
import 'primereact/resources/themes/saga-blue/theme.css' // You can change the theme
import 'primereact/resources/primereact.css'
import { Provider, ErrorBoundary } from '@rollbar/react'
import { UserbackProvider } from '@userback/react'
import { ROLLBAR_KEY, ROLLBAR_ENV, USERBACK_KEY } from '@env'

const rollbarConfig = {
  accessToken: ROLLBAR_KEY,
  environment: ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <UserbackProvider token={USERBACK_KEY}>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <PrimeReactProvider>
            <App />
          </PrimeReactProvider>
        </ErrorBoundary>
      </Provider>
    </UserbackProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
