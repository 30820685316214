import React, { useEffect, useState } from 'react'
import { approvePhotos, ApproveUserProfile, approveVerifyPhotos, banUser, denyPhotos, denyUserProfile, denyVerifyPhotos, getApprovalPhotos, getVerifyPhotos, unbanUser } from '../../../../../../common/_services/admin.service'
import ReviewPhoto from '../../_components/reviewPhoto'
import { User, UserPhoto, UserVerifyPhoto } from '../../../../../../common/types/user'
import NoData from '../../../../components/noData'
import ProfileBox from '../../_components/profile/profileBox'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from 'primereact/datatable'
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent, ColumnProps, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { getUsers } from '_services/users.service'
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Tag } from 'primereact/tag'
import SidebarWrapper from '../../_components/profile/components/SidebarWrapper'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { TabPanel, TabView } from 'primereact/tabview'
import { PhotoWithName } from './userApproval'

interface photoReviewProps {
  photos: PhotoWithName[]
  onAction: () => {}
}

const PhotoReview: React.FC<photoReviewProps> = (props:photoReviewProps) => {
  const [users, setUser] = useState<User[]>([])
  const [viewUser, setViewUser] = useState<string>('');

  return (
      <div className="p-3">
          <DataTable
            value={props.photos}
          >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
              <Column
                bodyClassName={(y,o) =>  y.sort === 1 ? 'first-photo' : ''}
                field="photo"
                header="Photo"
                style={{ width: '200px' }}
                body={(y,o) => <Image onClick={() => {setViewUser(y.userId)}}  src={y.imgUrl } alt="Image" width="200px" className="shadow-4" />}>
              </Column>
              <Column
                field="name"
                header="Name"
                body={(y,o) => <>{y.firstName} {y.lastName}</>}>
              </Column>
              <Column field="id"
                header="Action"

                body={(y,o) =>
                  <>
                    <Button label="Deny" className="btn btn-secondary" onClick={() => {
                      denyPhotos([y.imgUrl],[y.userId],props.onAction)
                    }}/>
                    <Button label="Approve" className="btn btn-primary mx-2" onClick={() => {
                      approvePhotos([y.imgUrl],[y.userId],props.onAction)
                    }}/>
                  </>
                }>
              </Column>
          </DataTable>
      </div>
  )
}



export default PhotoReview
