import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../../_core/hooks'
import { getRandomVerifyPhoto } from '_services/enums.service'
import { EnumVerifyPhoto } from 'types/enums.options'
import { Button } from 'primereact/button'
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload'
import blankImage from '../../../../assets/images/no_profile_image@2x.png'
import { Image } from 'primereact/image';
import { confirmVerifyUpload, requestMediaUrl } from '_services/users.service'
import { uploadImageToPresignedUrl } from '_services/fetches/S3upload'
import Webcam from "react-webcam"

export type ProfileVerifyProps = {
  onClose: () => void
}

export const ProfileVerify: React.FC<ProfileVerifyProps> = (props) => {
  const [verifyPhoto, setVerifyPhoto] = useState<EnumVerifyPhoto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [verificationImageSource, setVerificationImageSource] = useState<string | null>(null)
  const webcamRef = React.useRef(null)

  const fetchVerifyPhoto = async () => {
    setVerifyPhoto((await getRandomVerifyPhoto()).data)
  }

  useFetch(
    () =>  getRandomVerifyPhoto(),
    (x) => {
      if(x != undefined)
      {
        setVerifyPhoto(x.data)
      }
    },
    []
  )

  const customBase64Uploader = async (file:string) => {
    setLoading(true)
    let blob = await (await fetch(file)).blob()

    await requestMediaUrl(
      blob.type,
      async (res) => {
        await uploadImageToPresignedUrl(res.data.url, blob)
        await confirmVerifyUpload(
          res.data.id,
          verifyPhoto?.id || '',
          (e) => {
            props.onClose()
            setLoading(false)
          }
        )
      },
      (e) => {
        setLoading(false)
      },
    )
}

const capture = React.useCallback(
  () => {
    const imageSrc = webcamRef.current!.getScreenshot()
    setVerificationImageSource(imageSrc)
  },
  [webcamRef]
);

  return (
    <div className="d-flex flex-column">
      <h5 className="m-0 text-center">
        To get verified copy the pose below and take a photo matching the pose, we will check to make sure the photos match.
      </h5>
      <div className="my-2 d-flex justify-content-evenly">
        <div className='flex-column d-flex align-self-end text-center'>
          <Image className='p-1' src={verifyPhoto?.photoUrl} alt="Pose Image" width='221' />

          <Button className="btn link-primary" onClick={() => fetchVerifyPhoto()}>New Pose</Button>
        </div>
        <div className='d-flex flex-column align-self-end'>
           {  verificationImageSource == null ?
                    <>
                      <Webcam
                        className='d-flex flex-column align-self-end'
                        mirrored
                        audio={false}
                        height={294}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={221}
                        videoConstraints={{
                          width: 221,//450
                          height: 294,
                          facingMode: "user"
                        }}
                      />
                      <Button className="btn btn-primary"onClick={capture}>Capture photo</Button>
                    </>
                  :
                    <>
                      <Image className='p-1' src={verificationImageSource} alt="Image" width="221" />
                      <Button className="btn link-primary"onClick={() => {setVerificationImageSource(null)}}>Reset Photo</Button>
                    </>

            }
        </div>
      </div>

        <p className="m-0 text-center">
          Pictures are only used to verify you and wont be posted on your account.
          For more information on how we use, retain, and protect your personal data, please read our<br/> <a href="/privacy-policy">privacy policy</a>
        </p>
        <Button className="btn btn-primary my-2" loading={loading} disabled={!verificationImageSource} onClick={() => {customBase64Uploader(verificationImageSource || '')}}>Upload Confirm Photo</Button>

      {/* <FileUpload
        className='text-center my-2'
        chooseLabel='Upload'
        mode="basic"
        name="demo[]"
        accept="image/*"
        maxFileSize={25000000}
        uploadLabel='TDOG DOG EST'
        uploadOptions={{label:'testtestest'}}
        uploadHandler={customBase64Uploader}
        customUpload
        onSelect={(e) => {setVerificationImageSource(e)}}
      /> */}
    </div>
  )
}
