import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaExclamationCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const PaymentNotifications: React.FC = () => {
  const [successPayment, setSuccessPayment] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/discover')
    }, 5000)

    return () => clearTimeout(timer)
}, [])
  const SuccessPaymentMessage = () => {
    return (
      <div className="">
        <div className="text-center mb-3">{<FaCheckCircle size={180} color="lightgreen" />}</div>
        <p className="text-center fs-3 fw-bolder">Your payment was successful</p>
        <p className="text-center fs-4">Thank you for becoming a premium member!</p>
      </div>
    )
  }

  const FailedPaymentMessage = () => {
    return (
      <div className="">
        <div className="text-center mb-3">{<FaExclamationCircle size={180} color="lightpink" />}</div>
        <p className="text-center fs-3 fw-bolder">Your payment has failed</p>
        <p className="text-center fs-4">
          Your payment was not successfully processed.
          <br />
          Please contact our customer support.
        </p>
      </div>
    )
  }

  return (
    <div>
      {successPayment === true ? <SuccessPaymentMessage /> : <FailedPaymentMessage />}
      <div className="text-center my-4">
        <p className="text-center">you will be redirected to discover if not press the link:</p>
        <Button
          link
          label="Return to Discover"
          onClick={() => {
            navigate('/discover')
          }}
        ></Button>
      </div>
    </div>
  )
}

export default PaymentNotifications
