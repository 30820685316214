'use client'

import { makeAutoObservable } from 'mobx'
import jwt_decode from 'jwt-decode'
import { setAxiosErrorInterceptor, setAxiosJWTInterceptor } from '_services/config'

interface JWTContents {
  ID: string
  permissions: string
  unique_name: string
  img: string
}
class AuthStore {
  token: string | null = null
  permissions: string[] = []
  name: string = ''
  img: string = ''
  userID: string = ''

  constructor() {
    makeAutoObservable(this)
    const storedToken = localStorage.getItem('userToken')
    if (storedToken) {
      this.setToken(storedToken)
    }
  }

  setToken(jwt: string) {
    setAxiosJWTInterceptor(jwt)
    setAxiosErrorInterceptor(
      () => this.clearToken(),
      () => {}
    )
    const decoded: JWTContents = jwt_decode(jwt)
    this.token = jwt
    this.permissions = decoded.permissions ? decoded.permissions.split(',') : []
    this.userID = decoded.ID
    this.name = decoded.unique_name
    this.img = decoded.img
    localStorage.setItem('userToken', jwt)
  }

  clearToken() {
    this.token = null
    this.permissions = []
    this.userID = ''
    this.name = ''
    this.img = ''
    localStorage.removeItem('userToken')
  }

  hasPermission(permission: string) {
    return this.permissions.includes(permission)
  }

  get isAuthenticated() {
    return this.token !== null
  }

  logout(callback: () => void) {
    this.clearToken()
    callback()
  }
}

export const authStore = new AuthStore()
