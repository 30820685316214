import React, { useEffect, useRef } from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import heartPop from '../../assets/Lottie/HeartPopv2.json'


interface DislikeButtonProps {
  onPress: () => void
}

const LikeButton: React.FC<DislikeButtonProps> = ({ onPress }) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  useEffect(() => {
    const lottie = lottieRef.current
    if (lottie) {
      lottie.goToAndStop(0)
    }
  })

  const submit = () => {
    const lottie = lottieRef.current
    if (lottie) {
      lottie.goToAndPlay(0)
      onPress()
    }
  }

  return (
    <>
      <Lottie lottieRef={lottieRef} animationData={heartPop} loop={false} className='scale-up-style' onClick={submit} />
    </>
  )
}

export default LikeButton
