import React, { useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { SelectButton } from 'primereact/selectbutton'
import { MINIMUM_HEIGHT_CM, MINIMUM_HEIGHT_FT, MINIMUM_HEIGHT_IN, toCentimeters, toFeetInches } from 'util/helpers.converters'
import { FloatLabel } from 'primereact/floatlabel'

type HeightInputProps = {
  initialValue: number
  onChange: (value: number) => void
  errors: string | undefined
}

function getPreferredUnit() {
    const lang = navigator.language.toLowerCase()
    return ['en-us', 'en-gb'].includes(lang) ? 'ft/in' : 'cm'
}

const HeightInput: React.FC<HeightInputProps> = (props) => {
  const [heightFeet, setHeightFeet] = useState<number>(props.initialValue ? toFeetInches(props.initialValue).feet : 4)
  const [heightInches, setHeightInches] = useState<number>(toFeetInches(props.initialValue).inches || 1)
  const [heightCm, setHeightCm] = useState<number>(props.initialValue)
  const [unitPref, setUnitPref] = useState(getPreferredUnit())


  useEffect(() => {
    if (unitPref == 'cm') {
      const { feet, inches } = toFeetInches(heightCm)
      setHeightFeet(feet)
      setHeightInches(inches)
    } else {
      const cmValue = toCentimeters(heightFeet, heightInches)
      setHeightCm(Math.round(cmValue))
    }
    props.onChange(heightCm)
  }, [heightCm, heightFeet, heightInches])

  return (
    <div className="w-100">
      <div className="d-flex flex-grow-1 gap-2">
        {unitPref == 'ft/in'
          ?
            <>
              <div className="w-50">
                <FloatLabel>
                  <InputNumber
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    value={heightFeet}
                    onValueChange={(e) => setHeightFeet(e.value || MINIMUM_HEIGHT_FT)}
                    // showButtons
                    placeholder="ft"
                    mode="decimal"
                    min={4}
                    max={7}
                  />
                  <label htmlFor="number-input">Feet</label>
                </FloatLabel>
              </div>
              <div className="w-50">
                <FloatLabel>
                  <InputNumber
                    id="number-input"
                    name ="number-input"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    value={heightInches}
                    onValueChange={(e) => setHeightInches(e.value || MINIMUM_HEIGHT_IN)}
                    // showButtons
                    placeholder="in"
                    mode="decimal"
                    min={0}
                    max={11}
                  />
                  <label htmlFor="number-input">Inches</label>
                </FloatLabel>
              </div>
            </>
          :
            <div className="w-100">
              <FloatLabel>
                <InputNumber
                  buttonLayout="horizontal"
                  id="number-input"
                  name="number-input"
                  value={heightCm}
                  onValueChange={(e) => setHeightCm(e.value || MINIMUM_HEIGHT_CM)}
                  // showButtons
                  placeholder="cm"
                  mode="decimal"
                  min={120}
                  max={242}
                />
                <label htmlFor="number-input">Centimeters</label>
              </FloatLabel>
            </div>
        }
        <div className="text-nowrap">
          <SelectButton
            value={unitPref}
            onChange={(e:any) => setUnitPref(e.value)}
            options={['cm', 'ft/in']}
          />
        </div>
      </div>
      {!!props.errors &&
        <small id={'height-error'} className="text-danger">
          {props.errors}
      </small>}
    </div>
  )
}

export default HeightInput
