import React, { useState } from 'react'
import { EnumUserQuestionsData } from 'types/enums.options'
import { getUserQuestionsEnum } from '_services/enums.service'
import { useFetch } from '../../../../_core/hooks'
import { Dropdown } from 'primereact/dropdown'
import { getUserQuestions, updateProfile } from '_services/users.service'
import { UserQuestionsResponses } from 'types/user'
import { FormType, IncomingFormPayload } from 'types/payloads.users'
import { Button } from 'primereact/button';

type QuestionsInputProps = {
  userId: string
  onClose: () => void
}

const QuestionsInput: React.FC<QuestionsInputProps> = (props) => {
  const [questions, setUserQuestions] = useState<EnumUserQuestionsData[]>([])
  const [answers, setAnswers] = useState<UserQuestionsResponses[]>([])

  const handleDropdownChange = async (e: { value: any; target: { id: string, value:string } }) => {
    const { id, value } = e.target;
    const payload: IncomingFormPayload = {
      FormType: FormType.UserQuestion,
      Data: {
        questionId: id,
        response: value,
      }
    }
    await updateProfile(payload)
    let resAnswers = await getUserQuestions(props.userId)
    setAnswers(resAnswers.data)
  }

  useFetch(
    () => Promise.all([
      getUserQuestionsEnum(),
      getUserQuestions(props.userId),
    ]),
    (x) => {
      if(x != undefined)
      {
        const [resQuestions,resAnswers] = x
        setUserQuestions(resQuestions.data)
        setAnswers(resAnswers.data)
      }
    },
    []
  )

  return (
    <div className="">
      {questions.map((x) =>
        <div key={x.id} className="d-flex flex-column gap-2">
          <label htmlFor={x.id}>{x.question}</label>
          <Dropdown
            id={x.id}
            name={x.id}
            options={x.options.sort((a,b) => a.sort - b.sort)}
            optionLabel="display"
            optionValue="id"
            value={answers.find(y => y.questionId == x.id)?.questionOptionId || ''}
            onChange={handleDropdownChange}
          />
        </div>
      )}
        <Button className="btn btn-primary w-100" type="button" onClick={() => props.onClose()}>Save</Button>
    </div>
  )
}

export default QuestionsInput
