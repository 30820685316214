interface BasicDataForm {
  FirstName: string
  LastName: string
  Birthday: string
}

interface EmailForm {
  Email: string
}

type SexForm = {
  Sex: string
}

type DesiredSexForm = {
  PreferredSex: string
}

type EducationLevelForm = {
  HighestLevel: string
}

type LifestyleForm = {
  HasOrWantsKids: string
  SmokeTobacco: string
  DrinkAlcohol: string
  SmokeWeed: string
}

type ReligiousBeliefsForm = {
  ReligiousBelief: string
}

type PoliticalBeliefsForm = {
  PoliticalBelief: string
}

type OccupationForm = {
  OccupationName: string
}

type HeightMeasurementForm = {
  measurement: string | null
}

type NationalityForm = {
  isoCode: string[]
}

type InterestsForm = {
  interests: string[]
}

type AboutMeForm = {
  inputText:  string
}

type BodyTypeForm = {
  bodyType:  string
}

type UserQuestionForm = {
  questionId: string
  response: string
}

type EducationBackgroundForm = {
  schoolType: string
  schoolName: string
  graduationYear: string
}

type LocationInfoForm = {
  longitude: number
  latitude: number
  country: string
}

type IncognitoStateForm = {
  isIncognito: boolean
}

export type OnboardingForm =
  BasicDataForm &
  EmailForm &
  SexForm &
  DesiredSexForm &
  EducationLevelForm &
  LifestyleForm &
  ReligiousBeliefsForm &
  PoliticalBeliefsForm &
  OccupationForm &
  HeightMeasurementForm &
  NationalityForm &
  AboutMeForm &
  BodyTypeForm &
  LocationInfoForm

export enum FormType {
  BasicData             = "BasicData",
  Email                 = "Email",
  Sex                   = "Sex",
  DesiredSex            = "DesiredSex",
  EducationLevel        = "EducationLevel",
  Lifestyle             = "Lifestyle",
  ReligiousBeliefs      = "ReligiousBeliefs",
  PoliticalBeliefs      = "PoliticalBeliefs",
  Occupation            = "Occupation",
  HeightMeasurement     = "HeightMeasurement",
  Nationality           = "Nationality",
  Interests             = "Interests",
  AboutMe               = "AboutMe",
  BodyType              = "BodyType",
  UserQuestion          = "UserQuestion",
  EducationBackground   = "EducationBackground",
  LocationInfo          = "LocationInfo",
  IncognitoState        = "IncognitoState",
  OnboardingForm        = "OnboardingForm",
}

interface FormTypeToDataMap {
 [FormType.BasicData           ] : BasicDataForm,
 [FormType.Email               ] : EmailForm,
 [FormType.Sex                 ] : SexForm,
 [FormType.DesiredSex          ] : DesiredSexForm,
 [FormType.EducationLevel      ] : EducationLevelForm,
 [FormType.Lifestyle           ] : LifestyleForm,
 [FormType.ReligiousBeliefs    ] : ReligiousBeliefsForm,
 [FormType.PoliticalBeliefs    ] : PoliticalBeliefsForm,
 [FormType.Occupation          ] : OccupationForm,
 [FormType.HeightMeasurement   ] : HeightMeasurementForm,
 [FormType.Nationality         ] : NationalityForm,
 [FormType.Interests           ] : InterestsForm,
 [FormType.AboutMe             ] : AboutMeForm,
 [FormType.BodyType            ] : BodyTypeForm,
 [FormType.UserQuestion        ] : UserQuestionForm,
 [FormType.EducationBackground ] : EducationLevelForm,
 [FormType.LocationInfo        ] : LocationInfoForm,
 [FormType.IncognitoState      ] : IncognitoStateForm,
 [FormType.OnboardingForm      ] : OnboardingForm,

}

export type IncomingFormPayload = {
  [K in FormType]: {
    FormType: K;
    Data: FormTypeToDataMap[K] | null;
  }
}[FormType];


export function createFormPayload<T extends FormType>(
  formType: T,
  formData: FormTypeToDataMap[T] | null
): { FormType: T; Data: FormTypeToDataMap[T] | null } {
  return {
    FormType: formType,
    Data: formData,
  };
}
