import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaSlidersH } from 'react-icons/fa'
import { Tooltip } from 'primereact/tooltip'
import { Dock } from 'primereact/dock'

import useResize from '../../../_core/hooks/useResize'
import BreakpointRender from '../../../_core/components/breakpoint-render'
import { dislikeUser, likeUser } from '../../../../../common/_services/likes.service'
import { getRandomUserId } from '../../../../../common/_services/users.service'

import ProfileBox from '../_components/profile/profileBox'
import HotListMessages from '../_components/hotlistMessages'
import MessagesBox from '../_components/messagesBox'
import SidebarWrapper from '../_components/profile/components/SidebarWrapper'
import UserPreferences from '../_components/profile/components/UserPreferences'
import Loader from '../../../components/loader'
import NoData from '../../../components/noData'
import LikesMe from '../screens/web-mobile/likes-me'
import Chat from '../screens/web-mobile/chat'
import ReportUser from '../../../components/buttons/ReportUserButton'
import { useUpdateEffect } from 'primereact/hooks'

const undefinedToNull = <a,>(x:a | undefined) : a | null => x === undefined ? null : x

const DiscoverLayout: React.FC = () => {
  const params = useParams()
  const breakpoint = useResize()
  const [showPref, setShowPref] = useState<boolean>(false)
  const [preferencesChanged, setPreferencesChanged] = useState<boolean>(false)
  const [userId, setUserId] = useState<string | null>()

  const openPreferences = () => {
    setPreferencesChanged(false)
    setShowPref(true)
  }
  const closePreferences = () => {
    if(preferencesChanged && !params.userId)
    {
      setUserId(undefined)
      getRandomUserId().then(setUserId)
    }
    setShowPref(false)
  }
  useEffect(() => {
    if(!!params.userId)
    {
      setUserId(params.userId)
    }
    else if(!params.kind)
    {
      getRandomUserId().then(setUserId)
    }
  },[params])

  const onLikePressed = async (userId: string) => {
    await likeUser(userId)
    setUserId(await getRandomUserId())
  }

  const onDislikePressed = async (userId: string) => {
    await dislikeUser(userId)
    setUserId(await getRandomUserId())
  }

  const items = [
    {
      label: 'Preferences',
      icon: () => <FaSlidersH className="mx-2" size={30} color="gray" onClick={openPreferences} />,
    },
  ]
  return (
    <div className="d-flex gap-2 h-100 w-100">
      <BreakpointRender gte={!!params.userId ? 'xl' : 'lg'}>
        <HotListMessages
          tab={params.kind === 'messages' || params.kind === 'likes' ? params.kind : 'matches'}
          userId={undefinedToNull(userId)}
        />
      </BreakpointRender>
      <Loader loading={!params.kind && userId === undefined}>
        <SidebarWrapper onHide={closePreferences} visible={showPref} position="right" title="Preferences">
          <UserPreferences onChange={() => setPreferencesChanged(true)} />
        </SidebarWrapper>
        {
          params.kind === 'likes' && params.userId === undefined
          ? <LikesMe></LikesMe>
          : params.kind === 'messages' && params.userId === undefined
          ? <Chat></Chat>
          : userId === null
          ?
            <NoData>
              <h6 className="text-center mt-5">
                No luck this time, but remember,
                <br/>
                love knows no boundaries!
                <br/>
                {'\u{1F30D}'}
                <br/>
                Adjust your filters and dive back in!
                <br/>
                <button type="button" className="btn btn-primary" onClick={() => setShowPref(true)}>Let's try to adjust those filters</button>
              </h6>
            </NoData>
          : params.kind === 'likes' || params.kind === undefined
          ?
          <>
            <Tooltip target=".preference-tooltip" />
            <BreakpointRender eq="desktop">
              <Dock
                model={items}
                data-pr-tooltip="Preferences"
                position="right"
                className="d-block preference-tooltip"
                data-pr-at="left-100 bottom"
                data-pr-my="left center-89"
                data-pr-showdelay={272}
              ></Dock>
            </BreakpointRender>
            <BreakpointRender eq="mobile">
              <Dock
                model={items}
                position="right"
                className="d-block position-fixed"
                style={{ 'marginTop':'-30px' }}
                data-pr-at="left-100 bottom"
                data-pr-my="left center-100"
                data-pr-showdelay={272}
              ></Dock>
            </BreakpointRender>
            <ProfileBox
              userId={userId!}
              showBlockReport
              showLikeDislike={[onLikePressed,onDislikePressed]}
            />
          </>
          : params.kind === 'profile' && breakpoint.isMobile
          ?
            <ProfileBox
              userId={userId!}
              showBlockReport
            />
          :
            <Loader loading={!userId}>
              <MessagesBox userId={userId!}/>
              <BreakpointRender gte="md">
                <ProfileBox
                  userId={userId!}
                  showBlockReport
                />
              </BreakpointRender>
            </Loader>
        }
      </Loader>
    </div>
  )
}

export default DiscoverLayout
