import { Context } from '@rollbar/react'
import { createContext } from 'react'
import { Conversation } from 'types/messages'
import { User } from 'types/user'

export type Interactions = {
  likes:Promise<{ user:User,is_new:boolean }[]>
  matches:Promise<{ user:User,is_new:boolean }[]>
  conversations:Promise<Conversation[]>
  setInteractions:(_:{
    likes?:{ user:User,is_new:boolean }[],
    matches:{ user:User,is_new:boolean }[],
    conversations:Conversation[],
  } | null) => void
}

export const promiseConstant = <a>(x:a) : Promise<a> => new Promise((resolve) => resolve(x))

export const ContextInteractions = createContext<Interactions>({
  'likes':promiseConstant([]),
  'matches':promiseConstant([]),
  'conversations':promiseConstant([]),
  'setInteractions':(_) => {},
})

export default ContextInteractions
