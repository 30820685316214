import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Blog } from 'types/blogs'


interface storyListProps {
  stories: Blog[]
  navLink: string
  actionLinkTitle: string
}

const StoryList: React.FC<storyListProps> = (props:storyListProps) => {
  let navigate = useNavigate()

  // useEffect(() => {
  //   fetchData()
  // }, [])

  // async function fetchData() {
  //   setLoading(true)
  //   await getBlogs((res) => {
  //     setBlogs(res.data)
  //   })
  //   setLoading(false)
  // }

  const dateString = '2020-01-01'
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-GB' || 'en-US')

  function strip(html:string){
   return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, " ")
 }
  return (
    <>
      {props.stories.map((x: Blog) => (
        <div className="col-md-4 mb-3 mb-md-2 pe-auto">
          <div className="card shadow" onClick={() => navigate(`${props.navLink}/${x.id}`)}>
            <div className="card-body m-pointer" >
              <img className="mb-4 rounded-2 " src={x.photo} alt={x.title} />
              <h5 className="mb-3">{x.title}</h5>
              {/* <h6 className="card-subtitle mb-2 text-body-secondary">
                {formattedDate}
              </h6> */}
              <p className="text-muted">{`${strip(x.content.substring(0, 255))}...`}</p>
              <a className="text-info d-flex justify-content-end pe-auto">[{props.actionLinkTitle}]</a>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default StoryList
