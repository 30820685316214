import { InputSwitch } from 'primereact/inputswitch'
import React, { useEffect, useState } from 'react'
import { PreferenceKeys } from '../../../../../../../mobileApp/phoneSrc/screens/profileScreens/PreferenceScreen'

interface WebPreferenceCardProps {
  title: string
  children: React.ReactNode
  enabled: boolean
  preferenceKey: PreferenceKeys
  onChange: (_key: PreferenceKeys, _enabled: boolean) => void
}

function WebPreferenceCard(props: WebPreferenceCardProps) {

  return (
    <div className="d-flex flex-column rounded p-2 my-3 bg-light">
      <div className="col row">
        <div className="col fs-4 fw-medium">{props.title}</div>
        <div className="col-3 d-flex align-items-center justify-content-end ">
          <InputSwitch checked={props.enabled} onChange={() => props.onChange(props.preferenceKey,!props.enabled)} alt="switch" />
        </div>
      </div>
      {props.enabled ? props.children : null}
    </div>
  )
}

export default WebPreferenceCard
