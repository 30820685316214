import React from 'react'
import { Skeleton } from 'primereact/skeleton'

const StorySkeleton: React.FC = () => {
  return (
    <div className="flex w-100 flex-wrap vertical-scroll">
      <div className="d-flex gap-4 justify-content-evenly flex-sm-nowrap ">
        <Skeleton width="50rem" height="30rem"></Skeleton>
        <Skeleton width="50rem" height="30rem"></Skeleton>
        <Skeleton width="50rem" height="30rem"></Skeleton>
      </div>
      <div className="d-flex gap-4 justify-content-evenly flex-sm-nowrap mt-2">
        <Skeleton width="50rem" height="30rem"></Skeleton>
        <Skeleton width="50rem" height="30rem"></Skeleton>
        <Skeleton width="50rem" height="30rem"></Skeleton>
      </div>
    </div>
  )
}

export default StorySkeleton
