import React, { useEffect, useState } from 'react'
import Navbar from './_layout/navbar'
import Footer from './_layout/footer'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Fieldset } from 'primereact/fieldset'
import { fdr } from 'types/enums.options'
import { getFDR } from '_services/enums.service'

const FrequentDenialReasons: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [faqs, setFaqs] = useState<fdr[]>([])

  let navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setLoading(true)
    await getFDR((res) => {
      setFaqs(res.data)
    })
    setLoading(false)
  }

  return (
    <>
      <div className="header">
        <Navbar />
      </div>
      <main>
        <section>
          <div className="d-flex container">
            <div className="row">
              <div className="col">
                <h2 className="text-center"> "Frequent Denial Reasons"</h2>
                <hr className="title-border" />
                <br />
                <div className="container">
                  <div className="row">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      faqs.map((x,index) =>
                        <div className="card mb-4">
                            <Fieldset pt={{legend:{className:'mt-3'}}} legend={<h5 className="m-3 w-100 text-start text-primary">{index + 1}. {x.question}</h5>} >
                              <p className="m-3">
                                 {x.answer}
                              </p>
                          </Fieldset>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default FrequentDenialReasons
