import axios, { CancelTokenSource } from 'axios'
import { API_BASE_URL} from '@env'

// export const isCancel = axios.isCancel

// export function createCancelToken(): CancelTokenSource {
//     return axios.CancelToken.source()
// }

let interceptorId: number | null = null

export function clearAxiosJWTInterceptor() {
    if (interceptorId !== null) {
        axios.interceptors.request.eject(interceptorId)
        interceptorId = null
    }
}

export function setAxiosJWTInterceptor(token: string) {
    clearAxiosJWTInterceptor()
    interceptorId = axios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

export function setAxiosErrorInterceptor(handleAuthError: (e:any) => void, handleError: () => void) {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      switch (error.response && error.response.status) {
        case 401:
        case 403:
          handleAuthError(error)
          return Promise.reject(error)
        case 400:
        case 422:
          return Promise.reject(error)
        case 500:
          handleError()
          return Promise.reject(error)
        default:
          throw error
      }
    }
  )

}
// GET method to grab resources
export const _getV1 = async (path:string,data: Record<string,unknown>={},handlers:Record<number,((_:Response) => any) | any>={}): Promise<any> => {
  try {
    const response = await axios.get(`${API_BASE_URL}${path}`, { params: data })
    return {
      status: response.status,
      data: response.data
    }
  } catch (error: any) {
    if(error.request.status in handlers)
    {
      return typeof handlers[error.request.status] === 'function' ? handlers[error.request.status](error.request) : handlers[error.request.status]
    }
    else
    {
      return {
        error: true,
        message: error.message
      }
    }
  }
}

// POST method to add resources
export const _postV1 = async (path: string, data: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${path}`, data)
    return {
      status: response.status,
      data: response.data,
    }
  } catch (error: any) {
    throw error
    console.log("here in post v1")
    return {
      error: true,
      message: error.message
    }
  }
}

// PUT method to update resources
export const _putV1 = async (path: string, data: Record<string, unknown>): Promise<any> => {
  try {
    const response = await axios.put(`${API_BASE_URL}${path}`, data)
    return {
      status: response.status,
      data: response.data
    }
  } catch (error: any) {
    return {
      error: true,
      message: error.message
    }
  }
}

// DELETE method to remove resources
export const _deleteV1 = async (path: string): Promise<any> => {
  try {
    const response = await axios.delete(`${API_BASE_URL}${path}`)
    return {
      status: response.status,
      data: response.data
    }
  } catch (error: any) {
    return {
      error: true,
      message: error.message
    }
  }
}
