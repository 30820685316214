import { _postV1, _getV1 } from './config/index'

export enum productTypes {
  Apple = 'Apple',
  Android = 'Android',
  Amazon = 'Amazon',
  Web = 'Stripe'
}

export interface PurchaseSubscription {
  purchaseType: string | undefined
  productId: string | undefined
  purchaseToken: string | undefined
  transactionId: string | undefined
  transactionDate: string | undefined
  transactionReceipt: string | undefined
  packageNameAndroid: string | undefined
}

export interface EnumProduct {
  description: string
  endDate: string
  externallyManaged: boolean
  id: string
  name: string
  platform: string
  price: number
  productType: 'subscription' | 'product'
  startDate: string
  best_offer: boolean
  most_popular: boolean
}

export async function postInAppPurchase(inAppPurchase: PurchaseSubscription, deviceType: productTypes, onSuccess: (res: any) => void, onFail: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/finalize${deviceType}Purchase`, inAppPurchase)
    onSuccess(response)
  } catch (error: any) {
    onFail(error)
  }
}

export async function postInAppStripe(onSuccess: (res: any) => void, onFail: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/finalizeWebPayment`, [])
    onSuccess(response)
  } catch (error: any) {
    onFail(error)
  }
}

export async function getProductsSubscriptions(deviceType: productTypes, onSuccess?: (e: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1(`/get${deviceType}Products`)
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onSuccess && onSuccess(error)
  }
}

export async function getStripeSubscriptions(onSuccess: (e: any) => void, onFail: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1(`/getStripeSubscriptions`)
    onSuccess(response)
  } catch (error: any) {
    onFail(error)
  }
}

export async function createStripeCheckoutSession(productId: string, onSuccess: (e: any) => void, onFail: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/createStripeCheckoutSession`, { productId: productId })
    onSuccess(response.data)
  } catch (error: any) {
    onFail(error)
  }
}

export async function getStripeCheckoutStatus(sessionId: string, onSuccess: (e: any) => void, onFail: (e: any) => void): Promise<any> {
  try {
    const response = await _getV1(`/getStripeSessionStatus/${sessionId}`)
    onSuccess(response.data)
  } catch (error: any) {
    onFail(error)
  }
}