import { Dialog } from 'primereact/dialog'
import React, { useRef, useState } from 'react'
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebonceEffect'
import { Slider } from 'primereact/slider'
import 'react-image-crop/src/ReactCrop.scss'
import { Button } from 'primereact/button'
import { confirmPhotoUpload, confirmVerifyUpload, requestMediaUrl } from '_services/users.service'
import { uploadImageToPresignedUrl } from '_services/fetches/S3upload'
import { Image } from 'primereact/image'
import imageCompression from 'browser-image-compression'


interface PhotoProps {
  isVisible: boolean
  onUpload: () => void
  onClose: () => void
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const PhotoCropper: React.FC<PhotoProps> = ({ isVisible, onClose, onUpload }) => {
  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef("")
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState<any>(0)
  const [aspect, setAspect] = useState<number | undefined>(3 / 4)
  const [uploading, setUploading] = useState<boolean>(false)


  async function uploadPhoto() {
    setUploading(true)
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist")
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext("2d")
    if (!ctx) {
      throw new Error("No 2d context")
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    await requestMediaUrl(
      blob.type,
      async (res) => {
        await uploadImageToPresignedUrl(res.data.url, blob)
        await confirmPhotoUpload(
          res.data.id,
          () => onUpload()
        )
        setUploading(false)
        setCrop(undefined)
        setImgSrc("")
      },
      (e) => {
      },
    )
  }

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || ""),
      )
      const file = e?.target?.files?.[0]
      if (!file) return

      const compressedBlob = await imageCompression(file as File, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1400,
        useWebWorker: true,
      })

      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      })

      reader.readAsDataURL(compressedFile)
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const rotateImg = () => {
    setRotate((prevState: number) => prevState + 90)
  }

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined)
  //   } else {
  //     setAspect(16 / 9)

  //     if (imgRef.current) {
  //       const { width, height } = imgRef.current
  //       const newCrop = centerAspectCrop(width, height, 16 / 9)
  //       setCrop(newCrop)
  //       // Updates the preview
  //       setCompletedCrop(convertToPixelCrop(newCrop, width, height))
  //     }
  //   }
  // }

  return (
    <Dialog className="flex text-center overflow-hidden" visible={isVisible} onHide={onClose} header="Add Pictures">
      <div className="flex justify-content-evenly">
        <input className="border-0 rounded-2 flex" type="file" accept="image/*" onChange={onSelectFile} />
        <div className="d-flex justify-content-center align-items-center my-2">
          <span className="pi pi-search mx-2"></span>
          <span className="flex justify-content-center mx-3 w-50">
            <Slider className="flex justify-content-center" id="scale-input" step={0.1} min={.9} max={2.5} value={scale} disabled={!imgSrc} onChange={(e) => setScale(e.value)} />
          </span>
          <div className="d-flex justify-content-center ">
            <Button type="button" icon="pi pi-sync mx-2 rounded-circle" rounded text aria-label="Filter" onClick={rotateImg} />
          </div>
        </div>

      </div>
      {!!imgSrc && (
        <>
        <Button type="button" onClick={() => uploadPhoto()} className="mb-4 btn btn-primary" loading={uploading} disabled={uploading}>Upload Picture</Button>
        <ReactCrop
          className="resizable-image"
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          // minWidth={400}
          minHeight={100}
        // circularCrop
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        </>
      )}
      {!!completedCrop && (
        <div className='d-flex flex-column-reverse'>
          <div className="d-none">

            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>

          <div>
            <a
              href="#hidden"
              ref={hiddenAnchorRef}
              download
              style={{
                position: "absolute",
                top: "-200vh",
                visibility: "hidden",
              }}
            >
              Hidden download
            </a>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default PhotoCropper