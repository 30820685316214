import React, { useState } from 'react'
import { EnumNormalizedStructure } from 'types/enums.options'
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete'
import { PreferenceKeys } from 'types/preferenceKeys'

interface UserPreferencesSelectorProps {
  title: string
  data: EnumNormalizedStructure[]
  selectedPreferences: EnumNormalizedStructure[]
  showInput: boolean
  onChange: (_preferenceKey: PreferenceKeys, _preference: EnumNormalizedStructure, _selected:boolean) => void
  preferenceKey: PreferenceKeys
}

export const WebUserPreferencesSelector = (props: UserPreferencesSelectorProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedData, setSelectedData] = useState<EnumNormalizedStructure[]>([])

  const search = (event: AutoCompleteCompleteEvent) => {
    setTimeout(() => {
      let _searchData: EnumNormalizedStructure[]

      if (!event.query.trim().length) {
        _searchData = [...props.data]
      } else {
        _searchData = props.data.filter((data) => {
          return data.display.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }
      selectedData //TODO: fix
      setSelectedData(_searchData)
    }, 250)
  }

  const toggleButton = (preferenceKey: PreferenceKeys, preference: EnumNormalizedStructure) => {
    props.onChange(preferenceKey, preference, !selectedButtons.includes(preference.id))
    setSearchQuery('') // Clearing the input field after selecting an option, will be used when search is added
  }

  const filteredData = props.data.filter((label) => label.display.toLowerCase().includes(searchQuery.toLowerCase()))

  const selectedButtons = props.selectedPreferences.map((p) => p.id)

  return (
    <div>
      {props.showInput && (
        <>
          <span className="d-flex justify-content-center flex-wrap">
            <AutoComplete
              className="w-100 my-1"
              field="display"
              suggestions={filteredData}
              completeMethod={search}
              onChange={(text) => setSearchQuery(text.value)}
              placeholder={`Choose from ${props.data.length} ${props.title.toLowerCase()}!`}
              value={searchQuery}
              itemTemplate={(item: EnumNormalizedStructure) => (
                <div
                  onClick={() => toggleButton(props.preferenceKey, item)}
                >
                  {item.display}
                </div>
              )}
            />
          </span>
        </>
      )}
      <div className="d-flex flex-wrap justify-content-center ">
        {props.showInput ? (
          <div className="d-flex flex-wrap justify-content-center ">
            {selectedButtons
              .map((id) => props.data.find((label) => label.id === id))
              .filter((selectedLabel): selectedLabel is EnumNormalizedStructure => selectedLabel !== undefined)
              .sort((a, b) => a!.display.localeCompare(b!.display))
              .map((selectedLabel) => (
                <button
                  type="button"
                  className={
                    selectedButtons.includes(selectedLabel.id) ? 'btn bg-secondary m-1' : 'btn bg-secondary-subtle m-1'
                  }
                  key={selectedLabel.id}
                  onClick={() => toggleButton(props.preferenceKey, selectedLabel)}
                >
                  <span className={selectedButtons.includes(selectedLabel.id) ? 'text-white' : 'text-black'}>
                    {selectedLabel!.display}
                  </span>
                </button>
              ))}
          </div>
        ) : (
          props.data.map((label) => (
            <button
              type="button"
              className={selectedButtons.includes(label.id) ? 'btn bg-secondary m-1' : 'btn bg-secondary-subtle m-1'}
              key={label.id}
              onClick={() => toggleButton(props.preferenceKey, label)}
            >
              <div className={selectedButtons.includes(label.id) ? 'text-white' : 'text-black'}>{label.display}</div>
            </button>
          ))
        )}
      </div>
    </div>
  )
}
