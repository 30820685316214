import React from 'react'
import Edit from '../../assets/images/edit.png'
import PropTypes from 'prop-types';

interface EditButtonProps {
  onPress: () => void
  style?: React.CSSProperties;
}

const EditButton: React.FC<EditButtonProps> = ({ onPress, style }) => {
  return (
    <>
      <img style={style} className="w-auto" src={Edit} alt="edit-button" onClick={onPress} />
    </>
  )
}

export default EditButton
