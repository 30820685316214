import React from 'react'
import Logo from '../../../assets/images/horizontal-logo.png'
import { Image } from 'primereact/image'


const CommunityGuidelines: React.FC = () => {
  return (
    <div className="d-flex justify-content-center vh-100 html-text">
      <header className='position-fixed bg-white flex justify-content-center w-100 text-center py-1 shadow-sm'>
        <Image src={Logo} alt="Dating Across the Pond" />
      </header>
      <div className=" w-75 p-3 my-2 pb-2">
        <br />
        <br />
        <div>
          <p>
            If you believe we’ve made a mistake in taking action on your account or content, you can always contact us
            at <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>
          </p>
          <p>
            If you have any questions or feedback about DAtP’s Community Guidelines, please reach out. Our support team
            is always available to help you at{' '}
            <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>
          </p>
          <br />
        </div>

        <h5>DAtP Community Guidelines</h5>

        <p>
          DAtP is a space to make kind connections in a safe, inclusive, and respectful way. In order to foster
          healthy and equitable relationships, we hold our members accountable for the way they treat each other.
        </p>
        {'\n'}
        <p>
          Our Community Guidelines help keep our members safe. They make it clear what content and conduct is not
          acceptable (on our platform and off).
        </p>
        <h5>Profile Guidelines</h5>
        <ul>
          <li>
            Age. You need to be at least 18 years old to join DAtP. Creating a profile that intentionally
            misrepresents you as being under the age of 18 is not allowed. We reserve the right to ask for your ID to
            verify your age, and we’ll block you from the platform if you’re underage.
          </li>
          <li>
            Profile Photos: We want your profile to clearly show what you look like, in your best light. That’s why we
            require your profile photo to depict only you and to clearly show your full face. We do not permit:
            Profile photos that are heavily distorted or contain exaggerated or unnatural digital effects to the point
            where it cannot be clearly determined that you’re the person in the photos
          </li>
          <li>Any overlaid symbols, icons, frames, or stickers that aren’t from DAtP on your profile photos</li>
          <li>Memes or photos with only — or primarily — text as a profile photo</li>
          <li>Profile photos of children on their own</li>
          <li>Profile photos with unclothed children</li>
          <li>Contain social media contact information</li>
          <li>
            Username. Members are allowed to use initials, abbreviations, contracted or shortened versions of their
            name, nicknames, full names, and middle names. Members do not have to use their legal name or full name,
            but usernames should be an authentic representation of the name you use in everyday life. We do not
            permit: Any words or phrases that violate our Community Guidelines
          </li>
          <li>Using the name of a celebrity or fictional character</li>
          <li>
            Words or characters (other than a valid name) including descriptive words, symbols (e.g. $, *, @,),
            emojis, numbers, or punctuation
          </li>
        </ul>
        <h5>Content and Conduct Guidelines</h5>
        <p>Adult Nudity and Sexual Activity</p>
        {'\n'}
        <p>
          We don’t permit nude, sexually explicit, or sexually vulgar profile content. We also don’t allow the
          commercial exchange of any romantic or sexual activity, content, or services, including attempts to sell,
          advertise, or buy adult sexual content.
        </p>
        <h5>Adult Nudity and Sexual Activity Guidelines</h5>
        <p>
          We know that some conversations on DAtP may discuss and involve sex, intimacy, romance, and attraction.
          Members using DAtP{' '}
        </p>
        <b>are allowed to:</b>
        <ul>
          <li>
            State their romantic or sexual intentions on their profiles, as long as it’s legal and not overly obscene,
            crude, or otherwise in violation of these Community Guidelines.
          </li>
          <li>
            Use their private messages on the platform as a place to engage in sexual conversations with matches, as
            long as everything is consensual (that means welcomed by both parties), honest, and law abiding.
          </li>
        </ul>
        <p>
          We value self-expression and diversity within our community and encourage our members to share profile
          photos that celebrate bodies of all shapes and sizes. Photos of members wearing athleticwear are permitted
          whether indoors or outdoors. Members are permitted to wear swimwear in profile photos if taken outdoors.
        </p>
        <p>However, </p>
        <b>we don’t allow profile photos or the sharing of links on profiles that depict:</b>
        <ul>
          <li>
            People where the following is shown, even if covered or partially covered by emojis, hair or transparent
            clothing:
          </li>
          <li>Genitals or pubic regions</li>
          <li>Bare buttocks</li>
          <li>Women’s nipples and areolas</li>
          <li>Visible explicit outlines of genitals</li>
          <li>People in lingerie or underwear</li>
          <li>Shirtless indoor photos</li>
          <li>Sexual acts of any kind, even if not explicitly visible</li>
          <li>
            Digitally-created or drawn imagery (e.g. cartoons, memes, anime, drawings) that depicts any of the above.
            This imagery doesn’t necessarily have to include human beings
          </li>
        </ul>
        <p>
          We also don’t allow DAtP to be used for any commercial exchange of sexual or romantic acts of any kind. This
          includes, but isn’t limited to, the following:
        </p>
        <ul>
          <li>
            Any attempts to sell, advertise, or buy sexual acts in exchange for money or something of value (e.g. sex
            work, escort services)
          </li>
          <li>
            Any attempts to sell, advertise, or buy adult sexual content (e.g. pornography, sexual live
            streams/webcamming). This includes using the platform to promote or re-route members to adult content
            websites
          </li>
          <li>
            Any behavior seeking or offering a romantic or sexual arrangement in exchange for money, financial
            support, or gifts (e.g. seeking a “sugar daddy” or “sugar baby”)
          </li>
        </ul>
        <p>
          We recognize that our members use DAtP with different intentions and may want to make this transparent on
          their profile. We encourage members to be open about what they are looking for; however, we do not permit
          overly obscene sexual content in public profiles or usernames such as:
        </p>
        <ul>
          <li>Explicit, vulgar, or unnecessary detail about kinks</li>
          <li>Descriptions of sexual acts</li>
          <li>Sexually explicit or vulgar jokes</li>
        </ul>
        <p>Bullying and Abusive Conduct</p>
        <p>
          Our community is for creating kind connections. We don’t allow content or behavior that makes any individual
          or group feel harassed, bullied, or targeted. This includes belittling, insulting, or intimidating behavior;
          making unsolicited comments about someone’s appearance; engaging in emotional abuse; blackmail; repeated
          unwanted contact; or wishing, encouraging, or praising acts of violence.
        </p>
        {'\n'}
        <h5>Bullying & Abusive Content</h5>
        <p>
          Our community is all about creating kind connections. We aim to create an experience which is free from
          behavior that makes any individual or group feel harassed, bullied, or targeted.
        </p>
        {'\n'}
        <p>Members may not:</p>
        <ul>
          <li>
            Engage in any bullying, belittling, insulting, mocking, trolling, intimidating, dehumanizing, or degrading
            behavior
          </li>
          <li>Make harmful unsolicited comments about someone’s body or appearance (e.g. body shaming)</li>
          <li>
            Wish, encourage, or praise acts of violence, or threatening acts of violence that would result in
            non-serious bodily injury
          </li>
          <li>Engage in emotional abuse and/or manipulation</li>
          <li>Repeat unwanted online contact of any kind</li>
          <li>
            Blame, shame, or target someone based on their status as a survivor/victim of sexual assault, sexual
            exploitation, sexual harassment, or domestic abuse
          </li>
          <li>
            Release a person’s private contact information, photos, or other personally identifying information
            without their consent
          </li>
          <li>
            Blackmail a member to reveal embarrassing, compromising or otherwise private information (not including
            sexual or intimate content)
          </li>
          <li>Not show up to an in-person meet up despite clear plans agreed by both parties</li>
          <li>Create a profile with malicious intent to publish defamatory content about a person</li>
          <li>
            Share content that is disrespectful toward victims, survivors, or families impacted by suicide,
            self-injury, or eating disorders
          </li>
        </ul>
        <p>
          This includes content about public figures. Members are permitted to express their criticism of public
          figures, but we will not tolerate abusive conduct in any form.
        </p>
        <p>Child Sexual Exploitation and Abuse</p>
        <p>
          We have a zero tolerance policy towards any form of child sexual exploitation and abuse. We don’t allow
          content that sexualizes or endangers children, real or fictional (e.g. anime, media, text, illustrations, or
          digital images). This includes any visual depictions or discussions of sexually explicit conduct involving a
          child. For the purposes of this policy, a child is anyone under the age of 18. It’s prohibited to upload,
          store, produce, share, or entice anyone to share child sexual abuse material, even if the intent is to
          express outrage or raise awareness about this issue.
        </p>
        <h5>Child Sexual Exploitation & Abuse</h5>
        <p>
          We understand that members might want to talk about and share images of their children with their
          connections. Any discussions about children must remain appropriate, relevant, and lawful. In addition, if
          you decide to include photos of children in your profile, they must be completely clothed and they cannot
          appear alone in the image. This includes any photos from your childhood.
        </p>
        {'\n'}
        <p>
          We don’t allow profile photos of babies’, toddlers’ or children’s genitalia or buttocks even when they’re
          covered or partially covered by emojis.
        </p>
        {'\n'}
        <p>
          Any violation of this policy will result in a member being removed from DAtP and reported to the
          authorities. In compliance with U.S. law, if we become aware of apparent child sexual exploitation, child
          grooming, or child abuse, we will report it to the{' '}
          <a href="https://www.missingkids.org/HOME">National Center for Missing and Exploited Children (NCMEC)</a>.
          NCMEC may make the report available to relevant local and global law enforcement authorities.
        </p>
        <h5>Commercial and Promotional Activity</h5>
        <p>
          DAtP is not a marketplace, and using the platform for unsolicited commercial or promotional purposes is not
          allowed.
        </p>
        <p>For example, the following behaviors aren’t allowed in any DAtP:</p>
        <ul>
          <li>
            Any unsolicited or irrelevant advertising or promotion of a product, brand, service, event, investment,
            business/organization, music, or performance
          </li>
          <li>
            Any group sales recruitment or promotion of a group sales product (multi-level marketing or pyramid
            schemes)
          </li>
          <li>
            Unsolicited promotion of your social media or website in private messages to other members (see Spam
            policy below)
          </li>
          <li>Sharing financial account information, links, or usernames to money transfer apps in profiles</li>
        </ul>
        <h5>Spam</h5>
        <p>
          Spam is any kind of unwanted or irrelevant content usually sent in bulk or high frequency. We don’t allow:
        </p>
        <ul>
          <li>Content that’s designed to deceive or mislead members in order to increase views or followers</li>
          <li>
            Misleading or misdirecting links including links to websites that pretend to be of a reputable brand or
            service
          </li>
          <li>
            Attempts to artificially influence conversations, connections, or engagement through the use of automation
            or scripting
          </li>
          <li>
            Sharing contact details, private messaging details or sensitive self-identifying information in your
            profile, or profile photo. Unsolicited promotion of your social media or website in private messages to
            other members (see commercial and promotional activity below)
          </li>
          <li>Images containing links or QR codes</li>
          <li>Creating an excessive number of accounts causing disruption to other members</li>
          <li>Multiple active profiles on our platform to engage in unwanted interactions</li>
        </ul>
        <h5>Commercial and Promotional Activity</h5>
        <p>
          DAtP is not a marketplace, and using the platform for unsolicited commercial or promotional purposes is not
          allowed. For example, the following behaviors aren’t allowed in any form on DAtP:
        </p>
        <ul>
          <li>
            Any unsolicited or irrelevant advertising or promotion of a product, brand, service, event, investment,
            business/organization, music, or performance
          </li>
          <li>
            Any group sales recruitment or promotion of a group sales product (multi-level marketing or pyramid
            schemes)
          </li>
          <li>
            Unsolicited promotion of your social media or website in private messages to other members (see also Spam)
          </li>
          <li>Sharing financial account information, links, or usernames to money transfer apps in profiles</li>
        </ul>
        <h5>Controlled Goods and Substances</h5>
        <p>
          We don’t allow members to use our platforms to buy, sell, supply, distribute or directly facilitate the
          purchase, sale, supply or distribution of illegal drugs and/or the misuse of controlled goods and
          substances.
        </p>
        {'\n'}
        <p>
          Controlled goods and substances include, but aren’t limited to: e-cigarettes, marijuana, drug paraphernalia,
          or the misuse of legal substances like prescription drugs, tobacco, or alcohol.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>Content that depicts the use of illegal drugs, or misuse of other controlled goods and substances</li>
          <li>The purchase, sale, trade, or solicitation of drugs or other controlled substances</li>
          <li>
            Content that provides information on how to buy, sell, or illegally obtain drugs or controlled goods and
            substances
          </li>
          <li>
            Content providing instructions on how to manufacture illegal drugs or controlled goods and substances
          </li>
        </ul>
        <p>
          In private messages or group chats, we may also allow imagery of drugs if shared explicitly for educational
          or health-related purposes. However, it’s important to make your intentions clear in sharing this content,
          or it may be removed and/or action may be taken against your account.
        </p>
        {'\n'}
        <p>Dangerous Organizations and Individuals</p>
        <p>
          We don’t permit organizations or individuals that proclaim, glorify, condone, or are known to support a
          violent, dangerous, or terrorist-based mission to have a presence on DAtP.
        </p>
        {'\n'}
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>
            Terrorist organizations and individuals which use violence and intimidation, especially against civilians,
            in the pursuit of political aims
          </li>
          <li>
            Violent extremists who incite violence against civilians, political systems, or values, including
            democracy, the peaceful transfer of power, the rule of law, individual liberty, or different identities
          </li>
          <li>
            Individuals that vocally, or through use of known symbols, support, condone or engage in behaviors
            typically attributed to terrorist groups.
          </li>
          <li>
            Behavior associated with known hate groups or the use of speech, symbols, gestures, or imagery associated
            with hate groups
          </li>
          <li>
            Criminal organizations or those who have engaged in, or plan to engage in, any criminal activity, either
            on or off the platform
          </li>
        </ul>
        <p>
          We understand that members might discuss or share content about a dangerous organization or individual for
          purposes of awareness, education, or condemnation. While we may allow this in private messages and group
          chats, it’s important to make your intentions clear in sharing this content. Otherwise, it may lead to your
          content being removed, action being taken against your account, and/or reporting to appropriate authorities.
        </p>
        {'\n'}
        <p>Identity-Based Hate</p>
        <p>
          We aim to foster a diverse and inclusive community on DAtP. We prohibit content or behavior that promotes or
          condones hate, dehumanization, degradation, or contempt against marginalized or minoritized communities
          based on the following protected attributes: race/ethnicity, national origin/nationality/immigration status,
          caste, sex, gender identity or expression, sexual orientation, disability or serious health condition, or
          religion/belief.
        </p>
        <h5>Identity-Based Hate</h5>
        <p>
          We aim to foster a diverse and inclusive community on DAtP. This means you should be respectful of other
          people’s beliefs, interests, and identities. We take a strong stance against identity-based hate.
        </p>
        {'\n'}
        <p>
          We consider identity-based hate to be any content or conduct that promotes or condones any form of hate,
          dehumanization, degradation, or contempt against marginalized or minoritized communities based on the
          following protected attributes: race/ethnicity, national origin/nationality/immigration status, caste, sex,
          gender identity or expression, sexual orientation, disability or serious health condition, or
          religion/belief.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>
            Expressing threats, praises, or wishes of violence, death, or serious harm referencing protected
            attributes
          </li>
          <li>Using identity-based slurs or epithets to cause harm</li>
          <li>
            Supporting or glorifying known hate groups or sharing content, symbols, gestures, or imagery associated
            with hate groups
          </li>
          <li>
            Insulting, expressing contempt, advocating harm, using degrading or disparaging language (including
            harmful stereotypes, expressions of superiority, inferiority, or dismissal), mocking, or taunting others
            based on a protected attribute
          </li>
          <li>Denying the concept or severity of hate crimes or historical events</li>
          <li>
            Misgendering (i.e. intentionally using someone’s wrong pronouns or gender) or dead-naming (i.e.
            intentionally calling someone by a name they no longer identify with)
          </li>
          <li>
            Intentionally reporting members to DAtP for no reason other than their gender identity or other protected
            attributes
          </li>
        </ul>
        <p>
          This also includes identity-based hate against public figures and people who aren’t members of our
          community.
        </p>
        {'\n'}
        <p>
          While you may express the protected attributes of matches that you are looking for on your profile, we don’t
          allow you to express protected attributes of matches that you aren’t interested in. For example: You may
          state “I’m looking for a Muslim partner,” but you may not state “No Jews or Christians.” We don’t allow any
          preferences around someone’s caste to be shared on profiles.
        </p>
        <h5>Inauthentic Profiles</h5>
        <p>
          DAtP celebrates authenticity, and we expect all our members to represent themselves accurately on their
          profile.
        </p>
        <p>
          Members are allowed to use initials, abbreviations, contracted/shortened versions of their name or middle
          name as long as these names are a variation of their authentic name.
        </p>
        <p>
          We don’t allow impersonation or misrepresentation on our platform. We consider these, and similar behaviors,
          as inauthentic behavior. This includes, but isn’t limited to:
        </p>
        <ul>
          <li>Catfishing (i.e. creating an online persona that isn’t you)</li>
          <li>Using someone else’s photos, artificially-generated photos, or enhanced photos to deceive others</li>
          <li>
            Misrepresenting, misleading or falsely stating facts about yourself (including name, gender, age, and
            permanent location)
          </li>
          <li>Pretending to be another person, including a celebrity or other public figure</li>
          <li>Allowing someone else to have access to -or control of- an account that does not represent them</li>
          <li>
            Sharing content that may infringe on someone else’s copyright, trademark, name, likeness, image, other
            intellectual property, or privacy.
          </li>
          <li>
            Uploading photos of yourself that that are heavily distorted or contain exaggerated/unnatural digital
            effects to the point where it cannot be clearly determined that you’re the person in the image
          </li>
          <li>Using profile photos that contain a QR code or URL</li>
          <li>Uploading profile photos of internet memes or images that are primarily text</li>
        </ul>
        <h5>Misinformation</h5>
        <p>
          Misinformation is false or misleading content that can quickly escalate into serious risks to individuals,
          communities, and institutions. Because of this, we don’t allow the sharing of demonstrably false or
          substantially misleading content that’s likely to cause serious harm or negatively impact individual or
          public safety.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>
            Content that directly contradicts information and guidance from leading and reputable global health
            organizations and public health authorities that could result in injury or harm to the public, for example
            about COVID-19, monkeypox, or any other communicable disease or illness
          </li>
          <li>
            Any proposed cures for COVID-19 or any other illnesses not widely recognized as effective by leading and
            reputable global health organizations and public health authorities
          </li>
          <li>
            Demonstrably false or substantially misleading information regarding abortion care that is likely to cause
            serious harm. This does not prohibit members from voicing personal opinions or policy perspectives on the
            issue, as long as the information doesn’t intend to harm, mislead, or contradict leading and reputable
            public health authorities and the discussion remains respectful and doesn’t violate our Bullying and
            abusive content policy
          </li>
          <li>
            False claims or misleading information on any civic process (e.g. elections, voting systems, census,
            referendums, etc.)
          </li>
          <li>
            Dangerous and unproven conspiracy-type theories that may lead to individual or public safety concerns
          </li>
          <li>Content from or links to third party sites that are known to produce or contain misinformation</li>
          <li>
            Content that denies the concept or severity of hate crimes or historical events (See Identity-Based Hate)
          </li>
          <li>
            Any type of misinformation relating to political affairs aiming to influence political outcomes, or
            manipulate historical fact.
          </li>
          <li>
            Content that has been intentionally manipulated or taken out of context to spread false or misleading
            information.
          </li>
        </ul>
        <p>
          Sharing any of the above content may lead to your content being removed and/or action being taken against
          your account.
        </p>
        <p>Physical and Sexual Violence</p>
        <p>
          We don’t tolerate any content, imagery, or behavior that commits or threatens credible acts of physical or
          sexual violence. This includes physical stalking, using our platform to assist, facilitate or support
          exploitation or human trafficking, and sexual assault of any kind, which we define as unwanted physical
          contact or attempted physical contact that is sexual in nature.
        </p>
        <h5>Physical and Sexual Violence</h5>
        <p>
          We don’t tolerate any content, imagery or behavior that commits or threatens credible acts of physical or
          sexual violence.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>Threatening or committing any type of physical harm to another person</li>
          <li>
            Threatening or committing sexual assault of any kind, which we define as any unwanted physical contact or
            attempted physical contact that is sexual in nature. These behaviors can range from:
          </li>
          <li>Unwanted sexual touching or kissing of any body part, or attempts to do so</li>
          <li>The unwanted removal of another’s clothes</li>
          <li>Stealthing (i.e. the non-consensual removal of barrier contraceptives)</li>
          <li>
            Non-consensual sexual penetration or attempts to do so, which include but aren’t limited to: rape,
            non-consensual oral sex, sexual acts while one party is unconscious or unable to consent to sexual
            activity, or sexual acts induced by coercion or a substance
          </li>
          <li>Expressing credible threats of violence that would result in serious bodily injury or death</li>
          <li>
            Physical stalking (i.e. surveilling or showing up at someone’s location in order to intimidate or initiate
            unwanted contact)
          </li>
          <li>
            Using our platform to facilitate exploitation or human trafficking. We define human trafficking as “the
            recruitment, transportation, transfer, harboring or receipt of adults through force, fraud or deception,
            with the aim of exploiting them for profit.” (UN Office on Drugs and Crime)
          </li>
        </ul>
        <p>
          We strongly encourage our members to report this type of behavior if it occurs on the platform, in person
          between members, or elsewhere outside of the DAtP platform. Our specialized safety team can provide external
          resources for support. Since this behavior may also be criminal in nature, reporting to a local law
          enforcement authority may also be an option. If we become aware of any of the above conduct, we may take
          action against offending member accounts, and may also cooperate with law enforcement in any resulting
          criminal investigations.
        </p>
        <h5>Scams and Theft</h5>
        <p>
          DAtP strictly prohibits any scam or theft activity intended to defraud or manipulate members out of
          financial or material resources. We define scams as members making seemingly dishonest or false
          representations that aim to manipulate or defraud victims for financial gain or cause them loss.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>Asking other members for financial support in the form of money, loans, investments, or donations</li>
          <li>Lying about your intentions for financial gain</li>
          <li>
            Misrepresenting or faking romantic intentions to manipulate or deceive members out of financial or
            material resources
          </li>
          <li>
            Misrepresenting or falsely stating facts about yourself (including name, gender, age, and permanent
            location) for financial gain (See inauthentic profiles and photos)
          </li>
          <li>Physically taking someone’s property or assets without their knowledge or permission</li>
        </ul>
        <h5>Sexual Harassment</h5>
        <p>
          Our mission at DAtP is to foster healthy and equitable relationships through kind connections, so we wholly
          prohibit sexual harassment. We consider sexual harassment to be any non-physical, unwanted, and unwelcome
          sexual behaviors between members.
        </p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>Cyberflashing (i.e. sharing unsolicited sexually explicit images)</li>
          <li>Committing in-person indecent exposure (i.e. unwanted flashing on a date)</li>
          <li>
            Obtaining, sharing, or threatening to share sexual images, videos, or screenshots of sexual conversations
            on any website or platform, without the consent of the person involved or depicted (commonly known as
            “revenge porn” or “non-consensual intimate image sharing”)
          </li>
          <li>
            Making unwanted sexual comments, questions, and advancements, including sexualized comments about
            someone’s body
          </li>
          <li>Fetishizing a member without their consent</li>
          <li>
            Sending unwanted sexualized imagery including memes, animations, drawings, etc., as well as unwanted
            pornographic imagery or links
          </li>
          <li>
            Seeking romantic or sexual relationships on DAtP app (e.g. flirting, “sexting,” or offering/proposing
            sexual or romantic activity)
          </li>
        </ul>
        <p>
          Remember, your off-platform behavior matters too. To protect victims and survivors, we may take action
          against your account for engaging in any of the above sexual harassment behaviors in person, on another
          platform, or over text.
        </p>
        <p>Spam</p>
        <p>
          We do not permit any kind of unwanted or irrelevant content sent in bulk or high frequency. This includes
          sharing misleading or misdirecting links, creating an excessive number of accounts causing disruption to
          other members, or having multiple active profiles on our platform to engage in unwanted interactions.
        </p>
        <p>Spam is any kind of unwanted or irrelevant content usually sent in bulk or high frequency.</p>
        <p>We don’t allow:</p>
        <ul>
          <li>Content that’s designed to deceive or mislead members in order to increase views or followers</li>
          <li>
            Misleading or misdirecting links including links to websites that pretend to be of a reputable brand or
            service
          </li>
          <li>
            Attempts to artificially influence conversations, connections, or engagement through the use of automation
            or scripting
          </li>
          <li>
            Sharing contact details, private messaging details or sensitive self-identifying information in your
            profile, or profile photo
          </li>
          <li>
            Unsolicited promotion of your social media or website in private messages to other members (see also
            commercial and promotional activity)
          </li>
          <li>Images containing links or QR codes</li>
          <li>Creating an excessive number of accounts causing disruption to other members</li>
          <li>Multiple active profiles on our platform to engage in unwanted interactions</li>
        </ul>
        <h5>Suicide and Self-Injury Promotion</h5>
        <p>
          We care deeply about our members and understand that some may struggle with mental health, self-injury,
          suicidal thoughts, substance use, or eating disorders. While we do permit members to share their personal
          experiences with these issues in a safe way, we do not allow any content that depicts, promotes, glorifies,
          or assists in activities that could lead to suicide, self-injury, or disordered eating or body image.
        </p>
        <p>We don’t allow:</p>
        <ul>
          <li>Encouraging, promoting, glorifying, or providing instructions for suicide or self-injury</li>
          <li>
            Depicting, promoting, normalizing, glorifying, or providing instructions for disordered eating, dieting,
            or body image.
          </li>
          <li>Graphic imagery depicting self-injury or suicide</li>
          <li>
            Content that’s disrespectful toward victims, survivors, or families impacted by suicide, self-injury, or
            eating disorders (See Bullying and Abusive Conduct)
          </li>
          <li>Dangerous amateur acts or viral “challenges” that can cause serious bodily harm</li>
        </ul>
        <p>
          In response to content that may describe a suicidal or self-injurious plan, intent, or thought, we may
          remove the content and send the member local supportive resources such as suicide prevention hotlines. If we
          have reason to believe a member is an imminent danger to themselves, we may contact local emergency
          authorities for intervention, which could include emergency services providing a welfare visit.
        </p>
        <h5>Violent or Graphic Content</h5>
        <p>We don’t permit violent, graphic, or gory content.</p>
        <p>This includes, but isn’t limited to:</p>
        <ul>
          <li>
            Imagery containing real or realistic-appearing blood, bodily fluids, violence, or injury. This includes:
          </li>
          <li>Excessive blood that appears to be real</li>
          <li>
            Graphic injuries or wounds, including those of self-injury (See Suicide and Self Injury), hunting, or food
            preparation
          </li>
          <li>Human bones or internal organs</li>
          <li>Fetal remains or miscarriages</li>
          <li>Graphic imagery of medical procedures</li>
          <li>Imagery that shows the violent, shocking, or graphic death or injury of a person or animal</li>
          <li>Imagery containing human remains or corpses</li>
          <li>
            Images depicting guns of any kind (except on a uniformed member of law enforcement or military personnel)
          </li>
          <li>General references or descriptions of violence in usernames or profile content</li>
          <li>
            General description of violence or physical injuries in graphic detail including references to dangerous
            organizations and individuals
          </li>
        </ul>
        <p>
          “Trophy” hunting photos of animals are not allowed unless the image depicts the following animals: bison,
          deer, duck, fish, elk, goose, moose, pheasants, rabbit, turkey, wild boar. However, trophy hunting photos
          depicting the permitted animals above will not be allowed if the photo depicts a gun or visible injuries to
          the animal.
        </p>
        <p>Platform Manipulation</p>
        <p>
          We prioritize fostering a community built on genuine connections, so any attempts to artificially influence
          connections, matching, conversations, or engagement through the use of automation or scripting is strictly
          prohibited.
        </p>
        <p>Safety Reporting</p>
        <p>
          Safety is a top priority at DAtP. We use a combination of human moderators and automated systems to monitor
          and review DAtP accounts and interactions for content that may be against our Community Guidelines, against
          our Terms and Conditions, or otherwise harmful.
        </p>
        <p>
          Our members play a critical role in the safety of DAtP by reporting content or behavior that may violate our
          Community Guidelines. If anything happens that makes you feel uncomfortable or unsafe, we highly encourage
          you to Unmatch or Block & Report – the member.
        </p>
        <p>
          However, please consider that disagreeing with or disliking a member or their content is not necessarily a
          reason to report them. We may take action against a member if we found them to be intentionally creating
          false or inappropriate reports against other members solely based on their protected attributes. This
          includes reporting transgender or nonbinary members for no reason other than their gender identity or
          expression or repeatedly sending false reports of bad behavior.
        </p>
        <p>Enforcement Philosophy</p>
        <p>
          All members must comply with the platform rules described and referenced in our Community Guidelines. If you
          behave in a way that goes against DAtP’s Community Guidelines, values, or otherwise act in any way we
          believe to be potentially harmful to DAtP or its members, we may take a range of actions on your account.
          When determining the penalty for violating our community guidelines, we consider a number of factors.
        </p>
        <p>For example, we may:</p>
        <ul>
          <li>Remove the content</li>
          <li>Issue a warning</li>
          <li>Ban the offending member from some or all Dating Across The Pond LLC apps</li>
        </ul>
        <p>
          When necessary, we also may cooperate with law enforcement to assist in potential criminal investigations
          related to member conduct.
        </p>
        <p>
          Your treatment towards others outside of the DAtP app can also result in action against your account. If
          we’re made aware of harm between members on dates, meetups with friends, via text message or direct
          messaging platforms, or relevant alleged criminal or harmful conduct committed in your past or outside of
          DAtP, we may take action as if it happened on our platform.
        </p>
        <p>
          If you believe we’ve made a mistake in taking action on your account or content, you can always contact us
          at <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>
        </p>
        <p>
          If you have any questions or feedback about DAtP’s Community Guidelines, please reach out. Our support team
          is always available to help you at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>
        </p>
        <br />
      </div>
    </div>
  )
}

export default CommunityGuidelines
