import React, { useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { Checkbox } from 'primereact/checkbox'
import { authStore } from '../_core/stores/authStore'
import { FACEBOOK_APP_ID, GOOGLE_APP_ID, APPLE_CLIENT_ID, APPLE_REDIRECT_URL, BASE_URL, EMAIL_SCOPE } from '@env'
import { InputText } from 'primereact/inputtext'
import { E164Number } from 'libphonenumber-js/types.cjs'
import useScript from '../_core/hooks/useScript'
import useMeta from '../_core/hooks/useMeta'


interface ConfirmPhoneFormProps {
  onSubmit: (phoneNumber: string, countryCode: string) => void
}


const ConfirmPhoneForm: React.FC<ConfirmPhoneFormProps> = (props) => {

  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>(undefined)

  const handleConfirm = () => {
    let number = parsePhoneNumber(phoneNumber as string)
    let formattedNumber = number!.nationalNumber.toString()
    let countryCode = number!.countryCallingCode
    props.onSubmit(formattedNumber,countryCode)
  };

  return (
    <form className="d-flex flex-column align-items-center" onSubmit={(e) => { e.preventDefault(); handleConfirm() }}>
      <span className="p-float-label mb-2 w-50">

      <PhoneInput
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="US"
        />
      </span>
      <p>Don't worry, we're just verifying your dog and bone(Phone Number) to make sure it's really you!</p>

      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex justify-content-between">
          <button className="btn my-3 ms-auto btn-primary">
            Next
          </button>
        </div>
      </div>

    </form>
  )
}

export default ConfirmPhoneForm
