import React from "react";
import { DynamicButtonList, DynamicButtonListProps } from "../_components/DynamicButtonList";
import { EnumNationalitiesData } from "types/enums.options";
import { FormType, createFormPayload } from "types/payloads.users";
import { EditableArea, EditableAreaProps } from "../_components/EditableArea";

export enum FormOptions {
  Nationality           = "Nationality",
  AboutMe               = "AboutMe",
}

export const OnboardingForms = {
  [FormOptions.Nationality]: {
    formTemplate: (props:DynamicButtonListProps<string[]>) => <DynamicButtonList {...props} />,
    nextStep: FormOptions.AboutMe,
  },
  [FormOptions.AboutMe]: {
    formTemplate: (props:EditableAreaProps) => EditableArea(props),
    nextStep:undefined,
  },
}


export const OnboardingPreviousStep = (opt: FormOptions): FormOptions | undefined => {
  for (const [option, mapping] of Object.entries(OnboardingForms)) {
    if (mapping.nextStep === opt) {
      return option as FormOptions
    }
  }
  return undefined; 
}