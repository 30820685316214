import React, { useEffect, useState } from 'react'
import { approvePhotos, ApproveUserProfile, approveVerifyPhotos, banUser, denyPhotos, denyUserProfile, denyVerifyPhotos, getApprovalPhotos, getVerifyPhoto, getVerifyPhotos, unbanUser } from '../../../../../../common/_services/admin.service'
import ReviewPhoto from '../../_components/reviewPhoto'
import { User, UserPhoto, UserVerifyPhoto } from '../../../../../../common/types/user'
import NoData from '../../../../components/noData'
import { useFetch } from '../../../../_core/hooks'
import ProfileBox from '../../_components/profile/profileBox'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from 'primereact/datatable'
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent, ColumnProps, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { getUsers } from '_services/users.service'
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Tag } from 'primereact/tag'
import SidebarWrapper from '../../_components/profile/components/SidebarWrapper'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { TabPanel, TabView } from 'primereact/tabview'
import { PhotoWithName } from './userApproval'

interface photoReviewProps {
  photos: UserVerifyPhoto[]
  onAction: () => {}
}
type ShowVerifyImageProps = {
  photo: UserVerifyPhoto
}

const VerifyReview: React.FC<photoReviewProps> = (props:photoReviewProps) => {
  const [viewUser, setViewUser] = useState<string>('');

  const ShowVerifyImage: React.FC<ShowVerifyImageProps> = (props) => {
    const [verifyPhoto, setVerifyPhoto] = useState<any>(undefined)

    useFetch(
      () => getVerifyPhoto(props.photo.verifyPhotoId),
      (x) => {
        if(x){
          setVerifyPhoto(x)
          return (x)
        }
      },
      []
    )

    return <div className="d-flex h-100">
        <Image src={verifyPhoto?.photoUrl} alt="Image" width="450" />

    </div>
  }

  const denyVerifyPhoto = async (imgUrl: string, userId:string, onSuccess: () => void, onFail: () => void) => {
    await denyVerifyPhotos(
      userId,
      imgUrl,
      onSuccess,
      onFail
    )
  }

  const approveVerifyPhoto = async (imgUrl: string, userId:string, onSuccess: () => void, onFail: () => void) => {
    console.log("here", imgUrl)
    await approveVerifyPhotos(
      userId,
      imgUrl,
      onSuccess,
      onFail
    )
  }

  return (
      <div className="p-3">
          <DataTable
            value={props.photos}
          >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
              <Column
                bodyClassName={(y,o) =>  y.sort === 1 ? 'first-photo' : ''}
                field="photo"
                header="Photo"
                style={{ width: '200px' }}
                body={(y,o) => <Image onClick={() => {setViewUser(y.userId)}}  src={y.imgUrl } alt="Image" width="300px" className="shadow-4" />}>
              </Column>
              <Column
                field="name"
                header="Verify Photo"
                body={(y,o) => <ShowVerifyImage photo={y}></ShowVerifyImage>}>
              </Column>

              <Column field="id"
                header="Action"

                body={(y,o) =>
                  <>
                    <Button label="Deny" className="btn btn-secondary" onClick={() => {
                      denyVerifyPhoto(y.imgUrl,y.userId,props.onAction,props.onAction)
                    }}/>
                    <Button label="Approve" className="btn btn-primary mx-2" onClick={() => {
                      approveVerifyPhoto(y.imgUrl,y.userId,props.onAction,props.onAction)
                    }}/>
                  </>
                }>
              </Column>
          </DataTable>
      </div>
  )
}



export default VerifyReview
