import React, { useEffect } from 'react'
import { GOOGLE_APP_ID } from "@env";
import useScript from '../../_core/hooks/useScript';
import { Buffer } from 'buffer';

interface Props {
  isCreateForm?: boolean
  onSuccess: (ssoID: string, name: string, email:string) => void,
}
const GoogleSingleSignOnButton: React.FC<Props> = ({isCreateForm, onSuccess }) => {
  useScript("https://accounts.google.com/gsi/client");

  useEffect(() => {
    (window as any).handleCredentialResponse = handleCredentialResponse;
  }, [])

  const handleCredentialResponse = (response: { credential: string }) => {
    const responsePayload = decodeJwtResponse(response.credential);

    onSuccess(responsePayload.sub, responsePayload.name, responsePayload.email);
  }

  const decodeJwtResponse = (token: string)  => {
    const [, payload] = token.split('.');
    const decodedToken = Buffer.from(payload, 'base64');

    return JSON.parse(decodedToken.toString());
  }

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_APP_ID}
        data-callback="handleCredentialResponse"
        data-context="signin"
        data-ux_mode="popup"
        data-auto_prompt="false">
      </div>
      <div
        className="g_id_signin sso-button w-100"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text={"signin_with"}
        data-size="large"
        >
      </div>
    </>
  )
}


export default GoogleSingleSignOnButton;