import React from 'react'
import Lottie from 'lottie-react'
import frogLoading from '../assets/Lottie/FrogLoading.json'

interface props {
  loading: boolean
  children?: React.ReactNode
}

function Loader(props: props) {
  return (
    props.loading
    ? <div className="d-flex justify-content-center align-items-center w-100 h-100"><Lottie animationData={frogLoading} /></div>
    : props.children
  )
}

export default Loader
