import { useState } from 'react'
import { Location,geocodeLatLng } from '../utility/geocoding'

let _location : Location | null | undefined = undefined
export const useLocation = (geocoder:Promise<google.maps.Geocoder>) => {
  const [location,setLocation] = useState<Location | null>(null)
  if(_location === undefined)
  {
    (async () => {
      if('geolocation' in navigator)
      {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            setLocation(_location = await geocodeLatLng(geocoder,position.coords.latitude,position.coords.longitude))
          },
          (_) => {
            setLocation(_location = null)
          }
        )
      }
      else
      {
        setLocation(_location = null)
      }
    })()
  }
  return location
}

export default useLocation
