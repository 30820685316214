import { _postV1 } from './config/index'

export async function reportUser(reportedUserId: string) {
  try {
    const response = await _postV1(`/reportUser/${reportedUserId}`, null)
    return response
  } catch (error: any) {
    return {
      error: true,
      message: error
    }
  }
}

export async function blockUser(blockedUserId: string) {
  try {
    const response = await _postV1(`/blockUser/${blockedUserId}`, null)
    return response
  } catch (error: any) {
    return {
      error: true,
      message: error
    }
  }
}