import { useEffect, useState } from 'react'
import { EnumBodyTypesData, EnumCountryData, EnumNationalitiesData, EnumNormalizedStructure, EnumPoliticalBeliefsData, EnumReligionsData, EnumUserInterestsData } from '../types/enums.options'
import { getBodyTypesEnum, getCountryOptions, getNationalitiesEnum, getPoliticalBeliefsEnum, getReligionsEnum, getUserInterestsEnum } from '../_services/enums.service'

export const getEnumOptions = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [countryData, setCountryData] = useState<EnumNormalizedStructure[]>([])
  const [nationalityData, setNationalityData] = useState<EnumNormalizedStructure[]>([])
  const [bodyTypeData, setBodyTypeData] = useState<EnumNormalizedStructure[]>([])
  const [religionData, setReligionData] = useState<EnumNormalizedStructure[]>([])
  const [politicalBeliefData, setPoliticalBeliefData] = useState<EnumNormalizedStructure[]>([])
  const [userInterestsData, setUserInterestsData] = useState<EnumNormalizedStructure[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countries, nationalities, bodyTypes, religions, politicalBeliefs, userInterests] = await Promise.all([
          getCountryOptions(),
          getNationalitiesEnum(),
          getBodyTypesEnum(),
          getReligionsEnum(),
          getPoliticalBeliefsEnum(),
          getUserInterestsEnum(),
        ])

        setCountryData(
          countries.data.map((option: EnumCountryData) => ({
            id: String(option.gid),
            display: option.nameLong,
            advancedOption: option.name,
          }))
        )
        setNationalityData(
          nationalities.data.map((option: EnumNationalitiesData) => ({
            id: option.isoCode,
            display: option.nationality,
            advancedOption: option.advancedOption,
          }))
        )

        setBodyTypeData(
          bodyTypes.data.map((option: EnumBodyTypesData) => ({
            id: option.name,
            display: option.display,
            advancedOption: false,
          }))
        )

        setReligionData(
          religions.data.map((option: EnumReligionsData) => ({
            id: option.name,
            display: option.display,
            advancedOption: option.advancedOption,
          }))
        )

        setPoliticalBeliefData(
          politicalBeliefs.data.map((option: EnumPoliticalBeliefsData) => ({
            id: option.name,
            display: option.display,
            advancedOption: false,
          }))
        )

        setUserInterestsData(
          userInterests.data
            .map((option: EnumUserInterestsData) => ({
              id: option.name,
              display: option.display,
              advancedOption: false,
            }))
            .sort((a: EnumUserInterestsData, b: EnumUserInterestsData) => a.display.localeCompare(b.display))
        )

        setLoading(false)
      } catch (error) {
        setError(`Failed to fetch data: ${error}`)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return { countryData, nationalityData, bodyTypeData, religionData, politicalBeliefData, userInterestsData, loading, error }
}
