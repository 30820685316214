import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { publicRoutes, authRoutes } from './_core/router/routes'
import BaseLayout from './pages/auth/_layout/authLayout'
import { authStore } from './_core/stores/authStore'
import { autorun } from 'mobx'
import './assets/scss/style.scss'
import 'primereact/resources/themes/saga-blue/theme.css'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(authStore.isAuthenticated)

  useEffect(() => {
    const disposer = autorun(() => {
      setIsAuthenticated(authStore.isAuthenticated)
    })

    return () => {
      disposer()
    }
  }, [])

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.component {...route.props}/>}   />
        ))}

        {/* Protected Routes */}
        {isAuthenticated ? (
          <Route path="/" element={<BaseLayout />}>
            {authRoutes.map((route) =>
              route.path === 'discover' ? (
                <Route key={route.path} path={route.path} element={<route.component {...route.props} />} />
              ) : (
                <Route key={route.path} path={route.path} element={<route.component {...route.props} />} />
              )
            )}
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}
      </Routes>
    </Router>
  )
}

export default App
