import React, { useState, ReactElement, useEffect, useRef } from 'react'

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { wipeUserAccount } from '_services/users.service'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../../_core/stores/authStore'
import { Toast } from 'primereact/toast'
import { useTimeout } from 'primereact/hooks'
import { delay } from 'util/helpers.converters'


const searchOptions = [
  { id: 'privacy_concerns', display: 'Concerns about privacy or data security' },
  { id: 'not_using_service', display: 'No longer using the service' },
  { id: 'prefer_another_service', display: 'Prefer another service' },
  { id: 'quality_issues', display: 'Issues with service quality or reliability' },
  { id: 'cost_related', display: 'Cost-related reasons' },
  { id: 'too_distracting', display: 'Service is too distracting or time-consuming' },
  { id: 'negative_experiences', display: 'Negative experiences or interactions' },
  { id: 'personal_reasons', display: 'Personal reasons or life changes' },
  { id: 'privacy_policy_changes', display: 'Changes in privacy policy or terms of service' },
  { id: 'other', display: 'Other (please specify)' },
]
interface WipeAccountData {
  deleteReason: string
  extraDetails: string
}

export const DeleteAccount = (): JSX.Element => {
  const navigate = useNavigate()
  const toast = useRef<Toast>(null)
  const [deleteReason, setDeleteReason] = useState<string>("")
  const [deleteDetails, setDeleteDetails] = useState<string>("")


  const accept = async () => {
    const wipeData: WipeAccountData = {
      deleteReason: deleteReason,
      extraDetails: deleteDetails
    }

    await wipeUserAccount(wipeData,
      async (res) => {
        toast.current.show({ severity: 'success', summary: 'Submitted', detail: 'Your account has been deleted successfully', life: 3000 })
        await delay(3000)
        authStore.logout(()=>{
            navigate('/')
        })

      },
      () => {}
    )
  }


  const confirmDelete = (event: { currentTarget: any }) => {
    confirmPopup({
        target: event.currentTarget,
        message:
          <div >

          <p> Are you sure you want to proceed?</p>
          </div>,
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept,
        // denyButton
    })
  }

  return (

    <>
    <Toast ref={toast} />
    <ConfirmPopup />
    <div className='mb-2'>
      <p> Deleting your account will delete all data including:</p>
        <ul className='mx-5'>
            <li>Messages</li>
            <li>Photos</li>
            <li>Matches</li>
            <li>Any ongoing subscriptions will be cancelled</li>
        </ul>
    </div>
    <Dropdown value={deleteReason} onChange={(e) => {setDeleteReason(e.value)}} options={searchOptions}
        optionLabel="display"
        optionValue='id'
        placeholder="Select a Reason" className="w-full md:w-14rem mb-2"
    />
    <InputTextarea placeholder="Additional Details" value={deleteDetails} onChange={(e) => {setDeleteDetails(e.target.value)}} rows={5} cols={30} />
    <div className="card flex flex-wrap gap-2 justify-content-center">
      <Button onClick={confirmDelete}  icon="pi pi-times" label="Delete" className="p-button-danger"></Button>
    </div>
  </>
  )
}

export default DeleteAccount
