import { EnumUserInterestsData } from "../types/enums.options"
import { UserInterests } from "../types/user"

export const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

export type Measurement = {
  dualLocaleDisplay : string
  metricDisplay     : string
  imperialDisplay   : string

  feet              : number
  inches            : number
  cm                : number
}
// Constants
export const CM_IN_A_FOOT = 30.48
export const CM_IN_AN_INCH = 2.54
export const MINIMUM_HEIGHT_FT = 4
export const MAX_HEIGHT_FT = 7
export const MINIMUM_HEIGHT_IN = 0
export const MAX_HEIGHT_IN = 11
export const MINIMUM_HEIGHT_CM = Math.round(MINIMUM_HEIGHT_FT * 12 * CM_IN_AN_INCH + MINIMUM_HEIGHT_IN * CM_IN_AN_INCH)
export const MAX_HEIGHT_CM = Math.round(MAX_HEIGHT_FT * 12 * CM_IN_AN_INCH + MAX_HEIGHT_IN * CM_IN_AN_INCH)

// Converters

export function formatHeightMeasurement(heightInCm: number): Measurement {
  const inchesTotal = heightInCm / 2.54
  const feet = Math.floor(inchesTotal / 12)
  const inches = Math.round(inchesTotal % 12)

  return {
      dualLocaleDisplay: `${feet}'${inches}" / ${heightInCm} cm`,
      metricDisplay: `${heightInCm} cm`,
      imperialDisplay: `${feet}'${inches}"`,

      feet: feet,
      inches: inches,
      cm: heightInCm
  }
}

export function getAgeFromBirthday(birthday: string): number {
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

export function sortUserInterests(interests: UserInterests[]) {
  return interests.sort((a, b) => {
    const categoryComparison = a.EnumInterest.category.localeCompare(b.EnumInterest.category)
    if (categoryComparison !== 0) return categoryComparison

    return a.EnumInterest.display.localeCompare(b.EnumInterest.display)
  })
}

export function getDefaultMeasurementSystem(locale: string): string {
  const imperialLocales = [
    'en_US', // United States
    'en_BS', // Bahamas
    'en_BZ', // Belize
    'en_FM', // Federated States of Micronesia
    'en_LR', // Liberia
    'en_MM', // Myanmar (uses a mix of imperial and metric)
    'en_PW', // Palau
  ]

  if (imperialLocales.includes(locale)) {
      return 'imperial'
  } else {
      return 'metric'
  }
}

export function stringToColor(str: string, color1: string, color2: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let mix = (hash % 100) / 100;
  let resultColor = [0, 1, 2].map(i => {
      let color1Component = parseInt(color1.substring(1 + i * 2, 3 + i * 2), 16);
      let color2Component = parseInt(color2.substring(1 + i * 2, 3 + i * 2), 16);
      let mixedComponent = Math.round(color1Component + mix * (color2Component - color1Component)).toString(16);
      return mixedComponent.padStart(2, '0');
  }).join('');

  return `#${resultColor}`;
}

export function getColorFromString(str: string) {
  // Hard-coded color range make these the app blue and orange
  const color1 = "#1F8293";
  const color2 = "#F2925E";

  return stringToColor(str, color1, color2);
}

export const toFeetInches = (cm: number) => {
  const totalInches = cm / CM_IN_AN_INCH
  const feet = Math.floor(totalInches / 12)
  const inches = Math.round(totalInches % 12)
  return { feet, inches }
}

export const toCentimeters = (feet: number, inches: number) => (feet * 12 + inches) * CM_IN_AN_INCH

export const groupBy = function<T, K extends keyof T>(xs: T[], key: K): Record<T[K] extends string | number | symbol ? T[K] : never, T[]> {
  return xs.reduce<Record<T[K] extends string | number | symbol ? T[K] : never, T[]>>((rv, x) => {
    const keyValue = x[key] as T[K] extends string | number | symbol ? T[K] : never;
    (rv[keyValue] = rv[keyValue] || []).push(x);
    return rv;
  }, {} as Record<T[K] extends string | number | symbol ? T[K] : never, T[]>);
}
