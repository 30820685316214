import { _postV1, _getV1 } from './config/index'

export async function sendSmsOtpForLogin(inputKind:string, toNumber: string, countryCode: string): Promise<any> {
  try {
    const response = await _postV1(`/auth/sendOtp`, {inputKind:inputKind, emailPhone: toNumber, countryCode: countryCode })
    return response
  } catch (error: any) {
    console.log('Error on POST sendSmsOtpForLogin:', error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function verifySmsOtp(toNumber: string, enteredOtp: string, fcmDeviceToken: string): Promise<any> {
  try {
    const response = await _postV1('/auth/confirmOtp', {
      emailPhone: toNumber,
      enteredOtp: enteredOtp,
      fcmDeviceToken: fcmDeviceToken
    })
    return response
  } catch (error: any) {
    console.log('Error on POST verifySmsOtp:', error)
    return {
      error: true,
      message: error,
    }
  }
}

export async function authenticateSSO(ssoID: string, name: string, email: string): Promise<any> {
    try {
    const response = await _postV1('/auth/loginSSO', {
      ssoID,
      name,
      email
    })
    return response
  } catch (error: any) {
    console.log('Error on POST loginSSO:', error)
    return {
      error: true,
      message: error,
    }
  }
}