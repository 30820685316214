import React, { useContext, useEffect, useState } from 'react'
import WrapperCardNew from '../../../../components/cards/WrapperCardNew'
import { getMyLikes } from '_services/users.service'
import { DataScroller } from 'primereact/datascroller'
import { Image } from 'primereact/image'
import { NavLink } from 'react-router-dom'
import { User } from 'types/user'
import ContextInteractions from '../../../../_core/contexts/interactions'
import { useSignalR } from '../../../../_core/hooks'
import noProfileImage from '../../../../assets/images/no_profile_image@2x.png'
import OurImage from '../../_components/profile/components/OurImage'

const columns = 2

const chunkArray = <a,>(n: number, xs: a[]): a[][] =>
  xs.reduce((agg: a[][], x, i) => {
    if (i % n == 0) {
      agg.push([])
    }
    agg[agg.length - 1].push(x)
    return agg
  }, [])

function LikesMe() {
  const { likes,setInteractions } = useContext(ContextInteractions)

  const invoke = useSignalR([
  ])
  useEffect(() => {
    invoke('SetSeen','likes').then(setInteractions)
  },[])

  return (
    <WrapperCardNew dependency={likes} className="w-100">{(likes) =>
      <div className="d-block d-lg-none h-100 w-100">
        <p className="text-center">Here you can see who has liked you and match much more streamlined.</p>
        <div className="d-flex justify-content-center flex-wrap vertical-scroll">
          <DataScroller
            value={chunkArray(columns, likes)}
            rows={5}
            itemTemplate={(likes) => (
              <div className="d-flex w-100 my-3">
                {likes.map((like: { user: User, is_new: boolean }) => (
                  <NavLink
                    key={like.user.id}
                    to={`/discover/likes/${like.user.id}`}
                    className="position-relative shadow mx-3 rounded-4 overflow-hidden border border-gray"
                    style={{ width: 100 / columns + '%' }}
                  >
                    {/* TODO: blur needs to be server-side */}
                    <OurImage
                      src={like.user.userPhotos?.[0]?.imgUrl}
                      default={noProfileImage}
                      alt="Image"
                      width="100%" />
                    <div className="rounded-4 border border-gray badge fs-6 text-bg-secondary-light position-absolute bottom-0 start-0">
                      {like.user.firstName} {like.user.lastName}
                    </div>
                  </NavLink>
                ))}
              </div>
            )}
          />
        </div>
      </div>
    }</WrapperCardNew>
  )
}

export default LikesMe
