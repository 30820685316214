import React, { useRef, useState } from 'react'
import { Sidebar, SidebarProps } from 'primereact/sidebar'
import { Button } from 'primereact/button'
import { Ripple } from 'primereact/ripple'
import { StyleClass } from 'primereact/styleclass'
import SidebarWrapper from '../../../pages/auth/_components/profile/components/SidebarWrapper'
import { Link } from 'react-router-dom'

type MobileNavProps = {
  onSignIn: () => void
  onCreate: () => void
}
const PublicMobileNavbar: React.FC<MobileNavProps> = (props:MobileNavProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [visibleCreate, setvisibleCreate] = useState<boolean>(false)

  return (

    <ul className="navbar-nav flex-col align-items-start ">
      <li className="nav-item">
        <Link className="nav-link px-3 fs-4 fw-medium" to="/faq">
          FAQ
        </Link>
        <Link className="nav-link px-3 fs-4 fw-medium" to="/frequent-denial-reasons">
          FDR
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link px-3 fs-4 fw-medium" to="/stories">
          Success Stories
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link px-3 fs-4 fw-medium" to="/blog">
          Blog
        </Link>
      </li>
      {/* <li className="nav-item">
        <button className="nav-link px-3" onClick={() => props.onCreate()}>
          Create Account
        </button>
      </li>
      <li className="nav-item">
        <button className="btn btn-primary" onClick={() => props.onSignIn()}>
          Sign in
        </button>
      </li> */}
  </ul>
  )
}

export default PublicMobileNavbar