import React from 'react'
import Logo from '../../../assets/images/horizontal-logo.png'
import { Image } from 'primereact/image'


const TermsAndConditions: React.FC = () => {
  return (
    <div className="d-flex justify-content-center vh-100 html-text">
      <header className='position-fixed bg-white flex justify-content-center w-100 text-center py-1 shadow-sm'>
        <Image src={Logo} alt="Dating Across the Pond" />
      </header>
      <div className=" w-75 p-3 my-2">

        <h5>DAtP terms</h5>

        <h5>DAtP Terms and Conditions of Use</h5>
        <p>
          Welcome to DAtP’s Terms and Conditions of Use (these "Terms"). This is a contract between you and Dating
          Across the Pond LLC (as defined further below) and we want you to know yours and our rights before you use
          the DAtP website or application ("DAtP" or the "App"). Please take a few moments to read these Terms before
          enjoying the App, because once you access, view or use the App, you are going to be legally bound by these
          Terms (so probably best to read them first!).
        </p>
        <p>
          Please be aware that if you subscribe to services for a term (the "Initial Term"), then the terms of your
          subscription will be automatically renewed for additional periods of the same duration as the Initial Term
          at DAtP’s then-current fee for such services, unless you cancel your subscription in accordance with Section
          5 below.
        </p>
        <p>
          You should also note that Section 12 of these Terms contains provisions governing how claims that you and
          Dating Across the Pond LLC have against each other are resolved. In particular, it contains an arbitration
          agreement that will, with limited exceptions, require disputes between us to be submitted to binding and
          final arbitration. You have a right to opt out of the arbitration agreement pursuant to Section 12 below. If
          you do not opt out of the arbitration agreement in accordance with Section 12, (1) you will only be
          permitted to pursue claims and seek relief against us on an individual basis only; and (2) you are waiving
          your right to seek relief in a court of law and to have a jury trial on your claims.
        </p>
        <p>
          And a notice to California subscribers: You may cancel your subscription and request a refund at any time
          prior to midnight of the third business day following the date you subscribed. If you subscribed using your
          Apple ID, refunds are handled by Apple, not DAtP. If you wish to request a refund, please visit{' '}
          <a href="https://getsupport.apple.com">https://getsupport.apple.com</a>. If you subscribed using your Google
          Play Store account or through DAtP, please contact customer support at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>.
        </p>
        <h5>1. DAtP RULES</h5>
        <p>
          Before you can use the App, you will need to register for an account ("Account"). In order to create an
          Account you must:
        </p>
        <ol>
          <li>
            be at least 18 years old or the age of majority to legally enter into a contract under the laws of your
            home country; and
          </li>
          <li>be legally permitted to use the App by the laws of your home country.</li>
        </ol>
        <p>
          You can create an Account via manual registration, or by using your Facebook login details. If you create an
          Account using your Facebook login details, you authorize us to access, display and use certain information
          from your Facebook account (e.g. profile pictures, relationship status, location and information about
          Facebook friends). For more information about what information we use and how we use it, please check out
          our Privacy Policy.
        </p>
        <p>
          Unfortunately, we cannot allow you to use another person’s Account or to share your Account with any other
          person without permission.
        </p>
        <p>
          You’ll have great fun on DAtP, but if you feel the need to leave, you can delete your Account at any time by
          going to the ‘Settings’ page when you are logged in and clicking on the ‘Delete account’ link. Your Account
          will be deleted immediately but it may take a little while for Your Content (defined below) to be completely
          removed from the App. Your profile information will be treated in accordance with our Privacy Policy. If you
          delete your Account and try to create a new account within this time period using the same credentials, we
          will re-activate your Account for you.
        </p>
        <p>
          We use a combination of automated systems and a team of moderators to monitor and review accounts and
          messages for content that indicates breaches of these Terms. We reserve the right at our sole discretion to
          terminate or suspend any Account, restrict access to the App, or make use of any operational, technological,
          legal or other means available to enforce the Terms (including without limitation blocking specific IP
          addresses), at any time without liability and without the need to give you prior notice. Without limiting
          the foregoing in any way, we expressly reserve the right to terminate or suspend your Account without notice
          (1) for violating these terms, (2) due to your conduct on the App, or your conduct with other users of the
          App (including your "offline" conduct), if we, in our sole discretion, determine your conduct was
          inappropriate or improper, (3) if we or our affiliates, in our or their sole discretion, determine your
          conduct on other apps operated by our affiliates was inappropriate or improper, or (4) for any reasons
          whatsoever that we deem, in our sole discretion, justifies termination. If your Account is terminated or
          suspended, you agree you will not receive a refund for any paid service or features you have already been
          charged for.
        </p>
        <p>
          You may not access, tamper with, or use non-public areas of the App or our systems. Certain portions of the
          App may not be accessible if you have not registered for an Account.
        </p>

        <h5>2. CONTENT TYPES</h5>
        <p>There are three types of content that you will be able to access on the App:</p>
        <ol>
          <li>content that you upload and provide ("Your Content");</li>
          <li>content that members provide ("Member Content"); and</li>
          <li>
            content that the Dating Across the Pond LLC provides (including, without limitation, database(s) and/or
            software) ("Our Content").
          </li>
        </ol>
        <p>There is certain content we can’t allow on DATP.</p>
        <p>
          We want our users to be able express themselves as much as possible and post all sorts of things on DATP,
          but we have to impose restrictions on certain content which:
        </p>
        <ul>
          <li>
            contains language or imagery which could be deemed offensive or is likely to harass, upset, embarrass,
            alarm or annoy any other person (including for example and without limitation, language that could be
            deemed discriminatory towards an individual’s race, color, ethnicity, national origin, religion,
            disability, sexual orientation, gender expression, gender identity or physical appearance);
          </li>
          <li>
            is obscene, pornographic, violent or otherwise may offend human dignity (including for example and without
            limitation, language that could be deemed discriminatory towards an individual’s race, color, ethnicity,
            national origin, religion, disability, sexual orientation, gender expression, gender identity or physical
            appearance);
          </li>
          <li>
            is abusive, insulting or threatening, discriminatory or which promotes or encourages racism, sexism,
            hatred or bigotry (including for example and without limitation, language that could be deemed
            discriminatory towards an individual’s race, color, ethnicity, national origin, religion, disability,
            sexual orientation, gender expression, gender identity or physical appearance);
          </li>
          <li>
            encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the
            submission of which in itself constitutes committing a criminal offence.
          </li>
          <li>is defamatory or libelous;</li>
          <li>
            relates to commercial activities (including, without limitation, sales, competitions and advertising,
            links to other websites or premium line telephone numbers);
          </li>
          <li>involves the transmission of "junk" mail or "spam";</li>
          <li>
            contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code designed to
            interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications,
            networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere
            with, wrongly intercept or expropriate any data or personal information whether from DAtP or otherwise;
          </li>
          <li>
            itself, or the posting of which, infringes any third party’s rights (including, without limitation,
            intellectual property rights and privacy rights);
          </li>
          <li>shows another person which was created or distributed without that person’s consent;</li>
          <li>is harmful to minors; or</li>
          <li>impersonates any other person, including falsely stating your name.</li>
        </ul>
        <p>Dating Across the Pond LLC operates a zero-tolerance policy for this kind of content.</p>
        <p>Your Content</p>
        <p>
          You agree that Your Content must comply with our Guidelines at{' '}
          <a href="/communityGuidelines">https://datingacrossthepond.com/en/guidelines</a>, as updated from time to
          time. As Your Content is unique, you are responsible and liable for Your Content and will indemnify, defend,
          release, and hold us harmless from any claims made in connection with Your Content. That was a lot of
          jargon, but you are what you post!
        </p>
        <p>
          You may not display any personal contact or banking information on your individual profile page whether in
          relation to you or any other person (for example, names, home addresses or postcodes, telephone numbers,
          email addresses, URLs, credit/debit card or other banking details). If you do choose to reveal any personal
          information about yourself to other users, whether via email or otherwise, it is at your own risk. We
          encourage you to use the same caution in disclosing details about yourself to third parties online as you
          would under any other circumstances.
        </p>
        <p>
          As DAtP is a public community, Your Content will be visible to other users of the App all around the world
          instantly - so make sure you are comfortable sharing Your Content before you post. As such, you agree that
          Your Content may be viewed by other users and any person visiting, participating in or who is sent a link to
          the App (e.g., individuals who receive a link to a user’s profile or shared content from other DAtP Users).
          By uploading Your Content on DAtP, you represent and warrant to us that you have all necessary rights and
          licenses to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide license to
          use Your Content in any way (including, without limitation, editing, copying, modifying, adapting,
          translating, reformatting, creating derivative works from, incorporating into other works, advertising,
          distributing and otherwise making available to the general public such Content, whether in whole or in part
          and in any format or medium currently known or developed in the future).
        </p>
        <p>
          We may assign and/or sub-license the above license to our affiliates and successors without any further
          approval by you.
        </p>
        <p>We do not have any obligation to store Your Content - if it’s important, you should make a copy.</p>
        <p>
          So that we can prevent the unconsented use of Your Content by other members or third parties outside of
          DAtP, you authorize us to act on your behalf with respect to such infringing and/or unauthorized uses. This
          expressly includes the authority, but not the obligation, for us to send takedown notices (including,
          without limitation, pursuant to 17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices)) on your behalf if Your
          Content is taken and used by third parties outside of DAtP.
        </p>
        <p>Member Content</p>
        <p>
          Other members of DAtP will also share content via the App. Member Content belongs to the user who posted the
          content and is stored on our servers and displayed via the App at the direction of the user providing the
          Member Content.
        </p>
        <p>
          You do not have any rights in relation to other users’ Member Content, and you may only use other DAtP
          users’ personal information to the extent that your use of it matches DAtP’s purpose of allowing people to
          meet one another. You may not use other users’ information for commercial purposes, to spam, to harass,
          stalk or to make unlawful threats. We reserve the right to terminate your Account if you misuse other users’
          information.
        </p>
        <p>
          Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the Digital
          Millennium Copyright Act 1998. If you have a complaint about Member Content, please see the Digital
          Millennium Copyright Act section below for more information.
        </p>
        <p>Our Content</p>
        <p>
          You may be wondering what happens to the rest of the Content on DAtP. Well, it belongs to us! Any other
          text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, and other intellectual
          property appearing on DAtP, as well as the DAtP software and database(s), are owned, controlled or licensed
          by us and are protected by copyright, trademark, data, database rights and/or other intellectual property
          law rights. All right, title and interest in and to Our Content remains with us at all times.
        </p>
        <p>
          We grant you a non-exclusive, limited, personal, non-transferable, revocable, license to access and use Our
          Content, without the right to sublicense, under the following conditions:
        </p>
        <ol>
          <li>
            you shall not use, sell, modify, or distribute Our Content except as permitted by the functionality of the
            App;
          </li>
          <li>you shall not use our name in metatags, keywords and/or hidden text;</li>
          <li>
            you shall not create derivative works from Our Content or scrape, disable, decompile, analyze or in any
            way commercially exploit Our Content, in whole or in part, in any way; and
          </li>
          <li>you shall use Our Content for lawful purposes only.</li>
        </ol>
        <p>We reserve all other rights.</p>
        <p>No Obligation to Pre-Screen Content.</p>
        <p>
          Since DAtP is an online community, we generally try to avoid getting in the way and therefore don’t assume
          any obligation to pre-screen any of Your Content or any Member Content. However, there may be times where we
          need to step in, and we reserve the right to review, pre-screen, refuse and/or remove any Member Content and
          Your Content, including content exchanged between users in direct messages.
        </p>

        <h5>3. THE APP RESTRICTIONS</h5>
        <p>You agree to:</p>
        <ul>
          <li>
            comply with all applicable laws, including without limitation, privacy laws, intellectual property laws,
            anti-spam laws, equal opportunity laws and regulatory requirements;
          </li>
          <li>use your real name and real age in creating your DAtP account and on your profile; and</li>
          <li>use the services in a professional manner.</li>
        </ul>
        <p>You agree that you will not:</p>
        <ul>
          <li>act in an unlawful or unprofessional manner including being dishonest, abusive or discriminatory;</li>
          <li>
            misrepresent your identity, your age, your current or previous positions, qualifications or affiliations
            with a person or entity;
          </li>
          <li>disclose information that you do not have the consent to disclose;</li>
          <li>stalk or harass any other user of the App;</li>
          <li>create or operate a pyramid scheme, fraud or other similar practice, or;</li>
          <li>
            develop, support or use software, devices, scripts, robots, other types of mobile code or any other means
            or processes (including crawlers, browser plugins and add-on or other technology) to scrape or otherwise
            exfiltrate from DAtP or its services, or otherwise copy profiles and other data from the services.
          </li>
        </ul>
        <p>
          We don’t like users being naughty in the DAtP community. You can report any abuse or complain about Member
          Content by contacting us, outlining the abuse and/or complaint. You can also report a user directly from a
          profile or in chat by clicking the ‘Block & Report’ link. We reserve the right to investigate any possible
          violations of these Terms, any DAtP user’s rights, or any third party rights and we may, in our sole
          discretion, immediately terminate any user’s right to use of the App without prior notice, as set out
          further in Section 1 above, and/or remove any improper, infringing or otherwise unauthorized Member Content
          submitted to the App.
        </p>
        <p>
          We don’t control any of the things our users say or do, so you are solely responsible for your interactions
          with other users of the App.
        </p>
        <p>
          YOU UNDERSTAND THAT DATING ACROSS THE POND LLC DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS
          USERS. DATING ACROSS THE POND LLC ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO
          VERIFY THE STATEMENTS OF ITS USERS. DATING ACROSS THE POND LLC MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
          THE CONDUCT OF ANY USERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS. DATING ACROSS THE POND LLC
          RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER
          REGISTRATION SEARCHES) AT ANY TIME AND TO USE AVAILABLE PUBLIC RECORDS FOR ANY PURPOSE.
        </p>
        <p>
          You agree to, and hereby do, release Dating Across the Pond LLC and its successors from any claims, demands,
          losses, damages, rights, and actions of any kind, including personal injuries, death and property damage,
          that either directly or indirectly arises from your interactions with or conduct of other users of the App.
          If you are a California resident, you hereby waive California Civil Code Section 1542, which states, "A
          general release does not extend to claims that the creditor or releasing party does not know or suspect to
          exist in his or her favor at the time of executing the release and that, if known by him or her, would have
          materially affected his or her settlement with the debtor or released party." The foregoing release does not
          apply to any claims, demands, or any losses, damages, rights and actions of any kind, including personal
          injuries, death or property damage for any unconscionable commercial practice by Dating Across the Pond LLC
          or for such party’s fraud, deception, false, promise, misrepresentation or concealment, suppression or
          omission of any material fact in connection with the App.
        </p>
        <p>
          Scraping or replicating any part of the App without our prior consent is expressly prohibited. This includes
          by any means (automated or otherwise) other than through our currently available, published interfaces -
          unless you have been specifically allowed to do so in a separate agreement with us.
        </p>

        <h5>4. PRIVACY</h5>
        <p>
          DAtP will not pass your personal data to any third-party organization. Your data is collected to better
          understand and market to you the user. For information about how the Dating Across The Pond LLC collects,
          uses, and shares your personal data, please check out our Privacy Policy. By using DAtP, you acknowledge
          that we may use such data in accordance with our Privacy Policy.
        </p>

        <h5>5. PAYMENT TERMS</h5>
        <p>
          Generally. DAtP may offer products and services for purchase on the App ("In-App Purchase"). If you choose
          to make an In-App Purchase, you acknowledge and agree that additional terms, disclosed to you at the point
          of purchase, may apply, and that such additional terms are incorporated herein by reference.
        </p>
        <p>
          You may make an In-App Purchase through the following payment methods ("Payment Method"): (a) making a
          purchase through a third-party platform such as the Apple App Store and Google Play Store ("Third Party
          Store"), or (b) paying with your credit card, debit card, or PayPal account, which will be processed by a
          third-party processor. Once you have made an In-App Purchase, you authorize us to charge your chosen Payment
          Method. If payment is not received by us from your chosen Payment Method, you agree to promptly pay all
          amounts due upon demand by us.
        </p>
        <p>
          Subscriptions and Auto-Renewal: DAtP may offer some services as automatically-renewing subscriptions, e.g.,
          a one-month subscription, three-month subscription, or six-month subscription ("Premium Services"). IF YOU
          PURCHASE AN AUTOMATICALLY RENEWING SUBSCRIPTION, YOUR SUBSCRIPTION WILL RENEW AT THE END OF THE PERIOD,
          UNLESS YOU CANCEL, AT DATP’S THEN-CURRENT PRICE FOR SUCH SUBSCRIPTIONS.
        </p>
        <p>
          To avoid charges for a new subscription period, you must cancel, as described below, before the end of the
          then-current subscription period. Deleting your account or deleting the application from your device does
          not cancel your subscription. You will be given notice of changes in the pricing of the Premium Services to
          which you have subscribed and an opportunity to cancel. If DAtP changes these prices and you do not cancel
          your subscription, you agree that you will be charged at DAtP’s then-current pricing for subscription.
        </p>
        <p>
          Cancelling Subscriptions. If you purchased a subscription directly from DAtP, you may cancel or change your
          Payment Method via the payment settings option under your profile. If you purchased a subscription through a
          Third Party Store, such as the Apple App Store or the Google Play Store, you will need to access your
          account with that Third Party Store and follow instructions to change or cancel your subscription. If you
          cancel your subscription, you may use your subscription until the end of the period you last paid for, but
          (i) you will not (except as set forth in the subsection entitled "Refunds" below) be eligible for a prorated
          refund, (ii) your subscription will not be renewed when that period expires and (iii) you will then no
          longer be able to use the Premium Services or In-App Purchases enabled by your subscription.
        </p>
        <p>
          Because our Services may be utilized without a subscription, canceling your subscription does not remove
          your profile from our Services. If you wish to fully terminate your account, you must terminate your account
          as set forth in Section 13.
        </p>
        <p>
          Dating Across the Pond LLC operates a global business, and our pricing varies by a number of factors. We
          frequently offer promotional rates - which can vary based on region, length of subscription, bundle size and
          more. We also regularly test new features and payment options. Dating Across the Pond LLC reserves the
          right, including without prior notice, to limit the available quantity of or discontinue making available
          any product, feature, service or other offering; to impose conditions on the honoring of any coupon,
          discount, offer or other promotion; to bar any user from making any transaction; and to refuse to provide
          any user with any product, service or other offering or to honor any offer.
        </p>
        <p>
          Free Trials. If you sign up for a free trial and do not cancel, your trial may convert into a paid
          subscription and your Payment Method will be charged at the then-current price for such subscription. Once
          your free trial converts to a paid subscription, your paid subscription will continue to automatically renew
          at the end of each period, and your Payment Method will be charged, until you cancel. To avoid charges for a
          new subscription period, you must cancel before the end of the then-current subscription period or free
          trial period as described above. Deleting your account or deleting the application from your device does not
          cancel your free trial. If you have signed up for a free trial on DAtP through the Apple Store or Google
          Play Store previously, you will not be eligible for another free trial and you will then be automatically
          signed up to a subscription and charged as described in this paragraph.
        </p>
        <p>
          Refunds. Generally, all charges for purchases are nonrefundable, and there are no refunds or credits for
          partially used periods.
        </p>
        <p>
          For subscribers residing in Arizona, California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York,
          North Carolina, Ohio, Rhode Island, and Wisconsin, the terms below apply:
        </p>
        <p>
          YOU, THE BUYER, MAY CANCEL YOUR SUBSCRIPTION, WITHOUT PENALTY OR OBLIGATION, AT ANY TIME PRIOR TO MIDNIGHT
          OF THE THIRD BUSINESS DAY FOLLOWING THE DATE YOU SUBSCRIBED (EXCLUDING SUNDAYS AND HOLIDAYS). IN THE EVENT
          THAT YOU DIE BEFORE THE END OF YOUR SUBSCRIPTION PERIOD, YOUR ESTATE SHALL BE ENTITLED TO A REFUND OF THAT
          PORTION OF ANY PAYMENT YOU HAD MADE FOR YOUR SUBSCRIPTION WHICH IS ALLOCABLE TO THE PERIOD AFTER YOUR DEATH.
          IN THE EVENT THAT YOU BECOME DISABLED (SUCH THAT YOU ARE UNABLE TO USE DATP) BEFORE THE END OF YOUR
          SUBSCRIPTION PERIOD, YOU SHALL BE ENTITLED TO A REFUND OF THAT PORTION OF ANY PAYMENT YOU HAD MADE FOR YOUR
          SUBSCRIPTION WHICH IS ALLOCABLE TO THE PERIOD AFTER YOUR DISABILITY BY PROVIDING THE COMPANY NOTICE IN THE
          SAME MANNER AS YOU REQUEST A REFUND AS DESCRIBED BELOW.
        </p>
        <p>To request a refund:</p>
        <p>
          To request a refund: In addition to cancelling, eligible subscribers must request a refund to receive one.
          If you subscribed using your Apple ID, refunds are handled by Apple, not DAtP. To request a refund, go to
          iTunes, click on your Apple ID, select "Purchase history," find the transaction and hit "Report Problem".
          You can also submit a request at <a href="https://getsupport.apple.com">https://getsupport.apple.com</a>.
        </p>
        <p>Taxes.</p>
        <p>
          The payments required under this Section 5 do not include any Sales Tax that may be due in connection with
          the Premium Services provided under these Terms. If DAtP determines it has a legal obligation to collect a
          Sales Tax from you in connection with these Terms, DAtP will collect such Sales Tax in addition to the
          payments required under this Section 5. If any Premium Services, or payments for any Premium Services, under
          these Terms are subject to any Sales Tax in any jurisdiction and you have not remitted the applicable Sales
          Tax to DAtP, you will be responsible for the payment of such Sales Tax and any related penalties or interest
          to the relevant tax authority. As used herein, "Sales Tax" means any sales or use tax and any other tax
          measured by sales proceeds that is the functional equivalent of a sales tax where the applicable taxing
          jurisdiction does not otherwise impose a sales or use tax.
        </p>

        <h5>6. LOCATION-BASED FEATURES & PUSH NOTIFICATIONS</h5>
        <p>
          We may provide you with emails, text messages, push notifications, alerts and other messages related to the
          App and/or the DAtP services, such as enhancements, offers, products, events, and other promotions. After
          downloading the App, you will be asked to accept or deny push notifications/alerts. If you deny, you will
          not receive any push notifications/alerts. If you accept, push notifications/alerts will be automatically
          sent to you. If you no longer wish to receive push notifications/alerts from the App, you may opt out by
          changing your notification settings on your mobile device. With respect to other types of messaging or
          communications, such as emails, text messages, etc., you can unsubscribe or opt out by either following the
          specific instructions included in such communications, or by emailing us with your request at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>.
        </p>
        <p>
          The App may allow access to or make available opportunities for you to view certain content and receive
          other products, services and/or other materials based on your location. To make these opportunities
          available to you, the App will determine your location using one or more reference points, such as GPS,
          Bluetooth and/or software within your mobile device. If you have set your mobile device to disable GPS,
          Bluetooth or other location determining software or do not authorize the App to access your location data,
          you will not be able to access such location-specific content, products, services and materials. For more
          about how the App uses and retains your information, please read the Privacy Policy.
        </p>

        <h5>7. DISCLAIMER</h5>
        <p>
          THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO YOU "AS IS" AND "AS AVAILABLE" WITHOUT
          WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT GUARANTEE THE COMPATIBILITY
          OF ANY MATCHES.
        </p>
        <p>
          SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS OR IMPLIED WARRANTIES, THEN WE GRANT THE
          MINIMUM EXPRESS OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR
          WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.
        </p>
        <p>
          ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE
          OR THAT YOUR USE OF THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT, ANY
          MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF THE APP OR SITE IS AT
          YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. DATING ACROSS THE POND
          LLC IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. DATING ACROSS THE POND LLC DOES NOT CONDUCT CRIMINAL
          BACKGROUND CHECKS ON ITS MEMBERS.
        </p>

        <h5>8. LIMITATION OF LIABILITY</h5>
        <p>
          NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL, OR PUNITIVE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFIT OR GOODWILL, LOSS OF OR
          DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, SITE, OUR
          CONTENT, OR ANY MEMBER CONTENT, HOWEVER CAUSED, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), PROPRIETARY RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
        </p>
        <p>
          THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU BECOME
          DISSATISFIED IN ANY WAY WITH THE APP OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR USE OF THE APP
          AND SITE.
        </p>
        <p>
          YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE APP OR SITE. BECAUSE SOME STATES DO NOT
          ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES, THESE
          PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION OF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR
          UNENFORCEABLE FOR ANY REASON, THEN OUR AGGREGATE LIABILITY SHALL NOT EXCEED ONE HUNDRED AND ONE DOLLARS
          ($101).
        </p>
        <p>
          THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN AND REFLECTS A FAIR
          ALLOCATION OF RISK. THE APP AND SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU AGREE THAT THE
          LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE REMEDIES SPECIFIED HEREIN WILL SURVIVE
          EVEN IF FOUND TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE. THE FOREGOING DOES NOT APPLY TO LIABILITY ARISING
          FROM ANY FRAUD OR FRAUDULENT MISREPRESENTATIONS, OR ANY OTHER LIABILITY THAT CANNOT BE LIMITED BY APPLICABLE
          LAW.
        </p>

        <h5>9. INDEMNITY</h5>
        <p>
          All the actions you make and information you post on DAtP remain your responsibility. Therefore, you agree
          to indemnify, defend, release, and hold us, and our partners, licensors, affiliates, contractors, officers,
          directors, employees, representatives and agents, harmless, from and against any third party claims, damages
          (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs and expenses
          (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in
          connection with:
        </p>
        <ol>
          <li>any negligent acts, omissions or willful misconduct by you;</li>
          <li>your access to and use of the App;</li>
          <li>the uploading or submission of Content to the App by you;</li>
          <li>any breach of these Terms by you; and/or</li>
          <li>your violation of any law or of any rights of any third party.</li>
        </ol>
        <p>
          We retain the exclusive right to settle, compromise and pay any and all claims or causes of action which are
          brought against us without your prior consent. If we ask, you will co-operate fully and reasonably as
          required by us in the defense of any relevant claim.
        </p>
        <p>
          The foregoing provision does not require you to indemnify Dating Across the Pond LLC for any unconscionable
          commercial practice or any fraud, deception, false promise, misrepresentation or concealment, suppression or
          omission of any material fact in connection with the App.
        </p>

        <h5>10. PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</h5>
        <p>
          If you believe any content on DAtP infringes the copyright in a work that you own, please submit a
          notification alleging such infringement ("DMCA Takedown Notice") to Dating Across the Pond LLC’s Copyright
          Agent. The Takedown Notice must include the following:
        </p>
        <ol>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed;
          </li>
          <li>
            Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
            at a single online site are covered by a single notification, a representative list of such works;
          </li>
          <li>
            Identification of the material claimed to be infringing or to be the subject of infringing activity and
            that is to be removed or access disabled and information reasonably sufficient to permit the service
            provider to locate the material;
          </li>
          <li>
            Information reasonably sufficient to permit the service provider to contact you, such as an address,
            telephone number, and, if available, an electronic mail;
          </li>
          <li>
            A statement that you have a good faith belief that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or the law; and
          </li>
          <li>
            A statement that, under penalty of perjury, the information in the notification is accurate and you are
            authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.
          </li>
        </ol>
        <p>
          Takedown Notices should be sent to Dating Across the Pond LLC Copyright Agent at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>. If you wish to reach
          out to Dating Across the Pond LLC’s Copyright Agent via other methods, you can write to: DAtP, Attn: Dating
          Across the Pond LLC’s Copyright Agent, 1133 South Allen Street, State College, Pennsylvania, 16801; or
          phone: +1 – 212.401.4833.
        </p>

        <h5>11. THIRD PARTY APP STORE</h5>
        <p>
          The following additional terms and conditions apply to you if you download the App from a Third Party Store.
          To the extent that the other terms and conditions of these Terms are less restrictive than, or otherwise
          conflict with, the terms and conditions of this Section, the more restrictive or conflicting terms and
          conditions in this Section will apply, but solely with respect to the App and the Third Party Store. You
          acknowledge and agree that:
        </p>
        <ol>
          <li>
            These Terms are concluded solely between you and Dating Across the Pond LLC and not with the providers of
            the Third Party Store, and Dating Across the Pond LLC (and not the Third Party Store providers) is solely
            responsible for the App and the content thereof. To the extent that these Terms provide for usage rules
            for the App which are less restrictive or in conflict with the applicable terms of service of the Third
            Party Store from which you obtain the App, the more restrictive or conflicting term of the Third Party
            Store will take precedence and will apply.
          </li>
          <li>
            The Third Party Store provider has no obligation whatsoever to provide any maintenance and support
            services with respect to the App. Dating Across the Pond LLC is solely responsible for any product
            warranties, whether express or implied by law, to the extent not effectively disclaimed. The Third Party
            Store provider will have no warranty obligation whatsoever with respect to the App, and any other claims,
            losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty
            will be the sole responsibility of Dating Across the Pond LLC.
          </li>
          <li>
            Dating Across the Pond LLC, not the Third Party Store provider, is responsible for addressing any claims
            you or any third party may have relating to the App or your possession and/or use of the App, including,
            but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any
            applicable legal or regulatory requirement; (iii) claims arising under consumer protection or similar
            legislation; and/or (iv) intellectual property infringement claims.
          </li>
          <li>
            The Third Party Store provider and its subsidiaries are third party beneficiaries of these Terms, and,
            upon your acceptance of these Terms, the Third Party Store provider from whom you obtained the App will
            have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a
            third party beneficiary thereof.
          </li>
        </ol>
        <p>
          In the event of a conflict between a Third Party Store’s or mobile carrier’s applicable terms and conditions
          and these Terms, the terms and conditions of the Third Party Store or mobile carrier shall govern and
          control. We are not responsible and have no liability whatsoever for third-party goods or services you
          obtain through a Third Party Store or mobile carrier. We encourage you to make whatever investigation you
          feel necessary or appropriate before proceeding with any online transaction with any of these third parties.
        </p>

        <h5>12. DISPUTE RESOLUTION.</h5>
        <p>
          Please read the following arbitration agreement in this Section ("Arbitration Agreement") carefully. Unless
          you opt out in the manner described in Section 13(9) below, this Arbitration Agreement requires you and
          Dating Across the Pond LLC to resolve disputes by binding arbitration instead of in court and limits the
          manner in which we may seek relief from each other.
        </p>
        <ol>
          <li>
            When Does This Arbitration Agreement Apply? This Arbitration Agreement applies to any disputes or claims
            of any kind whatsoever (whether based in contract, tort, statute, regulation, ordinance, fraud,
            misrepresentation or any other legal or equitable theory) between you and Dating Across the Pond LLC
            arising out of or relating to the Terms, prior versions of the Terms, your use of our App, or any other
            aspect of your relationship with DAtP, including claims or disputes arising (but not actually filed in
            arbitration) before the effective date of these Terms. It requires that, and by entering into these Terms
            you and Dating Across the Pond LLC agree, that such disputes or claims will be resolved by binding
            arbitration, rather than in court, except that (i) you or DAtP may assert individual claims in small
            claims court if your claims qualify; and (ii) you or DAtP may seek equitable relief in court for
            infringement or misuse of intellectual property rights.
          </li>
          <li>
            Notice of Dispute and Informal Resolution. Before beginning the arbitration process, you and Dating Across
            the Pond LLC agree to first notify one another of the dispute in writing at least 60 days in advance of
            initiating an arbitration. Notice to Dating Across the Pond LLC must be sent by letter to our registered
            agent: 1133 South Allen Street, State College, Pennsylvania, 16801, and must provide your name, current
            email address, mailing address, and telephone number, as well as the name, email address, and telephone
            number associated with your DAtP account (if different from your current information); and describe the
            nature of the claim and the specific relief being sought. You and DAtP agree to meet and confer, via
            teleconference or videoconference, in a good faith effort to informally resolve any claim or dispute
            covered by this Arbitration Agreement. If either party is represented by counsel, that counsel may
            participate in the informal dispute resolution conference. During this process, you may be provided with
            an offer of judgment. A party defending against a claim may serve on an opposing party an offer to allow
            judgment on specified terms, with the costs then accrued. If the judgment that the offeree finally obtains
            is not more favorable than the unaccepted offer, the offeree must pay the costs incurred after the offer
            was made. All offers, promises, conduct, and statements made in the course of the informal dispute
            resolution process by any party, its agents, employees, and attorneys are confidential and not admissible
            for any purpose in any subsequent proceeding, provided that evidence that is otherwise admissible or
            discoverable shall not be rendered inadmissible or non-discoverable as a result of its use in the informal
            dispute resolution process. The informal dispute resolution conference shall occur within 60 days of
            receipt of the written notice described above, unless an extension is mutually agreed upon. If, after
            participating in that conference, the parties are unable to resolve the dispute, the claimant may commence
            an arbitration in accordance with this Agreement. Completion of this informal dispute resolution is a
            condition precedent to filing any demand for arbitration. Failure to do so is a breach of this Agreement,
            and no demand for arbitration shall be valid unless such written notice is provided and good faith
            discussions have been conducted.
          </li>
          Any statute of limitations will be tolled while the parties engage in the informal dispute resolution
          process described in this section. How Do You Start The Arbitration Process? If you and Dating Across the
          Pond LLC are unable to resolve the dispute within 60 days, either party may proceed to file a claim for
          arbitration. To begin the arbitration process, you or Dating Across the Pond LLC must submit notice by
          certified mail of the claim with an individualised arbitration demand. To be valid, the demand must contain
          the name of the claiming party (you or Dating Across the Pond LLC), your or Dating Across the Pond LLC ‘s
          mailing address, the email address or phone number associated with your DAtP account (if applicable), and a
          detailed description of the dispute and the relief sought. Notice to Dating Across the Pond LLC must be
          submitted to our registered agent: 1133 South Allen Street, State College, Pennsylvania, 16801.
          <li>
            Survival of Agreement. The terms of this Arbitration Agreement will continue, even after your relationship
            with DAtP has ended.
          </li>
        </ol>

        <h5>13. REMEDIES & TERMINATION</h5>
        <p>
          These Terms commence on the date you accept them (as described in the preamble) and continue until
          terminated in accordance with the terms herein.
        </p>
        <p>
          You can delete your Account at any time by logging into the App, going to the "Settings" tab (the gear
          icon), and following the instructions to terminate your account. Please note that if you delete your
          Account, your subscription will continue until the end of the subscription period for which applicable fees
          have been paid, and you will not be entitled to a refund except as stated in Section 5. In addition to
          deleting your account, you will also need to cancel/manage any recurring subscriptions purchased via a Third
          Party Store (e.g., iTunes, Google Play) to avoid additional billing.
        </p>
        <p>
          In the event that Dating Across the Pond LLC determines, in its sole discretion, that you have breached any
          portion of these Terms, have misused the App, or have otherwise demonstrated conduct which the Dating Across
          the Pond LLC regards as inappropriate or unlawful (whether on or off the App), Dating Across the Pond LLC
          reserves the right to: (a) warn you via email (to any email addresses you have provided Dating Across the
          Pond LLC) that you have violated the Terms; (b) delete your User Content; (c) discontinue your Account; (d)
          discontinue your subscription(s) without refund; (e) notify and/or send your User Content to and/or fully
          cooperate with the proper law enforcement authorities for further action; and/or (f) pursue to any other
          action which Dating Across the Pond LLC deems to be appropriate. You agree that all terminations for cause
          shall be made in Dating Across the Pond LLC’s sole discretion and that Dating Across the Pond LLC shall not
          be liable to you or any third party for any termination of your Account.
        </p>
        <p>
          Termination of these Terms or your Account includes the removal of access to your Account, and all related
          information and content associated with or inside your Account.
        </p>
        <p>
          If your account is terminated by you or by Dating Across the Pond LLC for any reason, all provisions of
          these Terms which by their nature should survive, shall survive termination of these Terms, including,
          without limitation, the Arbitration Agreement, ownership provisions, warranty disclaimers and limitation of
          liability. Your information will be maintained and deleted in accordance with our Privacy Policy.
        </p>

        <h5>14. MISCELLANEOUS</h5>
        <p>There are a few more things we need to mention before you can use Dating Across the Pond.</p>
        <p>
          These Terms, which we may amend from time to time, constitute the entire agreement between you and Dating
          Across the Pond LLC. The Terms supersede all previous agreements, representations and arrangements between
          us (written or oral), excluding the Privacy Policy. Nothing in this clause shall limit or exclude any
          liability for fraudulent misrepresentation.
        </p>
        <p>
          Dating Across the Pond LLC has taken reasonable steps to ensure the currency, availability, correctness and
          completeness of the information contained on DAtP and provides that information on an "as is", "as
          available" basis. Dating Across the Pond LLC does not give or make any warranty or representation of any
          kind about the information contained on DAtP, whether express or implied. Use of DAtP and the materials
          available on it is at your sole risk. Dating Across the Pond LLC is not responsible for any loss arising
          from the transmission, use of data, or inaccurate Member Content.
        </p>
        <p>
          You are responsible for taking all necessary precautions to ensure that any material you may obtain from
          DAtP is free of viruses or other harmful components. You accept that DAtP will not be provided uninterrupted
          or error free, that defects may not be corrected or that Dating Across the Pond LLC, or the server that
          makes it available, are free of viruses or bugs, spyware, Trojan horse or any similar malicious software.
          Dating Across the Pond LLC is not responsible for any damage to your computer hardware, computer software,
          or other equipment or technology including, but without limitation damage from any security breach or from
          any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or
          transmission, computer line or network failure or any other technical or other malfunction.
        </p>
        <p>
          The communications between you and Dating Across the Pond LLC may take place via electronic means, whether
          you use the App or send Dating Across the Pond LLC emails, or whether Dating Across the Pond LLC posts
          notices in the App or communicates with you via email. For contractual purposes, you (a) consent to receive
          communications from Dating Across the Pond LLC in electronic form; and (b) agree that all terms and
          conditions, agreements, notices, disclosures, and other communications that Dating Across the Pond LLC
          provides to you electronically satisfy if it were to be in writing. The foregoing does not affect your
          statutory rights, including but not limited to the Electronic Signatures in Global and National Commerce Act
          at 15 U.S.C. Sec. 7001 et. seq.
        </p>
        <p>Our Terms are set in stone? Well not necessarily.</p>
        <p>
          As DAtP grows, we might have to make changes to these Terms so we reserve the right to modify, amend or
          change the Terms at any time (a "Change"). If we do this then the Changes will be posted on this page and we
          will indicate the Effective Date of the updates at the bottom of the Terms. In certain circumstances, we may
          send an email to you notifying you of a Change. It’s also possible that we might ask you to agree to our
          Changes, but we’ll let you know. You should regularly check this page for notice of any Changes – we want
          our users to be as informed as possible.
        </p>
        <p>
          Your continued use of DAtP following any Change constitutes your acceptance of the Change and you will be
          legally bound by the new updated Terms. If you do not accept any Changes to the Terms, you should stop using
          DAtP immediately (we hope you don’t!).
        </p>
        <p>Additional items:</p>
        <p>
          If, for any reason, any of the Terms are declared illegal, invalid or otherwise unenforceable by a court of
          a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable, it shall be
          severed and deleted from the Terms and the remainder of the Terms shall survive, remain in full force and
          effect and continue to be binding and enforceable.
        </p>
        <p>
          No failure or delay in exercising any right, power or privilege under the Terms shall operate as a waiver of
          such right or acceptance of any variation of the Terms and nor shall any single or partial exercise by
          either party of any right, power or privilege preclude any further exercise of the right or the exercise of
          any other right, power or privilege.
        </p>
        <p>You represent and warrant that:</p>
        <ol>
          <li>
            you are not located in a country that is subject to a U.S. Government embargo, or that has been designated
            by the U.S. Government as a "terrorist supporting" country; and
          </li>
          <li>you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
        </ol>
        <p>
          By using the App, you agree and acknowledge that DAtP is a global app operating through servers located in a
          number of countries around the world, including the United States. If you live in a country with data
          protection laws, the storage of your personal data may not provide you with the same protections as you
          enjoy in your country of residence. By submitting your personal information, or by choosing to upgrade the
          services you use, or by making use of the applications available on DAtP, you agree to the transfer of your
          personal information to, and storage and processing of your personal information in, any such countries and
          destinations.
        </p>
        <p>
          The App may contain links to third-party websites or resources. In such cases, you acknowledge and agree
          that we are not responsible or liable for:
        </p>
        <ol>
          <li>the availability or accuracy of such websites or resources; or</li>
          <li>the content, products, or services on or available from such websites or resources.</li>
        </ol>
        <p>
          Links to such websites or resources do not imply any endorsement. You acknowledge sole responsibility for
          and assume all risk arising from your use of any such websites or resources. Framing, in-line linking or
          other methods of association with the App are expressly prohibited without first obtaining our prior written
          approval.
        </p>
        <p>
          These Terms, and any rights and licences granted hereunder, may not be transferred or assigned by you, but
          may be assigned by us without restriction.
        </p>
        <p>
          In the event there is a discrepancy between this English language version and any translated copies of the
          Terms, the English version shall prevail.
        </p>
        <p>
          If you have any questions, complaints or claims with respect to the App, please contact us at{' '}
          <a href="mailto:support@datingacrossthepond.com">support@datingacrossthepond.com</a>.
        </p>

        <h5>15. GOVERNING FORUM & LAW</h5>
        <p>
          Subject to Section 12(2), your access to the App, Our Content, and any Member Content, any claims arising
          from or related to your relationship with Dating Across the Pond LLC, and these Terms are governed and
          interpreted by the laws of the State of Pennsylvania. All claims arising out of or relating to these Terms
          and/or your relationship with Dating Across the Pond LLC, that for whatever reason are not submitted to
          arbitration, and all claims or cases challenging the enforceability or applicability of the arbitration
          provisions herein, will be litigated exclusively in the federal or state courts of Centre County,
          Pennsylvania. You agree that such courts shall have personal jurisdiction and venue and waive any objection
          based on inconvenient forum. You agree that you will not file or participate in a class action against us.
        </p>

        <h5>16. Dating Across the Pond LLC</h5>
        <p>
          The Terms constitute a binding legal agreement between you as user ("you") and the Dating Across the Pond
          ("we" or "us"). Dating Across the Pond LLC includes, but is not limited to, Dating Across the Pond LLC (a
          Pennsylvania limited liability company), and Love Becca LLC (a New York corporation).
        </p>

        <dl>
          <dt>Effective date</dt>
          <dd>The Terms were last updated on: November 1, 2023.</dd>
        </dl>
        <br />
      </div>
    </div>
  )
}

export default TermsAndConditions
