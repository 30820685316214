import React, { useEffect, useRef, useState } from 'react'
import { Menu } from 'primereact/menu'
import { MenuItem } from 'primereact/menuitem'
import { Toast } from 'primereact/toast'
import { FaChevronRight, FaExternalLinkAlt, FaRegTrashAlt, FaSignOutAlt } from 'react-icons/fa'
import { User } from '../../../../../../common/types/user'
import { cancelStripeSubscription, deletePhoto, getCurrentUser, getUserById, updateProfile, wipeUserAccount } from '_services/users.service'
import { authStore } from '../../../../_core/stores/authStore'
import Loader from '../../../../components/loader'
import { formatHeightMeasurement } from 'util/helpers.converters'
import { InputSwitch } from 'primereact/inputswitch'
import { FormType, IncomingFormPayload, createFormPayload } from 'types/payloads.users'

import { EditableField } from '../../_forms/_components/EditableField'
import { DynamicButtonList } from '../../_forms/_components/DynamicButtonList'
import { EnumBodyTypesData, EnumNationalitiesData, EnumPoliticalBeliefsData, EnumReligionsData } from 'types/enums.options'
import { getBodyTypesEnum, getNationalitiesEnum, getPoliticalBeliefsEnum, getReligionsEnum } from '_services/enums.service'
import { EducationOptions, SchoolTypes } from 'enums/EducationLevels'
import HeightInput from '../../_forms/_components/heightField'
import EditCardTemplate from '../../_forms/add_edit/_components/editCardLayout'
import { FormOptions, OnboardingForms } from '../../_forms/add_edit/enumEditForms'
import PhotoUpload from '../../_components/profile/components/PhotoUpload'
import VideoInterviewsManage from '../../_components/profile/components/video-interviews/manage'
import InterestInput from '../../_forms/_components/InterestInput'
import QuestionsInput from '../../_forms/_components/QuestionsInput'
import { useNavigate } from 'react-router-dom'
import LifestyleChoicesEdit from '../../_forms/add_edit/_components/lifeStyleChoicesEdit'
import { ProfileVerify } from '../../_forms/_components/ProfileVerify'
import { EditableArea } from '../../_forms/_components/EditableArea'
import DeleteAccount from '../../_forms/add_edit/_components/deleteAccount'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Purchase } from 'types/purchases'
import { formatDateTime, formatDateToLocalStandard, formatPrice } from 'util/formatters'
import useSignalR from '../../../../_core/hooks/useSignalR'
import { HubReceive,HubInvoke } from 'enums/HubMethods'



type SidebarMenuItem = Omit<MenuItem,'template'> & {
  action?:string
  value: String | []
  classLabel: String
  classValue: String
  icon: React.ReactNode
  editTemplate?: (_: SidebarMenuItem, _user:User) => React.ReactNode
  onClick: () => void
  href: string
  template:(_:SidebarMenuItem) => React.ReactNode
}

type EditProfileProps = {
  action?:string
  onUpdate: () => void
}

const EditProfile: React.FC<EditProfileProps> = (props) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState<boolean>(false)
  const [editing, setEditing] = useState<SidebarMenuItem>()
  const toast = useRef<Toast>(null)
  const [bodyTypeOptions, setBodyTypeOptions] = useState<EnumBodyTypesData[]>([])
  const [nationalityOptions, setNationalityOptions] = useState<EnumNationalitiesData[]>([])
  const [politicalBeliefOptions, setPoliticalBeliefOptions] = useState<EnumPoliticalBeliefsData[]>([])
  const [religionOptions, setReligionOptions] = useState<EnumReligionsData[]>([])
  const [showPurchaseModal, setShowPurchasModal] = useState<boolean>(false)

  const invoke = useSignalR([
    [HubReceive.CurrentUser,(x:User) => {console.log("ws user",x);
      // setUser(x)
      fetchData()
    }],
  ])


  const fetchData = async (loading = true) => {
    setLoading(loading)
    if (authStore.userID) {
      try {
        let userResponse = await getCurrentUser()
        setUser(userResponse.data)
      } catch (error) {
        alert(error)
      }
      setLoading(false)
    }
  }

  const fetchOptions = async () => {
    setBodyTypeOptions((await getBodyTypesEnum()).data)
    setNationalityOptions((await getNationalitiesEnum()).data)
    setReligionOptions((await getReligionsEnum()).data)
    setPoliticalBeliefOptions((await getPoliticalBeliefsEnum()).data)
  }

  useEffect(() => {
    fetchData()
    fetchOptions()
  }, [authStore.userID])

  const handleUserUpdate = async (payload: IncomingFormPayload) => {
    await updateProfile(payload,
      (res: any) => { (res) },
      (e: any) => { (e) },
    )
    props.onUpdate()
  }

  const itemRenderer = (item:SidebarMenuItem) => (
    <a href={item.href} className="d-flex profile-menu-items profile-menu-link-override button-description"
      onClick={() => item.editTemplate ? setEditing(item) : (item.onClick? item.onClick() : null)}>
      <div className="col text-start">
        <span className={`text-nowrap ${item.classLabel}`}>{item.label}</span>
      </div>
      <div className="col text-end">
        <span className={`text-nowrap ${item.classValue}`}>{item.value}</span>
      </div>
      <div className="">
        <span>{item.icon || <FaChevronRight />}</span>
      </div>
    </a>
  )

  const items : { label:string,items:SidebarMenuItem[] }[] = [
    {
      label: 'Membership Status',
      items: [
        {
          label: 'Membership',
          classValue: user?.activePurchases && user?.activePurchases.length > 0 ? 'color-success' : 'color-primary',
          value: user?.activePurchases && user?.activePurchases.length > 0 ? user?.activePurchases[0].Product.name : 'Sign up now',
          template: itemRenderer,
          icon: <></>,
          ...(user?.activePurchases && user?.activePurchases.length == 0 ? {
              onClick: () => {
                navigate('/upgrade')
              }}
          : {
            onClick: () => {
              setShowPurchasModal(true)
            }})
        },
        {
          label: 'Photo Verification',
          classValue: user?.userVerifyPhoto?.approved_by ? 'color-success' : 'color-primary',
          value: user?.userVerifyPhoto?.approved_by ? 'Photo Verified' : user?.userVerifyPhoto ? 'Pending Approval' : 'Get Verified',
          template: itemRenderer,
          ...(!user?.userVerifyPhoto && !user?.userVerifyPhoto?.approved_by ? {editTemplate: (item: SidebarMenuItem) =>
            <ProfileVerify
              onClose={async () => {
                setEditing(undefined)
                await fetchData()
              }}
            />} : {}),
          icon: <></>,
        },
      ],
    },
    {
      label: 'Information',
      items: [
        {
          action:'photos',
          label: 'Photos',
          value: 'Add or Remove Pictures' ,
          template: itemRenderer,
          editTemplate: (_: SidebarMenuItem,user:User) =>
            <EditCardTemplate
              label={'Photos'}
              onClose={() => setEditing(undefined)}
              onSave={ () => setEditing(undefined)}
            >
              <PhotoUpload
                images={user.userPhotos}
                userId={authStore.userID}
                onUpload={async () => {await fetchData()}}
                onRemove={async (url:string) => await deletePhoto(url,fetchData,() => {})}
              />
            </EditCardTemplate>
        },
        {
          'action':'video-interviews',
          'label':'Video Interviews',
          'value':'Add or Remove Videos',
          'template':itemRenderer,
          'editTemplate':(_: SidebarMenuItem,user:User) =>
            <EditCardTemplate
              label={'Video Interviews'}
              onClose={() => setEditing(undefined)}
              onSave={() => setEditing(undefined)}
              handleSave={false}
            >
              <VideoInterviewsManage
                // onUpload={async () => {await fetchData()}}
                // onRemove={async (url:string) => await deletePhoto(url,fetchData,() => {})}
              />
            </EditCardTemplate>
        },
        {
          label: 'First Name',
          value: user?.userMetadata ? user?.firstName : 'Missing data',
          template: itemRenderer,
          icon: <></>,
        },
        {
          label: 'Last Name',
          value: user?.userMetadata ? user?.lastName : 'Missing data',
          template: itemRenderer,
          icon: <></>,
        },
        {
          label: 'Nationality',
          value: user?.userMetadata
            ? user?.userMetadata?.Nationalities?.map((n) => n.EnumNationality.nationality).join(', ')
            : 'Nationality missing',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Nationality'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: EnumNationalitiesData[]) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.Nationality,
                    { isoCode: x.map(y => y.isoCode) }
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
            >
            <DynamicButtonList
              multiple
              initialValue={user?.userMetadata?.Nationalities?.map(y => y.EnumNationality) || []}
              onSave={async () => {}}
              primaryOptions={nationalityOptions.filter(x => !x.advancedOption)}
              secondaryOptions={nationalityOptions.filter(x => x.advancedOption)}
              optionLabel="nationality"
              optionLabelKey="isoCode"
              maxSelection={3}
            />
            </EditCardTemplate>
        },
        {
          label: 'Interests',
          value: 'Select all of your interests!',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Interest'}
              handleSave={false}
              onClose={async () => {
                await fetchData()
                setEditing(undefined)
              }
              }
              onSave={() => {}}
            >
              <InterestInput
                initalValue={user?.userInterest.map(y => y.interestName) || []}
                userId={''}
                onClose={async () => {
                  await fetchData()
                  setEditing(undefined)
                }}
              />
            </EditCardTemplate>
        ,
        },
        {
          label: 'Occupation',
          value: user?.userMetadata ? user?.userMetadata?.occupationName.substring(0,25) + (user?.userMetadata?.occupationName.length > 25 ? '...' : '') : 'Occupation not provided',
          template: itemRenderer,
          editTemplate: (_: SidebarMenuItem, user: User) =>
            <EditCardTemplate
              label={'Occupation'}
              onClose={() => setEditing(undefined)}
              onSave={async (e: any) => {
                if(e){
                  await handleUserUpdate(
                    createFormPayload(
                      FormType.Occupation,
                      { OccupationName: e.target.value }
                    )
                  )
                  await fetchData()
                }
                setEditing(undefined)
              }}
            >
              <EditableField
                value={user.userMetadata?.occupationName}
                onChange={() => { } }
                label={''}
                errors={undefined}
              />
            </EditCardTemplate>
            ,
        },
        {
          label: 'Highest Education',
          value: user?.userMetadata ? user?.userMetadata?.highestEducationLevel : 'No education provided',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Occupation'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: string | undefined) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.EducationLevel,
                    x ? { HighestLevel: x } : null
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
            >
              <DynamicButtonList
                initialValue={user?.userMetadata?.highestEducationLevel || ''}
                onChange={() => {}}
                primaryOptions={EducationOptions.map(x=> x.display)}
              />
            </EditCardTemplate>
        },
        {
          label: 'About Me',
          value: 'Your "About Me" section!',
          template: itemRenderer,
          editTemplate: (_:SidebarMenuItem, user:User) => {
          const AboutMeFormComponent = OnboardingForms[FormOptions.AboutMe].formTemplate
          return  <EditCardTemplate
                    label={'About You'}
                    onClose={() => setEditing(undefined)}
                    onSave={async (e:any) => {
                      if(e){
                        await handleUserUpdate(
                          createFormPayload(
                            FormType.AboutMe,
                            { inputText: e.target.value }
                          )
                        )
                        await fetchData()
                      }
                      setEditing(undefined)
                    }}
                  >
                    <EditableArea value={user.userMetadata?.aboutMe || ''} onChange={() => { } } label={''} errors={undefined}/>
                  </EditCardTemplate>
          }
        },
        {
          label: 'Body Type',
          classValue: user?.userMetadata && user?.userMetadata.BodyType ? '' : 'color-primary',
          value: user?.userMetadata && user?.userMetadata?.BodyType ? user?.userMetadata?.BodyType.display : 'No body type selected',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Body Type'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: any ) => {
                 await handleUserUpdate(
                   createFormPayload(
                     FormType.BodyType,
                     x ? { bodyType: x.name } : null
                   )
                 )
                 await fetchData()
                 setEditing(undefined)
               }}
            >
              <DynamicButtonList
                vertical
                initialValue={user?.userMetadata?.BodyType}
                onChange={() => {}}
                primaryOptions={bodyTypeOptions}
                optionLabel="display"
                optionLabelKey="name"
              />
            </EditCardTemplate>
        },
        {
          label: 'Lifestyle Choices',
          value: 'Answer some questions!',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <LifestyleChoicesEdit
              label={''}
              onClose={() => {setEditing(undefined)}}
              onSave={async (smokes,drinks,weed,kids) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.Lifestyle,
                    {
                      SmokeTobacco: smokes,
                      DrinkAlcohol: drinks,
                      HasOrWantsKids: kids,
                      SmokeWeed: weed,
                    }
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
              smokes={user?.userMetadata?.smokesTobacco || ''}
              drinks={user?.userMetadata?.drinksAlcohol || ''}
              weed={user?.userMetadata?.smokesWeed || ''}
              kids={user?.userMetadata?.hasOrWantsKids || ''}
            />
        },
        {
          label: 'Religious Belief',
          classValue: user?.userMetadata && user?.userMetadata.Religion ? '' : 'color-primary',
          value: user?.userMetadata && user?.userMetadata.Religion ? user?.userMetadata.Religion.display : 'No religions chosen',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Religious Belief'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: EnumReligionsData | undefined) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.ReligiousBeliefs,
                    x ? { ReligiousBelief: x.name } : null
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
            >
            <DynamicButtonList
              initialValue={user?.userMetadata?.Religion}
              onChange={() =>{}}
              primaryOptions={religionOptions.filter(x => !x.advancedOption)}
              secondaryOptions={religionOptions.filter(x => x.advancedOption)}
              optionLabel="display"
              optionLabelKey="name"
            />
            </EditCardTemplate>
        },
        {
          label: 'Political Belief',
          classValue: user?.userMetadata && user?.userMetadata.PoliticalBelief ? '' : 'color-primary',
          value: user?.userMetadata && user?.userMetadata.PoliticalBelief ? user?.userMetadata.PoliticalBelief.display : 'No parties chosen',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Political Belief'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: EnumPoliticalBeliefsData | undefined) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.PoliticalBeliefs,
                    x ? { PoliticalBelief: x.name } : null
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
            >

            <DynamicButtonList
              multiple
              initialValue={user?.userMetadata?.PoliticalBelief}
              onChange={() => {}}
              primaryOptions={politicalBeliefOptions}
              optionLabel="display"
              optionLabelKey="name"
            />
          </EditCardTemplate>
        },
        {
          label: 'Height',
          classValue: user?.userMetadata && user?.userMetadata.heightMeasurement ? '' : 'color-primary',
          value: user?.userMetadata && user?.userMetadata?.heightMeasurement
            ? user?.userMetadata?.heightMeasurement
              ? formatHeightMeasurement(user?.userMetadata?.heightMeasurement).dualLocaleDisplay
              : undefined
            : 'No height entered',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'How Tall Are You?'}
              onClose={() => setEditing(undefined)}
              onSave={async (x: number) => {
                await handleUserUpdate(
                  createFormPayload(
                    FormType.HeightMeasurement,
                    { measurement: x.toString() }
                  )
                )
                await fetchData()
                setEditing(undefined)
              }}
            >
              <HeightInput
                initialValue={user?.userMetadata?.heightMeasurement || 0}
                onChange={() => { }}
              />
            </EditCardTemplate>

        },
        {
          label: 'Q & A',
          value: 'Q & A!',
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Q & A!'}
              handleSave={false}
              onClose={() => setEditing(undefined)}
              onSave={() => setEditing(undefined)}
            >
              <QuestionsInput
                userId={user?.id || ''}
                onClose={() => setEditing(undefined)}
              />
            </EditCardTemplate>
        },
      ],
    },
    {
      label: 'Control Visibility',
      items: [
        {
          label: 'Incognito State',
          classValue: user?.isIncognito ? '' : 'color-primary',
          icon: <InputSwitch
            checked={user?.isIncognito || false}
            onClick={async () => {
              await handleUserUpdate(
                createFormPayload(
                  FormType.IncognitoState,
                  { isIncognito: !user?.isIncognito }
                )
              )
              await fetchData(false)
              setEditing(undefined)
            }}

          />,
          template: itemRenderer,
        },
      ],
    },
    {
      label: 'Account Settings',
      items: [
        {
          label: 'Phone',
          value: user?.phone ? user?.phone : "Uh oh,it's missing",
          classValue: user?.phone ? '' : 'color-primary',
          template: itemRenderer,
          icon: <></>,
        },
        {
          label: 'Email',
          value: user?.email ? user?.email : "Uh oh,it's missing",
          classValue: user?.email ? '' : 'color-primary',
          template: itemRenderer,
          icon: <></>,
        },
        // {
        //   label: 'Push Notifications',
        //   value: 'Control your notifications!',
        //   template: itemRenderer,
        // },
        // {
        //   label: 'Location',
        //   value: user?.countryFriendlyName ? user?.countryFriendlyName : "Uh oh,it's missing",
        //   classValue: user?.countryFriendlyName ? '' : 'color-primary',
        //   template: itemRenderer,
        //   icon: <></>,
        // },
      ],
    },
    // {
    //   label: 'Visuals',
    //   items: [
    //     {
    //       label: 'Appearance Settings',
    //       value: 'Light/Dark Theme',
    //       template: itemRenderer,
    //     },
    //   ],
    // },
    {
      label: 'Safety',
      items: [
        // {
        //   label: 'Community Guidelines',
        //   icon: <FaExternalLinkAlt />,
        //   href: '/community-guidelines',
        //   template: itemRenderer,
        // },
        // {
        //   label: 'Safety & Policy',
        //   icon: <FaExternalLinkAlt />,
        //   template: itemRenderer,
        // },
        // {
        //   label: 'Safety Tips',
        //   icon: <FaExternalLinkAlt />,
        //   template: itemRenderer,
        //   href: '/blog',
        // },
        {
          label: 'Help & Support',
          icon: <FaExternalLinkAlt />,
          href: '/community-guidelines',
          template: itemRenderer,
        },
      ],
    },
    {
      label: 'Legal',
      items: [
        {
          label: 'Privacy Policy',
          icon: <FaExternalLinkAlt />,
          template: itemRenderer,
          href: '/privacy-policy',
        },
        {
          label: 'Terms of Service',
          icon: <FaExternalLinkAlt />,
          href: '/terms-and-conditions',
          template: itemRenderer,
        },
      ],
    },
    {
      label: 'Account Management',
      items: [
        {
          label: 'Delete Account',
          icon: <FaRegTrashAlt size={20} />,
          template: itemRenderer,
          editTemplate: (item: SidebarMenuItem) =>
            <EditCardTemplate
              label={'Delete my account'}
              handleSave={false}
              onClose={() => setEditing(undefined)}
              onSave={() => setEditing(undefined)}
            >
             <DeleteAccount />
            </EditCardTemplate>
        },
        {
          label: 'Log Out',
          icon: <FaSignOutAlt size={20} />,
          template: itemRenderer,
          onClick: () => {
            authStore.logout(()=>{
              navigate('/')
            })
          }
        },
      ],
    },
  ]

  useEffect(() => {
    setEditing(
      props.action
      ? items
        .reduce((agg,x) => agg.concat(x.items),[] as SidebarMenuItem[])
        .find((x) => x.action === props.action)
      : undefined
    )
  },[props.action])

  return (
    <div className="d-flex flex-column h-100">
    <Toast ref={toast} />

     {user?.purchases && user?.purchases.length > 0 && <Dialog header="Subscription Management" visible={showPurchaseModal} style={{ width: '50vw' }} onHide={() => {if (!showPurchaseModal) return; setShowPurchasModal(false); }}>
      {
        user?.activePurchases.length > 0 && <div className="d-flex justify-content-between m-2">
          <h5>Current Subscription: {user?.activePurchases[0].Product.name}</h5>
          <Button className="btn btn-danger" onClick={async () => {
              await cancelStripeSubscription(user?.activePurchases[0].stripeSubscriptionId,async () =>{
              toast.current?.show({ severity: 'success', summary: 'Subscription Cancelled', detail: 'Sorry to see you go!' });
              await fetchData()
              setShowPurchasModal(false)
            })
          }}>Cancel Subscription</Button>
        </div>
      }
      <DataTable value={user?.purchases} tableStyle={{ minWidth: '50rem' }}>
        <Column field="Product.name" header="Prouct"></Column>
        <Column field="Price" header="Price" body={(x:Purchase,o) => <>{formatPrice(x.price,x.currency)}</>}></Column>
        <Column field="transactionDate" header="Date" body={(x:Purchase,o) => <>{formatDateToLocalStandard(new Date(x.transactionDate))}</>}></Column>
        {/* <Column field="transactionDate" header="Date" body={(x:Purchase,o) => <>{new Date(x.transactionDate)}</>}></Column> */}
    </DataTable>
    </Dialog>}
      <Loader loading={loading}>
        {
          user && (editing && editing.editTemplate
            ? <div className="h-100 vertical-scroll border border-1">{editing.editTemplate(editing,user)}</div>
            : <Menu model={items} className="w-100 p-menu-override vertical-scroll" />)
        }
      </Loader>
    </div>
  )
}

export default EditProfile
