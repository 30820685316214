import React, { useState, ReactElement, useEffect } from 'react'
import { Button } from 'primereact/button'
import { DynamicButtonList } from '../../_components/DynamicButtonList'
import { alcoholUseOptions, drugUseOptions, kidsOptions, tobaccoUseOptions } from 'enums/LifestyleChoiceEnums'


export type LifestyleChoicesEditProps   =
  {
    label    : string
    onClose  : () => void
    onSave   : (smokes:string,drinks:string,weed:string,kids:string) => void
    smokes   : string
    drinks   : string
    weed     : string
    kids     : string
  }

export const LifestyleChoicesEdit = (props: LifestyleChoicesEditProps): JSX.Element => {
  const [smokes, setSmokes] = useState(props.smokes)
  const [drinks, setDrinks] = useState(props.drinks)
  const [weed, setWeed] = useState(props.weed)
  const [kids, setKids] = useState(props.kids)

  return (
    <div className="p-2">
    <div className="d-flex w-100 align-items-center justify-content-between mb-4">
      <h5>Lifestyle Choices</h5>
      <Button label="Done" className="btn btn-link m-0" onClick={props.onClose} />
    </div>
      <h6>Has or Wants Kids</h6>
      <DynamicButtonList
        initialValue={kidsOptions.find(y => y.id == kids)}
        onChange={(x:any) => {setKids(x.id)}}
        primaryOptions={kidsOptions}
        optionLabel="display"
        optionLabelKey="id"
      />
      <h6>Smokes Tobacco</h6>
      <DynamicButtonList
        initialValue={tobaccoUseOptions.find(y => y.id == smokes)}
        onChange={(x:any) => {setSmokes(x.id)}}
        primaryOptions={tobaccoUseOptions}
        optionLabel="display"
        optionLabelKey="id"
      />
      <h6>Drinks Alcohol</h6>
      <DynamicButtonList
        initialValue={alcoholUseOptions.find(y => y.id == drinks)}
        onChange={(x:any) => {setDrinks(x.id)}}
        primaryOptions={alcoholUseOptions}
        optionLabel="display"
        optionLabelKey="id"
      />
      <h6>Smokes Weed</h6>
      <DynamicButtonList
        initialValue={drugUseOptions.find(y => y.id == weed)}
        onChange={(x:any) => {setWeed(x.id)}}
        primaryOptions={drugUseOptions}
        optionLabel="display"
        optionLabelKey="id"
      />
    <Button
      type="button"
      onClick={() => {props.onSave(smokes,drinks,weed,kids)}}
      className="btn btn-primary w-100 mt-3"
      label="Save"
    />
  </div>

  )
}

export default LifestyleChoicesEdit
