import React, { useState, ReactElement, useEffect } from 'react'
import { Skeleton as Skeleton_ } from 'primereact/skeleton'
import useResize from '../_core/hooks/useResize'

interface NoDataProps {
  children?: React.ReactNode
  backgroundImage?: string
}

export const NoData: React.FC<NoDataProps> = ({  children,backgroundImage='assets/images/Pond_2_Frogs.png' }) => {

  const breakpoint = useResize()


  return (
    <div
      className="w-100 h-100 justify-content-center"
      style={{
        background: `url(${backgroundImage}) no-repeat bottom center`,
        backgroundSize: breakpoint.isDesktop ? "115%": "250%",
      }}
    >
      {children}
    </div>
  )
}

export default NoData
