import React from 'react'
import { Card } from 'primereact/card'
import { Skeleton,Structure as SkeletonStructure } from '../skeleton'

export interface CardPassThroughOptions {
  root?: React.HTMLAttributes<HTMLDivElement>
  header?: React.HTMLAttributes<HTMLDivElement>
  body?: React.HTMLAttributes<HTMLDivElement>
  title?: React.HTMLAttributes<HTMLDivElement>
  subTitle?: React.HTMLAttributes<HTMLDivElement>
  content?: React.HTMLAttributes<HTMLDivElement>
  footer?: React.HTMLAttributes<HTMLDivElement>
}

type WrapperCardProps<T> = {
  title?: string
  pt?: CardPassThroughOptions
  className?: string
  style?: React.CSSProperties
} & (
  {
    dependency: Promise<T>
    headerContent?: React.ReactNode | ((_:T) => React.ReactNode)
    footerContent?: React.ReactNode | ((_:T) => React.ReactNode)
    children?: React.ReactNode | ((_:T) => React.ReactNode)
    structure?: SkeletonStructure | SkeletonStructure[]
  } | {
    headerContent?: React.ReactNode
    footerContent?: React.ReactNode
    children?: React.ReactNode
  }
)

const pt = (pt_:CardPassThroughOptions | undefined) => ({
  ...pt_,
  header: {
    ...pt_?.header,
    className: `bg-primary-content ${pt_?.header?.className || ''}`,
  },
  body: {
    ...pt_?.body,
    className: `d-flex flex-column flex-grow-1 p-0 ${pt_?.body?.className || ''}`,
  },
  content: {
    ...pt_?.content,
    className: `flex-grow-1 vertical-scroll p-0 ${pt_?.content?.className || ''}`,
    style: { flexBasis: 0 },
  },
  footer: {
    className:'pt-0',
  },
})

const cardSkeleton = (structure:SkeletonStructure | SkeletonStructure[] | undefined,header:any | undefined,footer:any | undefined) : SkeletonStructure[] =>
  ([] as SkeletonStructure[]).concat(
    header ? [{ 'height':'55px','className':'mb-1' }] : [],
    [{'className':'flex-grow-1','children':structure}],
    footer ? [{ 'height':'65px','className':'mt-1' }] : [],
  )

export const WrapperCard = <T,>(props: WrapperCardProps<T>) => {
  return (
    !('dependency' in props)
    ?
      <Card
        className={[
          'd-flex flex-column h-100',
          props.className,
        ].join(' ')}
        style={props.style}
        header={
          <>
            {props.title ? <h2 className="px-2">{props.title}</h2> : null}
            {props.headerContent}
          </>
        }
        footer={props.footerContent}
        pt={pt(props.pt)}
      >
        {props.children}
      </Card>
    :
      <Skeleton
        dependency={props.dependency}
        structure={cardSkeleton(props.structure,props.headerContent,props.footerContent)}
        className={[
          'd-flex flex-column h-100',
          props.className,
        ].join(' ')}
      >
        {
          (value) =>
            <Card
              className={[
                'd-flex flex-column h-100',
                props.className,
              ].join(' ')}
              style={props.style}
              header={
                <>
                  {props.title ? <h2 className="px-2">{props.title}</h2> : null}
                  {typeof props.headerContent === 'function' ? props.headerContent(value) : props.headerContent}
                </>
              }
              footer={typeof props.footerContent === 'function' ? props.footerContent(value) : props.footerContent}
              pt={pt(props.pt)}
            >
              {typeof props.children === 'function' ? props.children(value) : props.children}
            </Card>
        }
      </Skeleton>
  )
}

export default WrapperCard
