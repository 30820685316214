import React, { useState, ReactElement } from 'react'
import { Button } from 'primereact/button'


export type EditCardTemplateProps<T, U = T | T[]> =
  {
    label: string
    onClose: () => void
    children: ReactElement
    handleSave?: boolean
  } & (
    U extends T[]
    ? {
      onSave            : (_:T[]) => void | Promise<void>
    }
    : {
      onSave?            : (_:T | undefined) => void | Promise<void>
    }
  )

// type EditCardTemplateProps<T> = {
//   label: string
//   onClose: () => void
//   onSave: (value: T | undefined) => void
//   children: ReactElement
// }

export const EditCardTemplate = <X,>(props: EditCardTemplateProps<X>): JSX.Element => {
  const [value, setValue] = useState<X>()
  const [loading, setLoading] = useState<boolean>(false)

  const handleChange = (newValue:X) => {
    console.log("test",newValue)
    setValue(newValue)
  }
  const onsave = async () => {
    setLoading(true)
    props.onSave && await props.onSave(value)
    setLoading(false)
  }

  return (
    <div className="p-2">
      <div className="d-flex w-100 align-items-center justify-content-between mb-4">
        <h5>{props.label}</h5>
        <Button label="Done" className="btn btn-link m-0" onClick={props.onClose} />
      </div>
      {React.cloneElement(props.children, { onChange: handleChange })}
      {props.handleSave == undefined || props.handleSave ? <Button
        type="button"
        onClick={() => {onsave()}}
        loading={loading}
        className="btn btn-primary w-100 mt-3"
        label="Save"
      /> : <></>}
    </div>
  )
}

export default EditCardTemplate
