import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SignInModal from '../modals/signIn'
import SidebarWrapper from '../../../pages/auth/_components/profile/components/SidebarWrapper'
import PublicMobileNavbar from './mobileSideBar'
import Logo from '../../../assets/images/horizontal-logo.png'

const Navbar: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [visibleCreate, setvisibleCreate] = useState<boolean>(false)
  const navigate = useNavigate()
  const [showPref, setShowPref] = useState<boolean>(false)

  return (
    <>
      <SignInModal title={visibleCreate ? 'Create Account' : 'Sign In'} isCreateModal={visibleCreate} isVisible={visible || visibleCreate} onClose={() => {setvisibleCreate(false);setVisible(false); } } />
      <div className="nav">
        <div className="d-flex justify-content-between align-items-center w-100">
          <Link className="nav-link px-3" to="/">
            <img
              src="/assets/images/horizontal-logo.png"
              srcSet="/assets/images/horizontal-logo@2x.png 2x"
              alt="Dating Across the Pond"
            />

          </Link>
          <SidebarWrapper onHide={() => setShowPref(false)} visible={showPref} position="right" header={<img src={Logo}/>}>
            <PublicMobileNavbar onSignIn={() => {setShowPref(false);setVisible(true)}} onCreate={() => {setShowPref(false); setvisibleCreate(true)}}/>
          </SidebarWrapper>
          <div className='pi pi-bars fs-1 fw-bold d-md-none' onClick={() => setShowPref(true)}>

          </div>
          {
            <ul className="navbar-nav flex-row align-items-center d-none d-md-flex">
              <li className="nav-item">
                <Link className="nav-link px-3" to="/faq">
                  FAQ
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link px-3" to="/stories">
                  Success Stories
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link px-3" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                  <a className="btn btn-primary" href="https://www.datebritishguys.com"> Date British Guys </a>
              </li>
            </ul>
          }
        </div>
      </div>
    </>
  )
}

export default Navbar
