import React, { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { InputTextarea } from "primereact/inputtextarea"
import { Dialog } from 'primereact/dialog'

interface webReportDialog {
  isDialogVisible: boolean
  openDialog: () => void
  closeDialog: () => void
  reportReason: string
  setReportReason: (reason: string) => void
  handleReport: () => void
}

export default function ReportUser(props: webReportDialog) {
  const toast = useRef<Toast>(null);
  const [open, setOpen] = useState<boolean>(false)


  function acceptToast() {
    toast.current?.show({ severity: 'success', summary: 'Submitted', detail: 'Your report has been submitted.', life: 3000 })
    props.handleReport()
  }

  function rejectToast() {
    toast.current?.show({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled the report.', life: 3000 })
    props.closeDialog()
  }

  function blockToast() {
    toast.current?.show({ severity: 'warn', summary: 'Block/Unmatch', detail: 'You have blocked and unmatched with this user.', life: 3000 })
    props.handleReport()
  }

  function suspiciousProfile() {
    toast.current?.show({ severity: 'success', summary: 'Suspicious User', detail: 'Thank You!, the profile has been flagged for review.', life: 3000 })
    props.handleReport()
  }

  const openOtherText = () => {
    setOpen(prev => !prev)
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="d-flex justify-content-center">
        <Button label="Block or Report" link size='large' iconPos='right' loadingIcon icon="pi pi-flag-fill" style={{ color: 'tomato' }} onClick={props.openDialog} />
      </div>
      <div className="flex justify-content-center">
        <Dialog
          header="Block or Report"
          visible={props.isDialogVisible}
          style={{ width: '100%', maxWidth: '850px', maxHeight: '100%' }}
          onHide={() => { if (!props.isDialogVisible) return; props.closeDialog() }}
        >
          <>
            <div className='flex'>
              <p className='text-center fw-medium'>Your feedback is anonymous.</p>
              <div className='d-flex flex-column row-gap-3 rounded '>
                <Button
                  label='Block and Unmatch' type='button' className='rounded-2 btn btn-secondary-light'
                  style={{ color: 'black' }} severity="info"
                  tooltipOptions={{ position: 'top' }} tooltip='Blocked users wont see you in their search results.'
                  onClick={(blockToast)}
                >
                </Button>
                <Button
                  label='Suspicious Profile' type='button' className='rounded-2 btn btn-secondary-light'
                  style={{ color: 'black' }} severity="info"
                  tooltipOptions={{ position: 'top' }} tooltip="Flag's the users profile for our team to investigate."
                  onClick={(suspiciousProfile)}
                >
                </Button>
                <Button
                  label='Other' type='button' className='rounded-2 btn btn-secondary-light'
                  style={{ color: 'black' }} severity="info"
                  onClick={openOtherText}>
                </Button>
              </div>
              {open ? (
                <>
                  <div>
                    <p className='text-center fw-medium mt-3'>Please provide any details to help us:</p>
                  </div>
                  <InputTextarea
                    autoFocus autoResize value={props.reportReason}
                    maxLength={255} placeholder='Details...'
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.setReportReason(e.target.value)}
                    rows={4} cols={40}
                  />
                  <div className='d-flex justify-content-between text-end'>
                    {props.reportReason.length > 3 ?
                      <span className='border-1 text-center'><b>Thank you for your feedback our team will look into the matter.</b></span >
                      : null
                    }
                    <p className="text-end p-1" style={{ fontSize: 12 }}>{props.reportReason.length}/255</p>
                  </div>
                </>
              ) : (
                null)
              }
            </div>
            <div className="d-flex justify-content-center align-items-center gap-1 my-2 ">
              <i className="pi pi-exclamation-circle" style={{ color: 'orange' }}></i>
              <span className='text-center'>Please confirm to submit the report.</span>
            </div>
            <span className='d-flex column-gap-4 justify-content-center'>
              <Button label='Cancel' onClick={(rejectToast)} type='button' className='rounded-2 btn btn-secondary-light'></Button>
              <Button label='Submit' onClick={(acceptToast)} severity='danger' type='button' className='rounded-2 btn btn-primary-light'></Button>
            </span>
          </>
        </Dialog>
      </div>
    </>
  )
}