interface ValidationMessages {
  required: string
  type: string
  invalid: string
  length: string
  match: string
  greater: string
  max: string
  valid: string
  numeric: string
  email: Record<string, any>
  phone: Record<string, any>
  fieldNames: Record<string, string>
  }

const validationMessages: ValidationMessages = {
  required: "%s is required",
  "type": "%s is required",
  invalid: "%s is required",
  length: "%s is required to be longer than %s characters",
  match: "Fields must match",
  greater: "%s is required to be greater than %s",
  max: "%s must be less than or equal to %slbs",
  valid: "Must be a valid %s",
  numeric: "%s must be a number",
  email: {},
  phone: {},
  fieldNames: {
    email: "Email",
    country: "Country",
    aboutMe: "About Me",
    weight: "Package Weight",
    password: "Password",
    measurement: "Measurement",
    confirmPassword: "Password Confirmation",
    phone: "Phone Number",
    "first-name": "First name",
    "firstName": "First name",
    "last-name": "Last name",
    "lastName": "Last name",
    "address-line-1": "Address",
    city: "City",
    sex: "Gender",
    preferredSex: "Looking for",
    drinkAlcohol: "Field",
    smokeTobacco: "Field",
    smokeWeed: "Field",
    hasOrWantsKids: "Field",
    occupationName: "Occupation",
    religiousBelief : "Religious belief ",
    politicalBelief : "Political belief",
    highestLevel : "Education level",
    bodyType : "Body type ",
    inputText :'About me',
    "zip-postal": "Zip Code",
    "state-province-region": "State/Province/Region",
  },
}
type FormatArg = string | number | boolean;

function sprintf(format: string, ...args: FormatArg[]): string {
  let argIndex = 0;
  return format.replace(/%s/g, () => String(args[argIndex++]));
}

export function getFieldAndValue(input: Record<string, string[]>): string[] {
  const result: string[] = []

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const errorMessages = input[key]
      for (const errorMessage of errorMessages) {
        const finalFieldName = validationMessages.fieldNames[key] || key
        result.push(`${finalFieldName} ${errorMessage}`)
      }
    }
  }

  return result
}

export function getValidationErrors(input: Record<string, string[]>) {

  return Object.entries(input).reduce<{[key: string]: string}>((accumulator, [key, valueArray]) => {
    const finalFieldName = validationMessages.fieldNames[key] as string || key
    accumulator[key] = valueArray.map(y => y in validationMessages
      ? sprintf(validationMessages[y as keyof ValidationMessages] as string,finalFieldName)
      : y
    ).join(' ')
    return accumulator
}, {})
}

export default validationMessages