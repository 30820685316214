export enum HubReceive {
  Messages = 'ReceiveMessages',
  Message = 'ReceiveMessage',

  Likes = 'ReceiveLikes',
  Matches = 'ReceiveMatches',
  Conversations = 'ReceiveConversations',

  CurrentUser = 'CurrentUser',
}

export enum HubInvoke {
  GetMessages = 'GetMessages',
  GetConversations = 'GetConversations',
  GetMatches = 'GetMatches',
  GetLikes = 'GetLikes',
  SetSeen = 'SetSeen',
  SetSeenConversation = 'SetSeenConversation',
  SendMessage = 'SendMessage',
  GetCurrentUser = 'GetCurrentUser',
}
