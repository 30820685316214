import React,{ useCallback,useEffect,useState } from 'react'
import Webcam from 'react-webcam'
import * as joda from '@js-joda/core'
import { _postV1 } from '_services/config'
import { confirmVerifyUpload,requestMediaUrl } from '_services/users.service'
import { uploadFileToPresignedUrl } from '_services/fetches/S3upload'
import { Await } from '../../../../../../_core/components/await'
import { Knob, KnobChangeEvent } from 'primereact/knob'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { classNames } from 'primereact/utils'
import { UserVideoInterview } from 'types/user'
import { Dropdown } from 'primereact/dropdown'

type Question = {
  id:string
  question:string
}
type PresignedUrl = {
  id      :string
  userId  :string
  url     :string
  location:string
  mimeType:string
}
enum Stage {
  ERROR             ='error',
  PERMISSIONS       ='permissions',
  PERMISSIONS_FAILED='permissions-failed',
  ROOT              ='root',
  QUESTION          ='question',
  RECORDING         ='recording',
  REVIEWING         ='reviewing',
  REVIEWING_ERROR   ='reviewing-error',
  UPLOADING         ='uploading',
}
type StageData
  = {
    stage:Stage.ERROR
    data?:string
  }
  | {
    stage:Stage.PERMISSIONS | Stage.PERMISSIONS_FAILED
    data:null
  }
  | {
    stage:Stage.ROOT
    data:{
      webcam:() => JSX.Element
    }
  }
  | {
    stage:Stage.QUESTION
    data:{
      webcam:() => JSX.Element
      question:Promise<Question>
      epoch:joda.Instant
    }
  }
  | {
    stage:Stage.RECORDING
    data:{
      webcam:() => JSX.Element
      question:Question
      epoch:joda.Instant
    }
  }
  | {
    stage:Stage.REVIEWING
    data:{
      question:Question
      blob:Blob
      objectUrl:string
    }
  }
  | {
    stage:Stage.REVIEWING_ERROR
    data:{
      message:string
    }
  }
  | {
    stage:Stage.UPLOADING
    data:{
      upload:Promise<void>
    }
  }
type Props = {
  replace?:UserVideoInterview
  onUpload:(_:string) => void
}

const constMimeType = 'video/webm'
const constQuestionTime : joda.Duration = joda.Duration.ofSeconds(5)
// const constQuestionTime : joda.Duration = joda.Duration.ZERO
const constMinLength : joda.Duration = joda.Duration.ofSeconds(15)
const constMaxLength : joda.Duration = joda.Duration.ofSeconds(75)
const constMaxRecentQuestions = 5
const constVideoWidth = 221
const constVideoAspectRatio = 296/221

const now = () => joda.Instant.now()
const formatDurationText = (x:joda.Duration) : string => {
  const seconds = x.get(joda.ChronoUnit.SECONDS)
  const pieces = [] as string[]
  if(seconds > 60)
  {
    const minutes = Math.floor(seconds / 60)
    pieces.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`)
  }
  pieces.push(`${seconds % 60} seconds`)
  return pieces.join(' and ')
}
const formatDurationNumeric = (x:joda.Duration) : string => {
  const seconds = x.get(joda.ChronoUnit.SECONDS)
  const pieces = [] as string[]
  if(seconds > 60)
  {
    const minutes = Math.floor(seconds / 60)
    pieces.push(`${minutes}`)
  }
  pieces.push(`:${seconds % 60}`)
  return pieces.join('')
}
const checkPermissions = async (setDevices:(_:MediaDeviceInfo[]) => void) : Promise<Stage> => {
  try
  {
    const _ = await navigator.mediaDevices.getUserMedia({
      'video':true,
      'audio':true,
    })
    const [permissionAudio,permissionVideo,devices] = await Promise.all([
      navigator.permissions.query({ 'name':'microphone' }),
      navigator.permissions.query({ 'name':'camera' }),
      navigator.mediaDevices.enumerateDevices(),
    ])
    if([permissionAudio,permissionVideo].every((x) => x.state == 'granted'))
    {
      setDevices(devices)
      return Stage.ROOT
    }
    else
    {
      setDevices([])
      return Stage.PERMISSIONS_FAILED
    }
  }
  catch(e)
  {
    console.error(e)
    return Stage.PERMISSIONS_FAILED
  }
}
const getQuestion = async (recentQuestions:Question[]) : Promise<Question> => (await _postV1('/video-interviews/get-question',{
  'recentQuestions':recentQuestions.map((x) => x.id),
})).data
const getUploadUrl = async () : Promise<PresignedUrl> => requestMediaUrl(constMimeType)

/*
const doDownload = (objectUrl:string,filename:string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  a.href = objectUrl
  a.download = `${filename}.webm`
  a.click()
}
  */

let mutableChunks = [] as Blob[]
let mutableRecentQuestions = [] as Question[]

type MyMediaDeviceInfo = {
  deviceId:string
  label:string
}

export const VideoInterview : React.FC<Props> = (props) => {
  const refCamera = React.useRef<Webcam | null>(null)
  const refRecorder = React.useRef<MediaRecorder | null>(null)

  const [stateDevices,setDevices] = useState<MediaDeviceInfo[]>([])
  const stateDevicesAudio = stateDevices.filter((x) => x.kind === 'audioinput').map((x) => ({ 'deviceId':x.deviceId,'label':x.label }))
  const stateDevicesVideo = stateDevices.filter((x) => x.kind === 'videoinput').map((x) => ({ 'deviceId':x.deviceId,'label':x.label }))
  const [stateDeviceAudio,setDeviceAudio] = useState<MyMediaDeviceInfo>()
  const [stateDeviceVideo,setDeviceVideo] = useState<MyMediaDeviceInfo>()
  useEffect(() => {
    if(!stateDeviceAudio || !stateDevicesAudio.map((x) => x.deviceId).includes(stateDeviceAudio.deviceId))
    {
      setDeviceAudio(stateDevicesAudio.find((x) => x.deviceId === 'default') || stateDevicesAudio[0])
    }
    if(!stateDeviceVideo || !stateDevicesVideo.map((x) => x.deviceId).includes(stateDeviceVideo.deviceId))
    {
      setDeviceVideo(stateDevicesVideo.find((x) => x.deviceId === 'default') || stateDevicesVideo[0])
    }
  },[stateDevices])
  const webcam = useCallback(() =>
    <div className='position-relative'>
      <Webcam
        ref={refCamera}
        width={constVideoWidth}
        height={constVideoWidth*constVideoAspectRatio}
        // mirrored
        audio
        muted
        audioConstraints={{
          'deviceId':stateDeviceAudio?.deviceId,
        }}
        videoConstraints={{
          'width':constVideoWidth,
          'height':constVideoWidth*constVideoAspectRatio,
          'facingMode':'user',
          'deviceId':stateDeviceVideo?.deviceId,
        }}
        className="rounded-2 z-0"
      />
    </div>
  ,[stateDeviceAudio,stateDeviceVideo])

  const [stateStage,_setStage] = useState<StageData>({ 'stage':Stage.PERMISSIONS,'data':null })
  const [stateCurrentTime,setCurrentTime] = useState<joda.Instant>(now())

  const addVideoInterview = async (urlId:string,questionId:string) : Promise<void> =>
    props.replace
    ? (await _postV1(`/video-interviews/replace`,{
      'existing_id':props.replace.id,
      'url_id':urlId,
      'question_id':questionId,
    })).data
    : (await _postV1('/video-interviews/create',{
      'url_id':urlId,
      'question_id':questionId,
    })).data

  // DEBUGGING
  useEffect(() => {
    console.log(stateStage)
  },[stateStage])

  const setError = (error:string) => {
    _setStage({
      'stage':Stage.ERROR,
      'data':error,
    })
  }
  const setStage = useCallback(async (stage:Stage) => {
    switch(stage)
    {
      case Stage.ERROR:
        _setStage({
          'stage':stage,
          'data':undefined,
        })
        break
      case Stage.ROOT:
        _setStage({
          'stage':stage,
          'data':{
            'webcam':webcam,
          },
        })
        break
      case Stage.QUESTION:
        if(stateStage.stage === Stage.ROOT)
        {
          _setStage({
            'stage':stage,
            'data':{
              'webcam':stateStage.data.webcam,
              'question':(async () => {
                const question = await getQuestion(mutableRecentQuestions)
                mutableRecentQuestions = [
                  question,
                  ...(mutableRecentQuestions.length >= constMaxRecentQuestions ? mutableRecentQuestions.slice(0,constMaxRecentQuestions - 1) : mutableRecentQuestions),
                ]
                return question
              })(),
              'epoch':now(),
            },
          })
        }
        else
        {
          _setStage({
            'stage':Stage.ERROR,
            'data':'Root must precede Question',
          })
        }
        break
      case Stage.RECORDING:
        if(stateStage.stage === Stage.QUESTION)
        {
          mutableChunks = []
          refRecorder.current = new MediaRecorder(refCamera.current!.stream!,{
            'mimeType':constMimeType,
          })
          refRecorder.current.addEventListener('dataavailable',({ data }) => {
            console.log('dataavailable',mutableChunks,data)
            if(data.size > 0)
            {
              mutableChunks.push(data)
            }
          })
          refRecorder.current.start()
          _setStage({
            'stage':stage,
            'data':{
              'webcam':stateStage.data.webcam,
              'question':await stateStage.data.question,
              'epoch':now(),
            },
          })
        }
        else
        {
          _setStage({
            'stage':Stage.ERROR,
            'data':'Question must precede Recording',
          })
        }
        break
      case Stage.REVIEWING:
        if(stateStage.stage === Stage.RECORDING)
        {
          refRecorder.current?.addEventListener('stop',() => {
            if(stateCurrentTime.isBefore(stateStage.data.epoch.plus(constMinLength)))
            {
              _setStage({
                'stage':Stage.REVIEWING_ERROR,
                'data':{
                  'message':'Recording is too short.'
                },
              })
            }
            else
            {
              const blob = new Blob(mutableChunks,{
                'type':constMimeType,
              })
              _setStage({
                'stage':stage,
                'data':{
                  'blob':blob,
                  'objectUrl':URL.createObjectURL(blob),
                  'question':stateStage.data.question,
                },
              })
            }
          })
          refRecorder.current!.stop()
        }
        else
        {
          _setStage({
            'stage':Stage.ERROR,
            'data':'Recording must precede Reviewing',
          })
        }
        break
      case Stage.UPLOADING:
        if(stateStage.stage === Stage.REVIEWING)
        {
          _setStage({
            'stage':stage,
            'data':{
              'upload':(async () => {
                const presignedUrl = await getUploadUrl()
                await Promise.all([
                  uploadFileToPresignedUrl(presignedUrl.url,stateStage.data.blob),
                  addVideoInterview(presignedUrl.id,stateStage.data.question.id),
                ])
                props.onUpload(presignedUrl.url)
              })()
            },
          })
        }
        else
        {
          _setStage({
            'stage':Stage.ERROR,
            'data':'Reviewing must precede Uploading',
          })
        }
        break
      case Stage.PERMISSIONS_FAILED:
        _setStage({
          'stage':stage,
          'data':null,
        })
        break
      case Stage.PERMISSIONS:
        _setStage({ 'stage':stage,'data':null })
        setStage(await checkPermissions(setDevices))
        break
      default:
        _setStage({
          'stage':Stage.ERROR,
          'data':undefined,
        })
        break
    }
  },[stateStage,stateCurrentTime])
  // TODO: factor out into a "useCurrentTime" hook
  useEffect(() => {
    const interval = window.setInterval(() => setCurrentTime(now()),100)
    return () => window.clearInterval(interval)
  },[])
  useEffect(() => {
    setStage(Stage.PERMISSIONS)
  },[])

  if(stateStage.stage === Stage.QUESTION && stateCurrentTime.isAfter(stateStage.data.epoch.plus(constQuestionTime)))
  {
    _setStage({
      ...stateStage,
      'data':{
        ...stateStage.data,
        'epoch':joda.Instant.MAX.minus(60*1000,joda.ChronoUnit.SECONDS),
      },
    })
    setStage(Stage.RECORDING)
  }
  if(stateStage.stage === Stage.RECORDING && stateCurrentTime.isAfter(stateStage.data.epoch.plus(constMaxLength)))
  {
    setStage(Stage.REVIEWING)
  }

  switch(stateStage.stage)
  {
    case Stage.PERMISSIONS:return <>
      <div>
        <p className='py-2'>Verifying access to camera and microphone...</p>
          <i className='pi pi-video fs-4  mx-3 '></i>
          <i className='pi pi-spin pi-spinner-dotted fs-1 mx-3'></i>
          <i className='pi pi-volume-down fs-4 mx-3'></i>
      </div>
    </>
    case Stage.PERMISSIONS_FAILED:return <>
      <p>
        Video Interviews require access to your camera and microphone.
      </p>
      <p>
        If you would like to continue, please grant access and click "I've Granted Access".
      </p>
      <p>
        If you have already granted access and are still seeing this message,
        <br/>
        it's possible another application is already using your camera.
      </p>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setStage(Stage.PERMISSIONS)}>I've Granted Access</button>
    </>
    case Stage.ROOT:return <>
      <div className="m-auto mb-2" style={{ 'maxWidth':constVideoWidth+'px' }}>
        <Dropdown
          options={stateDevicesAudio || []}
          optionValue="deviceId"
          optionLabel="label"
          value={stateDeviceAudio?.deviceId}
          onChange={(e) => setDeviceAudio(stateDevicesAudio.find((x) => x.deviceId == e.value))}
        ></Dropdown>
      </div>
      <div className="m-auto mb-2" style={{ 'maxWidth':constVideoWidth+'px' }}>
        <Dropdown
          options={stateDevicesVideo || []}
          optionValue="deviceId"
          optionLabel="label"
          value={stateDeviceVideo?.deviceId}
          onChange={(e) => setDeviceVideo(stateDevicesVideo.find((x) => x.deviceId == e.value))}
        ></Dropdown>
      </div>
      {stateStage.data.webcam()}
      <p>
        When you click <b>Start</b> to begin, a question will be
        <br/>
        displayed and recording will start in <b>{constQuestionTime.isZero() || constQuestionTime.isNegative() ? '.' : `${formatDurationText(constQuestionTime)}`}</b>.
      </p>
      <p>
        Your response must be at least <b>{formatDurationText(constMinLength)}</b>,<br/>
        and recording will end after <br/> <b>{formatDurationText(constMaxLength)}</b>.
      </p>
      <span className="d-flex justify-content-center">
        <button type="button" className="btn btn-sm btn-success d-flex align-items-center shadow" onClick={() => setStage(Stage.QUESTION)}><span>START</span> <i className="pi pi-video m-1"></i></button>
      </span>
    </>
    case Stage.QUESTION: return <>
      {stateStage.data.webcam()}
      <Await dependency={stateStage.data.question} either>
        {(question) =>
          question === undefined
            ? <>
              <p className='fs-4'>Selecting a question...</p>
              <i className='pi pi-question fs-1 mx-3'></i>
            </>
            : <>
              <div className='z-1 position-absolute top-50 start-50 translate-middle text-white' style={{ fontSize: 80 }}>
                <CountdownCircleTimer
                  isPlaying
                  duration={constQuestionTime.get(joda.ChronoUnit.SECONDS)}
                  colors={['#75FF9A', '#75FF9A', '#75FF9A', '#75FF9A']}
                  colorsTime={[7, 5, 2, 0]}
                  size={150}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>
              {/* <span className="fw-bold ms-1 w-100 h-100 d-flex  count-overlay" style={{ fontSize: 300 }}>
                {formatDurationNumeric(joda.Duration.ofSeconds(constQuestionTime.get(joda.ChronoUnit.SECONDS) - stateStage.data.epoch.until(stateCurrentTime,joda.ChronoUnit.SECONDS)))}
              </span> */}
              <div className='d-flex justify-content-center'>
                <h3 className='w-75'>{question.question}</h3>
              </div>
              {/* <p>
                <span className="me-1">Recording will start in:</span>
              </p> */}
            </>
        }
      </Await>

    </>
    case Stage.RECORDING:
      const duration = joda.Duration.ofSeconds(stateStage.data.epoch.until(stateCurrentTime,joda.ChronoUnit.SECONDS))
    return <>
      {stateStage.data.webcam()}
      <div className='d-flex justify-content-center'>
        <h3 className='w-75'>{stateStage.data.question.question}</h3>
      </div>
      <Knob
        value={stateStage.data.epoch.until(stateCurrentTime,joda.ChronoUnit.SECONDS)}
        onChange={() =>formatDurationNumeric(duration)}
        min={0}
        max={75}
        valueColor={stateStage.data.epoch.until(stateCurrentTime,joda.ChronoUnit.SECONDS) >= 15 ?'#198754': '#BB2D3B'}
        rangeColor={'#999'}
        readOnly
        valueTemplate={formatDurationNumeric(duration)}
        className='position-absolute top-50 start-50 translate-middle bg-primary rounded-circle bg-opacity-25'
        size={70}
        textColor='white'
      />
      <p className="d-flex align-items-center justify-content-center">
        {/* <span className={[
          'fs-4',
          'me-1',
          duration.compareTo(constMinLength) === -1 ? 'text-danger' : '',
        ].join(' ')
        }>{formatDurationNumeric(duration)}</span> */}
        <button
          type="button"
          className="btn btn-sm d-flex align-items-center shadow text-white "
          style={{ background: stateStage.data.epoch.until(stateCurrentTime, joda.ChronoUnit.SECONDS) > 15 ?  '#F2925E' : '#BB2D3B' }}
          onClick={() => setStage(Stage.REVIEWING)}
        >
          <span className="fw-medium">FINISH</span>
          <i className="pi pi-stop-circle m-1 rec-button-animation"></i>
        </button>
      </p>
      <p className='text-center'>Happy with you're response?<br/> Then press <b>Finish</b> after the<br/> question has been answered.</p>
      {/* <p className='text-center'>If you're happy with your response<br/> and don't require more time you<br/> may press the <b>Finished</b> button after<br/> <b>{formatDurationText(constMinLength)}</b>.</p> */}
    </>
    case Stage.REVIEWING:return <>
      <video src={stateStage.data.objectUrl} controls className="rounded-2 w-90" width={constVideoWidth+'px'}></video>
      <div className='d-flex justify-content-center'>
        <h3 className='w-75'>{stateStage.data.question.question}</h3>
      </div>
      <p>
        <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => setStage(Stage.ROOT)}>Try Again</button>
        <button type="button" className="btn btn-sm btn-primary ms-2" onClick={() => setStage(Stage.UPLOADING)}>Accept and Upload</button>
        {/* <button type="button" className="btn btn-sm btn-primary" onClick={() => doDownload(stateStage.data.objectUrl,'video-interview')}>Download</button> */}
      </p>
    </>
    case Stage.REVIEWING_ERROR:return <>
      <p>{stateStage.data.message}</p>
      <p>
        <button type="button" className="btn btn-sm btn-primary" onClick={() => setStage(Stage.ROOT)}>Try Again</button>
      </p>
    </>
    case Stage.UPLOADING:return <>
      <Await dependency={stateStage.data.upload} either>
        {(status) =>
          status === undefined
          ? <>
            <p>Uploading video...</p>
          </>
          : <>
            <p>Done.</p>
          </>
        }
      </Await>
    </>
    case Stage.ERROR:return <>
      {stateStage.data}
    </>
    default:return <>
      ERROR
    </>
  }
}

export default VideoInterview
