import React,{ useEffect,useState } from 'react'
import { Button,ButtonProps } from 'primereact/button'

type Props = {
  dependency?:Promise<unknown>

  label?:string
  disabled?:boolean
  severity?:ButtonProps['severity']
  className?:string
}

const pt = {
  'root':{
    'type':'submit',
  },
}

const SubmitButton : React.FC<Props> = (props) => {
  const [value, setValue] = useState<Promise<unknown>>()

  const wait = async () => {
    setValue(props.dependency)
    await value
    setValue(undefined)
  }

  useEffect(() => {
    wait()
  },[props.dependency])

  return (
    <Button
      pt={pt}
      label={props.label}
      disabled={props.disabled || value !== undefined}
      loading={value !== undefined}
      className={[
        'btn',
        props.disabled ? 'disabled-button' : `btn-${props.severity || 'primary'}`,
        props.className,
      ].join(' ')}
    ></Button>
  )
}

export default SubmitButton
