import React, { useEffect, useState } from 'react'
import Navbar from './_layout/navbar'
import Footer from './_layout/footer'
import { Blog } from 'types/blogs'
import { getSuccessStories } from '_services/blogs.service'
import StoryList from './_components/storyList'
import StorySkeleton from './_components/storySkeleton'

const SuccessStoryPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [stories, setStories] = useState<Blog[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setLoading(true)
    await getSuccessStories((res) => {
      setStories(res.data)
    })
    setLoading(false)
  }

  return (
    <>
      <div className="header">
        <Navbar />
      </div>
      <main>
        <section>
          <div className="d-flex container">
            <div className="row">
              <div className="col">
                <h2 className="text-center"> "Success Stories"</h2>
                <hr className="title-border" />
                <br />
                <div className="container">
                  <div className="row">
                    {loading ? (
                      <StorySkeleton />
                    ) : (
                      <StoryList stories={stories} navLink={'/successStory'} actionLinkTitle="WATCH THEIR STORY..."/>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default SuccessStoryPage
