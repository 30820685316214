import React, { useEffect, useRef, useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { Checkbox } from 'primereact/checkbox'
import { authStore } from '../_core/stores/authStore'
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URL, EMAIL_SCOPE } from '@env'
import { InputText } from 'primereact/inputtext'
import { E164Number } from 'libphonenumber-js/types.cjs'
import useScript from '../_core/hooks/useScript'
import useMeta from '../_core/hooks/useMeta'
import SubmitButton from '../components/submit-button'
import FacebookSingleSignOnButton from '../components/buttons/FacebookSingleSignOnButton'
import GoogleSingleSignOnButton from '../components/buttons/GoogleSingleSignOnButton'
import { authenticateSSO } from '_services/auth.service'
import { useNavigate } from 'react-router-dom'

interface SignInFormProps {
  isCreateForm?: boolean
  onSubmit: (phoneNumber: string, countryCode: string) => Promise<unknown>,
  onSuccess: () => void,
}

enum EnumLoginType {
  PHONE,
  DATE_BRITISH_GUYS,
}

const SignInForm: React.FC<SignInFormProps> = ({isCreateForm, onSubmit, onSuccess }) => {
  useScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js")
  useMeta("appleid-signin-client-id", APPLE_CLIENT_ID)
  useMeta("appleid-signin-scope", EMAIL_SCOPE)
  useMeta("appleid-signin-redirect-uri", APPLE_REDIRECT_URL)
  const navigate = useNavigate()
  const refInput = useRef<HTMLInputElement>()
  const [loginType, setLoginType] = useState<EnumLoginType>(EnumLoginType.PHONE)
  const [acceptedTermsConditions, setAcceptedTermsConditions] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>(undefined)
  const [email, setEmail] = useState<string>('')
  const [submitting, setSubmitting] = useState<Promise<unknown>>()

  useEffect(() => {
    refInput.current?.focus()
  }, [loginType])

  const switcheroo = {
    [EnumLoginType.PHONE]: {
      'toggleButtonText': 'I have a Date British Guys account',
      'loginType': EnumLoginType.DATE_BRITISH_GUYS,
      'value': phoneNumber,
      'inputComponent': () =>
        // TODO: style appropriately - use PrimeReact?
        <PhoneInput
          ref={(x) => refInput.current = x as unknown as HTMLInputElement}
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="US"
        />,
      'submit': () => {
        let number = parsePhoneNumber(phoneNumber as string)
        let formattedNumber = number!.nationalNumber.toString()
        let countryCode = number!.countryCallingCode

        return onSubmit(formattedNumber, countryCode) // TODO: include `loginType`?
      },
    },
    [EnumLoginType.DATE_BRITISH_GUYS]: {
      'toggleButtonText': 'I have a Dating Across the Pond account',
      'loginType': EnumLoginType.PHONE,
      'value': email,
      'inputComponent': () =>
        <InputText
          ref={(x) => refInput.current = x as unknown as HTMLInputElement}
          placeholder="Date British Guys email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />,
      'submit': () => {
        return new Promise((_,reject) => reject())
      }
    }
  }

  const handleSocialLogin = async (ssoID: string, name: string, email: string) => {
    const response = await authenticateSSO(ssoID, name, email);

    authStore.setToken(response.data.token)
    if(!response.data.userComplete){
      navigate('/onboarding')
    }else{
      navigate('/discover')
    }

    onSuccess();
  }

  const handleSocialLoginFailure = (err: any) => {
    console.error(err);
  }

  return (
    <>
      <form className="d-flex flex-column align-items-center" onSubmit={(e) => { e.preventDefault(); setSubmitting(switcheroo[loginType].submit()) }}>
        <div>
          <button
            type="button"
            className="btn btn-link link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            onClick={() => setLoginType(switcheroo[loginType].loginType) }
          >
            {switcheroo[loginType].toggleButtonText}
          </button>
        </div>
        <span className="p-float-label mb-2 " style={{width:'80%',maxWidth:300}}>
          {switcheroo[loginType].inputComponent()}
        </span>
        <div className="d-flex">
          <Checkbox
            className='my-2'
            style={{ marginRight: 4 }}
            inputId="ingredient1"
            name="pizza"
            value="Cheese"
            onChange={(e) => setAcceptedTermsConditions(e.checked!!)}
            checked={acceptedTermsConditions}
          />
          <label htmlFor="ingredient1" className="mt-1">
            I have read and agreed to <span className='fw-medium'>Dating Across the Pond</span> <a href="/terms-and-conditions">Terms of use</a> and <a href="/privacy-policy">Privacy Policy</a>
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex justify-content-between">
            <SubmitButton
              dependency={submitting}
              label="Submit"
              disabled={!acceptedTermsConditions || !switcheroo[loginType].value}
              className="my-3"
            />
          </div>
        </div>
      </form>
      <div className="text-center w-100">
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-inline-block align-middle" style={{ width: '30%' }}>
            <hr className="mb-2" />
          </div>
          <span className="mx-2">or with</span>
          <div className="d-inline-block align-middle" style={{ width: '30%' }}>
            <hr className="mb-2" />
          </div>
        </div>
        <div className="d-md-flex justify-content-evenly my-3 w-100 text-center">
          <div className="m-auto my-2 mx-md-2" style={{ 'maxWidth':'300px' }}>
            <FacebookSingleSignOnButton isCreateForm={isCreateForm || false} onSuccess={handleSocialLogin}/>
          </div>
          <div className="m-auto my-2 mx-md-2" style={{ 'maxWidth':'300px' }}>
            <GoogleSingleSignOnButton isCreateForm={isCreateForm || false} onSuccess={handleSocialLogin}/>
          </div>
          <div
            id="appleid-signin"
            className="signin-button sso-button m-auto my-2 mx-md-2"
            style={{ 'maxWidth':'300px' }}
            data-height="40px"
            data-type="sign-in"
          >
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInForm
