import React from 'react'

interface ProductBannerProps {
  title: string
  color?: string
}
const ProductBanner = (props: ProductBannerProps) => {
  return (
    <div className="position-absolute top-0 start-50 translate-middle ">
      <h3 className="text-center">
        <span className="badge bg-primary-light border-secondary-subtle text-black border border-1 border-primary">
          {props.title}
        </span>
      </h3>
    </div>
  )
}
export default ProductBanner
