import { useEffect,useMemo } from 'react'
import { GCP_MAPS_API_KEY } from '@env'
import { useJsApiLoader } from '@react-google-maps/api'

export type Location = {
  country:string
  latitude:number
  longitude:number
}

export const useGeocoding = () : Promise<google.maps.Geocoder> => {
  const { isLoaded } = useJsApiLoader({
    'googleMapsApiKey':GCP_MAPS_API_KEY,
  })
  return new Promise<google.maps.Geocoder>((resolve) => {
    const interval = window.setInterval(() => {
      if(isLoaded)
      {
        window.clearInterval(interval)
        resolve(new google.maps.Geocoder())
      }
    },10)
  })
}

export const geocodeLatLng = async (geocoder:Promise<google.maps.Geocoder>,lat:number,lng:number) : Promise<Location | null> => {
  const response = await (await geocoder).geocode({
    'location':{
      'lat':lat,
      'lng':lng,
    }
  })
  if(!!response.results.length)
  {
    const addressComponents = response.results[0].address_components
    return {
      'country':`${addressComponents.find((x) => x.types.includes('administrative_area_level_1'))?.long_name}, ${addressComponents.find((x) => x.types.includes('country'))?.short_name}`,
      'latitude':response.results[0].geometry.location.lat(),
      'longitude':response.results[0].geometry.location.lng(),
    }
  }
  else
  {
    return null
  }
}

export const geocodeCountry = async (geocoder:Promise<google.maps.Geocoder>,countryName:string) : Promise<Location | null> => {
  const response = await (await geocoder).geocode({
    'address':countryName,
  })
  if(!!response.results.length)
  {
    return {
      country:countryName,
      latitude:response.results[0].geometry.location.lat(),
      longitude:response.results[0].geometry.location.lng(),
    }
  }
  else
  {
    return null
  }
}
