import React, { useEffect, useState } from 'react'
import Navbar from '../_layout/navbar'
import Footer from '../_layout/footer'
import { Blog } from 'types/blogs'
import { getBlog } from '_services/blogs.service'
import { useParams } from 'react-router-dom'

interface StoryPostProps {
  fetchStory: (
    x: string,
    onSuccess?: (res: any) => void,
    onFail?: (e: any) => void
  ) => void;
  renderStoryPhoto?: boolean
}

const StoryPost: React.FC<StoryPostProps> = (props) => {
  const [story, setStory] = useState<Blog>()
  const { id } = useParams()
  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    await props.fetchStory(id, (res) => {
      setStory(res.data)
    })
  }

  return !story ? (
    <></>
  ) : (
    <>
      <div className="header">
        <Navbar />
      </div>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="text-center">{story.title}</h2>
                <hr className="title-border" />
                <br />
                <div className="container">
                  <div className="row">
                    <div className="mb-3 mb-md-0">
                      <div className="card shadow">
                        <div className="card-body">
                          <div className='d-flex justify-content-center w-100'>
                          {props.renderStoryPhoto && <img className="mb-4 centerImage rounded-1" src={story.photo} alt="StoryImage" />}
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: story.content }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default StoryPost
