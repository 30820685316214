export type PreferenceKeys =
  | 'location'
  | 'nationality'
  | 'gender'
  | 'age'
  | 'userInterest'
  | 'politicalBelief'
  | 'religiousBelief'
  | 'bodyType'
  | 'kids'
  | 'weedUse'
  | 'alcoholUse'
  | 'tobaccoUse'

export type PreferencesType = {
  [key in PreferenceKeys]: string[]
}

export const DefaultPref : PreferencesType = {
    location: [],
    nationality: [],
    gender: [],
    age: [],
    userInterest: [],
    politicalBelief: [],
    religiousBelief: [],
    bodyType: [],
    kids: [],
    weedUse: [],
    alcoholUse: [],
    tobaccoUse: [],
}

export const emptyPreferences : () => PreferencesType = () => ({
  location: [],
  nationality: [],
  gender: [],
  age: [],
  userInterest: [],
  politicalBelief: [],
  religiousBelief: [],
  bodyType: [],
  kids: [],
  weedUse: [],
  alcoholUse: [],
  tobaccoUse: []
})
