export const uploadFileToPresignedUrl = async (url:string,file:Blob) => {
  try
  {
    const response = await fetch(url,{
      'method':'PUT',
      'body':file,
      'headers':{
        'Content-Type':file.type,
      },
    })

    console.log('presigned', response)

    if(response.ok)
    {
      console.log('File uploaded successfully.')
    }
    else
    {
      console.error('File upload failed.')
      console.error('Status:',response.status)
      console.error('Status text:',response.statusText)
    }
  }
  catch(error)
  {
    console.error('Error uploading file:',error)
  }
}
export async function uploadImageToPresignedUrl(
  presignedUrl: string,
  file: Blob
) {
  return uploadFileToPresignedUrl(presignedUrl,file)
}
