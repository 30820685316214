import React from 'react'
import { Skeleton } from 'primereact/skeleton'

const UpgradeSkeleton: React.FC = () => {
  return (
    <div className="flex card h-100 w-100 bg-secondary-light vertical-scroll">
      <div className="flex">
        <br className="d-none d-xxl-block d-lg-none d-sm-block" />
        <br className="d-none d-xxl-block d-lg-none d-sm-block" />
        <br className="d-none d-xxl-block d-lg-block d-sm-block" />
        <br className="d-none d-lg-block d-sm-block" />
        <br className="d-none d-lg-block d-sm-block" />
        <div className="d-flex flex-column flex-wrap gap-1 align-items-center">
          <Skeleton width="50%" height="2rem" className="m-2"></Skeleton>
          <Skeleton width="60%" height="2rem" className="mb-2 d-lg-none"></Skeleton>
          {/* <Skeleton width="90%" height="2rem" className="mb-2 d-sm-block d-sm-none"></Skeleton>
          <Skeleton width="75%" height="2rem" className="mb-2 d-sm-block d-sm-none"></Skeleton> */}
        </div>
      </div>
      <br className="d-none d-sm-block" />
      <br className="d-none d-sm-block" />
      <br className="d-none d-sm-block" />
      <div className="d-flex gap-3 p-3 justify-content-evenly flex-wrap flex-sm-nowrap">
        <Skeleton width="24rem" height="25rem" className="d-inline-block"></Skeleton>
        <Skeleton width="24rem" height="25rem"></Skeleton>
        <Skeleton width="24rem" height="25rem"></Skeleton>
        <Skeleton width="24rem" height="25rem"></Skeleton>
      </div>
    </div>
  )
}

export default UpgradeSkeleton
