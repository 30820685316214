import { useEffect,useState } from 'react'

export const useFetch = <a>(fetch:() => Promise<a>) : [Promise<a>,() => void] => {
  const [reload,setReload] = useState(0)
  const [fetching,setFetching] = useState<Promise<a>>(new Promise(() => {}))
  useEffect(() => {
    setFetching(fetch())
    return () => {
    }
  },[reload])
  return [fetching,() => setReload((x) => (x + 1) % 2)]
}

export default useFetch
