import React, { useEffect, useState } from 'react'
import Navbar from './_layout/navbar'
import Footer from './_layout/footer'
import { useNavigate } from 'react-router-dom'
import { Blog } from 'types/blogs'
import { getBlogs } from '_services/blogs.service'
import StoryList from './_components/storyList'
import StorySkeleton from './_components/storySkeleton'

const BlogPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [blogs, setBlogs] = useState<Blog[]>([])

  let navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setLoading(true)
    await getBlogs((res) => {
      setBlogs(res.data)
    })
    setLoading(false)
  }

  const dateString = '2020-01-01'
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-GB' || 'en-US')

  return (
    <>
      <div className="header">
        <Navbar />
      </div>
      <main>
        <section>
          <div className="d-flex container">
            <div className="row">
              <div className="col">
                <h1 className="text-center"> "DAtP Blog"</h1>
                <hr className="title-border" />
                <br />
                <div className="container">
                  <div className="row">
                    {loading ? (
                      <StorySkeleton />
                    ) : (
                      <StoryList stories={blogs} navLink={'/blogPost'} actionLinkTitle="READ MORE ..."/>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default BlogPage
