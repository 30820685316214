import { useEffect,useState } from "react"

export type BreakpointValue
  = 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'

export type Breakpoint = {
  value:BreakpointValue
  isMobile:boolean
  isDesktop:boolean
}

export const useResize = () : Breakpoint => {
  const [breakpoint,setBreakpoint] = useState<Breakpoint>({ 'value':'xs','isMobile':true,'isDesktop':false })
  const f = () : void => {
    const width = window.visualViewport?.width?.valueOf()
    if(width)
    {
      if(width < 576)
      {
        return setBreakpoint({ 'value':'xs','isMobile':true,'isDesktop':false })
      }
      if(width >= 576 && width < 768)
      {
        return setBreakpoint({ 'value':'sm','isMobile':true,'isDesktop':false })
      }
      if(width >= 768 && width < 992)
      {
        return setBreakpoint({ 'value':'md','isMobile':true,'isDesktop':false })
      }
      if(width >= 992 && width < 1200)
      {
        return setBreakpoint({ 'value':'lg','isMobile':false,'isDesktop':true })
      }
      if(width >= 1200 && width < 1400)
      {
        return setBreakpoint({ 'value':'xl','isMobile':false,'isDesktop':true })
      }
      if(width >= 1400)
      {
        return setBreakpoint({ 'value':'xxl','isMobile':false,'isDesktop':true })
      }
    }
  }
  useEffect(() => {
    f()
    window.addEventListener('resize',f)
    return () => window.removeEventListener('resize',f)
  },[])
  return breakpoint
}

export default useResize
