import React, { useState } from 'react';
import { Image } from 'primereact/image';
import noProfileImage from '../../../../../assets/images/no_profile_image@2x.png'
import { User } from 'types/user';

interface ImageProps {
  alt: string
  rounded?: '0' | '1' | '2' | '3' | '4' | '5'
  className?: string | undefined
  default: string
  height?: string
  src: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  overflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
  width?: string
}

const OurImage = (props: ImageProps) => {

  const [imageError, setImageError] = useState<boolean>(false)

  return (
    <Image
      alt={props.alt}
      onError={(_) => setImageError(true)}
      src={imageError ? props.default : props.src}
      width={props.width}
      height={props.height}
      imageStyle={{objectFit:props.objectFit, overflow:props.overflow}}
      pt={{
        'image': { 'className': `rounded-${props.rounded}` }
      }}
    />
  );
}

export default OurImage;