import React, { useEffect, useState } from 'react'
import { approvePhotos, ApproveUserProfile, approveVerifyPhotos, banUser, denyPhotos, denyUserProfile, denyVerifyPhotos, getApprovalPhotos, getVerifyPhotos, unbanUser } from '../../../../../../common/_services/admin.service'
import ReviewPhoto from '../../_components/reviewPhoto'
import { User, UserPhoto, UserVerifyPhoto, UserVideoInterview } from '../../../../../../common/types/user'
import NoData from '../../../../components/noData'
import ProfileBox from '../../_components/profile/profileBox'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from 'primereact/datatable'
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent, ColumnProps, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { getUsers } from '_services/users.service'
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Tag } from 'primereact/tag'
import SidebarWrapper from '../../_components/profile/components/SidebarWrapper'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { TabPanel, TabView } from 'primereact/tabview'
import { PhotoWithName } from './userApproval'

interface photoReviewProps {
  videos: UserVideoInterview[]
  onAction: () => {}
}

const VideoInterviewReview: React.FC<photoReviewProps> = (props:photoReviewProps) => {

  return (
      <div className="p-3">
          <DataTable
            value={props.videos}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
            <Column
              field="photo"
              header="Video"
              style={{ width: '200px' }}
              body={(y:UserVideoInterview,o) =>  <video className="user-video" src={y.url} controls></video>}>
            </Column>
            <Column field="id"
              header="Action"

              body={(y,o) =>
                <>
                  <Button label="Coming Soon" className="btn btn-secondary" onClick={() => {
                    // denyPhotos([y.imgUrl],[y.userId],props.onAction)
                  }}/>
                  <Button label="Coming Soon" className="btn btn-primary mx-2" onClick={() => {
                    // approvePhotos([y.imgUrl],[y.userId],props.onAction)
                  }}/>
                </>
              }>
            </Column>
          </DataTable>
      </div>
  )
}



export default VideoInterviewReview
