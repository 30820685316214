import { EnumNormalizedStructure } from "../../common/types/enums.options"

export namespace LifestyleDbTypes {
  export enum KidsTypes {
    HasKids = 'has_kids',
    NoKids = 'no_kids',
    Someday = 'someday',
    Undecided = 'undecided',
  }

  export enum TobaccoUseTypes {
    Often = 'often',
    Socially = 'socially',
    Never = 'never',
    Undecided = 'undecided',
  }

  export enum AlcoholUseTypes {
    Yes = 'yes',
    Socially = 'socially',
    Never = 'never',
    Undecided = 'undecided',
  }

  export enum DrugUseTypes {
    ToRelax = 'to_relax',
    Socially = 'socially',
    Never = 'never',
    Undecided = 'undecided',
  }
}

// ONBOARDING (need to duplicate these because of tense, makes no sense on preferences page)
export const kidsOptions: EnumNormalizedStructure[] = [
  { id: LifestyleDbTypes.KidsTypes.HasKids,          display: 'I have kids/a kid', advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.NoKids,           display: 'No kids',           advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.Someday,          display: 'Someday',           advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.Undecided,        display: 'Undecided',         advancedOption: false },
]

export const tobaccoUseOptions: EnumNormalizedStructure[] = [
  { id: LifestyleDbTypes.TobaccoUseTypes.Often,      display: 'Often',     advancedOption: false },
  { id: LifestyleDbTypes.TobaccoUseTypes.Socially,   display: 'Socially',  advancedOption: false },
  { id: LifestyleDbTypes.TobaccoUseTypes.Never,      display: 'Never',     advancedOption: false },
  { id: LifestyleDbTypes.TobaccoUseTypes.Undecided,  display: 'Undecided', advancedOption: false },
]

export const alcoholUseOptions: EnumNormalizedStructure[] = [
  { id: LifestyleDbTypes.AlcoholUseTypes.Yes,        display: 'Yes',       advancedOption: false },
  { id: LifestyleDbTypes.AlcoholUseTypes.Socially,   display: 'Socially',  advancedOption: false },
  { id: LifestyleDbTypes.AlcoholUseTypes.Never,      display: 'Never',     advancedOption: false },
  { id: LifestyleDbTypes.AlcoholUseTypes.Undecided,  display: 'Undecided', advancedOption: false },
]

export const drugUseOptions: EnumNormalizedStructure[] = [
  { id: LifestyleDbTypes.DrugUseTypes.ToRelax,       display: 'To relax',  advancedOption: false },
  { id: LifestyleDbTypes.DrugUseTypes.Socially,      display: 'Socially',  advancedOption: false },
  { id: LifestyleDbTypes.DrugUseTypes.Never,         display: 'Never',     advancedOption: false },
  { id: LifestyleDbTypes.DrugUseTypes.Undecided,     display: 'Undecided', advancedOption: false },
]

// PREFERENCES
export const kidsPreferenceOptions: EnumNormalizedStructure[] = [
  { id: LifestyleDbTypes.KidsTypes.HasKids,          display: 'Has kids / a kid',   advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.NoKids,           display: 'Never wants kids',   advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.Someday,          display: 'Someday wants kids', advancedOption: false },
  { id: LifestyleDbTypes.KidsTypes.Undecided,        display: 'Undecided',          advancedOption: false },
]