import React, { useEffect, useState } from 'react'
import Navbar from './_layout/navbar'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import SignInModal from './modals/signIn'


const PublicHome: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <div className="template-public-root vertical-scroll">
      <SignInModal isVisible={visible} onClose={() => setVisible(false)} />
      <main>
        <div className="header">
          <Navbar />
          <div className="container masthead">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h1>Globally Recognized International Dating Since 2010.</h1>
                <h4>
                  Now with video interviews and verification technology to match members from every nationality with more
                  transparency.
                </h4>
                {/* <button className="btn btn-primary btn-lg">Sign in</button> */}
                <h3>Coming Soon</h3>
                <div className="d-flex gap-5 align-items-center justify-content-center flex-wrap pt-3">
                  <img
                    className="p-home-shadow rounded-2"
                    src="/assets/images/googleComingSoon.png"
                    srcSet="/assets/images/googleComingSoon.png 1x, /assets/images/googleComingSoon@2x.png 2x"
                    alt="Google Play Store" />
                  <img
                    className="p-home-shadow rounded-2"
                    src="assets/images/appleComingSoon.png"
                    srcSet="assets/images/appleComingSoon.png 1x, assets/images/appleComingSoon@2x.png 2x"
                    alt="Apple Store" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="text-center">What Makes Us Different</h2>
                <hr className="title-border" />
                <div className="container split-bg">
                  <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="card shadow">
                        <div className="card-body">
                          <img
                            className="mb-4"
                            src="/assets/images/heart.png"
                            srcSet="/assets/images/heart@2x.png 2x"
                            alt="Heart"
                          />
                          <h5 className="mb-3">Est. 2010 to spread love</h5>
                          <p className="text-muted">
                            DateBritishGuys.com (now DAtP) was featured in The New York Times, BBC News, and Elle.com.
                            More than 150,000 people have used our dating service since 2010. Our mission is to add a
                            little love to the world.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="card shadow">
                        <div className="card-body">
                          <img
                            className="mb-4"
                            src="/assets/images/match.png"
                            srcSet="/assets/images/match@2x.png 2x"
                            alt="Match"
                          />
                          <h5 className="mb-3">Broaden your horizons</h5>
                          <p className="text-muted">
                            Beautiful things happen when you mix cultures. This community is full of exotic,
                            interesting, and cultured global travelers. Search by nationality and location.
                            Attracted to another culture? Meet people who are attracted to yours.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="card shadow">
                        <div className="card-body">
                          <img
                            className="mb-4"
                            src="/assets/images/cheers.png"
                            srcSet="/assets/images/cheers@2x.png 2x"
                            alt="Cheers"
                          />
                          <h5 className="mb-3">* No ads * Video interviews * Real people</h5>
                          <p className="text-muted">
                            You get what you pay for in the dating world. Free sites = more scammers. Just imagine a
                            nightclub that lets anyone in for free. All our VIP members have approved video interviews.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="d-flex align-items-center gap-5 justify-content-center flex-wrap pt-3">
            <img
              className="p-home-shadow rounded-2"
              src="/assets/images/googleComingSoon.png"
              srcSet="/assets/images/googleComingSoon.png 1x, /assets/images/googleComingSoon@2x.png 2x"
              alt="Google Play Store"
            />
            <img
              className="p-home-shadow rounded-2"
              src="/assets/images/appleComingSoon.png"
              srcSet="/assets/images/appleComingSoon.png 1x , /assets/images/appleComingSoon@2x.png 2x"
              alt="Apple Store"
            />
          </div>
        </section>
        <section className="bg-light">
          <img className="half-circle" src="/assets/images/circle-divider.png" alt="circle divider" />
          <div className="container gap-5 d-flex justify-content-center align-items-center">
            <img
              className="mb-4 d-none d-md-inline p-home-shadow"
              src="/assets/images/screen1TU.png"
              srcSet="/assets/images/screen1TU@2x.png 2x"
              alt="Screenshot of app"
            />
            <img
              className="mb-4 p-home-shadow"
              src="/assets/images/screen2TU.png"
              srcSet="/assets/images/screen2TU@2x.png 2x"
              alt="Screenshot of app"
            />
            <img
              className="mb-4 d-none d-md-inline p-home-shadow"
              src="/assets/images/screen3TU.png"
              srcSet="/assets/images/screen3TU@2x.png 2x"
              alt="Screenshot of app"
            />
          </div>
        </section>
        <section className="bg-light pb-0">
          <div className="container">
            <div className="row">
              <div className="col d-flex align-items-center">
                <ul className="timeline">
                  <li>
                    <h3>Find people across the pond.</h3>
                  </li>
                  <li>
                    <h3>Make a match.</h3>
                  </li>
                  <li>
                    <h3>Build a connection.</h3>
                  </li>
                  <li>
                    <h3>Adventure together.</h3>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 order-md-first">
                <img src="/assets/images/map.png" srcSet="/assets/images/map@2x.png 2x" alt="Map of users" />
              </div>
            </div>
          </div>
        </section>
        <section className="grunge-circle-right">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>
                  Now with video interviews and verification technology to match members from every nationality with
                  more transparency.
                </h1>
                {/* <h4>Coming Soon</h4> */}
                {/* <button className="btn btn-primary btn-lg mb-5 mb-md-0">Sign in</button> */}
              </div>
              <div className="col ">
                <div className="d-md-flex justify-content-end">
                  <img
                    className="p-home-shadow rounded-2"
                    src="/assets/images/googleComingSoon.png"
                    srcSet="/assets/images/googleComingSoon.png 1x,/assets/images/googleComingSoon@2x.png 2x"
                    alt="Google Play Store"
                  />
                </div>
                <div className="d-md-flex pt-5 justify-content-end">
                  <img
                    className="p-home-shadow rounded-2"
                    src="/assets/images/appleComingSoon.png"
                    srcSet="/assets/images/appleComingSoon.png 1x, /assets/images/appleComingSoon@2x.png 2x"
                    alt="Apple Store"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mobile-home-buttons sticky-bottom">
          <div className="d-flex flex-column d-md-none p-2 back-blur bottom-0">
            <button className="btn btn-primary shadow mx-3" type="button" onClick={() => setVisible(true)}>
              <span className="fs-4 fw-medium ">Create Account</span>
            </button>
            <button
              className="btn btn-secondary text-white shadow mx-3"
              type="button"
              onClick={() => setVisible(true)}
            >
              <span className="fs-4 fw-medium text-align-center">Sign in</span>
            </button>
          </div>
        </div>
        <footer>
          <div className="d-flex d-md-flex justify-content-between ">
            <div>
              <div className="mb-3 align-items-center">
                <img
                  src="/assets/images/logo-white.png"
                  srcSet="/assets/images/logo-white@2x.png 2x"
                  alt="Dating Across the Pond"
                />
              </div>
              <i className="fa-brands fa-instagram fa-xl me-2">
                <FaInstagram size={35} />
              </i>
              <i className="fa-brands fa-facebook fa-xl me-2">
                <FaFacebookSquare size={35} />
              </i>
              <a href="/privacy-policy" className="me-2 text-white">
                Privacy Policy
              </a>
              <a href="/terms-and-conditions" className="me-2 text-white">
                Terms and Conditions
              </a>
              <a href="/faq" className="me-2 text-white">
                FAQ
              </a>
              <a href="/frequent-denial-reasons" className="me-2 text-white">
                FDR
              </a>
              <a href="#" className="me-2 text-white">
                Contact
              </a>
              <a href="/blog" className="me-2 text-white">
                Blog
              </a>
            </div>
            <div className="d-md-flex justify-content-end mt-4 mt-md-0">{/* <h4>Coming Soon</h4> */}</div>
            {/* <button className="btn btn-lg btn-outline-light">Join Today</button> */}
          </div>
        </footer>
      </main>
    </div>
  )
}

export default PublicHome
