import React, { useEffect, useRef, useState } from 'react';
import { SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { FloatLabel } from "primereact/floatlabel";
import PhotoUpload from '../../_components/profile/components/PhotoUpload';
import { authStore } from '../../../../_core/stores/authStore';
import { User } from 'types/user';
import { EnumBodyTypesData, EnumNationalitiesData, EnumUserGendersData, EnumPoliticalBeliefsData, EnumReligionsData, EnumVerifyPhoto, genderPreferenceOptions } from 'types/enums.options';
import { deletePhoto, getCurrentUser, getUserById, updateProfile } from '_services/users.service';
import { getBodyTypesEnum, getGendersEnum, getNationalitiesEnum, getPoliticalBeliefsEnum, getRandomVerifyPhoto, getReligionsEnum,  getUserQuestionsEnum } from '_services/enums.service';
import { Dialog } from 'primereact/dialog';
import HeightInput from '../_components/heightField';
import { useFetch,useLocation } from '../../../../_core/hooks'
import { useGeocoding,geocodeCountry } from '../../../../_core/utility/geocoding'
import { MultiSelect } from 'primereact/multiselect';
import { EducationOptions } from 'enums/EducationLevels';
import { alcoholUseOptions, drugUseOptions, kidsOptions, tobaccoUseOptions } from 'enums/LifestyleChoiceEnums';
import QuestionsInput from '../_components/QuestionsInput';
import InterestInput from '../_components/InterestInput';
import { FormType, IncomingFormPayload, OnboardingForm } from 'types/payloads.users';
import { useNavigate } from 'react-router-dom';
import { getValidationErrors } from 'util/validation.messages';
import { EditableField } from '../_components/EditableField';
import { EditableCalendar } from '../_components/EditableCalendar';
import { EditableDropdown } from '../_components/EditableDropdown';
import { EditableArea } from '../_components/EditableArea';
import { Toast } from 'primereact/toast';
import { nativeJs } from '@js-joda/core';
import { ProfileVerify } from '../_components/ProfileVerify';
import { getEnumOptions } from 'hooks/getEnumOptions';
import { DropdownChangeEvent } from 'primereact/dropdown';

const AddEditForm: React.FC = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const [genders, setGenders] = useState<EnumUserGendersData[]>([])
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const toast = useRef<Toast>(null);
  const { countryData, nationalityData, bodyTypeData, religionData, politicalBeliefData } = getEnumOptions()
  const geocoder = useGeocoding()
  const location = useLocation(geocoder)

  useEffect(() => {
    setFormState({
      ...formState,
      ...location,
    })
  },[location])

  const [visible, setVisible] = useState(false)
  const [qaVisible, setQaVisible] = useState(false)
  const [interestVisible, setinterestVisible] = useState(false)

  const [formState, setFormState] = useState<OnboardingForm>({
    FirstName       : '',
    LastName        : '',
    Email           : '',
    Birthday        : '',
    PreferredSex    : '',
    HighestLevel    : '',
    HasOrWantsKids  : '',
    SmokeTobacco    : '',
    DrinkAlcohol    : '',
    SmokeWeed       : '',
    OccupationName  : '',
    inputText       : '',
    Sex             : '',
    ReligiousBelief : '',
    PoliticalBelief : '',
    measurement     : null,
    bodyType        : '',
    isoCode         : [],
    longitude       : 0,
    latitude        : 0,
    country         : '',
  })

  const fetchUser = async () => {
    setUser((await getCurrentUser()).data)
  }
  useFetch(
    () => getCurrentUser(),
    (y) => {
      if(y)
      {
        let x = y.data
        setUser(x)
        setFormState({
          ...formState,
          FirstName       : x.firstName                                                   ,
          LastName        : x.lastName                                                    ,
          Email           : x.email                                                       ,
          Birthday        : x.userMetadata
                              ? (x.userMetadata.birthday ? x.userMetadata.birthday + 'T00:00' : '')
                              :  subtractYears(new Date(),18).toISOString()                   ,
          PreferredSex    : x.userMetadata &&  x.userMetadata.preferredSex          != '' ? x.userMetadata.preferredSex                 : ""                 ,
          HighestLevel    : x.userMetadata &&  x.userMetadata.highestEducationLevel != '' ? x.userMetadata.highestEducationLevel        : ""                 ,
          HasOrWantsKids  : x.userMetadata &&  x.userMetadata.hasOrWantsKids        != '' ? x.userMetadata.hasOrWantsKids               : ""                 ,
          SmokeTobacco    : x.userMetadata &&  x.userMetadata.smokesTobacco         != '' ? x.userMetadata.smokesTobacco                : ""                 ,
          DrinkAlcohol    : x.userMetadata &&  x.userMetadata.drinksAlcohol         != '' ? x.userMetadata.drinksAlcohol                : ""                 ,
          SmokeWeed       : x.userMetadata &&  x.userMetadata.smokesWeed            != '' ? x.userMetadata.smokesWeed                   : ""                 ,
          OccupationName  : x.userMetadata &&  x.userMetadata.occupationName        != '' ? x.userMetadata.occupationName               : ""                 ,
          inputText       : x.userMetadata &&  x.userMetadata.aboutMe               != '' ? x.userMetadata.aboutMe                      : ""                 ,
          Sex             : x.userMetadata &&  x.userMetadata.sexName               != '' ? x.userMetadata.sexName                      : ""                 ,
          ReligiousBelief : x.userMetadata &&  x.userMetadata.religiousBeliefName   != '' ? x.userMetadata.religiousBeliefName          : ""                 ,
          PoliticalBelief : x.userMetadata &&  x.userMetadata.politicalBeliefName   != '' ? x.userMetadata.politicalBeliefName          : ""                 ,
          measurement     : x.userMetadata &&  x.userMetadata.heightMeasurement     != '' ? x.userMetadata.heightMeasurement            : null                 ,
          bodyType        : x.userMetadata &&  x.userMetadata.bodyTypeName          != '' ? x.userMetadata.bodyTypeName                 : ""                 ,
          isoCode         : x.userMetadata ? x.userMetadata?.Nationalities.map((x:any) => x.nationalityIso) : [] ,
        })
      }
    },
    [authStore.userID]
  )


  useFetch(
    () => Promise.all([
      getGendersEnum(),
    ]),
    (x) => {
      if(x != undefined)
      {
        const [genders] = x
        setGenders(genders.data.sort((x:EnumUserGendersData) => x.advancedOption ? 1 : -1))
      }
    },
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | SelectButtonChangeEvent>) => {
    const { id, value } = e.target
    setFormState({
      ...formState,
      [id]: value
    })
  }

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    console.log(e)
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setErrors({})
    const jsDate = nativeJs(new Date(formState.Birthday))
    if(user && user.userPhotos.length < 1){
      toast.current?.show({severity:'error', summary: 'A photo is required', detail:'One photo is required', life: 3000})
    }
    if(user && !user.userVerifyPhoto){
      toast.current?.show({severity:'error', summary: 'Please verify yourself', detail:'Photo verfication is required', life: 3000})
    }

    const payload: IncomingFormPayload = {
      FormType: FormType.OnboardingForm,
      Data: {
        ...formState,
        Birthday:(jsDate.toLocalDate().toString())
      }
    }
    await updateProfile(payload,
      () => {
        if(!(user && !user.userVerifyPhoto) && !(user && user.userPhotos.length < 1)){
          navigate('/discover')
        }
      },
      (e) => {
        toast.current?.show({severity:'error', summary: 'Form errors', detail:'Please correct the highlighted items', life: 3000})
        setErrors(getValidationErrors(e.response.data))
      }
    )
  }

  function subtractYears(date: Date, years: number) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(date.getFullYear() - years)
    return dateCopy
  }

  return (
    <form className="h-100 overflow-auto m-auto" onSubmit={(e) =>{ e.preventDefault(); handleSubmit(e)}}>
      <Toast ref={toast} />

      <div className="d-md-flex flex-md-row justify-content-center px-1">
        <div className="flex gap-3 p-2">

          <h3 className="mb-4">Tell us about you.</h3>
          <div className="d-flex gap-2 mb-4">
            <EditableField
              id="FirstName"
              name="FirstName"
              value={formState.FirstName}
              onChange={handleChange}
              label={'First Name'}
              errors={errors["firstName"]}
            />
            <EditableField
              id="LastName"
              name="LastName"
              value={formState.LastName}
              onChange={handleChange}
              label={'Last Name'}
              errors={errors["lastName"]}
            />
          </div>
          <div className="d-flex gap-2 mb-4">
            <EditableCalendar
              id="Birthday"
              errors={errors["birthday"]}
              label='Birthday'
              name="Birthday"
              maxDate={subtractYears(new Date(),18)}
              value={ new Date(formState.Birthday)}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  Birthday:  e.value ? e.value?.toLocaleDateString() : ''
                })
              }}
            />

            <EditableField
              id="Email"
              name="Email"
              value={formState.Email}
              onChange={handleChange}
              label={'Email'}
              errors={errors["email"]}
            />

          </div>
          <div className="mb-4">
            <FloatLabel>
              <label htmlFor="isoCode">Nationalities</label>
              <MultiSelect
                id="isoCode"
                name="isoCode"
                filter
                value={formState.isoCode}
                onChange={handleDropdownChange}
                options={nationalityData}
                optionLabel="display"
                optionValue='id'
                display="chip"
                selectionLimit={3}
                max={3}
                className="w-full"
              />
            </FloatLabel>
          </div>
          <div className="d-flex gap-2 mb-4">
            <EditableDropdown
              label="Gender"
              errors={errors["sex"]}
              id="Sex"
              name="Sex"
              value={formState.Sex}
              onChange={handleDropdownChange}
              options={genders}
              optionLabel="display"
              optionValue="name"
              className="w-full md:w-14rem"
              placeholder="Select your preference"
            />
            <EditableDropdown
              label="Looking to date"
              errors={errors["preferredSex"]}
              id="PreferredSex"
              name="PreferredSex"
              value={formState.PreferredSex}
              onChange={handleDropdownChange}
              options={genderPreferenceOptions}
              optionLabel="display"
              optionValue="id"
              className="w-full md:w-14rem"
              placeholder="Select your preference"
            />
          </div>
          <div className="d-flex gap-2 mb-4">
            <EditableDropdown
              label="Has or Wants Kids"
              errors={errors["hasOrWantsKids"]}
              options={kidsOptions}
              optionLabel="display"
              optionValue="id"
              id="HasOrWantsKids"
              name="HasOrWantsKids"
              value={formState.HasOrWantsKids}
              onChange={handleDropdownChange}
            />
            <EditableDropdown
              label="Smokes Tobacco"
              errors={errors["smokeTobacco"]}
              options={tobaccoUseOptions}
              optionLabel="display"
              optionValue="id"
              id="SmokeTobacco"
              name="SmokeTobacco"
              value={formState.SmokeTobacco}
              onChange={handleDropdownChange}
            />
          </div>
          <div className="d-flex gap-2 mb-4">
            <EditableDropdown
              label="Drinks Alcohol"
              errors={errors["drinkAlcohol"]}
              options={alcoholUseOptions}
              optionLabel="display"
              optionValue="id"
              id="DrinkAlcohol"
              name="DrinkAlcohol"
              value={formState.DrinkAlcohol}
              onChange={handleDropdownChange}
            />
            <EditableDropdown
              label="Smokes Weed"
              errors={errors["smokeWeed"]}
              options={drugUseOptions}
              optionLabel="display"
              optionValue="id"
              id="SmokeWeed"
              name="SmokeWeed"
              value={formState.SmokeWeed}
              onChange={handleDropdownChange}
            />
          </div>
          <div className="mb-4">
            <EditableField
              id="OccupationName"
              name="OccupationName"
              value={formState.OccupationName}
              onChange={handleChange}
              label={'Occupation Name'}
              errors={errors["occupationName"]}
            />
          </div>
          <div className="mb-4">
            <EditableArea
              maxLength={2000}
              id="inputText"
              name="inputText"
              value={formState.inputText}
              onChange={handleChange}
              label={'About Me'}
              errors={errors["inputText"]}
            />
          </div>
          <hr/>
          <h3 className="mb-4">A little more helps your chances!</h3>
          <div className="mb-4">
            <HeightInput
              errors={errors["measurement"]}
              initialValue={Number(formState.measurement) || 0}
              onChange={(e) => {
                  setFormState({
                  ...formState,
                  measurement: String(e)
                })
              }}
            />
          </div>
          <div className="d-flex gap-2 mb-4">
            <EditableDropdown
              label="Religious Belief"
              errors={errors["religiousBelief"]}
              id="ReligiousBelief"
              name="ReligiousBelief"
              options={religionData}
              optionLabel="display"
              optionValue="id"
              value={formState.ReligiousBelief}
              onChange={handleDropdownChange}
            />
            <EditableDropdown
              label="Political Belief"
              errors={errors["politicalBelief"]}
              id="PoliticalBelief"
              name="PoliticalBelief"
              options={politicalBeliefData}
              optionLabel="display"
              optionValue="id"
              value={formState.PoliticalBelief}
              onChange={handleDropdownChange}
            />

          </div>
          <div className="d-flex gap-2 mb-4">
          <EditableDropdown
              label="Highest Education Level"
              errors={errors["highestLevel"]}
              id="HighestLevel"
              name="HighestLevel"
              options={EducationOptions}
              optionLabel="display"
              optionValue="id"
              value={formState.HighestLevel}
              onChange={handleDropdownChange}
            />
            <EditableDropdown
              label="Body Type"
              errors={errors["bodyType"]}
              id="bodyType"
              name="bodyType"
              options={bodyTypeData}
              optionLabel="display"
              optionValue="id"
              value={formState.bodyType}
              onChange={handleDropdownChange}
            />
          </div>
        </div>
        <div className="flex justify-content-center h-100 p-2 mt-5" style={{ 'minWidth':'400px' }}>
          <div className="mb-2">
            {location
              ? <>Location: {location.country}</>
              :
              <EditableDropdown
                label="Location"
                errors={errors["country"]}
                id="Country"
                name="Country"
                value={formState.country}
                onChange={async (e) =>{
                  const location = await geocodeCountry(geocoder,e.value)
                  setFormState({
                    ...formState,
                    ...location,
                  })
                }}
                options={countryData}
                optionLabel="display"
                optionValue="display"
                className="w-full md:w-14rem"
                placeholder="Select your Country"
              />
            }
          </div>
          <div className="">
            <span>Verify your profile:</span>
            {
              !user?.userVerifyPhoto
                ? <Button type="button" className="btn btn-link btn-sm" label="Get Verified" onClick={() => setVisible(true)} />
                :  (user?.userVerifyPhoto?.approved_by
                      ? <span className="text-success mx-2">Approved</span>
                      : <span className="text-warning mx-2">Pending Approval</span>
                    )
            }
          </div>
          <div className="">
            <span>Q&A:</span>
            <Button type="button" className="btn btn-link btn-sm" label="Sure, I'll answer questions" onClick={() => setQaVisible(true)} />
          </div>
          <div className="">
            <span>Interest:</span>
            <Button type="button" className="btn btn-link btn-sm" label="What interest you?" onClick={() => setinterestVisible(true)} />
          </div>
          <Dialog  header="User Interest" visible={interestVisible} maximizable style={{width:'98%',maxWidth:'700px'}} onHide={async () => {await fetchUser();setinterestVisible(false)}}>
            <InterestInput
                userId={authStore.userID}
                initalValue={user &&  user.userInterest ? user.userInterest.map(x=> x.interestName) : []}
                onClose={async () => {
                  setinterestVisible(false)
                  await fetchUser()
                }}/>
          </Dialog>

          <Dialog header="Q&A" visible={qaVisible} maximizable style={{width:'98%',maxWidth:'700px'}} onHide={async () => {setQaVisible(false)}}>
            <div className="m-2" >
              <QuestionsInput userId={authStore.userID} onClose={() => setQaVisible(false)}/>
            </div>
          </Dialog>

          <Dialog header="Verify Your Profile" visible={visible} maximizable style={{width:'98%',maxWidth:'700px'}} onHide={() => setVisible(false)}>
            <ProfileVerify
              onClose={() => { setVisible(false); fetchUser()}}
            />
          </Dialog>

          {user && <span>
           {!!errors &&
            <small id={'photos-error'} className="text-danger">
                {errors['photos']}
            </small>}
              <div className="d-flex justify-content-center">
                <PhotoUpload
                  images={user.userPhotos!}
                  userId={''}
                  onUpload={async () => await fetchUser()}
                  onRemove={async (url:string) => await deletePhoto(url,async () => await fetchUser(),() => {})}
                />
              </div>
          </span>
          }

          {/* TODO: why, oh why do we need `overflow:visible` */}
          <Button className="btn btn-primary d-flex mx-auto my-2" style={{ 'overflow':'visible' }} type="submit" onClick={(e) => handleSubmit(e)}>Dive in (Save your profile)</Button>
          <br/>
        </div>
      </div>
    </form>
  )
}

export default AddEditForm
