import React, { useEffect } from 'react'
import { FACEBOOK_APP_ID } from "@env";
import { Button } from 'primereact/button';
import useScript from '../../_core/hooks/useScript';

interface Props {
  isCreateForm?: boolean
  onSuccess: (ssoID: string, name: string, email:string) => void,
}
const FacebookSingleSignOnButton: React.FC<Props> = ({isCreateForm, onSuccess }) => {
  useScript("https://connect.facebook.net/en_US/sdk.js")

  useEffect(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: FACEBOOK_APP_ID,
        xfbml: true,
        version: 'v20.0'
      });
    }
  }, [])

  const logIntoFacebook = () => {
    FB.login((response: any) => {
        if (response.authResponse) {
            FB.api('/me?fields=email,name', function (response: any) {
              onSuccess(response.id, response.name, response.email)
            });
        } else {
            console.error(new Error('User cancelled login or did not fully authorize.'));
        }
    }, {
        scope: 'public_profile,email'
    });
}

  return (
    <>
      <div id="fb-root"></div>
      <Button className="sso-facebook sso-button w-100 rounded-2" type="button" icon="pi pi-facebook" onClick={logIntoFacebook}>
        Sign in with Facebook
      </Button>
    </>
  )
}


export default FacebookSingleSignOnButton;