import { _postV1 } from './config/index'

export async function likeUser(likedUserId: string) {
  try {
    const response = await _postV1(`/likeUser/${likedUserId}`, null)
    return response
  } catch (error: any) {
    return {
      error: true,
      message: error
    }
  }
}

export async function dislikeUser(dislikedUserId: string) {
  try {
    const response = await _postV1(`/dislikeUser/${dislikedUserId}`, null)
    return response
  } catch (error: any) {
    return {
      error: true,
      message: error
    }
  }
}