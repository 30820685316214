import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Galleria } from 'primereact/galleria'
import { UserPhoto,UserVideoInterview } from '../../../../../../../common/types/user'

interface PhotoGalleryProps {
  children?: React.ReactNode
  imgInnerInformation?: React.ReactNode
  photos: UserPhoto[]
  videos: UserVideoInterview[] | null
}

const interleave = <a,b>(xs:a[],ys:b[],which:'x' | 'y'='x') : (a | b)[] => {
  switch(which)
  {
    case 'x':
      return (
        xs.length > 0
        ? ([xs[0]] as (a | b)[]).concat(interleave(xs.slice(1),ys,'y'))
        : ys.length > 0
        ? interleave(xs,ys,'y')
        : []
      )
    case 'y':
      return (
        ys.length > 0
        ? ([ys[0]] as (a | b)[]).concat(interleave(xs,ys.slice(1),'y'))
        : xs.length > 0
        ? interleave(xs,ys,'x')
        : []
      )
  }
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ children,imgInnerInformation,...props }) => {
  const navigate = useNavigate()
  const items = interleave(
    props.photos.sort((a,b) => a.sort - b.sort).map((x) => ({ 'url':x.imgUrl,'sort':x.sort,'isVideo':false })),
    props.videos === null
    ? [{ 'url':'','sort':0,'isVideo':null as boolean | null }]
    : props.videos.sort((a,b) => a.sort - b.sort).map((x) => ({ 'url':x.url,'sort':x.sort,'isVideo':true }))
  )
  const profileRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const responsiveOptions = [
    { breakpoint: '991px', numVisible: 4 },
    { breakpoint: '767px', numVisible: 3 },
    { breakpoint: '575px', numVisible: 1 },
  ]

  const next = () => {
    setActiveIndex(prevState => (prevState === items.length - 1) ? 0 : prevState + 1)
  }

  const prev = () => {
    setActiveIndex((prevState) => (prevState === 0 ? items.length - 1 : prevState - 1));
  };

  const itemTemplate = (item:{ url:string,imageAlt:string,isVideo:boolean | null }) => {
    return (
      <div className="image-container">
        {item.isVideo === null
          ? <div className="d-flex justify-content-center align-items-center" style={{ 'minHeight':'500px' }}>
              <h6 className="text-center">
                It wouldn't be playing fair to show you someone's
                <br/>
                videos when you don't have any yourself...
                <br/>
                <button className="btn btn-primary" onClick={() => navigate('/profile/video-interviews')}>Upload your own to become a VIP!</button>
              </h6>
            </div>
          : item.isVideo
          ? <video className="user-video" src={item.url} controls autoPlay></video>
          : <img className="user-image" src={item.url} alt={item.imageAlt}/>
        }
        <ImageCount index={items.findIndex((photo) => photo.url === item.url)} />
        {/* <UserInformation />*/}
        <span className='left-tap position-absolute top-50 start-0 translate-middle-y' onClick={prev}>
          <i className="pi pi-chevron-left position-absolute top-50 start-0 translate-middle-y fs-1 bg-secondary-light rounded-5 p-2 mx-2 opacity-50 text-primary"></i>
        </span>
        <span className='right-tap position-absolute top-50 end-0 translate-middle-y' onClick={next}>
          <i className="pi pi-chevron-right position-absolute top-50 end-0 translate-middle-y fs-1 bg-secondary-light rounded-5 p-2 mx-2 opacity-50 text-primary"></i>
        </span>
      </div>
    )
  }

  const ImageThumbnailTemplate = (item: { thumbUrl: string; alt: string }) => {
    return (
      <img
        src={item.thumbUrl}
        alt={item.alt}
        style={{
          width: '150px',
          height: '100px',
          objectFit: 'scale-down',
        }}
      />
    )
  }

  const UserInformation = () => {
    return (
      <div ref={profileRef} className="w-100 position-absolute bottom-0 start-50 translate-middle-x ">
        {/* <FadingDivComponent> */}
        {imgInnerInformation}
        {/* </FadingDivComponent> */}
      </div>
    )
  }

  const ImageCount = ({ index }: { index: number }) => {
    return (
      <div className="w-100 fs-4 fw-bold text-white">
        <h6 className="px-2 bg-gray position-absolute top-0 start-50 translate-middle-x rounded">
          {index + 1} of {items.length}
        </h6>
      </div>
    )
  }

  return (
    <div className="photo-wrapper w-100 rounded-25 rounded-sm-0 overflow-hidden">
      <Galleria
        activeIndex={activeIndex}
        value={items}
        responsiveOptions={responsiveOptions}
        numVisible={2}
        item={itemTemplate}
        onItemChange={(e) => setActiveIndex(e.index)}
        showThumbnails={false}
        // thumbnail={ImageThumbnailTemplate}
        // thumbnailsPosition="bottom"
      >
        <div className="sticky-bottom">{imgInnerInformation}</div>
      </Galleria>
      {children}
    </div>
  )
}

export default PhotoGallery
