import { _getV1, _postV1 } from './config/index'
import { UserVerifyPhoto } from 'types/user'

export async function getUserVerifyPhotos(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1('/admin/getUserVerifyPhotos', {})
    onSuccess && onSuccess(response.data)
    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function getVerifyPhoto(verifyPhotoId: string,onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/getVerifyPhoto/${verifyPhotoId}`, {})
    onSuccess && onSuccess(response.data)
    return response.data
  } catch (error: any) {
    throw error
  }
}
export async function getApprovalPhotos(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1('/admin/getApprovalPhotos', {})
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function approvePhoto(imgUrl: String, userId: any, onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/approvePhoto/${userId}`, { imgUrl: imgUrl })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function approveVerifyPhotos(userId: String, imgUrl: String, onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/approveVerifyPhoto/${userId}`, { imgUrl: imgUrl })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function denyVerifyPhotos(userId: String, imgUrl: String, onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/denyVerifyPhoto/${userId}`, { imgUrl: imgUrl })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}
// _____________________________________________ NEWER APPROVAL PROCESS ____________________________________________

export async function approvePhotos(imgUrls: String[], userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/approvePhotos`, { imgUrls: imgUrls, userIds:userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}
export async function denyPhotos(imgUrls: String[], userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/denyPhotos`, { imgUrls: imgUrls, userIds:userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function ApproveUserProfile(userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/ApproveUserProfiles`, { userIds: userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function denyUserProfile(userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/denyUserProfiles`, { userIds: userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function banUser(userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/banUserProfiles`, { userIds: userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function unbanUser(userIds: String[], onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1(`/admin/unbanUserProfiles`, { userIds: userIds })
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}

export async function getPendingVideoInterviews(onSuccess?: (res: any) => void, onFail?: (e: any) => void): Promise<any> {
  try {
    const response = await _postV1('/admin/getPendingVideoInterviews', {})
    onSuccess && onSuccess(response)
    return response
  } catch (error: any) {
    onFail && onFail(error)
    return error
  }
}
