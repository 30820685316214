import React, { useEffect, useState } from 'react'
import { EnumUserInterestsData, EnumUserQuestionsData } from 'types/enums.options'
import { getUserInterestsEnum, getUserQuestionsEnum } from '_services/enums.service'
import { useFetch } from '../../../../_core/hooks'
import { Dropdown } from 'primereact/dropdown'
import { getUserQuestions, updateProfile } from '_services/users.service'
import { UserQuestionsResponses } from 'types/user'
import { FormType, IncomingFormPayload } from 'types/payloads.users'
import { Button } from 'primereact/button';
import WrapperCardNew from 'src/components/cards/WrapperCardNew'
import { ToggleButton } from 'primereact/togglebutton'
import { groupBy } from 'util/helpers.converters'
import { Card } from 'primereact/card'

type InterestInputProps = {
  userId: string
  initalValue: string[]
  onClose: () => void
}

const InterestInput: React.FC<InterestInputProps> = (props) => {
  const [interest, setUserInterest] = useState<Record<string, EnumUserInterestsData[]>>({})
  const [answers, setAnswers] = useState<string[]>(props.initalValue)

  const handleToggle = (e: any) => {
    setAnswers(prev => {
      const value = e.target.name
      return prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    })
  }

  useEffect(
    () => {
      updateProfile({
        FormType: FormType.Interests,
        Data: {interests:answers}
      }
    )
    },[answers]
  )

  useFetch(
    () => Promise.all([
      getUserInterestsEnum(),
    ]),
    (x) => {
      if (x != undefined) {
        const [resInterst] = x
        setUserInterest(groupBy<EnumUserInterestsData, "category">(resInterst.data, "category"))
      }
    },
    []
  )

  return (
    <div className="">
      <div className="justify-content-center h-100 overflow-auto mb-4">
        <p>Select up to 20 interest total, Select up to 5 from any category</p>
        {Object.keys(interest).map((x) =>
          <Card key={x} className="mb-1 justify-content-center text-center">
            <h3>{x}</h3>
            <p> {interest[x].filter(y => answers.includes(y.name)).length}/5 </p>
            {interest[x].map(y =>
              <ToggleButton
                id={y.name}
                disabled={interest[x].filter(z => answers.includes(z.name)).length >= 5 && !answers.includes(y.name)}
                name={y.name}
                key={y.name}
                onChange={handleToggle}
                checked={answers.includes(y.name)}
                offLabel={y.display}
                onLabel={y.display}
                value={y.name}
                className="m-1"
              />
            )}
          </Card>
        )}
      </div>
      <Button className="btn btn-primary w-100" type="button" onClick={() => props.onClose()}>Save</Button>
    </div>
  )
}

export default InterestInput
