import { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { HubConnectionState } from '@microsoft/signalr'
import { authStore } from '../stores/authStore'

let _connection : Promise<signalR.HubConnection>

const connect = async () : Promise<signalR.HubConnection> => {
  const temp = new signalR.HubConnectionBuilder().withUrl(`/hub?userid=${authStore.userID}`).build()
  try
  {
    await temp.start()
    await temp.invoke('SetJwt',localStorage.getItem('userToken'))
  }
  catch(e)
  {
    console.error('useSignalR.connect', e)
  }
  finally
  {
    return temp
  }
}

export default (methods:[string, (_:any) => void][]) : ((_:string, ...args:any[]) => Promise<any>) => {
  const connection = _connection || (_connection=connect())
  useEffect(() => {
    const unbind = async () => {
      const c = await connection
      if(c.state === HubConnectionState.Connected)
      {
        methods.forEach(([method, f]) =>
          c.off(method, f)
        )
      }
    }
    const bind = async () => {
      const c = await connection
      await unbind()
      if(c.state === HubConnectionState.Connected)
      {
        methods.forEach(([method, f]) =>
          c.on(method, f)
        )
      }
    }
    bind()
    return () => { unbind() }
  }, [])
  return async (method:string, ...args:any[]) => {
    const c = await connection
    if(c.state === HubConnectionState.Connected)
    {
      return c.invoke(method, ...args)
    }
  }
}
