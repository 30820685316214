import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Menu } from 'primereact/menu'
import { MenuItem } from 'primereact/menuitem'
import { Toast } from 'primereact/toast'
import VideoInterview from './interview'
import { useFetchV2 } from '../../../../../../_core/hooks'
import { _getV1, _postV1 } from '_services/config'
import Skeleton from '../../../../../../components/skeleton'
import blankImage from '../../../../../../assets/images/blank-film-strip-gray.svg'

type VideoInterview = {
  id:string
  user_id:string
  question_id:string
  question:string
  sort:number
  url:string
  approved:string | null
}

type Props = {

}

const styleGrid = {
  'display':'grid',
  'gridTemplateColumns':'200px 200px',
  'rowGap':'.5rem',
  'columnGap':'.5rem',
}

const constMaxInterview = 5

const deleteVideo = async (x:VideoInterview) : Promise<unknown> => (await _postV1(`/video-interviews/${x.id}/delete`,{})).data

export const VideoInterviews : React.FC<Props> = (_) => {
  const [stateDialog,setDialog] = useState<VideoInterview | null | undefined>(undefined)
  const [statePreview,setPreview] = useState<string | null>(null)
  const [interviews,reload] = useFetchV2<VideoInterview[]>(async () => {
    return (await _getV1('/video-interviews')).data
  })
  const toast = useRef<Toast>(null)
  const popMenus = [
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
    useRef<Menu>(null),
  ]
  const items : (_:VideoInterview) => MenuItem[] = (x) => [
    {
      'label':'Options',
      'items':[
        {
          'label':'Replace',
          'icon':'pi pi-sync',
          'command':() => setDialog(x),
        },
        {
          'label':'Delete',
          'icon':'pi pi-trash',
          'command':async () => {
            await deleteVideo(x)
            reload()
          },
        },
      ]
    }
  ]
  return <>
    {!statePreview ? undefined :
    <div
      className="d-flex position-fixed top-0 bottom-0 start-0 end-0"
      style={{ 'backgroundColor':'black','alignItems':'center','justifyContent':'center','zIndex':100 }}
      onClick={() => setPreview(null)}
    >
      <video
        src={statePreview}
        controls
        autoPlay
      ></video>
    </div>
    }
    <p className="fs-6 text-center">Add / Remove your Video Interviews(s)!</p>
    <Skeleton
      dependency={interviews}
      structure={{
        'style':styleGrid,
        'children':new Array(4).fill({ 'width':'200px','height':'265px' }),
      }}
    >
      {(interviews) => <>
        <div style={styleGrid}>
          {(interviews || []).concat(new Array(constMaxInterview - interviews.length).fill(null)).map((x:VideoInterview | null,i) =>
            <div key={x?.id || i} className="position-relative">
              {x
                ? <video src={x.url} onClick={() => setPreview(x.url)} className="rounded-4 w-100 d-block"></video>
                : <img src={blankImage} className="w-100"/>
              }
              <span className="position-absolute bottom-0 end-0">
                <Toast ref={toast}></Toast>
                {x ? <Menu model={items(x)} popup popupAlignment='left' ref={popMenus[i]}/> : undefined}
                <Button
                  type="button"
                  className="rounded-circle fw-2"
                  icon={x ? 'pi pi-ellipsis-v' : 'pi pi-plus'}
                  onClick={(event) => x ? popMenus[i].current?.toggle(event) : setDialog(null)}
                />
              </span>
            </div>
          )}
        </div>
      </>}
    </Skeleton>
    <Dialog className="flex text-center overflow-hidden" visible={stateDialog !== undefined} onHide={() => { setDialog(undefined) }} header="Video Interview">
      <VideoInterview replace={stateDialog} onUpload={async () => { setDialog(undefined);reload() }}/>
    </Dialog>
  </>
}

export default VideoInterviews
