import { Params } from 'react-router-dom'
import BlogPage from '../../pages/public/blogPage'
import FAQPage from '../../pages/public/faqPage'
import PublicHome from '../../pages/public/PublicHome'
import DiscoverLayout from '../../pages/auth/_layout/discoverLayout'
import PaymentNotifications from '../../pages/auth/payment/components/PaymentNotifications'
import profileScreen from '../../pages/auth/screens/profileScreen'
import upgradeScreen from '../../pages/auth/payment/upgradeScreen'
import CommunityGuidelines from '../../pages/public/legal/CommunityGuidelines'
import TermsAndConditions from '../../pages/public/legal/TermsAndCondition'
import PrivacyPolicy from '../../pages/public/legal/PrivacyPolicy'
import { authStore } from '../stores/authStore'
import { permissions } from 'enums/PermissionEnums'
import { getBlog, getStory } from '_services/blogs.service'
import StoryPost from '../../pages/public/_components/storyPost'
import SuccessStoryPage from '../../pages/public/successStoryPage'
import  AddEditForm from '../../pages/auth/_forms/add_edit/add_edit'
import VerifyPhoto from '../../pages/auth/screens/VerifyPhoto'
import PhotoApproval from '../../pages/auth/screens/PhotoApproval'
import UserApproval from '../../pages/auth/screens/userApproval/userApproval'
import { Interactions } from '../contexts/interactions'
import FrequentDenialReasons from '../../pages/public/fdrPage'

export const publicRoutes = [
  { path: '', component: PublicHome },
  // { path    : "/createUser",   component: UserSignup },

  { path: '/blog', component: BlogPage },
  { path: '/stories', component: SuccessStoryPage },
  { path: '/faq', component: FAQPage },
  { path: '/frequent-denial-reasons', component: FrequentDenialReasons },
  { path: '/community-guidelines', component: CommunityGuidelines },
  { path: '/terms-and-conditions', component: TermsAndConditions },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/blogPost/:id', component: StoryPost, props:{fetchStory:getBlog, renderStoryPhoto:true} },
  { path: '/successStory/:id', component: StoryPost, props:{fetchStory:getStory, renderStoryPhoto:false} },
]

export const webNav = () => [
  {
    'path':'discover',
    'title':'Discover',
  },
  {
    'path':'upgrade',
    'title':'Upgrade',
  },
].concat(authStore.hasPermission(permissions.PhotoApproval) ? [
  {
    'path':'photo-verify',
    'title':'Photo Verification',
  }
] : []).concat(authStore.hasPermission(permissions.PhotoApproval) ? [
  {
    'path':'photo-approval',
    'title':'Photo Approval',
  }
] : []).concat(authStore.hasPermission(permissions.PhotoApproval) ? [
  {
    'path':'user-approval',
    'title':'User Approval',
  }
] : [])

type MobileNavItem = {
  'path':string
  'title':string
  'icon':string
  'badge':(_:Interactions) => Promise<number | false>
}

export const mobileNav : () => MobileNavItem[] = () => [
  {
    'path':'discover',
    'title':'Discover',
    'icon':'pi pi-search',
    'badge':async (_) => false,
  },
  {
    'path':'discover/likes',
    'title':'Likes Me',
    'icon':'pi pi-heart',
    'badge':async (x) => (await x.likes).filter((x) => x.is_new).length,
  },
  {
    'path':'discover/messages',
    'title':'Chat',
    'icon':'pi pi-comments',
    'badge':async (x) => (await x.conversations).filter((x) => !!x.unread).length + (await x.matches).filter((x) => x.is_new).length,
  },
  {
    'path':'upgrade',
    'title':'Upgrade',
    'icon':'pi pi-sparkles',
    'badge':async (_) => false,
  },
  {
    'path':'profile',
    'title':'Profile',
    'icon':'pi pi-user',
    'badge':async (_) => false,
  },
]

export const authRoutes = [
  {
    path: 'discover',
    component: DiscoverLayout,
    renderNav: true,
    replaced:(_:Readonly<Params<string>>) => `discover`,
  },
  {
    path: 'discover/:kind',
    component: DiscoverLayout,
    renderNav: true,
    replaced:(params:Readonly<Params<string>>) => `discover/${params.kind}`,
  },
  {
    path: 'discover/:kind/:userId',
    component: DiscoverLayout,
    renderNav: true,
    replaced:(params:Readonly<Params<string>>) => `discover/${params.kind}/${params.userId}`,
  },
  {
    path: 'user-approval',
    component: UserApproval,
    renderNav: true,
  },
  {
    path: 'profile',
    component: profileScreen,
    renderNav: true,
  },
  {
    path: 'profile/:action',
    component: profileScreen,
    renderNav: true,
    replaced:(params:Readonly<Params<string>>) => `profile/${params.action}`,
  },
  {
    path: 'upgrade',
    component: upgradeScreen,
    renderNav: true,
  },
  {
    path: 'success',
    component: PaymentNotifications,
    renderNav: true,
  },
  {
    path: 'onboarding',
    component: AddEditForm,
    renderNav: false,
  },
]
