import React from 'react'
import { EnumProductsData } from 'types/enums.options'
import ProductBanner from './productBanner'

export interface ProductCardProps {
  enumProduct: EnumProductsData
  isActive: boolean
  onClick: () => void
}

const ProductCard = (props: ProductCardProps) => {
  const cardClass = props.isActive
    // TODO: mouse-pointer utility removed
    ? 'col card mx-1 floating-card activeCard border-2 border-primary-subtle p-4 bg-info-subtle mouse-pointer'
    : 'd-flex justify-content-center floating-card w-100 p-3 card pe-auto icon-link mouse-pointer'

  return (
    <div className={cardClass} onClick={props.onClick}>
      {props.enumProduct.mostPopular && <ProductBanner title="Most Popular" />}
      {props.enumProduct.bestOffer && <ProductBanner title="Best Offer" />}
      <p className="text-center text-black fw-bolder fs-4 m-3"> {props.enumProduct.name} </p>
      <p className="text-center text-black fw-bold fs-4"> ${(props.enumProduct.price / 100).toFixed(2)} </p>
      {props.enumProduct.description && <h6 className="text-center "> {props.enumProduct.description} </h6>}
    </div>
  )
}

export default ProductCard
