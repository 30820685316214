import React, { useEffect, useState } from 'react'
import { FloatLabel } from 'primereact/floatlabel'
import { Dropdown, DropdownProps } from 'primereact/dropdown'

export type EditableDropdownProps = DropdownProps & {
  label: string
  errors: string | undefined
}

export const EditableDropdown: React.FC<EditableDropdownProps> = (props) => {
  return (
    <div className="w-50">
      <FloatLabel>
        <Dropdown
          {...props}
        />
        <label htmlFor="Sex">{props.label}</label>
      </FloatLabel>
        {!!props.errors &&
          <small id={props.id + '-error'} className="text-danger">
            {props.errors}
        </small>}
    </div>
  )
}
