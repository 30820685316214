import React, { ChangeEvent, useEffect, useState } from 'react'
import { InputText, InputTextProps } from 'primereact/inputtext'
import { FloatLabel } from 'primereact/floatlabel'
import { useUpdateEffect } from 'primereact/hooks'

export type EditableFieldProps = InputTextProps & {
  label: string
  errors: string | undefined
}

export const EditableField: React.FC<EditableFieldProps> = (props) => {
  return (
    <div className="flex-grow-1">
      <FloatLabel>
        <label htmlFor={props.id}>{props.label}</label>
        <InputText
          {...props}
        />
      </FloatLabel>
      {!!props.errors &&
        <small id={props.id + '-error'} className="text-danger">
          {props.errors}
      </small>}
    </div>
  )
}
