import React, { useEffect, useState } from 'react'
import { approvePhotos, ApproveUserProfile, approveVerifyPhotos, banUser, denyPhotos, denyUserProfile, denyVerifyPhotos, getApprovalPhotos, getPendingVideoInterviews, getUserVerifyPhotos, getVerifyPhotos, unbanUser } from '../../../../../../common/_services/admin.service'
import ReviewPhoto from '../../_components/reviewPhoto'
import { User, UserPhoto, UserVerifyPhoto, UserVideoInterview } from '../../../../../../common/types/user'
import NoData from '../../../../components/noData'
import { useFetch } from '../../../../_core/hooks'
import ProfileBox from '../../_components/profile/profileBox'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from 'primereact/datatable'
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent, ColumnProps, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { getUsers } from '_services/users.service'
import { Image } from 'primereact/image'
import { Button } from 'primereact/button'
import { Chip } from 'primereact/chip'
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Tag } from 'primereact/tag'
import SidebarWrapper from '../../_components/profile/components/SidebarWrapper'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { TabPanel, TabView } from 'primereact/tabview'
import PhotoReview from './photoReview'
import VerifyReview from './userVerifyReview'
import VideoInterviewReview from './videoInterviewReview'

export type PhotoWithName = (UserPhoto | UserVerifyPhoto & {firstName:string, lastName:string})

interface status {
  display:string,
  severity:"success" | "danger" | "info" | "warning" | null | undefined
}


const UserApproval: React.FC = () => {
  const [users, setUser] = useState<User[]>([])
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined)
  const [reviewMode, setReviewMode] = useState<string>('user')
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedPhotos, setSelectedPhotos] = useState<UserPhoto[]>([])
  const [viewUser, setViewUser] = useState<string>('')
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [verifyPhotos, setVerifyPhotos] = useState<UserVerifyPhoto[]>([])
  const [videoInterviews, setVideoInterviews] = useState<UserVideoInterview[]>([])

  const [statuses] = useState<status[]>([
    {display:'User Approved', severity: 'success'},
    {display:'Pending User Review', severity: 'danger'},
    {display:'Pending Photo Approval', severity: 'danger'},
    {display:'Photos Approved', severity: 'success'},
    {display:'Verified', severity: 'success'},
    {display:'Pending Verify', severity: 'danger'},
    {display:'Premium', severity: 'info'},
    {display:'Banned', severity: 'danger'},
    {display:'Denied User Approval', severity: 'danger'},
  ])

// The rule argument should be a string in the format "custom_[field]".
  FilterService.register('custom_id', (value, filters) => {
    if(filters.length == 0) return true
    let user = users.find(x => x.id == value)
    return user ?
    (
      (filters.includes('User Approved') ? !!user.approvedAt : true) &&
      (filters.includes('Pending User Review') ? !user.approvedAt : true) &&
      (filters.includes('Pending Photo Approval') ? user.userPhotos.some(y => !y.approvedAt) : true) &&
      (filters.includes('Photos Approved') ? user.userPhotos.length > 0 && user.userPhotos.every(y => !!y.approvedAt) : true) &&
      (filters.includes('Verified') ? !!user.userVerifyPhoto?.approved_by : true) &&
      (filters.includes('Pending Verify') ? !user.userVerifyPhoto?.approved_by : true) &&
      (filters.includes('Premium') ? (user.purchases && user.purchases.length > 0) : true) &&
      (filters.includes('Banned') ? user.isBanned : true) &&
      (filters.includes('Denied User Approval') ? !!user.deniedAt : true)
    )
    : false
  })

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'userMetadata.occupationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'userMetadata.aboutMe': { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: ['Pending User Review'], matchMode: FilterMatchMode.CUSTOM  },
  })

const multiFilterOption = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <MultiSelect
      id="status"
      name="status"
      display="chip"
      value={options.value}
      options={statuses}
      onChange={(e: MultiSelectChangeEvent) =>options.filterApplyCallback(e.value)}
      optionLabel="display"
      optionValue="display"
      placeholder="Any"
      className="p-column-filter"
      maxSelectedLabels={1}
      // itemTemplate={(x: status) => <Tag value={x.display} severity={x.severity}/>}

      style={{ minWidth: '10rem' }}
    />
  )
}

  const columns: ColumnProps[] = [
    {
      field: '',
      header: 'Profile Photo',

      body: (data: User,opt) =>
        <>
          <Image onClick={() => {setViewUser(data.id)}} src={data.userPhotos && data.userPhotos.length > 0 ? data.userPhotos[0].imgUrl : ''} alt="Image" width="64px" className="shadow-4" />
        </>
    },
      {
        field: 'firstName',
        header: 'Name',
        filter: true,
        filterPlaceholder: "Name search",
        body: (data: User,opt) => <>{data.firstName} {data.lastName}</>
      },
      {
        field: 'phone',
        header: 'Phone #',
        filter: true,
        filterPlaceholder: "Phone search",
      },
      {
        field: 'userMetadata.occupationName',
        header: 'Occupation',
        filter: true,
        filterPlaceholder: "Occupation search",
      },
      {
        field: 'userMetadata.aboutMe',
        header: 'About Me',
        style: { width: '15rem' },
        filter: true,
        filterPlaceholder: "About me search",
      },
      {
        field: 'id',
        header: 'Status',
        filter: true,
        style: { width: '1rem' },

        filterPlaceholder: "Status search",
        filterMatchMode:"custom_status",
        filterElement: multiFilterOption,
        // TOOO refactor
        body: (data: User,opt) => <>
          {data.userVerifyPhoto && data.userVerifyPhoto.approved_by ?
            <div><Tag value="Verified" severity="success" className="m-1"/> </div> : <div> <Tag  value="Pending Verify" severity="warning" className="m-1"/> </div>}
          {data.userPhotos && data.userPhotos.some(y => !y.approvedAt) ?
             <div><Tag value="Pending Photo Approval" severity="warning" className="m-1"/></div> :  <div><Tag  value="Photos Approved" severity="success" className="m-1" /></div>}
          {data && !!data.approvedAt ?
            <div> <Tag value="User Approved" severity="success" className="m-1"/> </div>:
             data.deniedAt
              ? <div><Tag value="Denied User Approval" severity="danger" className="m-1"/></div>
              : <div><Tag  value="Pending User Review" severity="warning" className="m-1"/></div>
          }
          {(data && data.purchases && data.purchases.length > 0) &&
            <div><Tag value="Premium" severity="info" className="m-1"/></div>}
          {data && data.isBanned &&
            <div><Tag value="Banned" severity="danger" className="m-1"/></div>}
          {}
        </>
      },
  ]

  useFetch(
    () => getUserVerifyPhotos(),
    (x) => {
      if(x){
        return setVerifyPhotos(x)
      }
    },
    []
  )

  useFetch(
    () => getPendingVideoInterviews((x)=>{setVideoInterviews(x.data)}),
    (x) => {
      if(x){
        console.log(x,"setting video interviews")
        return setVideoInterviews(x.data)
      }
    },
    []
  )

  const fetchUsers = async () => {
    getUserVerifyPhotos((x) => setVerifyPhotos(x))
    getUsers((x)=>{setUser(x.data)})
    getPendingVideoInterviews((x)=>{setVideoInterviews(x.data)})
  }

  const loadUsers = useFetch(
    () => getUsers((x)=>{setUser(x.data)}),
    (x) => {
      if (x){
        return setUser(x.data)
      }
    },
    []
  )

const onGlobalFilterChange = (e: { target: { value: any } }) => {
  const value = e.target.value
  let _filters = { ...filters }

  _filters['global'].value = value

  setFilters(_filters)
  setGlobalFilterValue(value)
}

const photoReviewTemplate = (x: PhotoWithName[]) => {
  return (
      <PhotoReview photos={x} onAction={fetchUsers}/>
  )
}
  return <div className="m-4 justify-content-center h-100">
      <SidebarWrapper
        position="right"
        className="w-full md:w-40rem lg:w-40rem"
        visible={!!viewUser}
        onHide={() => setViewUser('')}
        style={{width:'45%'}}
      >
      <ProfileBox
        userId={viewUser}
      />
      </SidebarWrapper>
      <TabView>
        <TabPanel header="User Approval">
          <>
            <DataTable
              stripedRows
              header={
                <div className="d-flex">
                  <div className="p-2 bd-highlight">
                    <h3>User Review</h3>
                  </div>
                  <div className="ms-auto p-2 bd-highligh">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </IconField>
                  </div>
                </div>
              }
              loading={loadUsers}
              filters={filters}
              globalFilterFields={['userMetadata.occupationName', 'firstName', 'lastName', 'userMetadata.aboutMe','phone']}
              filterDisplay="row"
              paginator
              rows={25}
              rowsPerPageOptions={[5, 10, 25, 50]}
              value={users}
              tableStyle={{ minWidth: '50rem' }}
              expandedRows={expandedRows}
              selection={selectedUsers}
              onSelectionChange={(e) => setSelectedUsers([e.value])}
              onRowToggle={(e) => {setExpandedRows(e.data)}}
              rowExpansionTemplate={(x, o) =>
                photoReviewTemplate(x.userPhotos ? x.userPhotos.filter(y => !y.approvedAt).map(z => ({...z, firstName: x.firstName, lastName: x.lastName}))
                .flat() : [])}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
              <Column expander={(x: User) => x.userPhotos.filter(y => !y.approvedAt).length > 0} style={{ width: '2rem' }} />
              {columns.map((x) => <Column
                key={x.field}
                {...x}
                />
              )}
              <Column field="id"
                style={{ width: '20rem' }}
                header="Action"
                body={(y: User, o) => <>
                  {y.isBanned && <Button label="Unban" className="btn btn-warning  mx-2" onClick={() => {
                    unbanUser([y.id],fetchUsers)
                  }}/>}
                  {!y.isBanned && <Button label="Ban" className="btn btn-danger  mx-2" onClick={() => {
                    banUser([y.id],fetchUsers)
                  }}/>}
                  {(!y.deniedAt) && <Button label="Deny" className="btn btn-secondary" onClick={() => {
                    denyUserProfile([y.id],fetchUsers)
                  }}/>}
                  {(!y.approvedAt) && <Button label="Approve" className="btn btn-primary mx-2"  onClick={() => {
                    ApproveUserProfile([y.id],fetchUsers)
                  }}/>}
                </>}
              ></Column>
            </DataTable>
          </>
        </TabPanel>
        <TabPanel header="Photo Review">
         <PhotoReview
            photos={users
              .map((x:User) => x.userPhotos.filter(y => !y.approvedAt).map(z => ({...z, firstName: x.firstName, lastName: x.lastName})))
              .flat()
            }
            onAction={fetchUsers}
          />
        </TabPanel>
        <TabPanel header="User Verify Review">
          <VerifyReview photos={verifyPhotos} onAction={fetchUsers}/>
        </TabPanel>
        <TabPanel header="Video Interview Review">
          <VideoInterviewReview videos={videoInterviews} onAction={fetchUsers}/>
        </TabPanel>
      </TabView>
   </div>
}

export default UserApproval
