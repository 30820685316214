import { STRIPE_P_KEY } from '@env'
import React, { useState, useEffect } from 'react'
import { createStripeCheckoutSession, getProductsSubscriptions, productTypes } from '_services/iap.service'
import { loadStripe } from '@stripe/stripe-js'
import { EnumProductsData } from 'types/enums.options'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import CheckoutTable from './components/checkoutTable'
import ProductCard from './components/ProductCard'
import UpgradeSkeleton from './components/upgradeSkeleton'
import { getCurrentUser } from '_services/users.service'
import { User } from 'types/user'
import { useFetch } from '../../../_core/hooks'
// import Loader from '../../../components/loader'

const stripePromise = loadStripe(STRIPE_P_KEY)
const UpgradeScreen: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [products, setProducts] = useState<EnumProductsData[]>([])
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadCheckout, setLoadCheckout] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [user, setUser] = useState<User | null>(null)

  const loadingOptions = useFetch(
    () => Promise.all([
      getCurrentUser(),
      getProductsSubscriptions(productTypes.Web),
    ]),
    (x) => {
      if (x != undefined) {
        const [user, products] = x
        setProducts(products.data)
        setUser(user.data)
      }
      return x
    },
    []
  )

  const startCheckoutSession = async (item: string) => {
    setLoading(true)
    await createStripeCheckoutSession(
      item,
      (response) => {
        setClientSecret(response)
      },
      (error) => {
        error
        alert('Failed to start checkout session')
      }
    )
    setLoading(false)
  }

  const selectProduct = (productId: string) => {
    if (selectedProduct !== productId) {
      setClientSecret(null)
      setSelectedProduct(productId)
    }
  }

  return (
    <div className="d-flex flex-column mobile-vertical-scroll h-100">
      {loadCheckout ? (
        <div className="h-100 d-md-flex bg-checkout">
          {selectedProduct && (
            <div className="flex justify-content-center">
              <CheckoutTable enumProduct={products.find((x) => x.id === selectedProduct) || products[0]} />
              <div>
                <p>
                  Before subscribing to our recurring payment plan, please ensure you review the{' '}
                  <a href="/terms-and-conditions">Terms</a> carefully.
                  Be mindful of renewal dates to avoid any unexpected transactions.
                  <br />
                  <q className="d-flex justify-content-center">
                    <b>Your satisfaction is our priority!</b>
                  </q>
                </p>
              </div>
            </div>
          )}
          {clientSecret && (
            <div id="checkout" className="vertical-scroll w-100">
              <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          )}
        </div>
      ) : (
        <div className="h-100">
          {loadingOptions ? (
            <UpgradeSkeleton />
          ) : (user && user.activePurchases && user.activePurchases.length == 0) || (user && user.activePurchases == null) ? (
            // <div className="d-flex justify-content-center align-items-center vw-100 bg-secondary-light">
            //   <Loader loading />
            // </div>
            <div className="bg-secondary-subtle rounded shadow d-flex flex-column justify-content-evenly h-100">
              <h3 className="text-center pt-3">
                <q className="text-center">Unlimited Likes, Matches, and Messaging</q>
                {/* <p className="text-center">Unlock longer message timers, Matching search results, Messaging</p> */}
              </h3>
              <div
                style={{ minHeight: '50%' }}
                className="d-flex flex-wrap flex-md-nowrap gap-3 p-4 mobile-vertical-scroll"
              >
                {products.map((item: EnumProductsData, index) => (
                  <ProductCard
                    key={index}
                    enumProduct={item}
                    isActive={item.id === selectedProduct}
                    onClick={() => {
                      selectProduct(item.id)
                      setActiveIndex(activeIndex === index ? null : index)
                      startCheckoutSession(item.id)
                      setLoadCheckout(true)
                    }}
                  />
                ))}
              </div>
            </div>
          ) :
            <div className="bg-secondary-subtle rounded shadow d-flex flex-column justify-content-evenly h-100">
              <h3 className="text-center pt-3">
                <q className="text-center">We want to thank you for already subscribing!</q>
                <p className="text-center">Manage your subscription inside your profile</p>
              </h3>
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default UpgradeScreen
